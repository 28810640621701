import { Link } from 'react-router-dom';

const MiscTaskView = ({ setActiveNav }: { setActiveNav: any }) => {
  return (
    <>
      <li className='nav-item'>
        <Link to='/vericlaim/new' className='nav-link' onClick={setActiveNav}>
          Schedule Claim
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/vericlaim/report' className='nav-link' onClick={setActiveNav}>
          View Claim Reports
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/fulfilments/partners/report' className='nav-link' onClick={setActiveNav}>
          HMO Report
        </Link>
      </li>
    </>
  );
};

export default MiscTaskView;
