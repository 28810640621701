import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { initialFulfilmentPartnersData } from '../../../interface/FulfilmentPartnersInterface';
import { IFulfilments } from '../../../interface/FulfilmentsInterface';
import { AllFulfilmentServiceMap, AllFulfilmentStatusMap } from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';
// modal imports

import { Link } from 'react-router-dom';
import { IPageInfo, defaultPageInfo } from '../../../interface/CommonInterface';
import { formatInputDate } from '../../../utils/dateTimeUtils';
import { modifyUrl } from '../../../utils/formUtils';
import { Tab, Tabs } from 'react-bootstrap';
import { fulfilmentTableTabs } from '../../../utils/mappings';
import FulfilmentsTableBody from './FulfilmentsTableBody';
import PaginationBar from '../../common/Pagination';
import { AppContext } from '../../../context/AppContext';
import { IAppState } from '../../../interface/AppInterface';
import PharmacyMapSidebar from './PharmacyMapSidebar';

const FulfilmentsTableTabs = () => {
  const initialParams = {
    partnerCode: '',
    fulfilmentStatus: 'All',
    partnerName: 'All',
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    fulfilmentService: 'All',
    assignedToMe: false,
  };

  const [partnersList, setPartnersList] = useState([initialFulfilmentPartnersData]);
  const [data, setData] = useState<IFulfilments[]>([]);
  const [allData, setAllData] = useState<IFulfilments[]>([]); //to hold all table result in unfiltered format

  const [pageInfo, setPageInfo] = useState<IPageInfo>(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [advSearch, setAdvSearch] = useState(false);

  const [filterParam, setFilterParam] = useState(initialParams);

  const [tabKey, setTabKey] = useState(fulfilmentTableTabs.all);

  const [dataUrl, setDataUrl] = useState(`${endpoints.FulfilmentRequests.mainUrl}?pageSize=200`);

  const { appState, dispatch }: { appState: IAppState; dispatch: any } = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [resultText, setResultText] = useState('');
  const [showSidemodal, setShowSideModal] = useState(false);

  /*############## API CALLS ################## */

  const getPartnersList = useCallback(async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;

    try {
      const response = await axios.get(url);

      //set to state
      setPartnersList(response.data);
      localStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (input === 'partnerCode') {
      const partnerName = partnersList.filter((x) => x.partnerCode === value)[0].partnerName;

      setFilterParam({
        ...filterParam,
        partnerName: partnerName,
        partnerCode: value,
      });
      return;
    }

    setFilterParam({
      ...filterParam,
      [input]: value,
    });
  };

  const getFulfilments = useCallback(
    async (pageIndex = 1) => {
      let url = `${dataUrl}&pageIndex=${pageIndex}`;
      try {
        const response = await axios.get(url);
        setData(response.data.data);

        setAllData(response.data.data);
        const responsePageInfo: IPageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
          callback: getFulfilments,
        };
        //set page info
        setPageInfo(responsePageInfo);
        setShowPagination(true);
      } catch (error) {
        addToast('There was error getting fulfilments', 'error');
      }
    },
    [dataUrl]
  );

  const downloadHandoverData = async (event: any) => {
    event.preventDefault();

    console.log(filterParam);
    const url = endpoints.FulfilmentExport.mainUrl;
    try {
      await axios.post(url, filterParam);
      addToast('Fulfilments sent to your email', 'success');
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
    }
  };

  /*########### End API Calls ######### */

  /*########### Table Functions ######### */

  // function to filter fulfilment data array based on fulfilment status

  const onSearchClick = async (event: any) => {
    await getFilteredFulfilments(1);
  };

  const getFilteredFulfilments = async (pageIndex: number) => {
    filterParam.fulfilmentStatus =
      filterParam.fulfilmentStatus === 'All' ? '' : filterParam.fulfilmentStatus;

    setIsSearch(true);

    let url = `${endpoints.FulfilmentRequests.mainUrl}/filter?partnerCode=${filterParam.partnerCode}&pageIndex=${pageIndex}&assigned=${filterParam.assignedToMe}`;

    if (filterParam.startDate !== '') {
      url += `&startDate=${filterParam.startDate}`;
    }
    if (filterParam.endDate !== '' && filterParam.endDate !== null) {
      url += `&endDate=${filterParam.endDate}`;
    }
    if (filterParam.fulfilmentStatus !== '' && filterParam.fulfilmentStatus !== 'All') {
      url += `&status=${filterParam.fulfilmentStatus}`;
    }
    if (filterParam.fulfilmentService !== '' && filterParam.fulfilmentService !== 'All') {
      url += `&service=${filterParam.fulfilmentService}`;
    }

    const qText = url.split(`${endpoints.FulfilmentRequests.mainUrl}/filter?`)[1];
    modifyUrl(qText, 'filter');

    const searchText = `${filterParam.fulfilmentStatus} ${filterParam.partnerName} fulfilments of 
      type ${filterParam.fulfilmentService} from ${filterParam.startDate} to ${filterParam.endDate} `;

    setResultText(searchText);

    try {
      const response = await axios.get(url);
      setTabKey(fulfilmentTableTabs.all);
      setData(response.data.data);

      const responsePageInfo: IPageInfo = {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        pageCount: response.data.pageCount,
        callback: getFilteredFulfilments,
      };
      //set page info
      setPageInfo(responsePageInfo);
      setShowPagination(true);
      //save result in context
      dispatch({
        type: 'SET_PROPERTY_STATE',
        propName: 'fulfilmentSearchResult',
        propData: response.data.data,
      });
    } catch (error: any) {
      addToast('There was an error getting data', 'error');
    } finally {
      setIsSearch(false);
    }
  };

  const changeTabs = (tabKey: string) => {
    let url = `${endpoints.FulfilmentRequests.mainUrl}/status?pageSize=200`;
    switch (tabKey) {
      case fulfilmentTableTabs.pending:
        url = `${endpoints.FulfilmentRequests.mainUrl}/status?pageSize=200&fulfilmentStatus=Pending`;
        break;
      case fulfilmentTableTabs.processing:
        url = `${endpoints.FulfilmentRequests.mainUrl}/status?pageSize=200&fulfilmentStatus=Processing`;
        break;
      case fulfilmentTableTabs.approved:
        url = `${endpoints.FulfilmentRequests.mainUrl}/status?pageSize=200&fulfilmentStatus=Approved`;
        break;
      case fulfilmentTableTabs.dispensed:
        url = `${endpoints.FulfilmentRequests.mainUrl}/status?pageSize=200&fulfilmentStatus=Dispensed`;
        break;
    }
    setDataUrl(url);

    setTabKey(tabKey);
  };

  /* ########### End Table Functions ################ */

  /* ######### Use effects ################# */

  const updateTableData = (updatedData: IFulfilments[]) => {
    setData(updatedData);
    setAllData(updatedData);
  };
  // useEffect to filter fulfilment data

  useEffect(() => {
    const partnerList = localStorage.getItem('partnersList');

    if (partnerList !== null && typeof partnerList !== 'undefined') {
      const parsedPartnerList = JSON.parse(partnerList);
      setPartnersList(parsedPartnerList);
    } else {
      //call api
      getPartnersList();
    }
  }, [getPartnersList]);

  useEffect(() => {
    //get the page url

    const urlParam = new URLSearchParams(window.location.search);
    let queryName = 'q';

    let queryParam = urlParam.get(queryName);

    //check if query was set for filter if no query param set for search
    if (queryParam === null || typeof queryParam === 'undefined') {
      queryName = 'filter';
      queryParam = urlParam.get(queryName);
    }

    //if nothing set
    if (queryParam !== null && typeof queryParam !== 'undefined') {
      //check if query param is a empty string
      if (queryParam !== '' && appState.fulfilmentSearchResult.length > 0) {
        setData(appState.fulfilmentSearchResult);

        if (queryName === 'q') {
          setSearchQuery(queryParam);
          setResultText(queryParam);
        } else {
          setResultText('');
        }

        return;
      }
    }

    getFulfilments(1);
  }, [getFulfilments]); // eslint-disable-line react-hooks/exhaustive-deps

  /* ######### End Use effects ################# */

  /*######### Search methods ######### */

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;
    setSearchQuery(searchParam);
    setResultText(searchParam);
    modifyUrl(searchParam);

    if (searchParam === '') {
      setAdvSearch(false);
      setShowPagination(true);
      if (allData.length === 0) {
        getFulfilments(1);
        return;
      }
      setData(allData);
      return;
    }

    //if not found, search remote?
    setShowPagination(false);

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.enrolleeFirstName?.toLowerCase().includes(searchParam) ||
        x.enrolleeLastName?.toLowerCase().includes(searchParam) ||
        x.enrolleePhone?.toLowerCase().includes(searchParam) ||
        x.enrollmentCode?.toLowerCase().includes(searchParam)
    );

    // //set table data to search result
    setData(searchResult);

    //save result to context
    if (searchResult.length > 0) {
      //startTimer(searchResult);
      dispatch({
        type: 'SET_PROPERTY_STATE',
        propName: 'fulfilmentSearchResult',
        propData: searchResult,
      });
    }

    if (searchParam.length >= 3) {
      setAdvSearch(true);
    }
  };

  const searchRemoteData = async (event: any) => {
    event.preventDefault();
    getFulfilmentByParams(1);
  };

  const getFulfilmentByParams = async (pageIndex: number) => {
    if (searchQuery === '') {
      return;
    }

    //call remote api
    //set table data to result. allData still holds initial result? (or not)
    const url = `${endpoints.FulfilmentRequests.mainUrl}/search?searchparam=${searchQuery}&pageIndex=${pageIndex}`;
    setIsSearch(true);
    try {
      const response = await axios.get(url);
      setData(response.data.data);

      const responsePageInfo: IPageInfo = {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        pageCount: response.data.pageCount,
        callback: getFulfilmentByParams,
      };
      //set page info
      setPageInfo(responsePageInfo);
      setShowPagination(true);

      dispatch({
        type: 'SET_PROPERTY_STATE',
        propName: 'fulfilmentSearchResult',
        propData: response.data.data,
      });
    } catch (error: any) {
      addToast('An error occured');
    } finally {
      setIsSearch(false);
    }
  };

  /*######### Search methods ######### */

  /*##########  Table fragments ########### */

  let searchRow: JSX.Element = (
    <div className='form-inline'>
      <input
        required
        value={formatInputDate(filterParam.startDate)}
        onChange={handleChange('startDate')}
        placeholder=' Start Date'
        type='date'
        className='form-control mb-2 mr-sm-2'
      />
      <input
        required
        value={formatInputDate(filterParam.endDate)}
        onChange={handleChange('endDate')}
        placeholder='End Date'
        type='date'
        className='form-control mb-2 mr-sm-2'
      />
      <select
        required
        value={filterParam.partnerCode}
        placeholder='Partner'
        onChange={handleChange('partnerCode')}
        className='custom-select mb-2 mr-sm-2'
      >
        <option value=''>All</option>
        {partnersList.map((option) => (
          <option value={option.partnerCode} key={option.partnerId}>
            {option.partnerName}
          </option>
        ))}
      </select>
      <select
        required
        value={filterParam.fulfilmentStatus}
        placeholder='Status'
        onChange={handleChange('fulfilmentStatus')}
        className='custom-select mb-2 mr-sm-2'
      >
        {AllFulfilmentStatusMap.map((option) => (
          <option value={option.value} key={option.key}>
            {option.text}
          </option>
        ))}
      </select>
      <select
        required
        value={filterParam.fulfilmentService}
        placeholder='Service'
        onChange={handleChange('fulfilmentService')}
        className='custom-select mb-2 mr-sm-2'
      >
        {AllFulfilmentServiceMap.map((option) => (
          <option value={option.value} key={option.key}>
            {option.text}
          </option>
        ))}
      </select>
      <div className='mb-2 mr-sm-2 f' title='Show only my requests'>
        <input
          type='checkbox'
          checked={filterParam.assignedToMe}
          onChange={handleChange('assignedToMe')}
        />
        <span className='ml-1'>Only my request</span>
      </div>

      <button className='btn btn-primary mb-2 mr-sm-2' onClick={onSearchClick}>
        {isSearch ? <ButtonLoader /> : 'Search'}
      </button>
      <button className='btn btn-secondary mb-2 mr-sm-2'>
        {isSearch ? <ButtonLoader /> : 'Load All'}
      </button>
    </div>
  );

  const closeModal = () => {
    setShowSideModal(!showSidemodal);
  };
  const mapSidebar = <PharmacyMapSidebar showModal={showSidemodal} close={closeModal} />;

  return (
    <div style={{ marginTop: '-30px' }}>
      {mapSidebar}
      <br />
      <h4 className='mr-auto'>
        Fulfilments
        {/* Fulfilments - ({showPagination ? pageInfo.pageCount * pageInfo.pageSize : data.length}) */}
      </h4>

      {searchRow}
      <br />
      <div className='row'>
        <div className='col-sm-6 mt-3 mt-sm-0 '>
          <div className=' form-inline'>
            <input
              type='search'
              className='form-control'
              placeholder='Search by name and phone, enrolle Id'
              onChange={searchTable}
              value={searchQuery}
              id='advSearchParam'
            />
            {advSearch ? (
              <button className='btn btn-success' onClick={searchRemoteData}>
                {isSearch ? <ButtonLoader /> : 'Search'}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className='col-sm-6 mt-3 mt-sm-0 text-right'>
          <button className='ml-2 btn btn-primary'>
            <Link
              to='/fulfilments/add'
              style={{ textDecoration: 'none', color: 'black' }}
              title='Add fulfilment'
            >
              <span className='d-none d-sm-block'>Add Fulfilments</span>
              <span className='bi bi-plus d-block d-sm-none'></span>
            </Link>
          </button>
          <button className='ml-2 btn btn-primary' onClick={() => setShowSideModal(!showSidemodal)}>
            <span className='d-none d-sm-block'> Pharmacy Map</span>
            <span className='bi bi-geo-alt-fill d-block d-sm-none'></span>
          </button>
        </div>
      </div>
      <br />
      {resultText !== '' ? (
        <div>
          Showing search results for <span className='text-danger'>{resultText}</span>
          <button className='btn btn-outline-primary btn-sm' onClick={downloadHandoverData}>
            Download handover sheet
          </button>
        </div>
      ) : (
        ''
      )}
      <Tabs
        fill
        id='fulfilment-table-tabs'
        activeKey={tabKey}
        className='table-responsive mt-3'
        onSelect={(k) => changeTabs(k as string)}
      >
        <Tab eventKey={fulfilmentTableTabs.all} title='All'>
          <FulfilmentsTableBody data={data} updateTableData={updateTableData} />
        </Tab>
        <Tab eventKey={fulfilmentTableTabs.pending} title='Pending'>
          <FulfilmentsTableBody data={data} updateTableData={updateTableData} />
        </Tab>
        <Tab eventKey={fulfilmentTableTabs.processing} title='Processing'>
          <FulfilmentsTableBody data={data} updateTableData={updateTableData} />
        </Tab>
        <Tab eventKey={fulfilmentTableTabs.dispensed} title='Dispensed'>
          <FulfilmentsTableBody data={data} updateTableData={updateTableData} />
        </Tab>
        <Tab eventKey={fulfilmentTableTabs.approved} title='Done'>
          <FulfilmentsTableBody data={data} updateTableData={updateTableData} />
        </Tab>
      </Tabs>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={pageInfo.callback}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default FulfilmentsTableTabs;
