import { useState, useCallback, useEffect } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import {
  IPostHealthSendClaims,
  initialHealthSendClaim,
  IHealthSendBeneficiary,
  IHealthSendDrug,
  IHealthSendTest,
} from '../../../interface/HealthSendInterface';
import { FulfilmentStatusMap, FulfilmentServiceMap } from '../../../utils/mappings';
import { IPharmacyList } from '../../../interface/PharmacyInterface';
import Select from 'react-select';
import AddDrugModal from './AddDrugModal';
import AddTestModal from './AddTestModal';

const AddHealthSendClaims = () => {
  // const { userId } = useParams() as any;

  const [newClaim, setNewClaim] = useState<IPostHealthSendClaims>(initialHealthSendClaim);
  const [loading, setLoading] = useState(false);

  const [selectPharmacyList, setSelectPharmacyList] = useState<any[]>([]);

  const [drugModalOpen, setDrugModalOpen] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState(false);

  const beneficiaryList: IHealthSendBeneficiary[] = JSON.parse(
    sessionStorage.getItem('healthsendBeneficiaryList') as string
  );

  const handleChange = (input: string) => (e: any) => {
    setNewClaim({ ...newClaim, [input]: e.target.value });
  };

  const selectChange = (selectedValue: any) => {
    setNewClaim({ ...newClaim, pharmacyCode: selectedValue.value });
  };

  const addDrugToClaim = (drug: IHealthSendDrug) => {
    let updatedDrugs = newClaim.drugs;

    drug.drugId = updatedDrugs.length + 1;
    drug.requestId = newClaim.claimId;

    updatedDrugs.push(drug);

    setNewClaim({
      ...newClaim,
      drugs: updatedDrugs,
    });
  };

  const addTestToClaim = (test: IHealthSendTest) => {
    let updatedTests = newClaim.tests;

    test.testId = updatedTests.length + 1;
    test.requestId = newClaim.claimId;
    test.enrollmentTestId = newClaim.beneficiaryId;

    updatedTests.push(test);

    setNewClaim({
      ...newClaim,
      tests: updatedTests,
    });
  };

  const deleteDrug = (id: number) => (event: any) => {
    event.preventDefault();

    let response = prompt('Delete this medication? Y/N');
    if (response?.toLowerCase() === 'y') {
      let updatedDrugs = newClaim.drugs.filter((x) => x.drugId !== id);
      setNewClaim({
        ...newClaim,
        drugs: updatedDrugs,
      });
    } else {
      addToast('Operation cancelled', 'warning');
    }
  };

  const deleteTest = (id: number) => (event: any) => {
    event.preventDefault();

    let response = prompt('Delete this medication? Y/N');
    if (response?.toLowerCase() === 'y') {
      let updatedTests = newClaim.tests.filter((x) => x.testId !== id);
      setNewClaim({
        ...newClaim,
        tests: updatedTests,
      });
    } else {
      addToast('Operation cancelled', 'warning');
    }
  };

  const setPharmacyDropdown = (pharmacyList: IPharmacyList[]) => {
    let selectData = pharmacyList.map(
      (val: { pharmacyName: string; pharmacyCode: string; address: string }) => {
        return {
          label: `${val.pharmacyName} - ${val.address}`,
          value: val.pharmacyCode,
        };
      }
    );

    setSelectPharmacyList(selectData);
  };

  const getPharmacyList = useCallback(async () => {
    const savedPharmacyList = sessionStorage.getItem('pharmacyList');

    if (savedPharmacyList === null || savedPharmacyList === undefined) {
      let url = `${endpoints.InsurancePharmacy.mainUrl}/list`;

      try {
        const response = await axios.get(url);
        const pharmacyList: IPharmacyList[] = response.data;
        sessionStorage.setItem('pharmacyList', JSON.stringify(pharmacyList));
        setPharmacyDropdown(pharmacyList);
      } catch (error) {
        addToast('Error getting pharmacy list', 'error');
      }
    } else {
      const pharmacyList: IPharmacyList[] = JSON.parse(savedPharmacyList);
      setPharmacyDropdown(pharmacyList);
    }
  }, []);

  const submit = async (e: any) => {
    e.preventDefault();

    if (
      newClaim.beneficiaryId === 0 ||
      newClaim.fulfilmentStatus === '-' ||
      newClaim.pharmacyCode.trim() === '' ||
      newClaim.drugs.length === 0 ||
      newClaim.tests.length === 0
    ) {
      addToast('Please fill all fields', 'error');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(endpoints.HealthSend.claims, newClaim);
      if (response.status === 200) {
        addToast('New claim submitted successfully', 'success');
      }
    } catch (error) {
      addToast('Error submitting new claim', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPharmacyList();
  }, [getPharmacyList]);

  let drugModal = (
    <AddDrugModal
      show={drugModalOpen}
      close={() => setDrugModalOpen(false)}
      addDrugToClaim={addDrugToClaim}
    />
  );

  let testModal = (
    <AddTestModal
      open={testModalOpen}
      close={() => setTestModalOpen(false)}
      addTestToClaim={addTestToClaim}
    />
  );

  return (
    <div>
      {drugModal}
      {testModal}
      <h5 className='text-center my-5' style={{ color: '#1489C8' }}>
        Add a new Claim
      </h5>
      <form onSubmit={submit}>
        <fieldset disabled={loading}>
          {/* 1st Row */}
          <div className='row my-2'>
            <div className='col-sm-12 col-md-6'>
              <div className='form-group'>
                <label>Beneficiary</label>
                <select
                  required
                  value={newClaim.beneficiaryId}
                  className='form-control'
                  onChange={handleChange('beneficiaryId')}
                >
                  <option value={0}>-</option>
                  {beneficiaryList.map((beneficiary) => (
                    <option key={beneficiary.beneficiaryId} value={beneficiary.beneficiaryId}>
                      {beneficiary.firstName} {beneficiary.lastName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-sm-12 col-md-6'>
              <div className='form-group'>
                <label>Enrollment Code</label>
                <input
                  required
                  value={newClaim.enrollmentCode}
                  onChange={handleChange('enrollmentCode')}
                  className='form-control'
                />
              </div>
            </div>
          </div>

          {/* 2nd Row */}
          <div className='row my-2'>
            <div className='col-sm-12 col-md-6'>
              <div className='form-group'>
                <label>Partner Code</label>
                <input
                  required
                  value={newClaim.partnerCode}
                  onChange={handleChange('partnerCode')}
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-6'>
              <div className='form-group'>
                <label>Fulfilment Service</label>
                <select
                  required
                  value={newClaim.fulfilmentService}
                  onChange={handleChange('fulfilmentService')}
                  className='form-control'
                >
                  <option value='-'>-</option>
                  {FulfilmentServiceMap.map((service) => (
                    <option key={service.key} value={service.value}>
                      {service.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* 3rd Row */}
          <div className='row my-2'>
            <div className='col-sm-12 col-md-6'>
              <div className='form-group'>
                <label>Fulfilment Status</label>
                <select
                  required
                  value={newClaim.fulfilmentStatus}
                  onChange={handleChange('fulfilmentStatus')}
                  className='form-control'
                >
                  <option value='-'>-</option>
                  {FulfilmentStatusMap.map((status) => (
                    <option key={status.key} value={status.value}>
                      {status.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-sm-12 col-md-6'>
              <div className='form-group'>
                <label>Select Pharmacy</label>
                <Select
                  options={selectPharmacyList}
                  onChange={selectChange}
                  className='react-select'
                  classNamePrefix='react-select'
                />
              </div>
            </div>
          </div>

          {/* 4th Row */}
          <div className='row my-2'>
            <div className='col-sm-12 col-md-6'>
              <button className='btn btn-info btn-sm' onClick={() => setDrugModalOpen(true)}>
                Add Medication +
              </button>
              <div className='table-responsive'>
                <table className='table'>
                  <tbody>
                    {newClaim.drugs.map((drug) => (
                      <tr key={drug.drugId}>
                        <td>{drug.drugId}</td>
                        <td>{drug.name}</td>
                        <td>
                          {`${drug.dose}, ${drug.strength}, ${drug.frequency}
                          ${drug.duration}`}
                        </td>

                        <td>
                          <button
                            className='btn'
                            style={{ marginTop: '-7px' }}
                            onClick={deleteDrug(drug.drugId)}
                          >
                            <i className='bi-trash text-danger' style={{ width: '1rem' }}></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-sm-12 col-md-6'>
              <button className='btn btn-info btn-sm' onClick={() => setTestModalOpen(true)}>
                Add Test +
              </button>
              <div className='table-responsive'>
                <table className='table'>
                  <tbody>
                    {newClaim.tests.map((test) => (
                      <tr key={test.testId}>
                        <td>{test.testId}</td>
                        <td>{test.testName}</td>
                        <td>{test.testResult}</td>
                        <td>
                          <button
                            className='btn'
                            style={{ marginTop: '-7px' }}
                            onClick={deleteTest(test.testId)}
                          >
                            <i className='bi-trash text-danger' style={{ width: '1rem' }}></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div>
            <button type='submit' className='btn btn-success btn-block mt-5'>
              Submit
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export { AddHealthSendClaims };
