import React, { useState, useCallback, useEffect } from 'react';
import { IHealthSendRequest } from '../../../interface/HealthSendInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import LoaderWithText from '../../common/LoaderWithText';
import { Link } from 'react-router-dom';

const UserRequests = ({ userId }: { userId: string }) => {
  const [requests, setRequests] = useState<IHealthSendRequest[]>([]);
  const [loading, setLoading] = useState(false);

  const getRequests = useCallback(async () => {
    setLoading(true);

    let url = endpoints.HealthSend.requests;

    if (typeof userId !== 'undefined' && userId !== '') {
      url = `${endpoints.HealthSend.requests}/${userId}`;
    }

    try {
      const response = await axios.get(url);
      setRequests(response.data);
    } catch (error: any) {
      addToast('Error getting user requests', 'error');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  return (
    <div>
      {loading ? (
        <LoaderWithText />
      ) : (
        <>
          <div className='mt-3 d-flex justify-content-end'>
            <Link
              to={`/healthsend/users/${userId}/request/new`}
              className='btn btn-outline-primary'
            >
              Add Request
            </Link>
          </div>
          <div className='d-flex flex-row flex-wrap'>
            {requests.map((request) => (
              <div
                key={request.requestDate}
                className='card shadow-lg m-4 p-8 border-0'
                style={{ cursor: 'pointer' }}
              >
                <div className='card-body'>
                  <h5 className='card-title'>
                    {request.firstName} {request.lastName}
                  </h5>
                  <h6 className='card-subtitle mb-2 text-muted'>{request.phoneNumber}</h6>
                  <p className='card-text'>Service - {request.services}</p>
                  <p className='card-text'>Request Date - {formatDateTime(request.requestDate)}</p>
                  <p className='card-text'>Amount - {request.totalAmountSent}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export { UserRequests };
