import axios from 'axios';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  IChatBoxMessages,
  IMessagesDataDTO,
  //   IReadMessage,
  //   IUnreadMessages,
} from '../../../interface/MessagesInterface';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { calculateMessageCount } from '../../../utils/formUtils';
import ChatBox from '../messages/Chatbox';
import '../messages/styles.css';
import ButtonLoader from '../../common/ButtonLoader';
import { isFormValidated } from '../../../utils/formUtils';
// import { formatDateTime } from '../../../utils/dateTimeUtils';

const SMS = ({ phone, tabOpen }: { phone: string; tabOpen: boolean }) => {
  //   const [searchQuery, setSearchQuery] = useState('');
  //   const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [messages, setMessage] = useState<IChatBoxMessages[]>([]);
  // const [inboxMessages, setInboxMessages] = useState<IUnreadMessages[]>([]);

  const [messageMetaData, setMessageMetaData] = useState({
    totalCount: 160,
    characterCount: 0,
    pageCount: 1,
  });

  const getMessagesByPhone = useCallback(async (phoneNumber: string) => {
    if (phoneNumber === '') {
      return;
    }
    try {
      const url = `${endpoints.InsuranceMessages.mainUrl}/${phoneNumber}`;
      const response = await axios.get(url);
      setMessage(response.data);
    } catch (error: any) {
      addToast('Could not fetch messages for the phone number', 'error');
      console.error(error);
    } finally {
      // setIsSearching(false);
    }
  }, []);

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();

    if (messages.length === 0 || messages === null) {
      addToast('Select a Phone number or search for a phone number', 'warning');
      return;
    }
    const phoneNumber = messages[0].phoneNumber;

    if (phoneNumber === '') {
      return;
    }

    if (!isFormValidated('message-form')) {
      return;
    }

    setLoading(true);

    const payload: IMessagesDataDTO = {
      phoneNumbers: [phoneNumber],
      messageContent: textMessage,
      messageChannel: 'SMS',
      isBulkSms: true,
      queueDate: '',
      sendLater: false,
      sendDate: '',
      hours: '',
      minutes: '',
      meridian: 1,
    };

    const url = `${endpoints.InsuranceMessages.mainUrl}`;

    try {
      await axios.post(url, payload);
      addToast('Message Sent Successfully', 'success');
      setTextMessage('');
      getMessagesByPhone(phoneNumber);
    } catch (error: any) {
      addToast('An error occured when sending your message', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const textMessage = e.target.value;
    setTextMessage(textMessage);

    const metaData = calculateMessageCount(textMessage.length);

    setMessageMetaData(metaData);
  };

  useEffect(() => {
    if (tabOpen) {
      getMessagesByPhone(phone);
    }
  }, [getMessagesByPhone, tabOpen, phone]);

  let replyBox = (
    <form id='message-form' onSubmit={sendMessage}>
      <div className='form-group'>
        <label>Send Message</label>
        <textarea
          disabled={isLoading ?? false}
          onChange={handleChange}
          value={textMessage}
          style={{ backgroundColor: 'white' }}
          placeholder='Reply...'
          className='form-control'
          required
          rows={6}
        ></textarea>
        <div style={{ marginTop: '20px' }}>
          <span className='messageMetaData'>
            {messageMetaData.characterCount}/{messageMetaData.totalCount}
          </span>
          <span className='messageMetaData'>Pages : {messageMetaData.pageCount}</span>
        </div>
        <br />
        <button type='submit' className='btn btn-primary'>
          Send
          {isLoading ? <ButtonLoader /> : ''}
        </button>
      </div>
    </form>
  );

  return (
    <Fragment>
      <div className='container'>
        {/* Inbox Grid */}
        <div className='row'>
          {/* Message Grid */}
          <div className='col-md-12 messageGrid'>
            <i className='bi bi-envelope-fill messages-icon'></i> Messages ({messages.length}) -
            {messages.length > 0 ? messages[0].phoneNumber : ''}
            <div style={{ marginBottom: '5%' }} className='d-flex justify-content-start'>
              <ChatBox messageList={messages} />
            </div>
            {replyBox}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SMS;
