import { IGetHealthSendClaims } from '../../../interface/HealthSendInterface';
import { Link } from 'react-router-dom';

const HealthSendClaims = ({
  claims,
  userId,
}: {
  claims: IGetHealthSendClaims[];
  userId: string;
}) => {
  return (
    <div>
      <div className='mt-3 d-flex justify-content-end'>
        <Link to={`/healthsend/user/${userId}/claims/new`} className='btn btn-primary'>
          Add Claim
        </Link>
      </div>
      {claims.length === 0 ? (
        <div>
          <div className='d-flex justify-content-center align-items-center'>
            <h4>No Claims Found</h4>
          </div>
        </div>
      ) : (
        <div>
          <div className='d-flex flex-row flex-wrap'>
            {claims.map((claim) => (
              <div
                key={claim.claimId}
                className='card shadow m-4 p-8 border-0'
                style={{ width: '15rem' }}
              >
                <div className='card-body'>
                  <h5 className='card-title'>{claim.beneficiaryId}</h5>
                  <h6 className='card-subtitle mb-2 text-muted'>{claim.fulfilmentService}</h6>
                  <div>
                    <h6 className='flex-start'>Total Amount:</h6>
                    <h6 className='flex-end'>Total Amount:</h6>
                  </div>
                  <p className='card-text fw-bold'>Drugs:</p>
                  <p className='card-text'>
                    {claim.drugs.map((drug) => (
                      <h6>{drug.name}</h6>
                    ))}
                  </p>
                  <p className='card-text fw-bold'>Tests:</p>
                  <p className='card-text'>
                    {claim.tests.map((test) => (
                      <h6>{test.testName}</h6>
                    ))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HealthSendClaims;
