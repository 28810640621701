/*
Agnostic search input, accepts a data source in form  {label:string, value:string}
*/
import React, { useState, useRef } from 'react';
import { useOutsideAlerter } from './OutsideClick';
import { IInputSearchBar, ReactSelectOption } from '../../interface/CommonInterface';

const InputSearch = (props: IInputSearchBar) => {
  const [inputSearchResult, setInputSearchResult] = useState<ReactSelectOption[]>(props.data);

  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  let timer: NodeJS.Timeout;

  const hideSearchResult = (event: any) => {
    setShowSearch(false);
  };

  const inputFocus = () => {
    setShowSearch(true);
  };

  /*To handle when we clck outside the search */

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideSearchResult);

  /* end click outside */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputSearch = event.target.value;
    setSearchTerm(inputSearch);
    // if (inputSearch.length < 3) {
    //   return;
    // }
    // searchPatient(inputSearch);
  };
  const clearTimer = () => {
    clearTimeout(timer);
  };

  const startTimer = (event: any) => {
    clearTimeout(timer);
    let searchTerm = event.target.value;
    timer = setTimeout(() => searchPatient(searchTerm), 500);
  };

  const searchPatient = async (searchTerm: string) => {
    if (searchTerm.length < 3) {
      return;
    }
    //show loading
    const loading: ReactSelectOption = {
      value: '',
      label: 'loading',
    };
    const loadingArray = [loading];
    setInputSearchResult(loadingArray);
    setShowSearch(true);

    // const url = `${endpoints.FulfilmentPatients.mainUrl}/${pharmacyId}/${searchTerm}`;

    try {
      const result = props.data.filter((x) => x.label.toLowerCase().includes(searchTerm.trim()));
      setInputSearchResult(result);

      //if no result, trigger callback with undefined, so calling component can handle
      if (result.length === 0 && props.callback) {
        props.callback(searchTerm);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const selectResult = (selectedResult: ReactSelectOption) => {
    setSearchTerm(selectedResult.label);
    setShowSearch(false);

    //callback set, call the callback passing the selected patient data
    if (props.callback) {
      props.callback(searchTerm, selectedResult);
    }
  };

  return (
    <div className='form-group ' ref={wrapperRef} onFocus={inputFocus}>
      {/* onFocus={toggleSearchResult} onBlur={toggleSearchResult}> */}
      <span className='bi bi-search search-icon'></span>
      <input
        type='text'
        className={
          props.inputStyle ? 'form-control pl-5 pr-4 ' + props.inputStyle : 'form-control pl-5 pr-4'
        }
        placeholder='Search. Start typing name or phone to select'
        onChange={handleChange}
        value={searchTerm}
        onKeyUp={startTimer}
        onKeyDown={clearTimer}
      />
      {showSearch && inputSearchResult.length > 0 ? (
        <div className='search-result-panel'>
          <ul style={{ marginLeft: '-30px', backgroundColor: 'white' }}>
            {inputSearchResult.map((data) => (
              <li key={data.value} className='search-result' onClick={() => selectResult(data)}>
                {data.label}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default InputSearch;
