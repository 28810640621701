import React, { useState, useCallback, useEffect } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { addToast } from '../../utils/toastNotifications';
import { endpoints } from '../../utils/URL';
import { INotificationMenu } from '../../interface/NotificationInterface';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import localforage from 'localforage';

// import { AppContext } from '../../context/AppContext';

const HeaderLogo = ({
  pushNotification,
  notificationCount,
  toggleNotification,
}: {
  pushNotification: boolean;
  notificationCount: number;
  toggleNotification: any;
}) => {
  const [notificationList, setNotificationList] = useState<INotificationMenu[]>([]);
  const [isLightTheme, setLightTheme] = useState(true);
  const auth = useAuth();

  const getNotificationList = useCallback(async () => {
    // setIsLoading(true);
    axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;

    try {
      const response = await axios.get(endpoints.InsuranceNotifications.getLatest);

      const list: INotificationMenu[] = response.data.map((x: { id: number; message: string }) => {
        return {
          id: x.id,
          value: x.id,
          content: x.message,
        };
      });

      setNotificationList(list);
    } catch (error: any) {
      addToast('There was an error getting the notifications', 'error');
      console.error('Error getting latest notifications', error);
    } finally {
      //setIsLoading(false);
    }
  }, [auth.user]);

  const markRead = (id: number) => async (event: any) => {
    event.preventDefault();
    //update local state to remove the 'read' one
    let updatedNotificationList = notificationList.filter((x: any) => x.key !== id);
    setNotificationList(updatedNotificationList);

    //call api
    await axios
      .put(endpoints.InsuranceNotifications.markRead + id)
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          getNotificationList();
          //   getUnreadCount();
          //notificationMenu.getUnreadCount();
        }
      })
      .catch((error) => {
        addToast('Error updating notifications', 'error');
      });
  };

  const toggle = (event: any) => {
    toggleNotification();
  };

  // Them related methods

  const getSavedTheme = useCallback(() => {
    //get from localstorage, if null or undefined, set as light
    const theme = localStorage.getItem('theme')?.toString();
    if (theme !== null && typeof theme !== 'undefined') {
      setTheme(theme);
    }
  }, []);

  const setTheme = (theme: string | undefined) => {
    //get from localstorage, if null or undefined, set as light
    if (theme === 'light') {
      document.body.classList.remove('dark-theme');
      setLightTheme(true);
    } else {
      document.body.classList.add('dark-theme');
      setLightTheme(false);
    }
    // }
  };

  const updatePortal = () => {
    const response = window.confirm('This will force an update to the portal. Continue?');
    if (!response) {
      return;
    }
    if ('serviceWorker' in window.navigator) {
      window.navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          if (registration) {
            registration?.unregister().then(() => {
              window.sessionStorage.clear();
              window.localStorage.clear();
              localforage.clear();
              window.location.reload();
            });
            localforage.clear();
            window.sessionStorage.clear();
            window.localStorage.clear();
          } else {
            localforage.clear();
            window.sessionStorage.clear();
            window.localStorage.clear();
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error('No service worker in navigator');
    }
  };

  const toggleTheme = (event: any) => {
    setLightTheme(event.target.checked);
    const theme = event.target.checked ? 'light' : 'dark';
    localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  useEffect(() => {
    getNotificationList();
    getSavedTheme();
  }, [getNotificationList, getSavedTheme]);

  return (
    <nav className='navbar navbar-light sticky-top flex-md-nowrap p-0 shadow navbar-custom'>
      <div className='' style={{ margin: '10px 0px 15px 70px' }}>
        <img
          src={require('../../assets/images/wellahealth.svg').default}
          alt='wellahealth'
          width='100'
        />
      </div>
      <button
        // className='navbar-toggler position-absolute d-md-none collapsed'
        className='navbar-toggler position-absolute collapsed'
        type='button'
        data-toggle='collapse'
        data-target='#sidebarMenu'
        aria-controls='sidebarMenu'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span
          className='navbar-toggler-icon'
          data-toggle='collapse'
          data-target='#sidebarMenu'
        ></span>
      </button>

      <div style={{ marginRight: '10px' }}>
        <button
          style={{ display: 'inline', marginRight: '10px' }}
          title='Update'
          onClick={updatePortal}
          className='btn btn-default'
        >
          Force Update <span className='bi bi-arrow-repeat' style={{ fontSize: '1.2rem' }}></span>
        </button>
        <Dropdown style={{ display: 'inline', marginRight: '10px' }}>
          <Dropdown.Toggle variant='' id='dropdown-basic-1'>
            <i className='bi bi-gear' style={{ fontSize: '1.2rem' }}></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='drop-down-menu-custom-1'>
            <Dropdown.Item as='div'>
              <div className='custom-control custom-switch'>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  checked={pushNotification}
                  onChange={toggle}
                  id='notification-toggle'
                />
                <label className='custom-control-label' htmlFor='notification-toggle'>
                  {pushNotification ? 'Notification on' : 'Notification off'}
                </label>
              </div>
            </Dropdown.Item>
            <Dropdown.Item as='div' title='Click to mark as read'>
              <div className='custom-control custom-switch'>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='theme-toggle'
                  checked={isLightTheme}
                  onChange={toggleTheme}
                />
                <label className='custom-control-label' htmlFor='theme-toggle'>
                  {isLightTheme ? 'Light Mode' : 'Dark Mode'}
                </label>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown style={{ display: 'inline', marginRight: '10px' }}>
          <Dropdown.Toggle variant='' id='dropdown-basic'>
            <i className='bi bi-bell' style={{ fontSize: '1.2rem' }}>
              <Badge style={{ color: '#007bff' }}>
                {notificationCount < 99 ? notificationCount : '99+'}
              </Badge>
            </i>
          </Dropdown.Toggle>

          <Dropdown.Menu className='drop-down-menu-custom'>
            {notificationList.map((notification, key) => (
              <Dropdown.Item
                key={key}
                as='div'
                className='dropdown-item-custom'
                title='Click to mark as read'
                onClick={markRead(notification.value)}
              >
                {notification.content}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item>
              <Link to='/notifications'> View all notifications</Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <i
          className='bi bi-person-circle'
          style={{ fontSize: '1.2rem' }}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Logout'
        /> */}
      </div>
    </nav>
  );
};

export default HeaderLogo;
