import localforage from 'localforage';

const RefreshToast = () => {
  //a hack to unregister the current service worker and force a reload
  //https://stackoverflow.com/a/46982400/2929906
  const reloadPage = (event: any) => {
    if ('serviceWorker' in window.navigator) {
      window.navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          if (registration) {
            registration?.unregister().then(() => {
              localforage.clear();
              window.sessionStorage.clear();
              window.localStorage.clear();
              window.location.reload();
            });
            localforage.clear();
            window.sessionStorage.clear();
            window.localStorage.clear();
          } else {
            localforage.clear();
            window.sessionStorage.clear();
            window.localStorage.clear();
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error('No service worker in navigator');
    }
  };

  return (
    <div
      id='refresh-btn'
      className='refresh-toast' //style in style.css
      onClick={reloadPage}
      style={{ display: 'none' }}
    >
      New version available!! Click to update
    </div>
  );
};

export default RefreshToast;
