export interface IReceivedPayment {
  paymentSource: number;
  amountPaid: number;
  phoneNumber: string;
  email: string;
  name: string;
  status: number;
  transactionDate: string;
  isPaymentMatched: boolean;
  transactionReference: string;
  paymentDescription: string;
  paymentStatus: string;
  paymentMatchedTo: string;
  subscriptionType: string;
  productType: string;
}

export interface IPaymentLinkDTO {
  reference: string;
  email: string;
  amount: number;
  callback_url: string;
  metaData: IPaymentLinkMeta;
}

export interface IPaymentLinkMeta {
  orderId: string;
  phoneNumber: string;
  totalAmount: number;
}

export const initialReceivedPayment: IReceivedPayment = {
  paymentSource: 0,
  amountPaid: 0,
  phoneNumber: '',
  email: '',
  name: '',
  status: 0,
  transactionDate: '',
  isPaymentMatched: false,
  transactionReference: '',
  paymentDescription: '',
  paymentStatus: 'Pending',
  paymentMatchedTo: '',
  subscriptionType: 'Malaria',
  productType: 'Malaria',
};
