import { HMOReviewURL } from '../interface/HmoReviewURL';
import { VeriClaimJobType } from './constants';

export const PAYMENT_PLAN_MAPPING = [
  {
    key: 0,
    value: 'Monthly',
    text: 'Monthly',
    numberOfMonths: 1,
  },
  {
    key: 1,
    value: 'Quarterly',
    text: 'Quarterly',
    numberOfMonths: 3,
  },
  {
    key: 2,
    value: 'Biannually',
    text: 'Biannually',
    numberOfMonths: 6,
  },
  {
    key: 3,
    value: 'Yearly',
    text: 'Yearly',
    numberOfMonths: 12,
  },
];

export const NotificationTypeLookup = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

export const CLAIM_PROVIDER = [
  {
    key: 0,
    value: 'AXA',
    text: 'AXA',
  },
  {
    key: 1,
    value: 'Wellahealth',
    text: 'Wellahealth',
  },
];

export const BOOL_MAPPING2 = [
  {
    key: 0,
    value: true,
    text: 'true',
  },
  {
    key: 1,
    value: false,
    text: 'false',
  },
];

export const DECISION_MAPPING = [
  {
    key: 0,
    value: 'Yes',
    text: 'Yes',
  },
  {
    key: 1,
    value: 'No',
    text: 'No',
  },
];

export const PHARMACY_STATUS = [
  {
    key: 1,
    value: 'Active',
    text: 'Active',
  },
  {
    key: 2,
    value: 'Inactive',
    text: 'InActive (Cannot access App/Engage but will show up in search)',
  },
  {
    key: 3,
    value: 'Cancelled',
    text: 'Cancelled (Will not show up in search, but can still use App/Engage)',
  },
];

export const PharmacyStatusMap = {
  Active: 'Active',
  Inactive: 'Inactive',
  Cancelled: 'Cancelled',
};

export const STATUS_DISPLAY = [
  {
    key: 0,
    value: 'Unpaid',
    text: 'Unpaid',
  },
  {
    key: 1,
    value: 'Active',
    text: 'Active',
  },
  {
    key: 2,
    value: 'MissedPayment',
    text: 'MissedPayment',
  },
  {
    key: 3,
    value: 'Cancelled',
    text: 'Cancelled',
  },
];

export const PaymentChannels = {
  Paystack: 1,
  BankTransfer: 2,
  Cash: 3,
  Agent: 4,
  Monnify: 5,
};

export const PaymentPlansDisplay = {
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Biannually: 'Biannually',
  Yearly: 'Yearly',
};

export const StatusDisplay = {
  Unpaid: 'Unpaid',
  Active: 'Active',
  MissedPayment: 'MissedPayment',
  Cancelled: 'Cancelled',
};

export const GenderDisplay = {
  Female: 'Female',
  Male: 'Male',
  Others: 'Others',
};

export const SubscriptionStatus = {
  Unpaid: 1,
  Active: 2,
  MissedPayment: 3,
  Cancelled: 4,
};

export const PaymentPlan = {
  Monthly: 1,
  Quarterly: 3,
  Biannually: 6,
  Yearly: 12,
};

export const Gender = {
  Female: 1,
  Male: 2,
  Others: 3,
};

export let getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export let getValueByKey = (object: any, key: any) => {
  return object[key];
};

export const DebitTypeMap = [
  {
    key: 1,
    value: 'Payout',
    text: 'Payout',
  },
  {
    key: 2,
    value: 'Overdraft',
    text: 'Overdraft',
  },
];

export const FulfilmentStatusMap = [
  {
    key: 0,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 1,
    value: 'Processing',
    text: 'Processing',
  },
  {
    key: 2,
    value: 'Dispensed',
    text: 'Dispensed',
  },
  {
    key: 3,
    value: 'Completed',
    text: 'Completed',
  },
  {
    key: 4,
    value: 'Cancelled',
    text: 'Cancelled',
  },
  {
    key: 5,
    value: 'Approved',
    text: 'Approved',
  },
];

export const AllFulfilmentStatusMap = [
  {
    key: 0,
    value: 'All',
    text: 'All',
  },
  {
    key: 1,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 2,
    value: 'Processing',
    text: 'Processing',
  },
  {
    key: 3,
    value: 'Dispensed',
    text: 'Dispensed',
  },
  {
    key: 4,
    value: 'Completed',
    text: 'Completed',
  },
  {
    key: 5,
    value: 'Cancelled',
    text: 'Cancelled',
  },
  {
    key: 6,
    value: 'Approved',
    text: 'Approved',
  },
];

export const FulfilmentStatus = {
  Pending: 'Pending',
  Processing: 'Processing',
  Dispensed: 'Dispensed',
  Approved: 'Approved',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const FulfilmentExport = [
  {
    key: 0,
    value: 'ClaimFilingExport',
    text: 'Export to file for Claims',
  },
  {
    key: 1,
    value: 'RefillExport',
    text: 'Export for Refill',
  },
];

export const FulfilmentServiceMap = [
  {
    key: 0,
    value: 'Telemedicine',
    text: 'Telemedicine',
  },
  {
    key: 1,
    value: 'Acute',
    text: 'Acute',
  },
  {
    key: 2,
    value: 'Chronic',
    text: 'Chronic',
  },
];

export const PricelistFulfilmentServiceMap = [
  {
    key: 0,
    value: 'Telemedicine',
    text: 'Telemedicine',
  },
  {
    key: 1,
    value: 'Acute',
    text: 'Acute',
  },
  {
    key: 2,
    value: 'Chronic',
    text: 'Chronic',
  },
  {
    key: 3,
    value: 'Acute,Telemedicine',
    text: 'Acute,Telemedicine',
  },
  {
    key: 4,
    value: 'Acute,Chronic',
    text: 'Acute,Chronic',
  },
  {
    key: 5,
    value: 'Chronic,Telemedicine',
    text: 'Chronic,Telemedicine',
  },
  {
    key: 6,
    value: 'Acute,Chronic,Telemedicine',
    text: 'Acute,Chronic,Telemedicine',
  },
];

export const AllFulfilmentServiceMap = [
  {
    key: 0,
    value: 'All',
    text: 'All',
  },
  {
    key: 1,
    value: 'Telemedicine',
    text: 'Telemedicine',
  },
  {
    key: 2,
    value: 'Acute',
    text: 'Acute',
  },
  {
    key: 3,
    value: 'Chronic',
    text: 'Chronic',
  },
];

export const TestResultTypeMap = [
  {
    key: 0,
    value: 'NotDone',
    text: 'NotDone',
  },
  {
    key: 1,
    value: 'Invalid',
    text: 'Invalid',
  },
  {
    key: 2,
    value: 'Negative',
    text: 'Negative',
  },
  {
    key: 3,
    value: 'Positive',
    text: 'Positive',
  },
];

export const PRODUCT_TYPE = [
  {
    key: 0,
    value: 'Malaria',
    text: 'Malaria',
  },
  {
    key: 1,
    value: 'Cashplus',
    text: 'Cashplus',
  },
  {
    key: 2,
    value: 'Diabetes',
    text: 'Diabetes',
  },
  {
    key: 3,
    value: 'DiabetesPlus',
    text: 'DiabetesPlus',
  },
  {
    key: 4,
    value: 'Compact',
    text: 'Compact',
  },
  {
    key: 5,
    value: 'Pharma',
    text: 'Pharma',
  },
];

export const FULFILMENT_EMAIL_TYPE = [
  {
    key: 1,
    value: 'PickUp',
    text: 'Pickup mail',
  },
  {
    key: 2,
    value: 'NotReachable',
    text: 'Enrollee not reachable',
  },
  {
    key: 3,
    value: 'NotPicking',
    text: 'Enrolee not picking',
  },
  {
    key: 4,
    value: 'SwitchedOff',
    text: 'Enrollee phone switched off',
  },
  {
    key: 5,
    value: 'PharmacyNotNeeded',
    text: 'Enrollee doesnt want a pharmacy on our network.',
  },
];

export const BOOL_MAPPING = [
  {
    key: 0,
    value: true,
    text: 'Yes',
  },
  {
    key: 1,
    value: false,
    text: 'No',
  },
];

export const PARTNER_MAPPING = [
  {
    key: 0,
    value: 'SuperAgent',
    text: 'SuperAgent',
  },
  {
    key: 1,
    value: 'Company',
    text: 'Company',
  },
  {
    key: 2,
    value: 'Individual',
    text: 'Individual',
  },
];

export const GENDER_MAPPING = [
  {
    key: 0,
    value: 'Female',
    text: 'Female',
  },
  {
    key: 1,
    value: 'Male',
    text: 'Male',
  },
  {
    key: 2,
    value: 'Others',
    text: 'Others',
  },
];

export const ACQUISITION_CHANNEL_MAPPING = [
  {
    key: 0,
    value: 'Partner',
    text: 'Partner / Company',
  },
  {
    key: 2,
    value: 'Twitter',
    text: 'Twitter',
  },
  {
    key: 3,
    value: 'Flyer',
    text: 'Flyer',
  },
  {
    key: 4,
    value: 'Friend',
    text: 'Friend',
  },
  {
    key: 6,
    value: 'Search',
    text: 'Search',
  },
  {
    key: 7,
    value: 'Facebook',
    text: 'Facebook',
  },
  {
    key: 9,
    value: 'CardSales',
    text: 'Card Sales',
  },
  {
    key: 10,
    value: 'Company',
    text: 'Company / BizDev',
  },
  {
    key: 12,
    value: 'RadioAndBillboard',
    text: 'Radio and Billboard',
  },
  {
    key: 13,
    value: 'Pharmacy',
    text: 'Pharmacy',
  },
  {
    key: 14,
    value: 'AgentNetwork',
    text: 'AgentNetwork / WellaAgent',
  },
  {
    key: 15,
    value: 'LinkedIn',
    text: 'LinkedIn',
  },
  {
    key: 16,
    value: 'Events',
    text: 'Events',
  },
  {
    key: 17,
    value: 'Instagram',
    text: 'Instagram',
  },
  {
    key: 17,
    value: 'MTN MarketPlace',
    text: 'MTN MarketPlace',
  },
];

export const ACQUISITION_REASON_MAPPING = [
  {
    key: 1,
    value: 'Affordability',
    text: 'Affordability',
  },
  {
    key: 2,
    value: 'UnInsured',
    text: 'UnInsured',
  },
  {
    key: 3,
    value: 'Curious',
    text: 'Curious',
  },
  {
    key: 4,
    value: 'Referred',
    text: 'Referred',
  },
  {
    key: 5,
    value: 'Others',
    text: 'Others',
  },
];

export const AcquisitionReason = {
  Affordability: 1,
  UnInsured: 2,
  Curious: 3,
  Referred: 4,
  Others: 5,
};

export const PaymentChannelDisplay = {
  Paystack: 'Paystack',
  BankTransfer: 'BankTransfer',
  Cash: 'Cash',
  Agent: 'Agent',
  Monnify: 'Monnify',
};

export const PAYMENT_CHANNEL_MAPPING = [
  {
    key: 0,
    value: 'Paystack',
    text: 'Paystack',
  },
  {
    key: 1,
    value: 'BankTransfer',
    text: 'Bank Transfer',
  },
  {
    key: 2,
    value: 'Cash',
    text: 'Cash',
  },
  {
    key: 3,
    value: 'Agent',
    text: 'Agent',
  },
  {
    key: 4,
    value: 'Monnify',
    text: 'Monnify',
  },
];

export const ClaimStatusMap = [
  {
    key: 0,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 1,
    value: 'Processing',
    text: 'Processing',
  },
  {
    key: 2,
    value: 'Dispensed',
    text: 'Dispensed',
  },
  {
    key: 3,
    value: 'Completed',
    text: 'Completed',
  },
  {
    key: 4,
    value: 'Cancelled',
    text: 'Cancelled',
  },
  {
    key: 5,
    value: 'Blocked',
    text: 'Blocked',
  },
];

export const ClaimTypeMap = [
  {
    key: 1,
    value: 'HospitalPayout',
    text: 'HospitalPayout',
  },
  {
    key: 2,
    value: 'FuneralPayout',
    text: 'FuneralPayout',
  },
];

export const TESTRESULT = [
  {
    key: 1,
    value: 'NotDone',
    text: 'NotDone',
  },
  {
    key: 2,
    value: 'Invalid',
    text: 'Invalid',
  },
  {
    key: 3,
    value: 'Negative',
    text: 'Negative',
  },
  {
    key: 4,
    value: 'Positive',
    text: 'Positive',
  },
];

export const PRODUCT_VIEW = [
  {
    key: 0,
    value: 'Malaria',
    text: 'Insurance View',
  },
  {
    key: 1,
    value: 'Fulfilment',
    text: 'Fulfilment View',
  },
  {
    key: 2,
    value: 'Wallet',
    text: 'Wallet View',
  },
  // {
  //   key: 3,
  //   value: 'Zoi',
  //   text: 'Zoi View',
  // },
  {
    key: 4,
    value: 'Agent',
    text: 'Agents View',
  },
  {
    key: 5,
    value: 'Loan',
    text: 'Loans View',
  },
  {
    key: 6,
    value: 'Misc',
    text: 'Misc View',
  },
  {
    key: 7,
    value: 'healthsend',
    text: 'HealthSend View',
  },
];

export const DescriptionType = [
  {
    key: 1,
    value: 'Fulfilment',
    text: 'Fulfilment',
  },
  {
    key: 2,
    value: 'Claims',
    text: 'Claim',
  },
  {
    key: 3,
    value: 'Loans',
    text: 'Loans',
  },
  {
    key: 4,
    value: 'Payout',
    text: 'Payout',
  },
  {
    key: 5,
    value: 'Overdraft',
    text: 'Overdraft',
  },
  {
    key: 6,
    value: 'Refund',
    text: 'Refund',
  },
  {
    key: 7,
    value: 'ErxPayment',
    text: 'ErxPayment',
  },
  {
    key: 8,
    value: 'LoanCharges',
    text: 'LoanCharges',
  },
  {
    key: 9,
    value: 'InsuranceCommission',
    text: 'InsuranceCommission',
  },
];

export const TransactionStatus = [
  {
    key: 1,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 2,
    value: 'Completed',
    text: 'Completed',
  },
  {
    key: 3,
    value: 'Cancelled',
    text: 'Cancelled',
  },
];

export const TransactionStatusMap = {
  Pending: 'Pending',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const PayoutStatusMap = [
  {
    key: 1,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 2,
    value: 'Approved',
    text: 'Approved',
  },

  {
    key: 3,
    value: 'Cancelled',
    text: 'Cancelled',
  },
];

export const PayoutStatus = {
  Pending: 'Pending',
  Approved: 'Approved',
  Cancelled: 'Cancelled',
};

export const AllPayoutStatus = {
  Pending: 'Pending',
  Approved: 'Approved',
  Processing: 'Processing',
  Retrying: 'Retrying',
  Successful: 'Successful',
  Failed: 'Failed',
  Cancelled: 'Cancelled',
};

export const AllPayoutStatusMap = [
  {
    key: 0,
    value: '',
    text: 'All',
  },
  {
    key: 1,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 2,
    value: 'Approved',
    text: 'Approved',
  },
  {
    key: 3,
    value: 'Processing',
    text: 'Processing',
  },
  {
    key: 4,
    value: 'Retrying',
    text: 'Retrying',
  },
  {
    key: 5,
    value: 'Successful',
    text: 'Successful',
  },
  {
    key: 6,
    value: 'Failed',
    text: 'Failed',
  },
  {
    key: 7,
    value: 'Cancelled',
    text: 'Cancelled',
  },
];

export const TransactionType = [
  {
    key: 1,
    value: 'Credit',
    text: 'Credit',
  },
  {
    key: 2,
    value: 'Debit',
    text: 'Debit',
  },
];

export const TransactionTypeMap = {
  Credit: 'Credit',
  Debit: 'Debit',
};

export const SupportRequestType = [
  {
    key: 1,
    value: 'BloodPressureCheck',
    text: 'BP Check',
  },
  {
    key: 2,
    value: 'DiabetesScreening',
    text: 'Diabetes Screening',
  },
  {
    key: 3,
    value: 'DietPlan',
    text: 'Diet Plan',
  },
  {
    key: 4,
    value: 'MalariaTestDrugs',
    text: 'Malaria Test and Drugs',
  },
  {
    key: 5,
    value: 'ChatDoctor',
    text: 'Chat with a Doctor',
  },
  {
    key: 6,
    value: 'Hospitalization',
    text: 'Hospitalization',
  },
  {
    key: 7,
    value: 'Accident',
    text: 'Accident cover',
  },
  {
    key: 8,
    value: 'CallRequest',
    text: 'Call Request',
  },
];

export const LoanApplicationStatus = [
  {
    key: 1,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 2,
    value: 'Processing',
    text: 'Processing',
  },
  {
    key: 3,
    value: 'Approved',
    text: 'Approved',
  },
];

export const AllLoanApplicationStatus = [
  {
    key: 1,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 2,
    value: 'Processing',
    text: 'Processing',
  },
  {
    key: 3,
    value: 'Approved',
    text: 'Approved',
  },
  {
    key: 4,
    value: 'Disbursed',
    text: 'Disbursed',
  },
  {
    key: 5,
    value: 'Repaying',
    text: 'Repaying',
  },
  {
    key: 6,
    value: 'Completed',
    text: 'Completed',
  },
];

export const RepaymentPlan = [
  {
    key: 1,
    value: 'EvenSpread',
    text: 'Spread Evenly',
  },
  {
    key: 2,
    value: 'InterestFirst',
    text: 'Pay Interest First',
  },
];

export const SupportRequestStatus = [
  {
    key: 1,
    value: 'Pending',
    text: 'Pending',
  },
  {
    key: 2,
    value: 'Processing',
    text: 'Processing',
  },
  {
    key: 3,
    value: 'Blocked',
    text: 'Blocked',
  },
  {
    key: 4,
    value: 'Processed',
    text: 'Processed',
  },
];
export const RatingServiceType = {
  Claims: 'Claims',
  Fulfilment: 'Fulfilment',
};

export const HomeTiles = [
  {
    id: 'fulfilment',
    title: 'Fulfilments',
    icons: 'bi bi-shield-plus',
    link: '/fulfilments',
    count: 0,
  },
  {
    id: 'subscription',
    title: 'Subscriptions',
    icons: 'bi bi-person-circle',
    link: '/subscriptions',
    count: 0,
  },
  {
    id: 'claim',
    title: 'Claims',
    icons: 'bi bi-file-medical',
    link: '/claims',
    count: 0,
  },

  {
    id: 'pharmacy',
    title: 'Pharmacies',
    icons: 'bi bi-shop',
    link: '/pharmacies',
    count: 0,
  },
  {
    id: 'message',
    title: 'Messages',
    icons: 'bi bi-envelope',
    link: '/messages',
    count: 0,
  },
  {
    id: 'wallet',
    title: 'Wallets',
    icons: 'bi bi-wallet',
    link: '/wallets',
    count: 0,
  },

  {
    id: 'loans',
    title: 'Loans',
    icons: 'bi bi-bank',
    link: '/loans/applications',
    count: 0,
  },
  {
    id: 'support',
    title: 'Support Requests',
    icons: 'bi bi-ticket',
    link: '/support-requests',
    count: 0,
  },
];

export const ReportedIssues = [
  {
    key: 1,
    value: 'Late response',
    text: 'Late response',
  },
  {
    key: 2,
    value: 'Poor Handover',
    text: 'Poor Handover',
  },
  {
    key: 3,
    value: 'Not aware of wallet',
    text: 'Not aware of wallet',
  },
  {
    key: 4,
    value: 'Not seeing Wallet Balance',
    text: 'Not seeing Wallet Balance',
  },
  {
    key: 5,
    value: 'Not aware of wallet',
    text: 'Not aware of wallet',
  },
  {
    key: 6,
    value: 'Not onboarded to App',
    text: 'Not onboarded to App',
  },
  {
    key: 7,
    value: 'Rude attendant',
    text: 'Rude attendant',
  },
  {
    key: 8,
    value: 'Misinforming Enrollee about prescriptions',
    text: 'Misinforming Enrollee about prescriptions',
  },
  {
    key: 9,
    value: 'Claims they do not know WellaHealth',
    text: 'Claims they do not know WellaHealth',
  },
  {
    key: 10,
    value: 'Others',
    text: 'Others',
  },
];

export const OnboardingStatus = [
  {
    key: 1,
    value: 'NotOnboarded',
    text: 'Not Onboarded',
  },
  {
    key: 2,
    value: 'Onboarded',
    text: 'Onboarded',
  },
  {
    key: 2,
    value: 'NotApplicable',
    text: 'Not Applicable',
  },
];

export const PharmacyType = [
  {
    key: 1,
    value: 'Pharmacy',
    text: 'Pharmacy',
  },
  {
    key: 2,
    value: 'Laboratory',
    text: 'Laboratory',
  },
  {
    key: 3,
    value: 'PPMV',
    text: 'PPMV',
  },
  {
    key: 4,
    value: 'PharmacyAndLab',
    text: 'Pharmacy + Lab',
  },
];

export const FulfilmentDetailsTab = {
  info: 'Info',
  medications: 'Medications',
  messages: 'Messages',
  pharmacy: 'Pharmacy',
  tests: 'Tests',
  misc: 'Misc',
};

export const FulfilmentCommunicationTabs = {
  sms: 'SMS',
  notes: 'Notes',
  email: 'Email',
  whatsapp: 'WhatsApp',
  chat: 'Pharmacy Chat',
};

export const SubscriptionDetailsTab = {
  info: 'Info',
  payments: 'Payments',
  claims: 'Claims',
  beneficiaries: 'Beneficiaries',
  nextOfKin: 'NextOfKin',
  misc: 'Misc',
};

export const fulfilmentTableTabs = {
  all: 'All',
  pending: 'Pending',
  processing: 'Processing',
  dispensed: 'Dispensed',
  approved: 'Approved',
  cancelled: 'Cancelled',
  sortingCenter: 'SortingCenter',
};

export const LoadingStatus = {
  LOADING: 'Loading', //api loading and no data
  NODATA: 'NoData', //api loaded but no data
  LOADED: 'Loaded', //api loaded with data
};

export const FulfilmentCallbackTypes = {
  LoadAll: 'Loading',
  LoadAdvancedSearch: 'NoData',
  LoadQuery: 'Loaded',
};

export const HmoFileReport = [
  {
    key: 1,
    value: 'graphImageFileUrl',
    text: 'HMO TAT Graph',
  },
  {
    key: 2,
    value: 'ratingFileUrl',
    text: 'Satisfaction Ratings',
  },
];

export const HmoFileReportMap = {
  graphImageFileUrl: 'graphImageFileUrl',
  ratingFileUrl: 'ratingFileUrl',
};

export const HMOReviewURLs: HMOReviewURL[] = [
  {
    partnerCode: 'WHPX10002',
    reviewURL: 'https://forms.gle/rYvnkCT7mncuXR477',
    partnerName: 'Reliance HMO',
  },
  {
    partnerCode: 'WHPX10003',
    reviewURL: 'https://forms.gle/LMTF76pAC7r2hyJR7',
    partnerName: 'AXA Mansard',
  },
  {
    partnerCode: 'WHPX10005',
    reviewURL: 'https://forms.gle/VSWPcSF8DntM5KueA',
    partnerName: 'Hygeia',
  },
  {
    partnerCode: 'WHPX10006',
    reviewURL: 'https://forms.gle/gpaVPNbHBazHv7yF7',
    partnerName: 'Redcare',
  },
  {
    partnerCode: 'WHPX10007',
    reviewURL: 'https://forms.gle/SY6GZLKkL2zqYasM9',
    partnerName: 'Total Health Trust',
  },
  {
    partnerCode: 'WHPX10008',
    reviewURL: 'https://forms.gle/QrGhkAvFHnAbsrkf9',
    partnerName: 'Leadway',
  },
  {
    partnerCode: 'WHPX10078',
    reviewURL: 'https://forms.gle/Hoe1UxQ19f4cTBRA9',
    partnerName: 'Bastion Health',
  },
  {
    partnerCode: 'WHPX10079',
    reviewURL: 'https://forms.gle/NMhF8mEXt1LM89xe6',
    partnerName: 'Alleanza',
  },
  {
    partnerCode: 'WHPX10086',
    reviewURL: 'https://forms.gle/bBXXcSn9u2E15EF6A',
    partnerName: 'Kennedia HMO',
  },
  {
    partnerCode: 'WHPX10004',
    reviewURL: 'https://forms.gle/3trpMAxMd7h5srQ99',
    partnerName: 'Pro Health',
  },
  {
    partnerCode: 'WHPX10077',
    reviewURL: 'https://forms.gle/2G1rufZsHamNd82t5',
    partnerName: 'Marina HMO',
  },
  {
    partnerCode: 'WHPX10080',
    reviewURL: 'https://forms.gle/UZ9pszdMXcpn71U16',
    partnerName: 'Life Action Plus',
  },
  {
    partnerCode: 'WHPX10110',
    reviewURL: 'https://forms.gle/Ybz6EhBdYB6yMwZYA',
    partnerName: 'Rothauge',
  },
];

export const ClaimTypes = [
  {
    key: 0,
    value: 'Basic',
    text: 'Basic',
  },
  // {
  //   key: 1,
  //   value: 'Typhoid',
  //   text: 'Typhoid',
  // },
  // {
  //   key: 2,
  //   value: 'Telemedicine',
  //   text: 'Telemedicine',
  // },
  // {
  //   key: 3,
  //   value: 'Tests (Blood Sugar, Blood Pressure)',
  //   text: 'Tests (Blood Sugar, Blood Pressure)',
  // },
  // {
  //   key: 4,
  //   value: 'Common Diseases (Cough, Cold)',
  //   text: 'Common Diseases (Cough, Cold)',
  // },
];

export const MapMarkerColors = {
  StartingLocation: '#5ba532',
  InternalPharmacy: '#1489c8',
  Active: 'Active',
  Inactive: 'Inactive',
  Cancelled: 'Cancelled',
};

export const VeriClaimJobTypeMap = [
  {
    key: 0,
    text: 'Reliance Robot',
    value: 'RelianceRobot',
  },
  {
    key: 1,
    text: 'Hygeia Robot',
    value: 'HygeiaRobot',
  },
  {
    key: 2,
    text: 'Curacel Robot',
    value: 'CuracelJob',
  },
  {
    key: 3,
    text: 'Medismart Job',
    value: 'MedismartJob',
  },
];

export const VeriClaimHMOs = [
  {
    key: 0,
    text: 'Reliance',
    value: 'WHPX10002',
    jobType: VeriClaimJobType.RelianceRobot,
  },
  {
    key: 1,
    text: 'Hygeia',
    value: 'WHPX10005',
    jobType: VeriClaimJobType.HygeiaRobot,
  },
  {
    key: 0,
    text: 'Redcare',
    value: 'WHPX10006',
    jobType: VeriClaimJobType.MedismartJob,
  },
  {
    key: 0,
    text: 'Total Health Trust',
    value: 'WHPX10007',
    jobType: VeriClaimJobType.CuracelJob,

    // curacelId: 'tht',
  },
  {
    key: 1,
    text: 'Alleanza',
    value: 'WHPX10079',
    jobType: VeriClaimJobType.CuracelJob,

    // curacelId: 'alleanza',
  },
];

export const RobotHMO = [
  {
    key: 0,
    text: 'Reliance',
    value: 'WHPX10002',
  },
  {
    key: 1,
    text: 'Hygeia',
    value: 'WHPX10002',
  },
];
export const MedismartHMO = [
  {
    key: 0,
    text: 'Redcare',
    value: 'WHPX10006',
  },
];

export const CuracelHMO = [
  {
    key: 0,
    text: 'Total Health Trust',
    value: 'WHPX10007',
    // curacelId: 'tht',
  },
  {
    key: 1,
    text: 'Alleanza',
    value: 'WHPX10079',
    // curacelId: 'alleanza',
  },
];
