import React, {
  useState,
  useEffect,
  // useContext,
  Fragment,
  useCallback,
} from 'react';
import { IAgentData } from '../../../interface/AgentInterface';
// import {AppContext} from '../../../context/AppContext';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { formatDate } from '../../../utils/dateTimeUtils';
import { addToast } from '../../../utils/toastNotifications';

const AgentsTable = ({ history }: any) => {
  // const { dispatch } = useContext(AppContext);
  const [agentData, setAgentData] = useState<IAgentData[]>([]);

  const getAgentData = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.InsuranceAgents.mainUrl);
      setAgentData(response.data);
    } catch (error: any) {
      addToast('There was an error getting agents data', 'error');
    }
  }, []);

  const viewSubscriptions = (agentCode: string) => {
    history.push(`/subscriptions?type=AgentNetwork&code=${agentCode}`);
  };

  const changeStatus = async (agentCode: string) => {
    const agent = agentData.filter((x) => x.agentCode === agentCode)[0];

    const agentStatus = agent.status === 'Active' ? 'Inactive' : 'Active';

    const payload = {
      agentCode: agentCode,
      status: agentStatus,
    };

    const url = `${endpoints.InsuranceAgents.changeStatus}`;

    try {
      const response = await axios.put(url, payload);
      addToast('Status updated', 'success');
      if (response.status === 200) {
        getAgentData();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getAgentData();
  }, [getAgentData]);

  return (
    <Fragment>
      <div className='d-flex'>
        <h3 className=' mr-auto'>Agents</h3>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col-2'>Actions</th>
              <th scope='col'>Agent Code</th>
              <th scope='col'>Name</th>
              <th scope='col'>Phone</th>
              <th scope='col'>Email</th>
              <th scope='col'>Status</th>
              <th scope='col'>Address</th>
              <th scope='col'>State Of residence</th>
              <th scope='col'>Guarantor 1</th>
              <th scope='col'>Guarantor 2</th>
              <th scope='col'>Super Agent</th>
              <th scope='col'>Number of Subscribers</th>
              <th scope='col'>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {agentData.map((item: IAgentData) => {
              return (
                <tr key={item.insuranceAgentId}>
                  <td>
                    <i
                      onClick={() => viewSubscriptions(item.agentCode)}
                      className='bi bi-people group-icon'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='View Subscriptions'
                    ></i>
                    <i
                      onClick={() => changeStatus(item.agentCode)}
                      className={
                        item.status === 'Active'
                          ? 'bi bi-x-circle-fill group-icon'
                          : 'bi bi-check-lg group-icon'
                      }
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title={item.status === 'Active' ? 'Deactivate' : 'Activate'}
                    ></i>
                  </td>

                  <td>{item.agentCode}</td>
                  <td>
                    {item.firstName} {item.lastName}
                  </td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.email}</td>
                  <td>{item.status}</td>
                  <td>{item.location}</td>
                  <td>{item.stateOfResidence}</td>
                  <td>{item.guarantor1}</td>
                  <td>{item.guarantor2}</td>
                  <td>{item.superAgentName}</td>
                  <td>{item.numberOfSubscribers}</td>
                  <td>{formatDate(item.dateCreated)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default AgentsTable;
