import { useState } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { addToast } from '../../../utils/toastNotifications';
import {
  initialRequest,
  //   ICreateHealthSendRequest,
  ICreateHealthSendBeneficiary,
  IHealthSendBeneficiary,
  IHealthSendUser,
} from '../../../interface/HealthSendInterface';
import { Modal } from 'react-bootstrap';
import LoaderWithText from '../../common/LoaderWithText';

const AddHealthSendRequest = () => {
  const { userId } = useParams() as any;

  const [request, setRequest] = useState(initialRequest);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState('');

  const beneficiaryList: IHealthSendBeneficiary[] = JSON.parse(
    sessionStorage.getItem('healthsendBeneficiaryList') as string
  );

  const healthSendUser: IHealthSendUser = JSON.parse(
    sessionStorage.getItem('healthSendUser') as string
  );

  const addBeneficiary = () => {
    if (selectedBeneficiary === '') {
      addToast('No beneficiary selected', 'warning');
      return;
    }

    setModalLoading(true);
    try {
      var beneficiary = JSON.parse(selectedBeneficiary);
      var beneficiaryList = request.beneficiaries;
      var newBeneficiary: ICreateHealthSendBeneficiary = {
        ...beneficiary,
        userId: userId,
        countryCode: request.countryCode,
        planCode: 'WHV10000',
      };
      beneficiaryList.push(newBeneficiary);
      setRequest({
        ...request,
        beneficiaries: beneficiaryList,
      });
      addToast('Beneficiary added', 'success');
      setSelectedBeneficiary('');
    } catch (error) {
      addToast('Error adding beneficiary', 'error');
    } finally {
      setModalLoading(false);
    }
  };

  const clearBeneficiaries = () => {
    var answer = prompt('Are you sure you want to clear the Beneficiaries? Y/N');
    const answerLower = answer?.toLowerCase();
    if (answerLower === 'y') {
      setRequest({
        ...request,
        beneficiaries: [],
      });
      addToast('Beneficiaries cleared', 'info');
    } else {
      addToast('Operation cancelled', 'info');
    }
  };

  const handleChange = (input: string) => (e: any) => {
    setRequest({
      ...request,
      [input]: e.target.value,
    });
  };

  const sumbitRequest = async (e: any) => {
    e.preventDefault();

    if (request.beneficiaries.length === 0) {
      addToast('No Beneficiary Added', 'error');
      return;
    }

    if (request.totalAmount === 0) {
      addToast('Amount cannot be 0', 'error');
    }

    setLoading(true);

    let url = endpoints.HealthSend.requests;

    request.firstName = healthSendUser.firstName;
    request.lastName = healthSendUser.lastName;
    request.email = healthSendUser.email;
    request.phoneNumber = healthSendUser.phoneNumber;
    request.countryCode = healthSendUser.countryCode;
    request.externalId = healthSendUser.externalId;

    try {
      const response = await axios.post(url, request);
      if (response.status === 200) {
        addToast('Request sent successfully', 'success');
      }
      setRequest(initialRequest);
    } catch (error) {
      addToast('Error Creating Request', 'error');
    } finally {
      setLoading(false);
    }
  };

  let addBeneficiaryModal = (
    <Modal show={modalOpen} onHide={() => setModalOpen(false)} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Add Beneficiary to Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalLoading ? (
          <LoaderWithText />
        ) : (
          <div>
            <label>Please select a Beneficiary</label>
            <select
              className='form-control'
              onChange={(e) => setSelectedBeneficiary(e.target.value)}
            >
              <option value='-'>-</option>
              {beneficiaryList.map((beneficiary) => (
                <option key={beneficiary.beneficiaryId} value={JSON.stringify(beneficiary)}>
                  {beneficiary.firstName} {beneficiary.lastName}
                </option>
              ))}
            </select>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-secondary' onClick={() => setModalOpen(false)}>
          Close
        </button>
        <button className='btn btn-success' onClick={addBeneficiary}>
          Add to Request
        </button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {addBeneficiaryModal}
      <div>
        <h2 className='text-center mb-5'>Add Request Form</h2>
      </div>
      <form onSubmit={sumbitRequest}>
        <fieldset disabled={loading}>
          <div className='row'>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>Firstname</label>
                <input
                  value={healthSendUser.firstName}
                  // onChange={handleChange('firstName')}
                  className='form-control'
                  disabled
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>Lastname</label>
                <input
                  className='form-control'
                  value={healthSendUser.lastName}
                  // onChange={handleChange('lastName')}
                  disabled
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>Phone Number</label>
                <input
                  className='form-control'
                  value={healthSendUser.phoneNumber}
                  // onChange={handleChange('phoneNumber')}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>Country Code</label>
                <input
                  className='form-control'
                  value={healthSendUser.countryCode}
                  // onChange={handleChange('countryCode')}
                  disabled
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>External ID</label>
                <input
                  className='form-control'
                  value={healthSendUser.externalId}
                  // onChange={handleChange('externalId')}
                  disabled
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>Email</label>
                <input
                  className='form-control'
                  value={healthSendUser.email}
                  // onChange={handleChange('email')}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>Transaction Reference</label>
                <input
                  className='form-control'
                  value={request.transactionReference}
                  onChange={handleChange('transactionReference')}
                  required
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>Total Amount</label>
                <input
                  className='form-control'
                  type='number'
                  value={request.totalAmount}
                  onChange={handleChange('totalAmount')}
                  required
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-4'>
              <div className='form-group'>
                <label>
                  Beneficiaries ({request.beneficiaries.length}){' '}
                  {request.beneficiaries.length > 0 ? (
                    <span className='ml-5' onClick={clearBeneficiaries}>
                      <i className='bi bi-trash-fill text-danger' />
                    </span>
                  ) : (
                    <div></div>
                  )}
                </label>
                <button
                  type='button'
                  className='btn btn-primary btn-block'
                  onClick={() => setModalOpen(true)}
                >
                  Add Beneficiary<i className='bi bi-person-add'></i>
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <LoaderWithText />
          ) : (
            <button type='submit' className='btn btn-success btn-block mt-5'>
              Submit Request
            </button>
          )}
        </fieldset>
      </form>
    </div>
  );
};

export { AddHealthSendRequest };
