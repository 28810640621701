export interface IPriceList {
  priceListId: number;
  drugName: string;
  newDrugName: string;
  drugPrice: number;
  unitPrice: number;
  source: string;
  services: string;
  genericName: string;
  dosageForm: string;
  strength: string;
  notes: string;
  dateUpdated: string;
}

export interface IPriceListUpdate extends IPriceList {
  brandName: string;
  drugClass: string;
  packSize: number;
  // priceListId: number;
  // drugName: string;
  // drugPrice: number;
  // unitPrice: number;
  // source: string;
  // services: string;
  // groupName: string;
  // notes: string;
  // dateCreated: string;
  // dateUpdated: string;
  // sortingCenterPriceId: number;
}

export interface IPharmacyPriceList {
  priceId: number;
  pharmacyCode: string;
  referenceId: string;
  drugName: string;
  drugCost: string;
  additionalProps: string;
}

export interface ItemAvailabilityDTO {
  quantity: number;
  warehouse: string;
  drugName: string;
}

export interface ItemAvailabilityModalDTO {
  item: IPharmacyPriceList | undefined;
  open: boolean;
  close: Function;
}

export interface IEditPriceList {
  open: boolean;
  close: Function;
  modalData: IPriceList;
  callback?: Function;
  partnerCode: string;
}

export interface IAlternativePriceList {
  open: boolean;
  close: Function;
  pricelistId: number;
  drugId: number;
  partnerCode: string;
  fulfilmentService: string;
  quantity: number;
  callback?: Function;
}

export interface IPriceCheckDTO {
  drugName: string;
  inputAddress: string;
  stateOfPremise: string;
  lga: string;
  locationArea: string;
}

export interface IPriceCheckResponse {
  id: string;
  drugName: string;
  unitPrice: number;
  stateOfResidence: string;
  lga: string;
  locationArea: string;
  quantity: number;
}

export interface ITariffSuggestion {
  callback?: (drugId: number, selected?: IPriceList) => void;
  drugId: number;
  data: IPriceList[];
  searchTerm: string;
  required: boolean;
}

export interface ITariffSection {
  callback?: (drugId: number, selected?: ITariffSearchResult) => void;
  fulfilmentService: string;
  partnerCode: string;
  pharmacyCode: string;
  isActive: boolean;
  drugId: number;
  required: boolean;
}

export interface ITariffSearchResult {
  searchTerm: string;
  tariffCost: number;
  notes: string;
  quantity: number;
  unitCost: number;
  priceListId: number;
  tariffDrugName: string;
  newTariffDrugName: string;
  strength: string;
  dosageForm: string;
}

export const priceListFormData: IPriceListUpdate = {
  priceListId: 0,
  drugName: '',
  newDrugName: '',
  drugPrice: 0,
  unitPrice: 0,
  source: '',
  services: 'Acute,Chronic,Telemedicine',
  genericName: '',
  dosageForm: '',
  strength: '',
  notes: '',
  dateUpdated: '',
  brandName: '',
  drugClass: '',
  packSize: 1,
};
