import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { IZoiOrderResponse } from '../../../interface/ZoiOrderInterface';
import { addToast } from '../../../utils/toastNotifications';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import TableLoading from '../../common/TableLoading';
import { useParams } from 'react-router-dom';

const ZoiOrdersTable = () => {
  const { zoiUserId } = useParams() as any;

  const [allData, setAllData] = useState<IZoiOrderResponse[]>([]); //to hold all table result in unfiltered format
  const [tableData, setTableData] = useState<IZoiOrderResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState();

  const loadZoiOrders = useCallback(
    async (pageIndex: number) => {
      let url = `${endpoints.ZoiOrders.mainUrl}?pageSize=200&pageIndex=${pageIndex}`;

      if (typeof zoiUserId !== 'undefined' && zoiUserId !== '') {
        url = `${endpoints.ZoiOrders.getByUserId}/${zoiUserId}`;
      }

      try {
        setIsLoading(true);
        const response = await axios.get(url);

        setTableData(response.data);
        setAllData(response.data);

        if (response.data.length === 0) {
          addToast('No orders found', 'info');
        }
      } catch (error: any) {
        addToast('Error getting order', 'error');
        // setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [zoiUserId]
  );

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setTableData(allData);
      return;
    }

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.phoneNumber?.toLowerCase().includes(searchParam) ||
        x.dataProvider?.toLowerCase().includes(searchParam) ||
        x.dataPackage?.toLowerCase().includes(searchParam)
    );

    // //set table data to search result
    setTableData(searchResult);
  };

  const requeueOrder = async (orderId: number) => {
    let response = window.confirm('Do you want to resend this order?');

    if (!response) {
      return;
    }

    const url = `${endpoints.ZoiOrders.mainUrl}/queue`;
    const payload = {
      orderId: orderId,
    };
    try {
      await axios.post(url, payload);
      addToast('Order requeued successful');
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    const runOnComponentMount = async () => {
      await loadZoiOrders(1);
    };
    runOnComponentMount();
  }, [loadZoiOrders]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col-4'>Actions</th>
        <th scope='col'>Order Status</th>
        <th scope='col'>Phone number</th>
        <th scope='col'>Data provider</th>
        <th scope='col'>Data package</th>
        <th scope='col'>Amount</th>
        <th scope='col'>Notes</th>
        <th scope='col'>Date Created</th>
      </tr>
    </thead>
  );

  let noDataView = (
    <tbody className='text-center'>
      <tr>
        <td colSpan={11}>No Data found</td>
      </tr>
    </tbody>
  );

  let tableBody: JSX.Element = (
    <>
      {tableData ? (
        <tbody>
          {tableData.map((order: IZoiOrderResponse) => {
            return (
              <tr key={order.orderId}>
                <td>
                  {order.orderStatus === 'Pending' || order.orderStatus === 'Hold' ? (
                    <i
                      className='bi bi-arrow-clockwise group-icon'
                      onClick={() => requeueOrder(order.orderId)}
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Requeue Order'
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td>{order.orderStatus}</td>
                <td>{order.phoneNumber}</td>
                <td>{order.dataProvider}</td>
                <td>{order.dataPackage}</td>
                <td>{order.amountPaid}</td>
                <td>{order.notes}</td>
                <td>{formatDateTime(order.dateCreated)}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        noDataView
      )}
    </>
  );

  if (isLoading) {
    return <TableLoading />;
  }

  return (
    <div>
      {/* {infoModal} */}

      <br />
      <h3 className='mr-auto'>Zoi Orders ({tableData.length})</h3>
      <div className='row'>
        <input
          type='search'
          className='form-control col-4'
          placeholder='Search by phone'
          onChange={searchTable}
        />
      </div>
      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default ZoiOrdersTable;
