import {
  IGetHealthSendClaims,
  IHealthSendDrug,
  IHealthSendTest,
} from '../interface/HealthSendInterface';

const healthSendDrug: IHealthSendDrug = {
  drugId: 11,
  enrollmentDrugId: 13,
  name: 'Coartem',
  dose: '2 pills',
  frequency: 'twice daily',
  strength: '100mg',
  duration: '5 days',
  requestId: 1191,
  isDispensed: true,
  cost: 1200,
  tariffCost: 240,
  pharmacyCode: 'WHX1001',
  isPharmacyPaid: true,
  useSortingCenter: true,
  notes: '',
  tariffDrugName: 'Coartem',
  unitCost: 1200,
  quantity: 1,
};

const healthSendTest: IHealthSendTest = {
  testId: 11,
  enrollmentTestId: 19,
  testName: 'Malaria RDT',
  isCarriedOut: true,
  testResult: 'Positive',
  cost: 600,
  tariffCost: 250,
  requestId: 112,
  fileName: '',
  notes: '',
  pharmacyCode: 'WHX1001',
  isPharmacyPaid: true,
  hasPartnerPaid: true,
};

export const mockHealthSendClaims: IGetHealthSendClaims[] = [
  {
    claimId: 1,
    beneficiaryId: 12,
    enrollmentCode: 'HS2210',
    pharmacyCode: 'WHX1029',
    fulfilmentStatus: 'Completed',
    fulfilmentService: 'Acute',
    partnerCode: 'WHP1922',
    drugs: [healthSendDrug, healthSendDrug],
    tests: [healthSendTest],
  },
  {
    claimId: 33,
    beneficiaryId: 23,
    enrollmentCode: 'HS2299',
    pharmacyCode: 'WHX1129',
    fulfilmentStatus: 'Completed',
    fulfilmentService: 'Chronic',
    partnerCode: 'WHP1209',
    drugs: [healthSendDrug, healthSendDrug, healthSendDrug],
    tests: [healthSendTest],
  },
];
