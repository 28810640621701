import React, { Fragment, useState } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import ButtonLoader from '../../common/ButtonLoader';
import { NairaSymbol, PharmacyPriceListMap } from '../../../utils/constants';
import { IPharmacyPriceList } from '../../../interface/PriceListInterface';
import ItemAvailabilityModal from './ItemAvailabilityModal';
import { isFormValidated } from '../../../utils/formUtils';

const PharmacyPriceList = ({ history }: any) => {
  const initialParams = {
    pharmacyCode: '',
    searchQuery: '',
    pharmacyName: '',
  };

  const [tariffData, setTariffData] = useState<IPharmacyPriceList[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchParam, setSearchParam] = useState(initialParams);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<IPharmacyPriceList | undefined>();

  const handleChange = (input: string) => (event: any) => {
    setSearchParam({
      ...searchParam,
      [input]: event.target.value,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const selectData = (item: IPharmacyPriceList) => {
    setSelectedData(item);
    setModalOpen(true);
  };

  const onSearchClick = async (event: any) => {
    if (!isFormValidated('pricelist-search')) {
      return;
    }
    if (searchParam.pharmacyCode === '') {
      addToast('Please select a partner', 'warning');
      return;
    }

    event.preventDefault();

    setIsSearch(true);

    let url = `${endpoints.PharmacyPricelist.mainUrl}/${searchParam.pharmacyCode}/search?searchQuery=${searchParam.searchQuery}`;

    try {
      const response = await axios.get(url);
      setTariffData(response.data);
      setIsSearch(false);
    } catch (error: any) {
      addToast('There was an error getting data', 'error');
      setIsSearch(false);
    }
  };

  let searchInput = (
    <Fragment>
      <form className='row' id='pricelist-search'>
        <div className='col-md-4'>
          <label htmlFor='fulfilmentService' className='form-label'>
            Pharmacy Name
          </label>
          <select
            required
            onChange={handleChange('pharmacyCode')}
            value={searchParam.pharmacyCode}
            className='custom-select'
          >
            <option value=''></option>

            {PharmacyPriceListMap.map((option) => (
              <option key={option.pharmacyCode} value={option.pharmacyCode}>
                {option.pharmacyName}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-4'>
          <label htmlFor='searchQuery' className='form-label'>
            Drug Name
          </label>
          <input
            type='text'
            required
            onChange={handleChange('searchQuery')}
            value={searchParam.searchQuery}
            className='form-control'
          />
        </div>
        <div className='col-md-4'>
          <button
            onClick={onSearchClick}
            className='btn btn-block btn-primary'
            style={{ marginTop: '30px' }}
          >
            Search
            {isSearch ? <ButtonLoader /> : ''}
          </button>
        </div>
      </form>
    </Fragment>
  );

  let itemAvailabilityModal = (
    <ItemAvailabilityModal open={modalOpen} close={closeModal} item={selectedData} />
  );

  return (
    <Fragment>
      {itemAvailabilityModal}
      <div className='container' style={{ margin: '30px 0px' }}>
        {searchInput}
      </div>
      <div className='d-flex'>
        <h4 className='mr-auto'>
          {/* {tariffData.length > 0 && !isSearch
            ? `${searchParam.partnerName} tariff for ${searchParam.fulfilmentService} service`
            : ''} */}
        </h4>
      </div>

      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col-md-3'>Drug Name</th>
              <th scope='col-md-3'>Drug Price</th>
              <th scope='col-md-3'>Availability</th>
            </tr>
          </thead>
          <tbody>
            {tariffData.map((item: IPharmacyPriceList) => {
              return (
                <tr key={item.priceId}>
                  <td>{item.drugName}</td>
                  <td>
                    {NairaSymbol} {item.drugCost.toLocaleString()}
                  </td>
                  <td>
                    <button className='btn btn-info btn-sm' onClick={() => selectData(item)}>
                      Check Availability
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PharmacyPriceList;
