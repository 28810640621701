import { IRoute } from '../interface/CommonInterface';

//For routes with sub routes, add the sub-routes AFTER the main subroute
//e.g /profile has a sub route /profile/edit therefore, /profile/edit must be below /profile when declared below

const routes: IRoute[] = [
  {
    path: '/home',
    name: 'Home',
    tags: '',
    icon: '',
  },
  {
    path: '/partners',
    name: 'Partners',
    tags: '',
    icon: '',
  },
  {
    path: '/pharmacies',
    name: 'Pharmacies',
    tags: '',
    icon: '',
  },
  {
    path: '/pharmacies/add',
    name: 'Add Pharmacy',
    tags: '',
    icon: '',
  },
  {
    path: '/support-requests',
    name: 'Support Requests',
    tags: '',
    icon: '',
  },
  {
    path: '/claims',
    name: 'Insurance Claims',
    tags: '',
    icon: '',
  },
  {
    path: '/claims/:subscriptioncode',
    name: 'Insurance Claims',
    tags: '',
    icon: '',
  },
  {
    path: '/leads',
    name: 'Leads',
    tags: '',
    icon: '',
  },
  {
    path: '/messages',
    name: 'Message Hub',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/:subscriptionCode/details',
    name: 'Subscription Details',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/:partnercode',
    name: 'Subscriptions',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/add',
    name: 'Add Subscriptions',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/:subscriptioncode/beneficiaries',
    name: 'Beneficiaries',
    tags: '',
    icon: '',
  },

  {
    path: '/subscriptions/:subscriptioncode/claims/add',
    name: 'Add Claim',
    tags: '',
    icon: '',
  },
  {
    path: '/subscriptions/:subscriptioncode/payments',
    name: 'Subscription Payments',
    tags: '',
    icon: '',
  },
  {
    path: '/received-payments',
    name: 'Received Payments',
    tags: '',
    icon: '',
  },
  {
    path: '/fulfilments',
    name: 'Fulfilments Table',
    tags: '',
    icon: '',
  },
  {
    path: '/fulfilments/:requestId/details',
    name: 'Fulfilment Details',
    tags: '',
    icon: '',
  },
  {
    path: '/fulfilments/partners',
    name: 'Fulfilment Partners',
    tags: '',
    icon: '',
  },
  {
    path: '/fulfilments/add',
    name: 'Add Fulfilments',
    tags: '',
    icon: '',
  },
  {
    path: '/fulfilments/export',
    name: 'Export Fulfilments',
    tags: '',
    icon: '',
  },
  {
    path: '/wallets',
    name: 'Wallet Account Table',
    tags: '',
    icon: '',
  },
  {
    path: '/wallets/transactions',
    name: 'Transactions',
    tags: '',
    icon: '',
  },
  {
    path: '/wallets/:accountId/transactions',
    name: 'Transactions',
    tags: '',
    icon: '',
  },
  {
    path: '/tariff',
    name: 'Tariff table',
    tags: '',
    icon: '',
  },
  {
    path: '/tariff/add',
    name: 'Add Tariff',
    tags: '',
    icon: '',
  },
  {
    path: '/tariff/:pricelistid/edit',
    name: 'Edit Tariff',
    tags: '',
    icon: '',
  },
  {
    path: '/notifications',
    name: 'Notifications table',
    tags: '',
    icon: '',
  },
  {
    path: '/zoi/users',
    name: 'Zoi Users table',
    tags: '',
    icon: '',
  },
  {
    path: '/zoi/users:zoiuserid',
    name: 'Zoi Users table',
    tags: '',
    icon: '',
  },
  {
    path: '/zoi/orders',
    name: 'Zoi Orders table',
    tags: '',
    icon: '',
  },
];

export default routes;
