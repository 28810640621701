import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IFulfilmentPartners } from '../../../interface/FulfilmentPartnersInterface';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { isFormValidated } from '../../../utils/formUtils';
import Select from 'react-select';
import {
  AllFulfilmentServiceMap,
  AllFulfilmentStatusMap,
  FulfilmentExport,
} from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';
import './styles.css';
import { exportData } from '../../../interface/FulfilmentsInterface';
import { formatInputDate } from '../../../utils/dateTimeUtils';
import { useAuth } from 'react-oidc-context';

const ExportFulfilmentData = () => {
  const [isLoading, setLoading] = useState(false);
  const [initialExportData, setInitialExportData] = useState(exportData);
  const [partnersListSelect, setPartnersListSelect] = useState<IFulfilmentPartners[]>([]);

  const [partnerCode, setPartnerCode] = useState(initialExportData.partnerCode);
  const auth = useAuth();

  const getPartnersList = async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;

    try {
      const response = await axios.get(url);
      let selectData = response.data.map((val: IFulfilmentPartners) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      //set to state
      setPartnersListSelect(selectData);
      //save to local
      //TODO: save to context
      sessionStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const clearForm = () => {
    setLoading(false);
    setInitialExportData({ ...initialExportData });
  };

  const selectPartnerCodeChange = (selectedValue: any) => {
    setPartnerCode(selectedValue.value);
  };

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValidated('export-data-form') || partnerCode === '') {
      addToast('Please fill all fields and select a partner', 'warning');
      return;
    }

    const payload = initialExportData;
    payload.partnerCode = partnerCode;

    //if all is selected, set as null
    payload.fulfilmentService =
      payload.fulfilmentService === 'All' ? null : payload.fulfilmentService;

    payload.fulfilmentStatus = payload.fulfilmentStatus === 'All' ? null : payload.fulfilmentStatus;

    let url = `${endpoints.FulfilmentExport.mainUrl}`;

    try {
      setLoading(true);
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        addToast('Export successfully sent to your email', 'success');
      }
      clearForm();
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 422) {
          addToast('No data was exported');
        }
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setInitialExportData({
      ...initialExportData,
      [input]: event.target.value,
    });
  };

  useEffect(() => {
    exportData.email = auth?.user?.profile.email as string;
    setInitialExportData((x) => (x = exportData));

    const savedPartnersList = sessionStorage.getItem('partnersList');
    if (savedPartnersList !== null) {
      const partnersList = JSON.parse(savedPartnersList);
      let selectData = partnersList.map((val: { partnerName: string; partnerCode: string }) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      //set to state
      setPartnersListSelect(selectData);
    } else {
      //call api
      getPartnersList();
    }
  }, [auth]);

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Export Fulfilment Data</h3>
      <br />
      <form className='row justify-content-center' id='export-data-form'>
        <fieldset id='form-fields' disabled={isLoading ? true : false} className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='email' className='form-label'>
              Email address
            </label>
            <input
              value={initialExportData.email}
              className='form-control '
              type='email'
              placeholder='name@example.com'
              onChange={handleChange('email')}
            />
          </div>
          <br />

          <div className='form-group'>
            <label htmlFor='email' className='form-label'>
              Select Partner Name
            </label>
            <Select
              options={partnersListSelect}
              onChange={selectPartnerCodeChange}
              className='react-select'
              classNamePrefix='react-select'
            />
          </div>
          <br />
          <div className='form-group'>
            <label htmlFor='fulfilmentService' className='form-label'>
              Fulfilment Service
            </label>
            <select
              required
              onChange={handleChange('fulfilmentService')}
              value={initialExportData.fulfilmentService?.toString()}
              className='custom-select'
            >
              {AllFulfilmentServiceMap.map((option) => (
                <option key={option.key} value={option.value.toString()}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <br />

          <div className='form-group'>
            <label htmlFor='fulfilmentStatus' className='form-label'>
              Fulfilment Status
            </label>
            <select
              required
              onChange={handleChange('fulfilmentStatus')}
              value={initialExportData.fulfilmentStatus!}
              className='custom-select'
            >
              {AllFulfilmentStatusMap.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <br />
          <div className='form-group'>
            <label htmlFor='fulfilmentService' className='form-label'>
              Export Type
            </label>
            <select
              required
              onChange={handleChange('fulfilmentExportType')}
              value={initialExportData.fulfilmentExportType}
              className='custom-select'
            >
              {FulfilmentExport.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <br />

          <div className='form-group'>
            <label htmlFor='startDate' className='form-label'>
              Start Date
            </label>
            <input
              value={formatInputDate(initialExportData.startDate)}
              required
              className='form-control '
              type='date'
              onChange={handleChange('startDate')}
            />
          </div>

          <br />

          <div className='form-group'>
            <label htmlFor='endDate' className='form-label'>
              End Date
            </label>
            <input
              value={formatInputDate(initialExportData.endDate)}
              required
              className='form-control '
              type='date'
              onChange={handleChange('endDate')}
            />
          </div>
          <br />
          <br />

          <button className='btn btn-block btn-primary' onClick={submit}>
            {isLoading ? <ButtonLoader /> : 'Export'}
          </button>
        </fieldset>
      </form>
    </div>
  );
};

export default ExportFulfilmentData;
