import React, {
  useState,
  useEffect,
  // useContext,
  Fragment,
  useCallback,
} from 'react';
import {
  IPartnerData,
  initialAddPartnerModalData,
  initialPartnerBulkPaymentData,
  initialClaimDataExportData,
} from '../../../interface/PartnerInterface';
// import {AppContext} from '../../../context/AppContext';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { formatDate } from '../../../utils/dateTimeUtils';
import { addToast } from '../../../utils/toastNotifications';
import ClaimExportModal from './ClaimModalExport';
import PartnerBulkPaymentModal from './BulkPaymentsModal';
import SubscriberUploadModal from './SubscriberUploadModal';
import AddPartnerModal from './AddPartnerModal';
import './styles.css';

const PartnersTable = ({ history }: any) => {
  // const { dispatch } = useContext(AppContext);
  const [partnerData, setPartnerData] = useState<IPartnerData[]>([]);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(initialAddPartnerModalData);

  const [isBulkPaymentModalOpen, setBulkPaymentModalOpen] = useState(false);
  const [bulkPaymentData, setBulkPaymentData] = useState(initialPartnerBulkPaymentData);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [exportModalData, setExportModalData] = useState(initialClaimDataExportData);
  const [isSubscriberModalOpen, setSubscriberModalOpen] = useState(false);

  const openBulkPaymentModal = (partnerName: string, partnerCode: string, partnerType: string) => {
    if (partnerType !== 'Company') {
      addToast(`Cannot resubscribe for partner type ${partnerType}`, 'info');
      return;
    }

    setBulkPaymentData({
      ...bulkPaymentData,
      name: partnerName,
      partnerCode: partnerCode,
    });
    setBulkPaymentModalOpen(true);
  };

  const closeBulkPaymentModal = () => {
    setBulkPaymentModalOpen(false);
  };

  const openExportModal = (partnerName: string, partnerCode: string, partnerType: string) => {
    if (partnerType !== 'Company') {
      addToast(`Cannot export claims for partner type ${partnerType}`, 'info');
      return;
    }

    setExportModalData({
      ...exportModalData,
      name: partnerName,
      partnerCode: partnerCode,
    });
    setExportModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const displayAddPartnerModal = () => {
    setModalData(initialAddPartnerModalData);
    setModalOpen(true);
  };

  const openSubscriberModal = (partnerName: string, partnerCode: string, partnerType: string) => {
    setBulkPaymentData({
      ...bulkPaymentData,
      name: partnerName,
      partnerCode: partnerCode,
    });
    setSubscriberModalOpen(true);
  };

  const closeSubscriberModal = () => {
    setSubscriberModalOpen(false);
    getPartnerData();
  };

  const getPartnerData = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.InsurancePartners.mainUrl);
      setPartnerData(response.data);
    } catch (error: any) {
      addToast('There was an error getting partners data', 'error');
    }
  }, []);

  const handleChange = (input: string) => (e: any) => {
    setModalData({
      ...modalData,
      [input]: e.target.value,
    });
  };

  const viewSubscriptions = (partnerCode: string) => {
    history.push(`/subscriptions?type=Partner&code=${partnerCode}`);
  };

  useEffect(() => {
    getPartnerData();
  }, [getPartnerData]);

  let AddNewPartnerModal = (
    <AddPartnerModal
      open={isModalOpen}
      close={closeModal}
      modalData={modalData}
      getPartners={getPartnerData}
      handleChange={handleChange}
    />
  );

  let exportModal = (
    <ClaimExportModal
      open={isExportModalOpen}
      close={closeExportModal}
      modalData={exportModalData}
    />
  );

  let bulkPaymentModal = (
    <PartnerBulkPaymentModal
      open={isBulkPaymentModalOpen}
      close={closeBulkPaymentModal}
      modalData={bulkPaymentData}
    />
  );

  let subscriberUploadModal = (
    <SubscriberUploadModal
      open={isSubscriberModalOpen}
      close={closeSubscriberModal}
      modalData={bulkPaymentData}
    />
  );

  return (
    <Fragment>
      {AddNewPartnerModal}
      {exportModal}
      {bulkPaymentModal}
      {subscriberUploadModal}
      <div className='add-agent-icon'>
        <button className='btn btn-primary' onClick={() => displayAddPartnerModal()}>
          Add new Partner
        </button>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col-2'>Actions</th>
              <th scope='col'>Partner Code</th>
              <th scope='col'>Name</th>
              <th scope='col'>Contact Phone</th>
              <th scope='col'>Contact Email</th>
              <th scope='col'>Active</th>
              <th scope='col'>Partner Type</th>
              <th scope='col'>Date Created</th>
              <th scope='col'>Total Patients</th>
            </tr>
          </thead>
          <tbody>
            {partnerData.map((item: IPartnerData) => {
              return (
                <tr key={item.insurancePartnerId}>
                  <td>
                    <i
                      onClick={() =>
                        openExportModal(
                          item.partnerName,
                          item.partnerCode,
                          item.partnerType.toString()
                        )
                      }
                      className='bi bi-arrow-down-square-fill group-icon'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Export Claim'
                    ></i>
                    <i
                      onClick={() =>
                        openBulkPaymentModal(
                          item.partnerName,
                          item.partnerCode,
                          item.partnerType.toString()
                        )
                      }
                      className='bi bi-credit-card-fill group-icon'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Resubscribe'
                    ></i>
                    <i
                      onClick={() => viewSubscriptions(item.partnerCode)}
                      className='bi bi-people group-icon'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='View Subscriptions'
                    ></i>
                    <i
                      onClick={() =>
                        openSubscriberModal(
                          item.partnerName,
                          item.partnerCode,
                          item.partnerType.toString()
                        )
                      }
                      className='bi bi-arrow-up-square-fill group-icon'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Upload subscribers'
                    ></i>
                  </td>
                  <td>{item.partnerCode}</td>
                  <td>{item.partnerName}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.contactEmail}</td>
                  <td>
                    {item.isActive === true ? (
                      <i className='bi bi-check check-icon'></i>
                    ) : (
                      <i className='bi bi-x close-icon'></i>
                    )}
                  </td>
                  <td>{item.partnerType}</td>
                  <td>{formatDate(item.dateCreated)}</td>
                  <td>{item.numberOfSubscribers}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PartnersTable;
