import axios from 'axios';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  IEditPharmacyRating,
  initialRatingUpdate,
  IUpdatePharmacyRatingDTO,
} from '../../../interface/PharmacyRatingInterface';
import { endpoints } from '../../../utils/URL';
// import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import TableLoader from '../../common/TableLoading';
import RatingBar from '../../common/RatingBar';

const PharmacyRatingsModal = ({ open, close, data, keepOpen }: IEditPharmacyRating) => {
  const [isLoading, setLoading] = useState(false);
  const [rating, setRating] = useState<IUpdatePharmacyRatingDTO | null>(null);

  // const handleCheckboxChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const number = event.target.checked === true ? 1 : 0;
  //   setRating({ ...rating!, [input]: number });
  // };

  const handleRatingChange = (inputName: string, ratingValue: number) => {
    setRating({
      ...rating!,
      [inputName]: ratingValue,
    });
  };

  const getPharmacyRating = useCallback(async () => {
    setRating(null);
    const url = `${endpoints.PharmacyRating.mainUrl}/${data.serviceId}/?serviceType=${data.serviceType}&pharmacyCode=${data.pharmacyCode}`;
    try {
      const response = await axios.get(url);
      setRating(response.data);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setRating(initialRatingUpdate);
      }
      console.error(error);
    }
  }, [data.serviceId, data.serviceType, data.pharmacyCode]);

  const updatePharmacyRating = async (event: any) => {
    if (rating === null || typeof rating === 'undefined') {
      return;
    }
    event.preventDefault();

    if (rating.communication < 1 || rating.customerService < 1 || rating.payment < 1) {
      addToast('Please choose a rating for each section', 'error');
      return;
    }

    event.preventDefault();

    setLoading(true);

    rating.serviceId = data.serviceId;
    rating.pharmacyCode = data.pharmacyCode;
    rating.serviceType = data.serviceType;

    const url = `${endpoints.PharmacyRating.mainUrl}`;
    try {
      await axios.put(url, rating);
      addToast('Pharmacy Rating successfully updated', 'success');
      close();
    } catch (error: any) {
      addToast('An error occured when updating the pharmacy rating', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getPharmacyRating();
    }
  }, [getPharmacyRating, open]);

  return (
    <Modal show={open} onHide={close} backdrop={keepOpen ? 'static' : undefined}>
      <form id='edit-pharmacy-rating-modal'>
        <Modal.Header closeButton={!keepOpen}>
          <Modal.Title>Pharmacy Ratings for {data.pharmacyCode}</Modal.Title>
        </Modal.Header>
        {rating !== null && typeof rating !== 'undefined' ? (
          <>
            <Modal.Body className='modal-body'>
              <fieldset disabled={isLoading}>
                <Fragment>
                  <div>
                    <label>Response Time ({'< 15 minutes'})</label>
                    <RatingBar
                      ratingBarId={'communication'}
                      ratingValue={rating ? rating.communication : 0}
                      callback={handleRatingChange}
                    />
                  </div>
                  <div className='mt-4'>
                    <label>Good customer experience</label>
                    <RatingBar
                      ratingBarId={'customerService'}
                      ratingValue={rating ? rating.customerService : 0}
                      callback={handleRatingChange}
                    />
                  </div>
                  <div className='mt-4'>
                    <label>Wallet</label>
                    <RatingBar
                      ratingBarId={'payment'}
                      ratingValue={rating ? rating.payment : 0}
                      callback={handleRatingChange}
                    />
                  </div>
                  {/* <div className='form-check'>
                    <input
                      id='communication'
                      className='form-check-input'
                      checked={rating?.communication === 1 ? true : false}
                      onChange={handleCheckboxChange('communication')}
                      type='checkbox'
                    />
                    <label htmlFor='communication' className='form-check-label'>
                      Ease Of Communication ({'>'}5 min)
                    </label>
                  </div> */}
                  <br />
                  {/* <div className='form-check'>
                    <input
                      id='customer-service'
                      className='form-check-input'
                      checked={rating?.customerService === 1 ? true : false}
                      onChange={handleCheckboxChange('customerService')}
                      type='checkbox'
                    />
                    <label htmlFor='customer-service' className='form-check-label'>
                      Customer Service (friendly pharmacist)
                    </label>
                  </div>
                  <br />
                  <div className='form-check'>
                    <input
                      id='pharmaceutical-care'
                      className='form-check-input'
                      checked={rating?.pharmaceuticalCare === 1 ? true : false}
                      onChange={handleCheckboxChange('pharmaceuticalCare')}
                      type='checkbox'
                    />
                    <label htmlFor='pharmaceutical-care' className='form-check-label'>
                      Pharmaceutical Care (Counseled on how to take medication)
                    </label>
                  </div>
                  <br />
                  <div className='form-check'>
                    <input
                      id='payment'
                      className='form-check-input'
                      checked={rating?.payment === 1 ? true : false}
                      onChange={handleCheckboxChange('payment')}
                      type='checkbox'
                    />
                    <label htmlFor='payment' className='form-check-label'>
                      Wallet (Current using Wallet)
                    </label>
                  </div> */}
                  {/* <br />
                  <div className='form-check'>
                    <input
                      id='nps'
                      className='form-check-input'
                      checked={rating?.nps === 1 ? true : false}
                      onChange={handleCheckboxChange('nps')}
                      type='checkbox'
                    />
                    <label htmlFor='nps' className='form-check-label'>
                      NPS (9-10) Yes. (0-6) No
                    </label>
                  </div> */}
                </Fragment>
                {/* )} */}
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                className='btn btn-primary'
                onClick={updatePharmacyRating}
                disabled={isLoading}
              >
                Update Ratings
                {isLoading ? <ButtonLoader /> : ''}
              </button>
            </Modal.Footer>
          </>
        ) : (
          <div style={{ height: '100px' }}>
            <TableLoader />
          </div>
        )}
      </form>
    </Modal>
  );
};

export default PharmacyRatingsModal;
