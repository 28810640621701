import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  IFulfilmentDetailsProp,
  IFulfilmentDrugsData,
  IFulfilments,
  initialFulfilmentsData,
} from '../../../interface/FulfilmentsInterface';
import {
  IFulfilmentConfiguration,
  IFulfilmentPartners,
} from '../../../interface/FulfilmentPartnersInterface';
import { IPriceList } from '../../../interface/PriceListInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { decimalParser } from '../../../utils/numberUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import { FulfilmentDetailsTab } from '../../../utils/mappings';
import localforage from 'localforage';
import LoaderWithText from '../../common/LoaderWithText';
import AddDrugsToRequestModal from './AddDrugsToRequestModal';
import AlternativeDrugsModal from './AlternativeDrugsModal';
import { MedismartPaHMOs, SortingCenterPharmacy } from '../../../utils/constants';
import TariffSuggestion from '../../common/TariffSuggestion';
import FulfilmentDrugSummary from './FulfilmentDrugsSummary';
import MedismartSidebar from './MedismartSidebar';

const FulfilmentDrugs = ({
  fulfilmentRequest,
  tabKey,
  toggleLock,
  getFulfilment,
}: IFulfilmentDetailsProp) => {
  const [drugsData, setDrugsData] = useState<IFulfilmentDrugsData[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAlternativeOpen, setAlternativeOpen] = useState(false);
  const [selectedFulfilment, setSelectedFulfilment] =
    useState<IFulfilments>(initialFulfilmentsData);
  const [alternativePricelist, setAlternativePricelist] = useState({
    pricelistId: 0,
    drugId: 0,
    partnerCode: '',
    quantity: 0,
    fulfilmentService: 'Acute',
  });

  // const axiosInstance = axios.create();
  const [priceList, setPriceList] = useState<IPriceList[]>([]);

  const [partnerConfiguration, setPartnerConfiguration] = useState<IFulfilmentConfiguration>({
    partnerCode: '',
    partnerName: '',
    dispenseTests: false,
    dispenseDrugs: false,
    changePharmacy: false,
    sendDrugsDispensed: false,
    services: [],
    serviceCharge: 200,
    chargeValueType: 'Absolute',
    lockTariff: true,
  });
  const [showSidemodal, setShowSideModal] = useState(false);

  // const relianceUsername = process.env.REACT_APP_RELIANCE_USERID;
  // const reliancePassword = process.env.REACT_APP_RELIANCE_PASSWORD;

  const saveData = async (event: any) => {
    if (!isFormValidated('drugs-form')) {
      //addToast('Please fill all required fields');
      return;
    }

    event.preventDefault();

    const noTariffCost = drugsData.filter((x) => x.tariffCost === 0 && x.isDispensed);

    if (noTariffCost.length > 0) {
      addToast('Please enter tariff cost for all drugs dispensed');
      return;
    }

    const noDrugCost = drugsData.filter((x) => x.cost === 0 && x.isDispensed);

    if (noDrugCost.length > 0) {
      addToast('Please enter drug cost for all drugs dispensed');
      return;
    }

    try {
      toggleLock(true);
      setBtnLoading(true);

      // if (partnerConfiguration.sendDrugsDispensed) {
      //   await sendDataToReliance(
      //     fulfilmentRequest!.enrollmentId,
      //     fulfilmentRequest!.fulfilmentService
      //   );
      // }

      for (let i = 0; i < drugsData.length; i++) {
        await saveDataToRemote(drugsData[i]);
      }
      getFulfilment();
      //   close();
    } catch (error: any) {
      console.error(error);
    } finally {
      setBtnLoading(false);
      toggleLock(false);
    }
  };

  const saveDataToRemote = async (drugData: IFulfilmentDrugsData) => {
    //append tariff changes to notes only if there was a change
    // let auditNotes = '';
    // if (
    //   drugData.name !== drugData.tariffDrugName &&
    //   drugData.unitCost > 0 &&
    //   drugData.quantity > 0
    // ) {
    //   auditNotes = ` The tariff used is: ${drugData.tariffDrugName}, N${drugData.unitCost} per unit and ${drugData.quantity} unit was dispensed.`;
    // }

    // drugData.notes = drugData.notes === null ? auditNotes : drugData.notes + auditNotes;

    //TODO: Extract only required properties
    const url = endpoints.FulfilmentDrugs.mainUrl;
    await axios
      .put(url, drugData)
      .then((response) => {
        addToast('Drug dispensed details updated successfully', 'success');
        setDrugsData([]);
      })
      .catch((error) => {
        addToast('An error occured when sending request', 'error');
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const loadTariff = (
    drugId: number,
    tariffDrugName: string,
    tariffQuantity: number,
    priceListId?: number
  ) => {
    //get unit cost from pricelist
    let drugDetails = priceList.filter((x) => x.drugName === tariffDrugName);

    if (priceListId && priceListId !== null) {
      drugDetails = priceList.filter((x) => x.priceListId === priceListId);
    }
    let tariffUnitCost = 0;
    let tariffNotes = '';
    let newPricelistId = 0;
    let newTariffDrugName = '';

    if (drugDetails !== null && drugDetails.length > 0) {
      tariffUnitCost = drugDetails[0].unitPrice; //.drugPrice;
      tariffNotes = drugDetails[0].notes !== null ? drugDetails[0].notes : '';
      newPricelistId = drugDetails[0].priceListId ?? 0;
      newTariffDrugName = drugDetails[0].newDrugName !== null ? drugDetails[0].newDrugName : '';
    }
    //if found, set state of unit cost,
    let newTariffCost = tariffUnitCost * tariffQuantity;
    newTariffCost = decimalParser(newTariffCost, 2);
    setDrugsData(
      drugsData.map((drug) =>
        drug.drugId === drugId
          ? {
              ...drug,
              tariffDrugName: tariffDrugName,
              newtariffDrugName: newTariffDrugName,
              tariffCost: newTariffCost,
              unitCost: tariffUnitCost,
              quantity: tariffQuantity,
              tariffNotes: tariffNotes,
              //groupName: groupName,
              pricelistId: newPricelistId,
            }
          : drug
      )
    );
  };

  const handleTariffChange = (id: number, price?: IPriceList) => {
    if (!price) {
      return;
    }

    setDrugsData(
      drugsData.map((drug) =>
        drug.drugId === id
          ? { ...drug, tariffDrugName: price?.drugName!, newtariffDrugName: price?.newDrugName }
          : drug
      )
    );

    let allQuantity = drugsData.filter((x) => x.drugId === id);
    let quantity = allQuantity !== null && allQuantity.length > 0 ? allQuantity[0].quantity : 0;
    let drugName = price.drugName;
    loadTariff(id, drugName, quantity, price.priceListId);
  };

  //https://stackoverflow.com/a/55988040/2929906
  //https://stackoverflow.com/a/55788298/2929906
  const handleChange = (input: string, id: number) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setDrugsData(
      drugsData.map((drug) => (drug.drugId === id ? { ...drug, [input]: value } : drug))
    );

    if (input === 'quantity') {
      let quantity = parseInt(value);
      let drugNames = drugsData.filter((x) => x.drugId === id);
      let drugName = drugNames !== null && drugNames.length > 0 ? drugNames[0].tariffDrugName : '';

      loadTariff(id, drugName, quantity);
      return;
    }

    if (input === 'isDispensed') {
      setDrugsData(
        drugsData.map((drug) => (drug.drugId === id ? { ...drug, [input]: value, cost: 0 } : drug))
      );
    }
  };

  const openModal = () => {
    setModalOpen(true);
    setSelectedFulfilment(fulfilmentRequest!);
  };
  const closeModal = () => {
    setModalOpen(false);
    getDetails();
  };

  const closeSideModal = () => {
    setShowSideModal(!showSidemodal);
  };
  const openSideModal = () => {
    setShowSideModal(true);
    setSelectedFulfilment(fulfilmentRequest!);
  };

  const openAlternativeModal = (
    drugId: number,
    pricelistId: number,
    partnerCode: string,
    quantity: number,
    fulfilmentService: string
  ) => {
    setAlternativeOpen(true);
    setAlternativePricelist({
      ...alternativePricelist,
      partnerCode: partnerCode,
      pricelistId: pricelistId,
      quantity: quantity,
      fulfilmentService: fulfilmentService,
      drugId: drugId,
    });
    setSelectedFulfilment(fulfilmentRequest!);
  };
  const closeAlternativeModal = () => {
    setAlternativeOpen(false);
  };

  const getDetails = useCallback(async () => {
    setLoading(true);
    setDrugsData([]);
    try {
      const response = await axios.get(
        endpoints.FulfilmentDrugs.mainUrl + '/' + fulfilmentRequest!.requestId
      );
      const data = response.data;

      //for each data, check if the tariff exist, then
      // get price list from indexDB
      const savedPricelist: any = await localforage.getItem('pricelist');
      if (
        savedPricelist &&
        savedPricelist !== 'undefined' &&
        typeof savedPricelist != 'undefined'
      ) {
        const pricelist: IPriceList[] = savedPricelist; //TODO: optimize to load only selected partner list
        for (let i = 0; i < data.length; i++) {
          const drugName = data[i].name;
          //let selectedList: IPriceList;

          //first search using the service type
          let selectedPrice =
            pricelist.filter(
              (x) =>
                x.drugName === drugName &&
                x.source.includes(fulfilmentRequest!.partnerCode) &&
                x.services.includes(fulfilmentRequest!.fulfilmentService)
            )[0] || null;

          //if no result, load all for the partner
          if (selectedPrice === null) {
            selectedPrice =
              pricelist.filter(
                (x) => x.drugName === drugName && x.source.includes(fulfilmentRequest!.partnerCode)
              )[0] || null;
          }
          // const selectedPrice =
          //   pricelist.filter(
          //     (x) =>
          //       x.drugName === drugName &&
          //       x.source.includes(selectedData.partnerCode)
          //   )[0] || null;

          // const parsedSelectedPrice = selectedPrice || null;

          //set the tariffDrugName to the initial drug name,
          //unit cost to the cost loaded for the tariff

          if (data[i].tariffDrugName === '' || data[i].tariffDrugName === null) {
            data[i].tariffDrugName = data[i].name;
          }

          if (data[i].unitCost === 0) {
            //data[i].quantity = 0;
            data[i].unitCost = selectedPrice !== null ? selectedPrice.unitPrice : 0; //drugPrice : 0;
          }
          //only set tariff if 0
          if (data[i].tariffCost === 0) {
            data[i].tariffCost = selectedPrice !== null ? selectedPrice.unitPrice : 0; //.drugPrice : 0;
          }
        }
      }

      setDrugsData(data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [fulfilmentRequest]);

  const getFulfilmentPartners = async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;
    try {
      const response = await axios.get(url);

      const data: IFulfilmentConfiguration = response.data.map((x: IFulfilmentPartners) => {
        return {
          partnerCode: x.partnerCode,
          partnerName: x.partnerName,
          dispenseTests: true,
          dispenseDrugs: true,
          changePharmacy: true,
          sendDrugsDispensed: x.partnerCode === 'WHPX10002' ? true : false,
          services: ['telemedicine', 'acute', 'chronic'],
          serviceCharge: x.serviceCharge,
          chargeValueType: x.chargeValueType,
        };
      });
      setPartnerConfiguration(data);

      localStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (fulfilmentRequest && tabKey === FulfilmentDetailsTab.medications) {
      getDetails();

      const savedPartnerList = localStorage.getItem('partnersList');

      if (savedPartnerList !== null && typeof savedPartnerList !== 'undefined') {
        const parsedPartnerList = JSON.parse(savedPartnerList) as IFulfilmentPartners[];

        const selectedPartner = parsedPartnerList.filter(
          (x) => x.partnerCode === fulfilmentRequest.partnerCode
        )[0];

        const parsedPartnerConfig = selectedPartner || null;
        if (parsedPartnerConfig !== null) {
          //map
          const partnerConfig: IFulfilmentConfiguration = {
            partnerCode: parsedPartnerConfig.partnerCode,
            partnerName: parsedPartnerConfig.partnerName,
            dispenseTests: true,
            dispenseDrugs: true,
            changePharmacy: true,
            sendDrugsDispensed: parsedPartnerConfig.partnerCode === 'WHPX10002' ? true : false,
            services: ['telemedicine', 'acute', 'chronic'],
            serviceCharge: parsedPartnerConfig.serviceCharge,
            chargeValueType: parsedPartnerConfig.chargeValueType,
            lockTariff: true,
          };

          setPartnerConfiguration(partnerConfig);
        }
      } else {
        //get from api
        getFulfilmentPartners();
      }
    }
  }, [tabKey, fulfilmentRequest, getDetails]);

  //checks the last update to see if tariff is update for partner
  const setLastUpdate = useCallback(async (partnerCode: string) => {
    const lastUpdateVersion = `pricelist-lastupdate-${partnerCode}`;

    try {
      const response = await axios.get(`${endpoints.Pricelist.mainUrl}/lastupdate`);
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
  }, []);

  //checks the last update to see if tariff is update for partner
  const getLastUpdate = useCallback(async (partnerCode: string) => {
    const lastUpdateVersion = `pricelist-lastupdate-${partnerCode}`;
    const savePriceListVersion = localStorage.getItem(lastUpdateVersion);
    //if no version set, get pricelist
    if (typeof savePriceListVersion === 'undefined' || savePriceListVersion === null) {
      return true;
    }

    let lastupdateValue = new Date().toISOString();
    try {
      const response = await axios.get(`${endpoints.Pricelist.mainUrl}/lastupdate`);
      lastupdateValue = response.data;
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
    const previousUpdate = new Date(savePriceListVersion!);
    const lastUpdate = new Date(lastupdateValue);

    if (lastUpdate > previousUpdate) {
      return true;
    }

    return false;
  }, []);

  const loadToMemory = useCallback(
    (data: any, partnerCode: string) => {
      //save to memory
      let pricelist: IPriceList[] = [];

      const parsedPriceList: IPriceList[] = data;

      const partnerPriceList = parsedPriceList.filter((x) => x.source.includes(partnerCode));

      if (fulfilmentRequest!.fulfilmentService !== '') {
        let fulfilmentServicePrices = partnerPriceList.filter((x) =>
          x.services.includes(fulfilmentRequest!.fulfilmentService)
        );

        pricelist = fulfilmentServicePrices.length > 0 ? fulfilmentServicePrices : partnerPriceList;
      }

      setPriceList(pricelist);
    },
    [fulfilmentRequest]
  );

  //loads from remote, save to localdb and also in-memory
  const loadFromRemote = useCallback(
    async (partnerCode: string) => {
      try {
        const url = `${endpoints.Pricelist.mainUrl}/partner/${partnerCode}`;
        const response = await axios.get(url);

        //save price list response to indexDB
        localforage
          .setItem(`pricelist-${partnerCode}`, response.data)
          .then(async () => {
            //addToast('Price list saved successfully', 'success');
            await setLastUpdate(partnerCode);
          })
          .catch((err) => {
            console.log(err);
            //addToast('Price list not saved', 'error');
          });

        //save to memory
        loadToMemory(response.data, partnerCode);
      } catch (error: any) {
        console.error(error);
        addToast('There was error getting pricelist', 'error');
      }
    },
    [setLastUpdate, loadToMemory]
  );

  const getTariff = useCallback(
    (partnerCode: string) => {
      const priceListName = `pricelist-${partnerCode}`;
      localforage
        .getItem(priceListName)
        .then(async (value) => {
          if (value === null || typeof value === 'undefined') {
            await loadFromRemote(partnerCode);
          } else {
            //check if new version available
            const isNewVersionAvailable = await getLastUpdate(partnerCode);

            if (isNewVersionAvailable) {
              await loadFromRemote(partnerCode);
            } else {
              loadToMemory(value, partnerCode);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [loadFromRemote, loadToMemory, getLastUpdate]
  );

  useEffect(() => {
    if (fulfilmentRequest && tabKey === FulfilmentDetailsTab.medications) {
      //if sorting center, load sorting center pricelist
      if (fulfilmentRequest.pharmacyCode === SortingCenterPharmacy) {
        getTariff(fulfilmentRequest.pharmacyCode); //we store sorting center pricelist using the pharmacycode of the sorting center
      } else {
        getTariff(fulfilmentRequest.partnerCode);
      }
    }
  }, [tabKey, fulfilmentRequest, getTariff]);

  let addDrugsToRequestModal = (
    <AddDrugsToRequestModal open={isModalOpen} close={closeModal} modalData={selectedFulfilment} />
  );

  let alternativeDrug = (
    <AlternativeDrugsModal
      open={isAlternativeOpen}
      close={closeAlternativeModal}
      fulfilmentService={alternativePricelist.fulfilmentService}
      partnerCode={alternativePricelist.partnerCode}
      quantity={alternativePricelist.quantity}
      pricelistId={alternativePricelist.pricelistId}
      drugId={alternativePricelist.drugId}
      callback={loadTariff}
    />
  );

  const mapSidebar = (
    <MedismartSidebar
      showModal={showSidemodal}
      close={closeSideModal}
      fulfilmentInfo={fulfilmentRequest!}
    />
  );
  return (
    <div className='mt-5'>
      {addDrugsToRequestModal}
      {alternativeDrug}
      {mapSidebar}
      <div className='d-flex justify-content-between'>
        <button className='btn btn-primary' onClick={openModal}>
          Add Medication
        </button>
        {MedismartPaHMOs.includes(fulfilmentRequest?.partnerCode!) ? (
          <button className='btn btn-primary float-right' onClick={openSideModal}>
            PA Code Details
          </button>
        ) : (
          ''
        )}
      </div>
      {fulfilmentRequest ? (
        <div>
          {/* Prescribed Drugs for {fulfilment!.enrolleeFirstName} {fulfilment!.enrolleeLastName} -
          {fulfilment!.requestSource} */}
          {isLoading ? (
            <LoaderWithText />
          ) : (
            <div>
              <form id='drugs-form' className='row'>
                {drugsData.map((drug, key) => (
                  <div key={drug.drugId} className='col-sm-6 mt-5'>
                    <div className='card' style={{ padding: '10px' }}>
                      {/* <label>Drug {key + 1}</label> */}
                      <div className='form-group'>
                        <label htmlFor='name' className='form-label'>
                          Drug Name
                        </label>
                        <textarea
                          value={drug.name}
                          required
                          className='form-control '
                          disabled
                          id={'drug' + key}
                          onChange={handleChange('name', drug.drugId)}
                        />
                      </div>
                      <br />

                      <div className='form-group'>
                        <label className='form-label'>Dose </label>
                        <textarea
                          className='form-control'
                          readOnly
                          disabled
                          id={'dose' + key}
                          value={
                            drug.dosageForm === '' || drug.dosageForm === null
                              ? `${drug.dose} ${drug.strength} ${drug.frequency} ${drug.duration}`
                              : drug.dose
                          }
                        />
                      </div>
                      <br />

                      <div className='form-group'>
                        <input
                          type='checkbox'
                          id={'dispensed' + key}
                          checked={drug.isDispensed}
                          onChange={handleChange('isDispensed', drug.drugId)}
                          className='check-md'
                        />
                        <label htmlFor={'dispensed' + key} className='ml-2 form-label'>
                          Is Dispensed
                        </label>
                      </div>
                      <br />

                      <div className='tariff-box form-group'>
                        <div className='row'>
                          <div className='col-sm-12' style={{ fontSize: '12px' }}>
                            Tariff Cost Calculation. Note, the information in this box is saved and
                            <span>required</span>
                          </div>
                          <div className='col-sm-12 mt-3'>
                            {priceList.length > 0 ? (
                              <TariffSuggestion
                                data={priceList}
                                callback={handleTariffChange}
                                drugId={drug.drugId}
                                searchTerm={drug.tariffDrugName}
                                required={drug.isDispensed}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <br />
                          <div className='col-sm-6 mt-3'>
                            <label className='form-label'>Unit Cost </label>
                            <input
                              value={drug.unitCost}
                              id={'unitCost' + key}
                              type='number'
                              disabled
                              className='form-control '
                              required={drug.isDispensed}
                              onChange={handleChange('unitCost', drug.drugId)}
                              min={0}
                              step={0.01}
                            />
                          </div>

                          <div className='col-sm-6 mt-3'>
                            <label className='form-label'>Quantity </label>
                            <input
                              value={drug.quantity}
                              id={'quantity' + key}
                              min={drug.isDispensed ? 1 : 0}
                              max={360}
                              className='form-control '
                              type='number'
                              required={drug.isDispensed}
                              onChange={handleChange('quantity', drug.drugId)}
                            />
                          </div>

                          <div className='col-sm-12 mt-1'>
                            {/* <label className='form-label'>Notes </label> */}
                            <input
                              value={drug.tariffNotes}
                              id={'notes' + key}
                              disabled
                              className='form-control '
                              type='text'
                            />
                          </div>
                        </div>
                      </div>

                      <br />
                      <div className='form-group'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label className='form-label'>Tariff Cost </label>
                            <input
                              value={drug.tariffCost}
                              required={drug.isDispensed}
                              className='form-control '
                              type='number'
                              min={drug.isDispensed ? 1 : 0}
                              onChange={handleChange('tariffCost', drug.drugId)}
                              step={0.01}
                              disabled={
                                partnerConfiguration.lockTariff &&
                                drug.name.trim().toLowerCase() !== 'delivery'
                              }
                            />
                          </div>

                          <div className='col-sm-6'>
                            <label className='form-label'>Drug Cost</label>
                            <input
                              value={drug.cost}
                              id={'drugCost' + key}
                              required={drug.isDispensed}
                              className='form-control '
                              min={drug.isDispensed ? 1 : 0}
                              type='number'
                              onChange={handleChange('cost', drug.drugId)}
                              step={0.01}
                            />
                          </div>
                        </div>
                      </div>

                      {parseFloat(drug.cost.toString()) > parseFloat(drug.tariffCost.toString()) ? (
                        <div className=' form-group mb-4 text-danger'>
                          Drug cost is Higher than tarrif cost.
                          <span
                            className='ml-1 pointer text-primary'
                            onClick={() =>
                              openAlternativeModal(
                                drug.drugId,
                                drug.pricelistId,
                                fulfilmentRequest.partnerCode,
                                drug.quantity,
                                fulfilmentRequest.fulfilmentService
                              )
                            }
                          >
                            View alternatives
                          </span>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className='form-group'>
                        <label htmlFor='name' className='form-label'>
                          Notes
                        </label>
                        <textarea
                          value={drug.notes}
                          id={'notes' + key}
                          className='form-control '
                          onChange={handleChange('notes', drug.drugId)}
                        />
                      </div>
                      <br />
                      <hr />
                    </div>
                  </div>
                ))}

                <datalist id='tariffs'>
                  {priceList.map((price) => (
                    <option
                      key={price.priceListId}
                      value={price.drugName}
                      data-pricelistId={price.priceListId}
                    />
                  ))}
                </datalist>

                <div className='mt-5 ml-4'>
                  <h6>
                    Medication Summary
                    <span className='text-danger'>(Please verify before saving)</span>
                  </h6>
                  <FulfilmentDrugSummary data={drugsData} />
                </div>

                <div className='col-sm-12 mt-4'>
                  <div className='col-sm-6'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-lg btn-block'
                      disabled={isBtnLoading}
                      onClick={saveData}
                    >
                      Save
                      {isBtnLoading ? <ButtonLoader /> : ''}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FulfilmentDrugs;
