export interface IBeneficiaryList {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  gender: string;
}

export interface IBeneficiaryModalProps {
  open: boolean;
  close: () => void;
  handleChange: (input: string) => (event: any) => void;
  modalData: IBeneficiaryList;
  addToPlan: () => void;
}

export interface IBeneficiary {
  dateCreated: string;
  dateUpdated: string;
  insuranceBeneficiaryId: number;
  subscriptionCode: string;
  location: string;
  stateOfResidence: string;
  lga: string;
  area: string;
  streetName: string;
  isActive: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  gender: string;
}

export interface IAddEditBeneficiaryModal {
  open: boolean;
  close: () => void;
  modalData: IBeneficiary;
  getBeneficiaryData: () => void;
  handleChange: Function;
  isEdit: boolean;
}

export interface IBeneficiaryListProp {
  value: number;
  label: string;
}

//Initializations

export const initialBeneficiaryList: IBeneficiaryList = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  dateOfBirth: '',
  gender: 'Others',
};
