import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { INextOfKinInformation, initialNextOfKin } from '../../../interface/NextOfKinInterface';
import { ISubscriptionDetailsProp } from '../../../interface/SubscriptionInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { LoadingStatus, SubscriptionDetailsTab } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import TableLoader from '../../common/TableLoading';

const SubscriptionNextOfKin = ({
  tabKey,
  subscription,
  toggleLock,
  getSubscription,
}: ISubscriptionDetailsProp) => {
  const [nextOfKin, setNextOfKin] = useState<INextOfKinInformation | undefined>();
  const [loadingState, setLoadingState] = useState(LoadingStatus.LOADING);
  const [isDataFound, setDataFound] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setNextOfKin({
      ...nextOfKin!,
      [input]: event.target.value,
    });
  };

  const getSubscriptionNextOfKin = useCallback(async () => {
    const url = `${endpoints.InsuranceNextOfKin.mainUrl}/${subscription!.subscriptionCode}`;
    try {
      const response = await axios.get(url);
      setNextOfKin(response.data);
      setLoadingState(LoadingStatus.LOADED);
      setDataFound(true);
    } catch (error: any) {
      addToast('Next of kin not found', 'error');
      const newNextOfKin = initialNextOfKin;
      initialNextOfKin.subscriptionCode = subscription!.subscriptionCode;
      setNextOfKin(newNextOfKin);
      setLoadingState(LoadingStatus.LOADED);
    } finally {
      //setLoadingState(LoadingStatus.);
    }
  }, [subscription]);

  const saveSubscriptionNextOfKin = async (event: any) => {
    //validate form
    if (!isFormValidated('edit-nok-modal')) {
      addToast('Please fill all forms', 'warning');
      return;
    }

    event.preventDefault();

    setBtnLoading(true);
    const url = `${endpoints.InsuranceNextOfKin.mainUrl}`;
    try {
      await axios.post(url, nextOfKin);
      addToast('Next of Kin Information saved successfully', 'success');
      getSubscriptionNextOfKin();
    } catch (error: any) {
      addToast('Error when saving Next of Kin information', 'error');
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    if (subscription && tabKey === SubscriptionDetailsTab.nextOfKin) {
      getSubscriptionNextOfKin();
    }
  }, [subscription, tabKey, getSubscriptionNextOfKin]);

  return (
    <div className='container mt-5'>
      {loadingState === LoadingStatus.LOADING && <TableLoader />}
      {loadingState === LoadingStatus.LOADED && (
        <form id='edit-nok-modal'>
          <fieldset className='row' disabled={btnLoading}>
            <div className='form-group col-sm-4 mt-3'>
              <label>Subscription Code</label>
              <input
                type='text'
                className='form-control'
                value={nextOfKin!.subscriptionCode}
                disabled
              />
            </div>

            <div className='form-group col-sm-4 mt-3'>
              <label>First Name</label>
              <input
                type='text'
                value={nextOfKin?.firstName}
                required
                className='form-control'
                onChange={handleChange('firstName')}
              />
            </div>
            <div className='form-group  col-sm-4 mt-3'>
              <label>Last Name</label>
              <input
                type='text'
                value={nextOfKin?.lastName}
                required
                className='form-control'
                onChange={handleChange('lastName')}
              />
            </div>
            <div className='form-group  col-sm-4 mt-3'>
              <label>Phone Number</label>
              <input
                type='tel'
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                value={nextOfKin?.phoneNumber}
                required
                className='form-control'
                onChange={handleChange('phoneNumber')}
              />
            </div>
            <div className='form-group  col-sm-4 mt-3'>
              <label>Email</label>
              <input
                type='email'
                value={nextOfKin?.email}
                className='form-control'
                onChange={handleChange('email')}
              />
            </div>

            <div className='form-group col-sm-4 mt-3'>
              <label>Relationship</label>
              <input
                type='text'
                value={nextOfKin!.relationship}
                className='form-control'
                required
                onChange={handleChange('relationship')}
              />
            </div>
            <div className='form-group col-sm-4 mt-3'>
              <button className='btn btn-primary btn-block' onClick={saveSubscriptionNextOfKin}>
                {isDataFound ? 'Update' : 'Save'}
                {btnLoading ? <ButtonLoader /> : ''}
              </button>
            </div>
          </fieldset>
        </form>
      )}
    </div>
  );
};

export default SubscriptionNextOfKin;
