import { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { defaultPharmData, IPharmacyData } from '../../../interface/PharmacyInterface';
import { addToast } from '../../../utils/toastNotifications';
import AddEditPharmacyModal from './AddEditPharmacyModal';
import { Link } from 'react-router-dom';
import ClientModal from './ClientModal';
import { PharmacyType } from '../../../utils/mappings';

const PharmacyTable = () => {
  const [modalData, setModalData] = useState(defaultPharmData);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isClientModalOpen, setClientModalOpen] = useState(false);
  const [tableData, setTableData] = useState<IPharmacyData[]>([]);
  const [allData, setAllData] = useState<IPharmacyData[]>([]);
  const [providerType, setProviderType] = useState(PharmacyType[0].value);

  const getPharmacies = useCallback(async (providerType?: string) => {
    try {
      let url = `${endpoints.InsurancePharmacy.mainUrl}?pageSize=3000`;
      if (providerType) {
        url = `${url}&providerType=${providerType}`;
      }
      const response = await axios.get(url);
      const data = response.data.data;

      setTableData(data);
      setAllData(data);
      sessionStorage.setItem('pharmacies', JSON.stringify(data));
    } catch (error: any) {
      addToast('Error Getting Pharmacies', 'error');
    }
  }, []);

  const filterPharmacy = (event: any) => {
    const filter = event.target.value;
    setProviderType(filter);
    getPharmacies(filter);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = (data: IPharmacyData, edit: boolean) => {
    if (data.status === 'Cancelled') {
      alert('This Pharmacy is cancelled. Only edit');
    }
    setModalData(data);
    setModalOpen(true);
  };

  const openClientModal = (data: IPharmacyData) => {
    setModalData(data);
    setClientModalOpen(true);
  };
  const closeClientModal = () => {
    setClientModalOpen(false);
  };

  /*######### Search method ######### */
  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setTableData(allData);
      return;
    }

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.pharmacyName?.toLowerCase().includes(searchParam) ||
        x.addressInformation?.pharmacyAddress?.toLowerCase().includes(searchParam) ||
        x.addressInformation?.lga?.toLowerCase().includes(searchParam) ||
        x.addressInformation?.stateOfPremise?.toLowerCase().includes(searchParam) ||
        x.phoneNumber?.toLowerCase().includes(searchParam)
    );

    // //set table data to search result
    setTableData(searchResult);
  };
  /*######### Search method ######### */

  // const formatBackground = (status: string) => {
  //   if (status === 'Cancelled') {
  //     return { cursor: 'pointer', backgroundColor: 'gray' };
  //   }
  //   return { cursor: 'pointer' };
  // };

  useEffect(() => {
    getPharmacies();
  }, [getPharmacies]);

  let pharmacyModal: JSX.Element = (
    <AddEditPharmacyModal
      open={isModalOpen}
      close={closeModal}
      modalData={modalData}
      getPharmacies={getPharmacies}
    />
  );

  let reportModal: JSX.Element = (
    <ClientModal open={isClientModalOpen} close={closeClientModal} modalData={modalData} />
  );

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Pharmacy Code</th>
        <th scope='col'>Pharmacy Name</th>
        <th scope='col'>Phone</th>
        <th scope='col'>State of Premise</th>
        <th scope='col'>LGA</th>
        <th scope='col'>Address</th>
        <th scope='col'>Has Wallets?</th>
        <th scope='col'>Relationship Officer</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((pharmacy: IPharmacyData) => {
        return (
          <tr
            style={{ cursor: 'pointer' }}
            key={pharmacy.pharmacyCode}
            className={pharmacy.status === 'Cancelled' ? 'text-danger' : ''}
          >
            <td>
              <i
                onClick={() => openModal(pharmacy, true)}
                className='bi bi-info-circle update-icon mr-2'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Information'
              ></i>

              <i
                onClick={() => openClientModal(pharmacy)}
                className='bi bi-hospital update-icon mr-2'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Change client'
              ></i>
            </td>
            <td>{pharmacy.pharmacyCode}</td>
            <td>{pharmacy.pharmacyName}</td>
            <td>{pharmacy.phoneNumber}</td>
            <td>{pharmacy.addressInformation?.stateOfPremise}</td>
            <td>{pharmacy.addressInformation?.lga}</td>
            <td>{pharmacy.addressInformation?.pharmacyAddress}</td>
            <td>{pharmacy.accountId === null ? 'No' : 'Yes'}</td>
            <td>{pharmacy.relationshipOfficer}</td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div>
      {pharmacyModal}
      {reportModal}
      <div className='d-flex'>
        <h3 className='mr-auto'>
          {providerType} ({allData.length})
        </h3>
        <Link
          to='/pharmacies/add'
          className='btn btn-primary'
          // onClick={() => openModal(initialPharmacyData, false)}
        >
          Add new Pharmacy
        </Link>
      </div>
      <br />
      <div className='row'>
        <div className='col-6'>
          <div className='form-inline'>
            <input
              type='search'
              className='form-control'
              placeholder='Search by pharmacy name or pharmacy code'
              onChange={searchTable}
            />
          </div>
        </div>
        <div className='col-6'>
          <div className='form-inline float-right mr-5'>
            <select
              className='custom-select'
              value={providerType}
              onChange={filterPharmacy}
              // value={localState.providerType}
              // onChange={handleChange('providerType')}
            >
              {PharmacyType.map((pharmacyType) => (
                <option key={pharmacyType.key} value={pharmacyType.value}>
                  {pharmacyType.text}
                </option>
              ))}
            </select>
            {/* <button className='ml-3 btn btn-primary' onClick={}>
              Filter
            </button> */}
          </div>
        </div>
      </div>
      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default PharmacyTable;
