import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  initialPharmacyData,
  PharmacyDetailsModalProps,
  IPharmacyData,
} from '../../../interface/PharmacyInterface';
import { endpoints } from '../../../utils/URL';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';

const PharmacyDetailsModal = (props: PharmacyDetailsModalProps) => {
  const initialState = initialPharmacyData;

  const [pharmacyData, setPharmacyData] = useState<IPharmacyData | any>(initialState);
  const [isLoading, setLoading] = useState(false);

  const getDetails = useCallback(async () => {
    setPharmacyData(null);
    setLoading(true);
    try {
      const response = await axios.get(
        endpoints.InsurancePharmacy.mainUrl + '/' + props.pharmacyCode
      );
      const data = response.data;
      setPharmacyData(data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [props.pharmacyCode]);

  useEffect(() => {
    if (props.open) {
      getDetails();
    }
  }, [getDetails, props.open]);

  return (
    <Modal show={props.open} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>
          Pharmacy Details
          {isLoading ? <ButtonLoader /> : ''}
        </Modal.Title>
      </Modal.Header>

      {pharmacyData != null ? (
        <Modal.Body className='modal-body'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>Pharmacy Name</div>
              <div className='col-md-5 offset-1'>{pharmacyData.pharmacyName}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Phone Number</div>
              <div className='col-md-5 offset-1'>{pharmacyData.phoneNumber}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>State Of Premise</div>
              <div className='col-md-5 offset-1 '> {pharmacyData.stateOfPremise}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>LGA</div>
              <div className='col-md-5 offset-1 '> {pharmacyData.lga}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Address</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.pharmacyAddress}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Source</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.source}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Account Details</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.accountDetails}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>RDT Brand</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.rdtbrand}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>RDT Available</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.isRdtavailable}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Malaria Drugs</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.malariaDrug}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Malaria Drug Cost</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.malariaDrugCost}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>IS Trained</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.isTrained}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Claims Served</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.claimsServed}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Notes</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.notes}</div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default PharmacyDetailsModal;
