import { IBeneficiaryList } from './BeneficiaryInterface';
import { GenericDetailsModalProps } from './GenericInterface';

export interface ISubscriptionResponse {
  initialPayment: string;
  lastPayment: string;
  dateCreated: string;
  location: string;
  stateOfResidence: string;
  notes: string;
  subscriptionCode: string;
  acquisitionChannel: string;
  acquisitionReason: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  status: string;
  amount: number;
  paymentPlan: string;
  numberOfSubscribers: number;
  subscriptionAccount: string;
  healthSavingsAccount: number;
  nextPayment: string;
  product: string;
  onboardingStatus: string;
  agentCode: string | null;
  partnerCode: string | null;
}

export interface ISubscriptionData {
  paymentChannel: string;
  isPaymentVerified: boolean;
  // accountNumber: string;
  paymentDate: string;
  partnerCode: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  location: string;
  stateOfResidence: string;
  lga: string;
  area: string;
  streetName: string;
  isBeneficiary: boolean;
  acquisitionChannel: string;
  acquisitionReason: string;
  amount: number;
  paymentPlan: string;
  dateOfBirth: string;
  gender: string;
  dateCreated: Date;
  beneficiaryList: Array<IBeneficiaryList>;
  product: string;
  nextOfKinDTO: INextOfKinData | undefined;
}

export interface ISubscriptionInfoModal {
  open: boolean;
  modalData: ISubscriptionResponse;
  close: () => void;
  handleModalChange: Function;
}

export interface INextOfKinData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  relationship: string;
}

export interface INextOfKinModalProps {
  open: boolean;
  close: () => void;
  handleChange: (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  modalData: INextOfKinData;
  addToPlan: () => void;
}

export interface SubscriptionDetailsModalProps extends GenericDetailsModalProps {
  subscriptionCode: string;
}

export interface ISubscriptionDetailsProp {
  tabKey: string;
  subscription: ISubscriptionResponse | undefined;
  toggleLock: Function;
  getSubscription: Function;
  openNoteModal?: Function;
}

//Initialization

export const initialNextOfKinData: INextOfKinData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  relationship: '',
};

export const initialSubscriptionResponse: ISubscriptionResponse = {
  initialPayment: '',
  lastPayment: '',
  dateCreated: '',
  location: '',
  stateOfResidence: '',
  notes: '',
  subscriptionCode: '',
  acquisitionChannel: '',
  acquisitionReason: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  status: 'MissedPayment',
  amount: 0,
  paymentPlan: '',
  numberOfSubscribers: 0,
  subscriptionAccount: '',
  healthSavingsAccount: 0,
  nextPayment: '',
  product: 'Malaria',
  onboardingStatus: 'NotOnboarded',
  partnerCode: null,
  agentCode: null,
};

export const initialSubscriptionPostData: ISubscriptionData = {
  paymentChannel: 'Cash',
  isPaymentVerified: true,
  paymentDate: '',
  partnerCode: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  location: '',
  stateOfResidence: '',
  lga: '',
  area: '',
  streetName: '',
  isBeneficiary: true,
  acquisitionChannel: 'CardSales',
  acquisitionReason: 'Curious',
  amount: 600,
  paymentPlan: 'Monthly',
  dateOfBirth: '',
  gender: 'Others',
  dateCreated: new Date(),
  beneficiaryList: [],
  product: 'Malaria',
  nextOfKinDTO: initialNextOfKinData,
};
