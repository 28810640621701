import { Modal } from 'react-bootstrap';
import { isFormValidated } from '../../../utils/formUtils';

const WalletPayoutNote = ({
  open,
  close,
  callback,
}: {
  open: boolean;
  close: Function;
  callback?: Function;
}) => {
  const addNote = (event: any) => {
    if (!isFormValidated('wallet-note-form')) {
      return;
    }

    event.preventDefault();
    const notes = document.getElementById('cancellation-note') as HTMLInputElement;
    if (callback) {
      callback(notes.value);
    }
  };

  return (
    <Modal show={open} onHide={() => close()}>
      <form id='wallet-note-form'>
        <Modal.Header closeButton>
          <Modal.Title>Add reason for cancelling</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset>
            <div className='form-group'>
              <label>Notes</label>
              <textarea
                rows={3}
                className='form-control'
                id='cancellation-note'
                required
              ></textarea>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={addNote}>
            Add Notes
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default WalletPayoutNote;
