import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { endpoints } from '../../../utils/URL';
import { insurancePlans } from '../../../utils/insurancePlans';
import { addToast } from '../../../utils/toastNotifications';
import {
  BOOL_MAPPING,
  PAYMENT_CHANNEL_MAPPING,
  PAYMENT_PLAN_MAPPING,
  PRODUCT_TYPE,
} from '../../../utils/mappings';
import { isFormValidated } from '../../../utils/formUtils';
import axios from 'axios';
import { AddEditPaymentModal } from '../../../interface/SubscriptionPaymentInterface';
import { formatInputDate } from '../../../utils/dateTimeUtils';

const SubscriptionPaymentModal = ({
  open,
  close,
  modalData,
  edit,
  handleChange,
  getPayments,
  subscription,
}: AddEditPaymentModal) => {
  const [isLoading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState('');

  const changePaymentDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentDate(event.target.value);
  };

  const updatePayment = async () => {
    if (!isFormValid(modalData)) {
      addToast('Please Fill in required fields.', 'warning');
      return;
    }
    modalData.amountPaid = amount;
    setLoading(true);

    try {
      await axios.put(endpoints.InsurancePayments.mainUrl, modalData);

      addToast('Payment Successfully Updated', 'success');
      getPayments();
      close();
    } catch (error: any) {
      addToast('Error Updating Payment.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const savePayment = async () => {
    modalData.amountPaid = amount;
    //appState.paymentDate = appState.paymentDate === '' ? null : appState.paymentDate;
    const payload: any = modalData;
    payload.paymentDate = paymentDate;

    if (!isFormValid(modalData)) {
      addToast('Please Fill in required fields.', 'warning');
      return;
    }
    setLoading(true);

    try {
      await axios.post(endpoints.InsurancePayments.mainUrl, payload);

      addToast('Payment Successfully Added', 'success');
      getPayments();
      //setLoading(false);
      close();
    } catch (error: any) {
      //setLoading(false);
      addToast('Error Adding Payment.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = (formData: any) => {
    if (
      !isFormValidated('payment-modal') ||
      formData.isPaymentVerified === '' ||
      formData.paymentPlan === '' ||
      formData.paymentChannel === '' ||
      formData.peopleInPlan < 1 ||
      formData.amountPaid < 600
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const calculatePayment = () => {
      const paymentPlan = modalData.paymentPlan;
      const totalBeneficiaries = parseInt(modalData.peopleInPlan.toString());
      let selectedPayment;
      if (totalBeneficiaries < 1) {
        addToast('You must add atleast one person to payment plan', 'warning');
        return;
      }
      selectedPayment = insurancePlans.filter(
        (x) =>
          x.numberOfPersons === totalBeneficiaries &&
          x.planType === paymentPlan &&
          x.product === modalData.product
      );
      let price;
      if (selectedPayment === undefined || selectedPayment.length <= 0) {
        price = 0;
      } else {
        price = parseInt(selectedPayment[0].price.toString());
      }
      setAmount(price);
    };
    if (open) {
      calculatePayment();
    }
  }, [modalData.paymentPlan, modalData.product, modalData.peopleInPlan, open]);

  return (
    <Modal show={open} onHide={close}>
      <form id='payment-modal'>
        <fieldset>
          <Modal.Header closeButton>
            <Modal.Title>{edit ? 'Update Payment' : 'Add Payment'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <label>Product</label>
              <select
                className='custom-select'
                value={modalData.product}
                onChange={handleChange('product')}
                required
              >
                {PRODUCT_TYPE.map((product) => (
                  <option key={product.key} value={product.value}>
                    {product.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label>Payment Plan</label>
              <select
                className='custom-select'
                value={modalData.paymentPlan}
                onChange={handleChange('paymentPlan')}
                required
              >
                {PAYMENT_PLAN_MAPPING.map((payment) => (
                  <option key={payment.key} value={payment.value}>
                    {payment.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label>Payment Channel</label>
              <select
                className='custom-select'
                value={modalData.paymentChannel}
                onChange={handleChange('paymentChannel')}
                required
              >
                {PAYMENT_CHANNEL_MAPPING.map((channel) => (
                  <option key={channel.key} value={channel.value}>
                    {channel.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label>People In Plan</label>
              <input
                type='number'
                className='form-control'
                value={modalData.peopleInPlan}
                onChange={handleChange('peopleInPlan')}
                // disabled
                min={1}
                max={subscription ? subscription.numberOfSubscribers : 1}
              />
            </div>

            <div className='form-group'>
              <label>Amount Paid</label>
              <input type='number' className='form-control' value={amount} disabled step={0.01} />
            </div>
            <div className='form-group'>
              <label>Discounted Amount Paid</label>
              <input
                type='number'
                className='form-control'
                value={modalData.discountedAmount}
                onChange={handleChange('discountedAmount')}
                step={0.01}
              />
            </div>
            {/* <div className='form-group'>
              <label>Is Payment Verified</label>
              <select
                className='custom-select'
                value={modalData.isPaymentVerified.toString()}
                onChange={handleChange('isPaymentVerified')}
                required
              >
                {BOOL_MAPPING.map((channel) => (
                  <option key={channel.key} value={channel.value.toString()}>
                    {channel.text}
                  </option>
                ))}
              </select>
            </div> */}

            <div className='form-group'>
              <label>Change Payment Plan? (Set to NO for promos)</label>
              <select
                className='custom-select'
                value={modalData.isChangePaymentPlan.toString()}
                onChange={handleChange('isChangePaymentPlan')}
                required
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            {edit ? (
              <div></div>
            ) : (
              <div className='form-group'>
                <label>Payment Date</label>
                <input
                  type='date'
                  className='form-control'
                  value={formatInputDate(paymentDate)}
                  onChange={changePaymentDate}
                  required
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            ) : (
              <button
                className='btn btn-primary'
                type='submit'
                onClick={edit ? updatePayment : savePayment}
              >
                {edit ? 'Update' : 'Submit'}
              </button>
            )}
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default SubscriptionPaymentModal;
