import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { AddDrugsModalProps, drugsFormData } from '../../../interface/FulfilmentsInterface';
import { ITariffSearchResult } from '../../../interface/PriceListInterface';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import { isFormValidated } from '../../../utils/formUtils';
import { decimalParser } from '../../../utils/numberUtils';
import TariffSection from '../../common/TariffSection';

const AddDrugsModal = (props: AddDrugsModalProps) => {
  const [initialDrugsData, setInitialDrugsData] = useState(drugsFormData);

  const [lockTariff] = useState(true);

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setInitialDrugsData({
      ...initialDrugsData,
      [input]: value,
    });
  };

  const clearDrugsForm = () => {
    setInitialDrugsData({ ...drugsFormData });
  };

  const addDrugs = (event: React.FormEvent) => {
    if (!isFormValidated('add-drug-form')) {
      addToast('Please fill all fields!!', 'warning');
      return;
    }
    event.preventDefault();

    props.addToFulfilmentPlan(initialDrugsData);
    //add data to fulfilment
    clearDrugsForm();
  };

  const handleTariffSection = useCallback((drugId: number, selectedPrice?: ITariffSearchResult) => {
    if (!selectedPrice) {
      return;
    }

    let newTariffCost = decimalParser(selectedPrice.unitCost * selectedPrice.quantity, 2);

    setInitialDrugsData((drugInfo) => {
      return {
        ...drugInfo,
        //name: price.drugName,
        tariffDrugName: selectedPrice.tariffDrugName,
        newTariffDrugName: selectedPrice.newTariffDrugName,
        quantity: selectedPrice.quantity,
        unitCost: selectedPrice.unitCost,
        tariffCost: newTariffCost,
        strength: selectedPrice.strength,
        dosageForm: selectedPrice.dosageForm,
      };
    });
  }, []);
  useEffect(() => {
    //if a drug was passed, map

    if (props.open && props.initialDrug) {
      setInitialDrugsData(props.initialDrug);
    }
  }, [props.open, props.initialDrug]);

  return (
    <Fragment>
      <Modal show={props.open} onHide={props.close}>
        <form id='add-drug-form'>
          <Modal.Header closeButton>
            <Modal.Title> Add Drugs</Modal.Title>
          </Modal.Header>
          <Modal.Body className='modal-body'>
            <div className='col-md-12' style={{ color: 'red' }}>
              {props.partnerCode === '' ? (
                <span style={{ color: 'red' }}>
                  'No partner selected. Tariff suggestion will be turned off'
                </span>
              ) : (
                ''
              )}
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Drug Name
              </label>
              <input
                value={initialDrugsData.name}
                required
                className='form-control '
                type='text'
                onChange={handleChange('name')}
                list='tariff'
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Dose </label>
              <input
                value={initialDrugsData.dose}
                className='form-control '
                type='text'
                onChange={handleChange('dose')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <input
                type='checkbox'
                id={'dispensed1'}
                checked={initialDrugsData.isDispensed}
                onChange={handleChange('isDispensed')}
                className='check-md'
              />
              <label htmlFor={'dispensed1'} className='ml-2 form-label'>
                Is Dispensed
              </label>
            </div>

            <hr />
            <div>
              <TariffSection
                isActive={props.open}
                fulfilmentService={props.fulfilmentService}
                partnerCode={props.partnerCode}
                pharmacyCode={props.pharmacyCode}
                drugId={1}
                callback={handleTariffSection}
                required={initialDrugsData.isDispensed}
              />
            </div>
            <div className='col-md-12 mt-3'>
              <label className='form-label'>Tariff Cost </label>
              <input
                value={initialDrugsData.tariffCost}
                required={initialDrugsData.isDispensed}
                className='form-control '
                type='number'
                onChange={handleChange('tariffCost')}
                min={initialDrugsData.isDispensed ? 1 : 0}
                step={0.01}
                disabled={lockTariff && initialDrugsData.name.trim().toLowerCase() !== 'delivery'}
              />
            </div>
            <br />
            <div className='col-md-12'>
              <label className='form-label'>Cost </label>
              <input
                value={initialDrugsData.cost}
                required={initialDrugsData.isDispensed}
                className='form-control '
                type='number'
                onChange={handleChange('cost')}
                min={initialDrugsData.isDispensed ? 1 : 0}
                step={0.01}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Notes
              </label>
              <input
                value={initialDrugsData.notes}
                className='form-control '
                type='text'
                onChange={handleChange('notes')}
              />
            </div>
            <br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='btn btn-primary' onClick={addDrugs}>
              Add to Fulfilment
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default AddDrugsModal;
