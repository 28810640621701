import { IHealthSendBeneficiary } from '../../../interface/HealthSendInterface';
import { Link } from 'react-router-dom';

const HealthSendBeneficiaries = ({
  beneficiaries,
  userId,
}: {
  beneficiaries: IHealthSendBeneficiary[];
  userId: string;
}) => {
  return (
    <>
      <div className='mt-3 d-flex justify-content-end'>
        <Link to={`/healthsend/users/${userId}/beneficiaries/new`} className='btn btn-primary'>
          Add Beneficiary
        </Link>
      </div>
      <div className='d-flex flex-row flex-wrap'>
        {beneficiaries.map((beneficiary) => (
          <div key={beneficiary.dateOfBirth} className='card shadow m-4 p-8 border-0'>
            <div className='card-body'>
              <h5 className='card-title'>
                {beneficiary.firstName} {beneficiary.lastName}
              </h5>
              <h6 className='card-subtitle mb-2 text-muted'>{beneficiary.address}</h6>
              <p className='card-text'>Email - {beneficiary.email}</p>
              <p className='card-text'>Phone Number - {beneficiary.phoneNumber}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export { HealthSendBeneficiaries };
