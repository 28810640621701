import React, { useState } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import {
  DescriptionType,
  TransactionStatus,
  TransactionStatusMap,
  TransactionType,
  TransactionTypeMap,
} from '../../../utils/mappings';
import { IWalletAccountDTO, IWalletTransactionDTO } from '../../../interface/WalletsInterface';
import ButtonLoader from '../../common/ButtonLoader';

const AddTransactionModal = ({
  open,
  close,
  selectedWallet,
}: {
  open: boolean;
  close: any;
  selectedWallet: IWalletAccountDTO;
}) => {
  const [isLoading, setLoading] = useState(false);

  const [walletData, setWalletData] = useState<IWalletTransactionDTO>({
    pharmacyCode: '',
    amount: 0.0,
    transactionStatus: TransactionStatusMap.Completed,
    transactionType: 'Credit',
    paymentDescription: 'Fulfilment',
    notes: '',
    isPayout: false,
  });

  const saveTransaction = async (event: any) => {
    let url = endpoints.WalletTransactions.mainUrl;
    let message = 'Transaction added successfully';
    let errorMessage = 'Error adding transaction';

    if (!isFormValidated('transaction-modal') || selectedWallet!.providerCode === '') {
      addToast('Fill in all required fields', 'warning');
      return;
    }

    if (
      walletData.amount > selectedWallet.walletBalance &&
      walletData.transactionType === TransactionTypeMap.Debit
    ) {
      const confirmation = window.confirm(
        'The amount in the Debit exceed the wallet balance. Are you sure you want to continue?'
      );

      if (!confirmation) {
        return;
      }
    }

    event.preventDefault();

    if (selectedWallet === null || selectedWallet === undefined) {
      addToast('Select a wallet', 'warning');
      return;
    }

    event.preventDefault();

    if (walletData.transactionStatus === TransactionStatusMap.Cancelled) {
      addToast('Transaction status must be Pending or Completed', 'warning');
      return;
    }

    event.preventDefault();

    if (
      walletData.transactionType === 'Debit' &&
      walletData.transactionStatus !== TransactionStatusMap.Completed
    ) {
      addToast('Debit transactions must be marked completed', 'info');
      return;
    }
    event.preventDefault();

    walletData.pharmacyCode = selectedWallet!.providerCode;

    setLoading(true);

    try {
      await axios.post(url, walletData);
      addToast(message, 'success');
      close();
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (input === 'amount') {
      value = parseFloat(value);
    }

    if (input === 'transactionType' && value === TransactionTypeMap.Credit) {
      setWalletData({
        ...walletData,
        [input]: value,
        isPayout: false,
      });
      return;
    }

    if (
      walletData.transactionType === TransactionTypeMap.Debit &&
      input === 'amount' &&
      value > selectedWallet.walletBalance
    ) {
      setWalletData({
        ...walletData,
        [input]: value,
        isPayout: false,
      });
      return;
    }

    setWalletData({
      ...walletData,
      [input]: value,
    });
  };

  return (
    <Modal show={open} onHide={close}>
      <form id='transaction-modal'>
        <Modal.Header closeButton>
          <Modal.Title> Add Transactions for {selectedWallet?.providerName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='col-md-12'>
              <label htmlFor='phone' className='form-label'>
                Amount
              </label>
              <input
                value={walletData.amount}
                required
                className='form-control '
                type='number'
                min={0}
                step={0.01}
                onChange={handleChange('amount')}
              />
              <small>
                Current Balance :
                <span
                  className={selectedWallet?.walletBalance > 0 ? 'text-success' : 'text-danger'}
                >
                  NGN{selectedWallet?.walletBalance.toLocaleString()}
                </span>
              </small>
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='transactionType' className='form-label'>
                Transaction Type
              </label>
              <select
                required
                onChange={handleChange('transactionType')}
                value={walletData.transactionType}
                className='custom-select'
              >
                {TransactionType.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
              {walletData.transactionType === TransactionTypeMap.Debit &&
              selectedWallet?.walletBalance - walletData.amount >= 0 ? (
                <div className='mt-3'>
                  <input
                    id='payout-check'
                    type='checkbox'
                    className='custom-checkbox'
                    onChange={handleChange('isPayout')}
                    checked={walletData.isPayout}
                  />
                  <label className='ml-2' htmlFor='payout-check'>
                    Create a payout
                  </label>
                </div>
              ) : (
                ''
              )}
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='transactionStatus' className='form-label'>
                Transaction Status
              </label>
              <select
                required
                onChange={handleChange('transactionStatus')}
                value={walletData.transactionStatus}
                className='custom-select'
                disabled={walletData.transactionStatus === TransactionStatusMap.Completed}
              >
                {TransactionStatus.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='transactionStatus' className='form-label'>
                Description type
              </label>
              <select
                required
                onChange={handleChange('paymentDescription')}
                value={walletData.paymentDescription}
                className='form-control '
              >
                {DescriptionType.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='notes' className='form-label'>
                Notes
              </label>
              <textarea
                value={walletData.notes}
                className='form-control '
                rows={2}
                onChange={handleChange('notes')}
                required
              />
            </div>
            {walletData.amount > 0 ? (
              <div className='col-md-12 text-danger mt-4'>
                You are <strong className='fs-7'>{walletData.transactionType}ing</strong> this
                account with the sum of NGN
                <strong className='fs-7'>{(walletData.amount * 1).toLocaleString()}</strong>. The
                balance will be NGN
                <strong className='fs-7 mr-1'>
                  {walletData.transactionType === 'Credit'
                    ? (selectedWallet?.walletBalance + walletData.amount).toLocaleString()
                    : (selectedWallet?.walletBalance - walletData.amount).toLocaleString()}
                </strong>
                after this transaction
              </div>
            ) : (
              ''
            )}
            {walletData.isPayout ? (
              <div className='col-md-12 text-danger mt-4'>
                You have selected to create a <strong className='fs-7'>Payout</strong>. This will
                create a payout request that will need to be Approved before money is transferred to
                user's bank account.
              </div>
            ) : (
              ''
            )}
            <br />
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button type='submit' className='btn btn-primary' onClick={saveTransaction}>
            Save Transaction
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddTransactionModal;
