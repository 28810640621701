import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ISubscriptionInfoModal } from '../../../interface/SubscriptionInterface';
import {
  PAYMENT_PLAN_MAPPING,
  PRODUCT_TYPE,
  STATUS_DISPLAY,
  OnboardingStatus,
} from '../../../utils/mappings';
import { isFormValidated } from '../../../utils/formUtils';
import { ALL_STATES } from '../../../utils/allStates';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import ButtonLoader from '../../common/ButtonLoader';
import { formatDate } from '../../../utils/dateTimeUtils';
// import { IPartnerData } from '../../../interface/PartnerInterface';
// import { IAgentDataDTO } from '../../../interface/AgentInterface';
//import Select from 'react-select';

const MoreInfoModal = ({ open, close, modalData, handleModalChange }: ISubscriptionInfoModal) => {
  const [loading, setLoading] = useState(false);
  // const [partnerData, setPartnerData] = useState<IPartnerData[]>([]);
  // const [agentData, setAgentData] = useState<IAgentDataDTO[]>([]);

  const updateSubscription = async (event: any) => {
    if (!isFormValidated('edit-subscription-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }
    event.preventDefault();
    setLoading(true);
    const payload = modalData;
    try {
      await axios.put(endpoints.InsuranceSubscriptions.mainUrl, payload);

      addToast('Subscription updated successfully', 'success');

      close();
    } catch (error: any) {
      addToast('Error updating subscription', 'error');
    } finally {
      setLoading(false);
    }
  };

  //use for when we want to enable editing of partner and agent code
  // const loadPartners = useCallback(async () => {
  //   const url = endpoints.InsurancePartners.getPartnerList;
  //   try {
  //     const response = await axios.get(url);
  //     //map into resuable object
  //     let selectData = response.data.map((val: IPartnerData) => {
  //       return {
  //         label: val.partnerName,
  //         value: val.partnerCode,
  //       };
  //     });

  //     selectData.unshift({
  //       label: '-- None --',
  //       value: '',
  //     });
  //     setPartnerData(selectData);
  //   } catch (error:any) {
  //     console.error(error);
  //   }
  // }, []);

  // const loadAgents = useCallback(async () => {
  //   const url = `${endpoints.InsuranceAgents.mainUrl}/list`;
  //   try {
  //     const response = await axios.get(url);
  //     //map into resuable object
  //     let selectData = response.data.map((val: IAgentDataDTO) => {
  //       return {
  //         label: val.agentName,
  //         value: val.agentCode,
  //       };
  //     });
  //     selectData.unshift({
  //       label: '-- None --',
  //       value: '',
  //     });

  //     setAgentData(selectData);
  //   } catch (error:any) {
  //     console.error(error);
  //   }
  // }, []);

  // const selectPartnerChange = (selectedValue: any) => {};

  // const selectAgentChange = (selectedValue: any) => {
  //   //handleModalChange("")
  // };

  // useEffect(() => {
  //   if (open) {
  //     loadPartners();
  //     loadAgents();
  //   }
  // }, [open, loadPartners, loadAgents]);

  return (
    <Modal show={open} onHide={close}>
      <form id='edit-subscription-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={loading}>
            <div className='form-group'>
              <label>Subscription Code</label>
              <input
                type='text'
                value={modalData.subscriptionCode}
                className='form-control'
                disabled
              />
            </div>
            <div className='form-group'>
              <label>First Name</label>
              <input
                type='text'
                value={modalData.firstName}
                onChange={handleModalChange('firstName')}
                className='form-control'
                required
              />
            </div>
            <div className='form-group'>
              <label>Last Name</label>
              <input
                type='text'
                value={modalData.lastName}
                onChange={handleModalChange('lastName')}
                className='form-control'
              />
            </div>
            <div className='form-group'>
              <label>Email</label>
              <input
                type='email'
                value={modalData.email}
                onChange={handleModalChange('email')}
                className='form-control'
              />
            </div>
            <div className='form-group'>
              <label>Phone</label>
              <input
                type='text'
                value={modalData.phone}
                onChange={handleModalChange('phone')}
                className='form-control'
                required
              />
            </div>
            <div className='form-group'>
              <label>Product</label>
              <select
                value={modalData.product}
                required
                onChange={handleModalChange('product')}
                className='custom-select'
              >
                {PRODUCT_TYPE.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label>Status</label>

              <select
                className='custom-select'
                value={modalData.status}
                onChange={handleModalChange('status')}
              >
                {STATUS_DISPLAY.map((plan) => (
                  <option key={plan.key} value={plan.value}>
                    {plan.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label>Onboarding Status</label>
              <select
                className='custom-select'
                value={modalData.onboardingStatus}
                onChange={handleModalChange('onboardingStatus')}
              >
                {OnboardingStatus.map((plan) => (
                  <option key={plan.key} value={plan.value}>
                    {plan.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label>Amount</label>
              <input type='number' value={modalData.amount} className='form-control' disabled />
            </div>
            <div className='form-group'>
              <label>Payment Plan</label>
              <select
                className='custom-select'
                value={modalData.paymentPlan}
                onChange={handleModalChange('paymentPlan')}
              >
                {PAYMENT_PLAN_MAPPING.map((plan) => (
                  <option key={plan.key} value={plan.value}>
                    {plan.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label>Number of Beneficiaries</label>
              <input
                type='number'
                value={modalData.numberOfSubscribers}
                className='form-control'
                disabled
              />
            </div>

            <div className='form-group'>
              <label>Location</label>
              <input
                type='text'
                value={modalData.location}
                onChange={handleModalChange('location')}
                className='form-control'
              />
            </div>
            <div className='form-group'>
              <label>State of Residence</label>
              <select
                required
                onChange={handleModalChange('stateOfResidence')}
                value={modalData.stateOfResidence}
                className='custom-select'
              >
                {ALL_STATES.map((option) => (
                  <option key={option.value} value={option.value.toString()}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label>Initial Payment</label>
              <input
                type='text'
                value={formatDate(modalData.initialPayment)}
                disabled
                className='form-control'
              />
            </div>
            <div className='form-group'>
              <label>Last Payment</label>
              <input
                type='text'
                value={formatDate(modalData.lastPayment)}
                className='form-control'
                disabled
              />
            </div>
            <div className='form-group'>
              <label>Next Payment</label>
              <input
                type='text'
                value={formatDate(modalData.nextPayment)}
                disabled
                className='form-control'
              />
            </div>
            {/* <div className='form-group'>
              <label className='form-label'>Partner</label>
              <Select
                options={partnerData}
                onChange={selectPartnerChange}
                className='react-select'
                classNamePrefix='react-select'
              />
            </div>

            <div className='form-group'>
              <label className='form-label'>Agent</label>
              <Select
                options={agentData}
                onChange={selectAgentChange}
                className='react-select'
                classNamePrefix='react-select'
              />
            </div> */}

            <div className='form-group'>
              <label>Notes</label>
              <input
                type='text'
                value={modalData.notes}
                onChange={handleModalChange('notes')}
                className='form-control'
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={updateSubscription}>
            Update
            {loading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MoreInfoModal;
