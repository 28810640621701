import { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import {
  IGetHealthSendClaims,
  IHealthSendBeneficiary,
} from '../../../interface/HealthSendInterface';
import TableLoader from '../../common/TableLoading';
import axios from 'axios';
import { HealthSendBeneficiaries } from './HealthSendBeneficiaries';
import { UserRequests } from './UserRequests';
import HealthSendClaims from './HealthSendClaims';
import { mockHealthSendClaims } from '../../../utils/mockData';

const HealthSendUserDetails = () => {
  const { userId } = useParams() as any;

  const [beneficiaries, setBeneficiaries] = useState<IHealthSendBeneficiary[]>([]);
  const [claims] = useState<IGetHealthSendClaims[]>(mockHealthSendClaims);
  const [loading, setLoading] = useState(false);
  const [tabKey, setTabKey] = useState('beneficiaries');

  const getClaims = useCallback(async () => {
    // let url = ``
    // try {
    //   const response = await axios.get(url)
    //   setClaims(response.data)
    // } catch (error) {
    //   addToast('Error getting healthsend user claims', 'error')
    // }
  }, []);

  const getBeneficiaries = useCallback(async () => {
    if (typeof userId === 'undefined' && userId === '') {
      addToast('Select a user', 'info');
      return;
    }

    let url = `${endpoints.HealthSend.beneficiaries}/${userId}`;
    setLoading(true);

    try {
      const response = await axios.get(url);
      setBeneficiaries(response.data);
      sessionStorage.setItem('healthsendBeneficiaryList', JSON.stringify(response.data));
    } catch (error: any) {
      addToast('Error getting beneficiaries', 'error');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    getBeneficiaries();
    getClaims();
  }, [getBeneficiaries, getClaims]);

  return (
    <div>
      <div className='text-center mb-5'>
        <h2 className='my-5'>User Details</h2>
      </div>
      <div className=''>
        {loading && <TableLoader />}

        {!loading && (
          <Tabs fill activeKey={tabKey} onSelect={(readingKey) => setTabKey(readingKey as string)}>
            <Tab eventKey='beneficiaries' title={<h4>Beneficiaries</h4>}>
              <HealthSendBeneficiaries beneficiaries={beneficiaries} userId={userId} />
            </Tab>
            <Tab eventKey='requests' title={<h4>Requests</h4>}>
              <UserRequests userId={userId} />
            </Tab>
            <Tab eventKey='claims' title={<h4>Claims</h4>}>
              <HealthSendClaims claims={claims} userId={userId} />
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export { HealthSendUserDetails };
