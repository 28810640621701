import { ReactNode } from 'react';
import { ILeadsData } from './LeadsInterface';
import { IPharmacyList } from './PharmacyInterface';
import { IFulfilmentPartners } from './FulfilmentPartnersInterface';
import { IWalletAccountDTO } from './WalletsInterface';
import { IAXALookup } from './AXALookupInterface';
import { UserNotes } from './UserNotesInterface';
import { IFulfilments } from './FulfilmentsInterface';
export interface IAppState {
  token: string;
  isAuthenticated: boolean;
  userRole: string;
  loginId: string;
  oneClickLeadsData?: ILeadsData;
  subscriptionCode: string;
  pharmacyList: Array<IPharmacyList>;
  numberOfSubscribers: number;
  paymentPlan: string;
  isMalariaView: boolean;
  productView: string;
  fulfilmentPartnersList: Array<IFulfilmentPartners>;
  trackingNumber: string;
  selectedWallet: IWalletAccountDTO | null;
  //for real time updates
  insuranceSubscriptions: IRealTimeUpdateProp | null;
  supportRequests: IRealTimeUpdateProp | null;
  insurancePayments: IRealTimeUpdateProp | null;
  fulfilmentRequests: IRealTimeUpdateProp | null;
  walletTransactions: IRealTimeUpdateProp | null;
  insuranceClaims: IRealTimeUpdateProp | null;
  axaLookupData: IAXALookup | null;
  notesList: Array<UserNotes>;
  //for persisting refresh
  fulfilmentSearchResult: Array<IFulfilments>;
}

export interface IRealTimeUpdateProp {
  dataAction: string;
  dataId: string;
  id: number;
}

export interface IDispatch {
  type: string;
  payload: any;
  propName: string;
  propData: any;
}

export interface IContextProps {
  children: ReactNode;
}

export interface IPrivateRouteProps {
  exact?: boolean;
  path: string;
  component: any; //React.ComponentType<any>;
}

export interface IProps {
  history: any;
}

export interface ILoginProps {
  username: string;
  password: string;
}

export interface IConfirmationModal {
  show: boolean;
  hide: Function;
  title: string;
  content: string;
  okText?: string;
  cancelText?: string;
  okCallback?: Function;
  cancelCallback?: Function;
}

export const initialAppState: IAppState = {
  token: '',
  isAuthenticated: false,
  userRole: '',
  loginId: '',
  oneClickLeadsData: undefined,
  subscriptionCode: '',
  pharmacyList: [],
  numberOfSubscribers: 0,
  paymentPlan: 'Monthly',
  isMalariaView: true,
  productView: 'Malaria',
  fulfilmentPartnersList: [],
  trackingNumber: '',
  selectedWallet: null,
  insuranceSubscriptions: null,
  supportRequests: null,
  insurancePayments: null,
  fulfilmentRequests: null,
  walletTransactions: null,
  insuranceClaims: null,
  axaLookupData: null,
  notesList: [],
  fulfilmentSearchResult: [],
};

export const initialLoginState: ILoginProps = {
  username: '',
  password: '',
};
