import axios from 'axios';
import { useEffect, useState } from 'react';
import { IFulfilmentDetailsProp, IFulfilments } from '../../../interface/FulfilmentsInterface';
import { IPharmacyWalletDTO } from '../../../interface/PharmacyInterface';
import { isFormValidated } from '../../../utils/formUtils';
import {
  FulfilmentServiceMap,
  FulfilmentStatus,
  FulfilmentStatusMap,
} from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import TableLoader from '../../common/TableLoading';
import { PharmacyBlacklist } from '../../../utils/constants';
import { IPharmacyRatingData } from '../../../interface/PharmacyRatingInterface';
import PharmacyRatingsModal from '../claims/PharmacyRatingsModal';

const FulfilmentInfo = ({
  fulfilmentRequest,
  toggleLock,
  getFulfilment,
  openNoteModal,
}: IFulfilmentDetailsProp) => {
  //  const [fulfilmentRequest, setFulfilmentRequest] = useState<IFulfilments | undefined>();
  const [fulfilment, setFulfilmentRequest] = useState<IFulfilments | undefined>(fulfilmentRequest);
  const [payInstantly, setPayInstantly] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [pharmacy, setPharmacy] = useState<IPharmacyWalletDTO | undefined>();
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState<IPharmacyRatingData>({
    serviceId: 0,
    serviceType: 'Fulfilment',
    pharmacyName: '',
    pharmacyCode: '',
  });
  const [isConcluded, setConcluded] = useState(false);
  //const { requestId } = useParams() as any;

  const handleChange = (input: any) => (event: any) => {
    const previousStatus = fulfilment?.fulfilmentStatus;

    setFulfilmentRequest({
      ...fulfilment!,
      [input]: event.target.value,
    });
    const eventValue = event.target.value;
    if (
      input === 'fulfilmentStatus' &&
      (eventValue === FulfilmentStatus.Approved || eventValue === FulfilmentStatus.Completed)
    ) {
      setShowCheckbox(true);
      //call pharmacy api
      getPharmacyData();

      //show rating. modal can't be closed
      if (
        previousStatus !== FulfilmentStatus.Approved &&
        eventValue === FulfilmentStatus.Completed
      ) {
        toggleRatingModal();
        setConcluded(true);
      }

      if (
        eventValue === FulfilmentStatus.Approved &&
        previousStatus !== FulfilmentStatus.Approved
      ) {
        toggleRatingModal();
        setConcluded(true);
      }

      //if previous is Approved, rating done, so skip
      // if (previousStatus !== FulfilmentStatus.Approved) {
      //   toggleRatingModal();
      // }
    } else {
      setShowCheckbox(false);
      setPayInstantly(false);
    }
    //if Fulfilment Status set to Cancelled, show modal
    if (input === 'fulfilmentStatus' && eventValue === FulfilmentStatus.Cancelled) {
      if (openNoteModal) {
        openNoteModal(FulfilmentStatus.Cancelled, true);
      }
    }
  };
  const getPharmacyData = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/${fulfilmentRequest!.pharmacyCode}`;
    try {
      const response = await axios.get(url);
      const data = response.data as IPharmacyWalletDTO;

      if (data.payInstantly) {
        setPayInstantly(true);
      }

      setPharmacy(response.data);
    } catch (error: any) {
      console.error(error);
    } finally {
      //setLoading(false);
    }
  };

  const updateFulfilment = async (event: any) => {
    if (!isFormValidated('edit-fulfilment-form')) {
      addToast('Please fill all required fields!', 'warning');
      return;
    }

    event.preventDefault();
    //stop users from using WellaHealth pharmacies

    if (
      PharmacyBlacklist.includes(fulfilment!.pharmacyCode) &&
      fulfilment!.fulfilmentStatus !== 'Cancelled'
    ) {
      addToast('Please change from default pharmacy', 'error');
      return;
    }

    const totalCost = fulfilment!.drugCost + fulfilment!.testCost;

    if (isConcluded && totalCost < 50) {
      addToast(
        'Please fix all errors related to cost. If you are confused, consult your SOP or team lead',
        'error'
      );

      return;
    }

    event.preventDefault();

    // create payload
    let payload = {
      requestId: fulfilment!.requestId,
      enrollmentId: fulfilment!.enrollmentId,
      enrollmentCode: fulfilment!.enrollmentCode,
      enrolleeFirstName: fulfilment!.enrolleeFirstName,
      enrolleeLastName: fulfilment!.enrolleeLastName,
      enrolleePhone: fulfilment!.enrolleePhone,
      enrolleeEmail: fulfilment!.enrolleeEmail,
      enrolleeAddress: fulfilment!.enrolleeAddress,
      pharmacyCode: fulfilment!.pharmacyCode,
      isPharmacyPaid: fulfilment!.isPharmacyPaid,
      fulfilmentStatus: fulfilment!.fulfilmentStatus,
      fulfilmentService: fulfilment!.fulfilmentService,
      notes: fulfilment!.notes,
      preAuthorizationCode: fulfilment!.preAuthorizationCode,
      //pickUpDate: fulfilmentRequest.pickUpDate,
      payInstantly: payInstantly,
      //rating: rating,
    };

    setButtonLoading(true);
    toggleLock(true);
    //call api
    let url = endpoints.FulfilmentRequests.mainUrl;

    try {
      await axios.put(url, payload);
      addToast('Fulfilment Updated Successfully', 'success');
      // if (fulfilment?.partnerCode === RelianceHmoCode) {
      //   notifyHMO(fulfilment!.requestId, fulfilment!.fulfilmentStatus);
      // }
      getFulfilment();
    } catch (error: any) {
      addToast('An error occured updating the fulfilment', 'error');
    } finally {
      setButtonLoading(false);
      toggleLock(false);
    }
  };

  const reprofileFulfilment = async (event: any) => {
    const confirm = window.confirm(
      'This action will recreate this fulfilment as a new fulfilment. Do you wish to continue?'
    );

    if (!confirm) {
      return;
    }

    const payload = {
      requestId: fulfilment?.requestId,
      fulfilmentStatus: FulfilmentStatus.Pending,
    };

    const url = `${endpoints.FulfilmentRequests.mainUrl}/reprofile`;

    setButtonLoading(true);
    toggleLock(true);
    try {
      await axios.post(url, payload);
      addToast('Request successfully re-profiled', 'success');
    } catch (error: any) {
      addToast('An error occured while profiling the request', 'error');
    } finally {
      setButtonLoading(false);
      toggleLock(false);
    }
  };

  const toggleRatingModal = () => {
    if (fulfilmentRequest) {
      setRating({
        ...rating,
        pharmacyCode: fulfilmentRequest?.pharmacyCode!,
        serviceType: 'Fulfilment',
        serviceId: fulfilmentRequest?.requestId!,
        pharmacyName: fulfilmentRequest?.pharmacyCode,
      });
    }
    setShowRatingModal(!showRatingModal);
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayInstantly(event.target.checked);
  };

  useEffect(() => {
    setFulfilmentRequest(fulfilmentRequest);
  }, [fulfilmentRequest]);

  let editPharmacyRatingModal = (
    <PharmacyRatingsModal
      open={showRatingModal}
      close={toggleRatingModal}
      data={rating}
      keepOpen={true}
    />
  );

  return (
    <div className='container mt-5'>
      {fulfilment ? (
        <form id='edit-fulfilment-form'>
          {editPharmacyRatingModal}
          <fieldset disabled={isButtonLoading} className='row'>
            <div className='form-group col-sm-3 mt-3'>
              <label>Enrollment Code / HMO ID</label>
              <input
                type='text'
                className='form-control'
                value={fulfilment.enrollmentCode}
                disabled
              />
            </div>
            <div className='form-group col-sm-3 mt-3'>
              <label>Pre-Authorization Code</label>
              <input
                type='text'
                className='form-control'
                value={fulfilment.preAuthorizationCode}
                onChange={handleChange('preAuthorizationCode')}
              />
            </div>

            <div className='form-group col-sm-3 mt-3'>
              <label>First Name</label>
              <input
                type='text'
                value={fulfilment.enrolleeFirstName}
                required
                className='form-control'
                onChange={handleChange('enrolleeFirstName')}
              />
            </div>
            <div className='form-group  col-sm-3 mt-3'>
              <label>Last Name</label>
              <input
                type='text'
                value={fulfilment.enrolleeLastName}
                required
                className='form-control'
                onChange={handleChange('enrolleeLastName')}
              />
            </div>
            <div className='form-group  col-sm-4 mt-3'>
              <label>Phone Number</label>
              <input
                type='text'
                value={fulfilment.enrolleePhone}
                required
                className='form-control'
                onChange={handleChange('enrolleePhone')}
              />
            </div>
            <div className='form-group  col-sm-2 mt-3'>
              <label>Pickup code</label>
              <input type='text' disabled value={fulfilment.pickupCode} className='form-control' />
            </div>
            <div className='form-group  col-sm-6 mt-3'>
              <label>Email</label>
              <input
                type='text'
                value={fulfilment.enrolleeEmail}
                className='form-control'
                onChange={handleChange('enrolleeEmail')}
              />
            </div>
            <div className='form-group  col-sm-6 mt-3'>
              <label>Address</label>
              <input
                type='text'
                value={fulfilment.enrolleeAddress}
                className='form-control'
                required
                disabled
              />
            </div>
            <div className='form-group  col-sm-6 mt-3'>
              <label>Fulfilment Service</label>
              <select
                value={fulfilment.fulfilmentService}
                className='custom-select'
                onChange={handleChange('fulfilmentService')}
              >
                {FulfilmentServiceMap.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group  col-sm-6 mt-3'>
              <label>Fulfilment Status</label>
              <select
                value={fulfilment.fulfilmentStatus}
                className='custom-select'
                onChange={handleChange('fulfilmentStatus')}
              >
                {FulfilmentStatusMap.map((option) => (
                  <option value={option.value} key={option.key}>
                    {option.text}
                  </option>
                ))}
              </select>
              {showCheckbox ? (
                <div className=' mb-3'>
                  {pharmacy ? (
                    <div>
                      {pharmacy?.payInstantly ? (
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            checked={payInstantly}
                            onChange={handleCheckbox}
                            type='checkbox'
                            value=''
                          />

                          <label className='form-check-label'>Pay Pharmacy Instantly</label>
                        </div>
                      ) : (
                        <span className='text-info '>
                          Instant Payment not available for this pharmacy
                        </span>
                      )}
                      <div>
                        {pharmacy.accountId === null || pharmacy.accountId === '' ? (
                          <div>
                            {pharmacy.pharmacyName}
                            <span className='text-danger'>: does not have wallet.</span>
                          </div>
                        ) : (
                          <div>
                            {pharmacy.pharmacyName}
                            <span className='text-success'>
                              : has wallet. Please be sure that this is the correct pharmacy
                            </span>
                          </div>
                        )}
                        <div className='text-danger mt-2'>
                          You are about to pay {pharmacy.pharmacyName} the sum of ₦
                          {fulfilment.drugCost + fulfilment.testCost} for this fulfilment. Please
                          confirm before proceeding.
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='text-info'>Checking pharmacy wallet detail...</div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>

            <div className='form-group  col-sm-3 mt-3'>
              <label>Fulfilment Cost</label>
              <input
                type='text'
                value={fulfilment.drugCost + fulfilment.testCost}
                className='form-control'
                required
                disabled
              />
            </div>
            <div className='form-group  col-sm-3 mt-3'>
              <label>Amount Paid by Partner</label>
              <input
                type='text'
                value={fulfilment.drugTariffCost + fulfilment.testTariffCost}
                className='form-control'
                required
                disabled
              />
              {parseFloat((fulfilment.drugCost + fulfilment.testCost).toString()) >
              parseFloat((fulfilment.drugTariffCost + fulfilment.testTariffCost).toString()) ? (
                <div className='mb-4 text-danger'>Fulfilment cost is Higher than tarrif cost</div>
              ) : (
                ''
              )}
            </div>
            <div className='form-group col-sm-6 mt-2'>
              <label>Partner name</label>
              <input
                type='text'
                value={fulfilment.requestSource}
                className='form-control'
                required
                disabled
              />
            </div>
            <div className='form-group col-sm-6 mt-2'>
              <label>Notes</label>
              <input
                type='text'
                value={fulfilment.notes}
                className='form-control'
                onChange={handleChange('notes')}
              />
            </div>
            <br />
            <div className='form-group col-sm-4 mt-3'>
              <button
                className='btn btn-primary btn-block'
                type='submit'
                disabled={isButtonLoading}
                onClick={updateFulfilment}
              >
                Submit
                {isButtonLoading ? <ButtonLoader /> : ''}
              </button>
            </div>
            {fulfilment.fulfilmentStatus === FulfilmentStatus.Cancelled ? (
              <div className='form-group col-sm-4 mt-3'>
                <button
                  className='btn btn-info btn-block'
                  type='button'
                  disabled={isButtonLoading}
                  onClick={reprofileFulfilment}
                >
                  Reprofile fulfilment
                  {isButtonLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            ) : (
              ''
            )}
            {fulfilment.fulfilmentStatus === FulfilmentStatus.Approved ||
            fulfilment.fulfilmentStatus === FulfilmentStatus.Completed ? (
              <div className='form-group col-sm-4 mt-3'>
                <button
                  className='btn btn-info btn-block'
                  type='button'
                  disabled={isButtonLoading}
                  onClick={reprofileFulfilment}
                >
                  Refill fulfilment
                  {isButtonLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            ) : (
              ''
            )}
          </fieldset>
        </form>
      ) : (
        <TableLoader />
      )}
    </div>
  );
};

export default FulfilmentInfo;
