import { useState } from 'react';
import { IPharmacyClientGroup, IPharmacyData } from '../../../interface/PharmacyInterface';
import { Modal } from 'react-bootstrap';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import ButtonLoader from '../../common/ButtonLoader';
import { ReactSelectOption } from '../../../interface/CommonInterface';
import useSWR from 'swr';
import fetcher from '../../../utils/axiosFetcher';
import Select from 'react-select';

const ClientModal = ({
  open,
  close,
  modalData,
}: {
  open: boolean;
  close: Function;
  modalData: IPharmacyData;
}) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [clients, setClients] = useState<ReactSelectOption[]>([]);
  const [selectedClient, setSelectedClient] = useState<IPharmacyClientGroup | undefined>();
  const mapDataToSelect = (data: IPharmacyClientGroup[], key: string, config: any) => {
    const dataMap = data!.map((val: IPharmacyClientGroup) => {
      return {
        label: val.clientName,
        value: val.clientId,
      };
    });
    setClients(dataMap);
  };
  const { data } = useSWR<IPharmacyClientGroup[], any>(
    endpoints.InsurancePharmacy.getPharmacyClientList,
    fetcher,
    {
      fallbackData: [],
      errorRetryCount: 1,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onSuccess: mapDataToSelect,
    }
  );
  //data: IPractitionerData, key: string, config: any

  const selectChange = (selectedValue: any) => {
    const client = data?.filter((x) => x.clientId === selectedValue.value)[0];

    if (client === null) {
      return;
    }

    setSelectedClient(client);

    let pharmacyList = client?.pharmacies.reduce((acc, current) => {
      acc += `${current.pharmacyName} \n`;
      return acc;
    }, '');

    setComment(pharmacyList!);
  };

  const closeModal = () => {
    setComment('');
    setSelectedClient(undefined);
    if (close) {
      close();
    }
  };

  const updatePharmacyClient = async (event: any) => {
    if (!isFormValidated('pharmacy-client-modal')) {
      return;
    }
    event.preventDefault();

    if (selectedClient === null || typeof selectedClient === 'undefined') {
      addToast('Please select a client');
      return;
    }

    const url = `${endpoints.InsurancePharmacy.mainUrl}/clients`;

    const payload = {
      oldClientId: modalData.clientId,
      pharmacyId: modalData.pharmacyId,
      newClientId: selectedClient?.clientId,
    };

    setLoading(true);

    try {
      await axios.put(url, payload);
      addToast('Client updated', 'success');
      close();
    } catch (error: any) {
      console.error(error);
      addToast('An error occured', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={closeModal} backdrop='static'>
      <form id='pharmacy-client-modal'>
        <fieldset disabled={loading}>
          <Modal.Header closeButton>
            <Modal.Title>Update Client for {modalData.pharmacyName} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <label className='form-label'>New Pharmacy Client</label>
              <Select
                options={clients}
                onChange={selectChange}
                className='react-select'
                classNamePrefix='react-select'
              />
            </div>
            <div className='form-group'>
              <label>Pharmacies in the client</label>
              <textarea value={comment} className='form-control' rows={5} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-primary' onClick={updatePharmacyClient}>
              Update Client
              {loading ? <ButtonLoader /> : ''}
            </button>
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default ClientModal;
