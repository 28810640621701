import React, { useEffect, useState, useCallback, useContext } from 'react';
import Header from './Header';
import SideMenu from './SideMenu';
import { Redirect, Route } from 'react-router-dom';
import { IPrivateRouteProps, IRealTimeUpdateProp } from '../../interface/AppInterface';
// import { AppContext } from '../../context/AppContext';
import routes from '../../utils/routes';
import Breadcrumbs from '../common/BreadCrumbs';
import * as signalR from '@microsoft/signalr';
import { addToast } from '../../utils/toastNotifications';
import { endpoints } from '../../utils/URL';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import ConfirmationModal from '../common/ConfirmationModal';
import { AppContext } from '../../context/AppContext';
import ScreenLoading from '../common/ScreenLoading';

const Layout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  const { dispatch } = useContext(AppContext);

  const token: any = process.env.REACT_APP_WELLA_TOKEN;

  const [notificationCount, setNotificationCount] = useState(0);
  const notificationSound = new Audio('./done-for-you.ogg');
  const [pushNotification, setPushNotification] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  let counter = 1;
  //let savedToken = 'no token';

  /* Auth methods */

  const auth = useAuth();

  const loginAuth = () => {
    //save the current route so we can navigate to it after login
    sessionStorage.setItem('return-url', window.location.href);
    auth
      .signinRedirect()
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        window.location.href = '/';
      });
  };

  //if an error occurs during renewal,
  if (auth.error) {
    if (showModal) {
      console.log('error me');
    } else {
      setShowModal(true);
    }
  }

  if (!auth.isLoading) {
    //if authenticated, set axios header
    if (auth.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;
      // if (!tokenSaved) {
      //   setSavedToken(auth.user?.access_token as string);
      //   setTokenSaved(true);
      // }
      // console.log('iniital saved ', savedToken);
    } else {
      loginAuth();
      //auth.signinRedirect();
    }
  }

  /* End auth */

  /* Start of SignalR methods for /api/notify */

  //because of the nature of the connection object, we cannot access state object directly
  //hence we use a local variable to keep track of notification count
  // const updateNotificationCount = (message: any) => {
  //   counter = counter + 1;
  //   setNotificationCount(counter);
  //   // //play sound
  //   notificationSound.play();
  //   showNotification(message);

  //   //add to context
  // };
  const updateData = (message: string, dataTable: string, dataAction: string, dataId: string) => {
    const propData: IRealTimeUpdateProp = {
      dataAction: dataAction,
      dataId: dataId,
      id: new Date().getTime() + parseInt(dataId),
    };

    //set's the first character to lower case
    const propName = dataTable.charAt(0).toLowerCase() + dataTable.slice(1);

    // dispatch({
    //   type: 'SET_PROPERTY_STATE',
    //   propName: propName,
    //   propData: propData,
    // });

    dispatch({
      type: 'UNKNOWN',
      propName: propName,
      propData: propData,
    });

    //show push notification and make sound if message is set
    if (message !== '') {
      counter = counter + 1;
      setNotificationCount(counter);
      // //play sound
      notificationSound.play();
      showNotification(message);
    }
  };

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(endpoints.RealTime.coreApi, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
      // accessTokenFactory: () => {
      //   return savedToken;
      // },
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  const start = async () => {
    try {
      await connection.start();
      console.assert(connection.state === signalR.HubConnectionState.Connected);
      console.log('connected');
    } catch (err) {
      console.assert(connection.state === signalR.HubConnectionState.Disconnected);
      console.error(err);
      setTimeout(() => start(), 5000);
    }
  };

  connection.onclose(async () => {
    await start();
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
    addToast('Connection lost. Trying to reconnect', 'warning', false);
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === signalR.HubConnectionState.Connected);
    addToast('Connection re-established', 'success', true, true);
  });

  //connection.on('ReceiveNotification', updateNotificationCount);

  connection.on('GetUpdatedData', updateData);
  /* End of SignalR methods for /api/hubs*/

  /* Start signal R methods for /api/notify for publicAPI */
  const hubconnection = new signalR.HubConnectionBuilder()
    .withUrl(endpoints.RealTime.publicApi, {
      accessTokenFactory: () => {
        return token;
      },
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  const startHub = async () => {
    try {
      await hubconnection.start();
      console.assert(hubconnection.state === signalR.HubConnectionState.Connected);
      console.log('connected');
    } catch (err) {
      console.assert(hubconnection.state === signalR.HubConnectionState.Disconnected);
      console.error(err);
      setTimeout(() => startHub(), 5000);
    }
  };

  hubconnection.onclose(async () => {
    await startHub();
  });

  hubconnection.onreconnecting((error) => {
    console.assert(hubconnection.state === signalR.HubConnectionState.Reconnecting);
    addToast('Connection lost. Trying to reconnect', 'warning', false);
  });

  hubconnection.onreconnected((connectionId) => {
    console.assert(hubconnection.state === signalR.HubConnectionState.Connected);
    addToast('Connection re-established', 'success', true, true);
  });

  //hubconnection.on('ReceiveNotification', updateNotificationCount);
  hubconnection.on('GetUpdatedData', updateData);
  /* End of SignalR methods  /api/notify for publicAPI*/

  //web notification
  const showNotification = (message: string) => {
    const notificationOptions = {
      body: message,
      icon: '/logo.png',
      vibrate: [100, 50, 100],
      data: {
        dateOfArrival: Date.now(),
        primaryKey: 1,
      },
      actions: [
        {
          action: 'close',
          title: 'Close the notification',
        },
      ],
    };

    if (Notification.permission === 'granted') {
      navigator.serviceWorker.getRegistration().then((reg: any) => {
        reg.showNotification('You have a new notification', notificationOptions);
      });
    } else {
      //get permission
      toggleNotification();
    }
  };

  const checkNotificationPermission = async () => {
    if ('Notification' in window && navigator.serviceWorker) {
      if (Notification.permission === 'granted') {
        //do nothing
        // hide bell icon
        setPushNotification(true);
      } else {
        setPushNotification(false);
      }
    }
  };

  const toggleNotification = async () => {
    //if notification enabled, do nothing
    //else enable
    if ('Notification' in window && navigator.serviceWorker) {
      if (Notification.permission === 'granted') {
        // hide bell icon
        setPushNotification(true);
      } else {
        //show bell icon
        //request for notification
        try {
          await Notification.requestPermission((status) => {
            if (status === 'granted') {
              navigator.serviceWorker.getRegistration().then((reg: any) => {
                reg.showNotification('You have enabled notifications');
              });
              //hide bell
              setPushNotification(true);
            } else {
              console.log('notification disabled');
            }
          });
        } catch (error: any) {
          console.error('Permission error occured');
        }
      }
    }
  };

  // notification handlers
  const getUnreadCount = useCallback(async () => {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;

      const response = await axios.get(endpoints.InsuranceNotifications.getUnreadCount);
      const notificationCount = response.data;
      //disabled lint because there is no other viable alternative
      // eslint-disable-next-line
      counter = notificationCount;
      setNotificationCount(notificationCount);
    } catch (error: any) {
      console.error('Error getting badge count ', error);
    }
  }, [auth.user]);

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  useEffect(() => {
    getUnreadCount();
  }, [getUnreadCount]);

  // /* signalR use effect */
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      start();
    }
    //disabled lint because there is no other viable alternative
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      startHub();
    }
    //disabled lint because there is no other viable alternative
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {showModal ? (
        <ConfirmationModal
          show={showModal}
          hide={() => setShowModal(false)}
          title='Session expired'
          okCallback={loginAuth}
          content='Click Ok to Login'
        />
      ) : (
        <div></div>
      )}
      <div>
        {auth.isLoading ? (
          <div>Loading</div>
        ) : auth.isAuthenticated ? (
          <div>
            <Header
              pushNotification={pushNotification}
              notificationCount={notificationCount}
              toggleNotification={toggleNotification}
            />
            <ScreenLoading />

            <div className='container-fluid'>
              <span
                id='request-spinner'
                className='spinner-border spinner-border-sm text-success request-spinner d-none'
                role='status'
                aria-hidden='true'
              ></span>

              <div className='row'>
                <SideMenu />

                <Route
                  {...rest}
                  render={(props: any) => {
                    const crumbs = routes
                      // Get all routes that contain the current one.
                      .filter(({ path }) => props.match.path.includes(path))
                      // Swap out any dynamic routes with their param values.
                      // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                      .map(({ path, ...rest }) => ({
                        path: Object.keys(props.match.params).length
                          ? Object.keys(props.match.params).reduce(
                              (path, param) => path.replace(`:${param}`, props.match.params[param]),
                              path
                            )
                          : path,
                        ...rest,
                      }));

                    //return
                    // auth.isLoading ? (
                    //   <div>Loading</div>
                    // ) : auth.isAuthenticated ? (
                    return (
                      <main
                        role='main'
                        id='main-view'
                        className='col-md-9 ml-sm-auto col-lg-10 px-md-4'
                        style={{ marginTop: '30px', marginBottom: '100px' }}
                      >
                        <Breadcrumbs crumbs={crumbs} />
                        <Component {...props} />
                      </main>
                    );

                    //  ) : (
                    //     <Redirect to='/' />
                    //   );

                    // (isAuthenticated ? <Component {...props} /> : <Redirect to='/' />
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <Redirect to='/' />
        )}
      </div>
    </div>
  );
};

export default Layout;
