import axios from 'axios';
import { useEffect, useState } from 'react';
import { IEditFulfilmentModal } from '../../../interface/FulfilmentsInterface';
import {
  IFulfilmentConfiguration,
  IFulfilmentPartners,
} from '../../../interface/FulfilmentPartnersInterface';
import {
  initialPharmacyData,
  IPharmacyData,
  IPharmacyListData,
} from '../../../interface/PharmacyInterface';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import Select from 'react-select';
import { PharmacyStatusMap } from '../../../utils/mappings';

const PharmacyModal = ({ open, close, modalData }: IEditFulfilmentModal) => {
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [pharmacyData, setPharmacyData] = useState<IPharmacyData>(initialPharmacyData);

  const [isChangePharm, setChangePharm] = useState(false);
  const [pharmacyListSelect, setPharmacyListSelect] = useState([]);
  const [pharmacyListData, setPharmacyListData] = useState<IPharmacyListData[]>([]);

  const [newPharmacyCode, setNewPharmacyCode] = useState(modalData.pharmacyCode);

  const [partnerConfiguration, setPartnerConfiguration] = useState<IFulfilmentConfiguration>({
    partnerCode: '',
    partnerName: '',
    dispenseTests: false,
    dispenseDrugs: false,
    changePharmacy: false,
    sendDrugsDispensed: false,
    services: [],
    serviceCharge: 200,
    chargeValueType: 'Absolute',
    lockTariff: true,
  });

  const relianceUsername = process.env.REACT_APP_RELIANCE_USERID;
  const reliancePassword = process.env.REACT_APP_RELIANCE_PASSWORD;
  const axiosInstance = axios.create();

  const getPharmacyList = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/list`;

    try {
      const response = await axios.get(url);
      let selectData = response.data
        .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
        .map((val: any) => {
          return {
            label: `${val.pharmacyName} ${val.address}`,
            value: val.pharmacyCode,
          };
        });
      //set to state
      setPharmacyListSelect(selectData);
      setPharmacyListData(response.data);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('pharmacyList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const selectChange = (selectedValue: any) => {
    setNewPharmacyCode(selectedValue.value);
  };

  //fetch pharmacy details from api
  //set new pharmacy code and send details to reliance

  const savePharmacyCode = async () => {
    //save to reliance
    try {
      setIsSaving(true);

      //if (partnerConfiguration.partnerCode === 'WHPX10002') {
      await sendPharmacyCodeToReliance(newPharmacyCode);
      //}
      await updateFulfilmentPharmacy(newPharmacyCode);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  //save to remote
  const updateFulfilmentPharmacy = async (pharmacyCode: string) => {
    const payload = {
      pharmacyCode: pharmacyCode,
      requestId: modalData.requestId,
    };

    let url = `${endpoints.FulfilmentRequests.mainUrl}/${modalData.requestId}/pharmacy`;

    await axios
      .put(url, payload)
      .then((response) => {
        addToast('Pharmacy changed successfully', 'success');
        setChangePharm(false);
        getPharmacyData(pharmacyCode);
      })
      .catch((error) => {
        addToast('An error occured while updating pharmacy');
      });
  };

  const sendPharmacyCodeToReliance = async (pharmacyCode: string) => {
    const selectedPharmacy = pharmacyListData.filter((x) => x.pharmacyCode === pharmacyCode)[0];

    if (selectedPharmacy === null || typeof selectedPharmacy === 'undefined') {
      return false;
    }

    let payload = {
      provider: {
        pharmacy_code: pharmacyCode,
        name: selectedPharmacy.pharmacyName,
        address: selectedPharmacy.address,
      },
      service_type: modalData.fulfilmentService.toLowerCase(),
      extra_details: 'Please pick up between 8am and 8pm',
    };

    let updateResponse = false;
    let url = `${endpoints.Reliance.postTestResult}/${modalData.enrollmentId}/provider`;
    await axiosInstance
      .put(url, payload, {
        auth: {
          username: relianceUsername as string,
          password: reliancePassword as string,
        },
      })
      .then((response) => {
        addToast('Pharmacy change sent to Reliance', 'success');
        updateResponse = true;
      })
      .catch((error) => {
        //stop button loading
        addToast('An error occured while sending requests', 'error');
      });

    return updateResponse;
  };

  const getPharmacyData = async (pharmacyCode: string) => {
    setLoading(true);
    //setPharmacyData(initialPharmacyData);

    let url = `${endpoints.InsurancePharmacy.mainUrl}/${pharmacyCode}`;
    try {
      const response = await axios.get(url);
      setPharmacyData(response.data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changePharmacy = () => {
    setChangePharm(!isChangePharm);
  };

  const confirmPharmacy = () => {
    setNewPharmacyCode(modalData.pharmacyCode);
    sendPharmacyCodeToReliance(modalData.pharmacyCode);
  };

  //use effect to load all pharmacies if not already in session
  useEffect(() => {
    //load pharmacy list and save in session storage if not available
    if (open) {
      const savedPharmacyList = sessionStorage.getItem('pharmacyList');

      if (savedPharmacyList !== null) {
        const pharmacyList = JSON.parse(savedPharmacyList);
        let selectData = pharmacyList
          .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
          .map((val: { pharmacyName: string; pharmacyCode: string; address: string }) => {
            return {
              label: `${val.pharmacyName} ${val.address}`,
              value: val.pharmacyCode,
            };
          });
        //set to state
        setPharmacyListSelect(selectData);
        setPharmacyListData(pharmacyList);
      } else {
        //call api
        getPharmacyList();
      }
    }
  }, [open]);

  //use effect to load pharmacy data
  useEffect(() => {
    if (open) {
      getPharmacyData(modalData.pharmacyCode);
    }
  }, [modalData.pharmacyCode, open]);

  //use effect to load partner specific configuration
  useEffect(() => {
    if (open) {
      const savedPartnerList = localStorage.getItem('partnersList');

      if (savedPartnerList !== null && typeof savedPartnerList !== 'undefined') {
        const parsedPartnerList = JSON.parse(savedPartnerList) as IFulfilmentPartners[];

        const selectedPartner = parsedPartnerList.filter(
          (x) => x.partnerCode === modalData.partnerCode
        )[0];

        const parsedPartnerConfig = selectedPartner || null;
        if (parsedPartnerConfig !== null) {
          //map
          const partnerConfig: IFulfilmentConfiguration = {
            partnerCode: parsedPartnerConfig.partnerCode,
            partnerName: parsedPartnerConfig.partnerName,
            dispenseTests: true,
            dispenseDrugs: true,
            changePharmacy: true,
            sendDrugsDispensed: parsedPartnerConfig.partnerCode === 'WHPX10002' ? true : false,
            services: ['telemedicine', 'acute', 'chronic'],
            serviceCharge: parsedPartnerConfig.serviceCharge,
            chargeValueType: parsedPartnerConfig.chargeValueType,
            lockTariff: true,
          };

          setPartnerConfiguration(partnerConfig);
        }
      } else {
        addToast('Could not load partner configuration. Close modal and reload page', 'error');
      }
    }
  }, [open, modalData.partnerCode]);

  return (
    <Modal show={open} onHide={() => close()} backdrop={isSaving ? 'static' : true}>
      <Modal.Header closeButton={!isSaving}>
        <Modal.Title>
          Pharmacy Details
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <span>
              {partnerConfiguration.changePharmacy ? (
                <span>
                  <button
                    className='btn btn-light'
                    //   color='primary'
                    onClick={confirmPharmacy}
                    style={{ marginLeft: '20px' }}
                  >
                    Confirm
                  </button>
                  <button
                    className='btn btn-light'
                    //   color='primary'
                    onClick={changePharmacy}
                    style={{ marginLeft: '20px' }}
                  >
                    Change
                  </button>
                </span>
              ) : (
                ''
              )}
            </span>
          )}
        </Modal.Title>
      </Modal.Header>

      {isChangePharm ? (
        <div className='container' style={{ marginTop: '10px' }}>
          <div className='row'>
            <div className='col-md-8'>
              <Select
                options={pharmacyListSelect}
                onChange={selectChange}
                className='react-select'
                classNamePrefix='react-select'
              />
              <label>Select Pharmacy</label>
            </div>
            <div className='col-md-4'>
              <button type='button' className='btn btn-primary' onClick={savePharmacyCode}>
                Save
                {isSaving ? <ButtonLoader /> : ''}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {pharmacyData != null ? (
        <Modal.Body className='modal-body'>
          <div className='container'>
            <div className='row'>
              {pharmacyData.accountId === null || pharmacyData.accountId === '' ? (
                <div className='col-md-12 text-danger'>Pharmacy does not have walllets</div>
              ) : (
                ''
              )}
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Pharmacy Name</div>
              <div className='col-md-5 offset-1'>{pharmacyData.pharmacyName}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Phone Number</div>
              <div className='col-md-5 offset-1'>{pharmacyData.phoneNumber}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>State Of Premise</div>
              <div className='col-md-5 offset-1 '>
                {pharmacyData.addressInformation?.stateOfPremise}
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>LGA</div>
              <div className='col-md-5 offset-1 '> {pharmacyData.addressInformation?.lga}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Address</div>
              <div className='col-md-5 offset-1 '>
                {pharmacyData.addressInformation?.pharmacyAddress}
              </div>
            </div>
            <br />

            <br />
            <div className='row'>
              <div className='col-md-6'>Relationship Officer</div>
              <div className='col-md-5 offset-1 '>{pharmacyData.relationshipOfficer}</div>
            </div>
            <br />

            <br />
          </div>
        </Modal.Body>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default PharmacyModal;
