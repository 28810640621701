const TableLoader = () => {
  return (
    <div className='text-center'>
      <span
        className='spinner-border spinner-border-lg table-spinner'
        role='status'
        aria-hidden='true'
      ></span>
    </div>
  );
};

export default TableLoader;
