import { usePlacesWidget } from 'react-google-autocomplete';
import { IAddressComponent, IGoogleInputProps } from '../../interface/PharmacyInterface';

const GoogleAddressInput = ({ inputString, changeCallback, selectCallback }: IGoogleInputProps) => {
  //const [searchTerm, setSearchTerm] = useState(inputString);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place: any) => {
      const selectedAddress = place.address_components;

      if (selectedAddress === null || typeof selectedAddress === 'undefined') {
        return;
      }

      // const fullAddress = `${place!.name} ${place!.formatted_address}`;
      const fullAddress = `${place!.name} ${place!.formatted_address}`;

      //reset to defaults
      let addressInformation: IAddressComponent = {
        plus_code: '',
        street_number: '', //street number
        route: '', // street name
        locality: '', //Name of big town/City e.g Lagos
        administrative_area_level_1: '', //State
        administrative_area_level_2: '', //LGA
        sublocality_level_1: '', //Area
        neighborhood: '', //Area
        country: '', //Country
        postal_code: '', //Postal code
        colloquial_area: '', //Area
        fullAddress: fullAddress,
      };

      for (const component of place.address_components) {
        const addressType = component.types[0] as string;

        //if proprerty exist
        if (addressType in addressInformation) {
          const addressValue = component['long_name'] as string;

          //https://stackoverflow.com/a/62438434/2929906
          let addressKey = addressType as keyof typeof addressInformation;
          addressInformation[addressKey] = addressValue;
        }
      }

      if (selectCallback) {
        selectCallback(addressInformation);
      }
    },
    options: {
      types: [],
      //fields: ['address_component', 'geometry.location', 'formatted_address'],
      fields: ['address_component', 'formatted_address', 'name'],
      componentRestrictions: { country: 'ng' },
    },
  });

  const handleChange = (event: any) => {
    const value = event.target.value;

    // setSearchTerm(value);
    if (changeCallback) {
      changeCallback(value);
    }
  };

  return (
    <div className='form-group col-12'>
      <label>
        Patient location <span className='text-danger'>*</span>
      </label>
      <input
        // id='address'
        type='text'
        className='form-control'
        required
        value={inputString}
        onChange={handleChange}
        ref={ref as unknown as React.LegacyRef<HTMLInputElement>}
      />
    </div>
  );
};
export default GoogleAddressInput;
