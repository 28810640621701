import axios from 'axios';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  IChatBoxMessages,
  IMessagesDataDTO,
  IReadMessage,
  IUnreadMessages,
} from '../../../interface/MessagesInterface';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { calculateMessageCount } from '../../../utils/formUtils';
import ChatBox from './Chatbox';
import './styles.css';
import ButtonLoader from '../../common/ButtonLoader';
import { isFormValidated } from '../../../utils/formUtils';
import { formatDateTime } from '../../../utils/dateTimeUtils';

const Messages = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [messages, setMessage] = useState<IChatBoxMessages[]>([]);
  const [inboxMessages, setInboxMessages] = useState<IUnreadMessages[]>([]);

  const [messageMetaData, setMessageMetaData] = useState({
    totalCount: 160,
    characterCount: 0,
    pageCount: 1,
  });

  const getUnreadMessages = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.InsuranceMessages.getUnreadMessages);
      setInboxMessages(response.data);
    } catch (error: any) {
      console.error(error);
      addToast('There was an error fetching messages', 'error');
    }
  }, []);

  const getMessagesByPhone = async (phoneNumber: string) => {
    if (phoneNumber === '') {
      return;
    }
    try {
      const url = `${endpoints.InsuranceMessages.mainUrl}/${phoneNumber}`;
      const response = await axios.get(url);
      setMessage(response.data);
    } catch (error: any) {
      addToast('Could not fetch messages for the phone number', 'error');
      console.error(error);
    } finally {
      setIsSearching(false);
    }
  };

  const updateReadReceipt = async (messageId: number) => {
    const url = `${endpoints.InsuranceMessages.updateReadMessages}`;

    const payload: IReadMessage = {
      selectedId: [messageId],
      isRead: true,
    };

    try {
      await axios.put(url, payload);
      getUnreadMessages();
    } catch (error: any) {
      addToast('Failed to update read receipt', 'error');
      console.error(error);
    }
  };

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();

    if (messages.length === 0 || messages === null) {
      addToast('Select a Phone number or search for a phone number', 'warning');
      return;
    }
    const phoneNumber = messages[0].phoneNumber;

    if (phoneNumber === '') {
      return;
    }

    if (!isFormValidated('message-form')) {
      return;
    }

    setLoading(true);

    const payload: IMessagesDataDTO = {
      phoneNumbers: [phoneNumber],
      messageContent: textMessage,
      messageChannel: 'SMS',
      isBulkSms: true,
      queueDate: '',
      sendLater: false,
      sendDate: '',
      hours: '',
      minutes: '',
      meridian: 1,
    };

    const url = `${endpoints.InsuranceMessages.mainUrl}`;

    try {
      await axios.post(url, payload);
      addToast('Message Sent Successfully', 'success');
      setTextMessage('');
      getMessagesByPhone(phoneNumber);
    } catch (error: any) {
      addToast('An error occured when sending your message', 'error');
    } finally {
      setLoading(false);
    }
  };

  const viewMessage = (inboxId: number) => (e: any) => {
    const selectedMessage = inboxMessages.filter((x) => x.inboxId === inboxId)[0];

    const chatBoxMessage: IChatBoxMessages = {
      id: selectedMessage.inboxId,
      messageId: selectedMessage.inboxId,
      messageChannel: selectedMessage.messageChannel,
      messageContent: selectedMessage.messageContent,
      deliveryMessage: selectedMessage.deliveryMessage,
      deliveryStatus: 'Received',
      isRead: true,
      messageType: 'Inbox',
      messageDate: selectedMessage.dateReceived,
      phoneNumber: selectedMessage.phoneNumber,
    };

    const initialMessage = [chatBoxMessage];
    setMessage(initialMessage);

    //update read receipt for selected message
    //inboxId
    updateReadReceipt(selectedMessage.inboxId);

    //get previous messages
    getMessagesByPhone(selectedMessage.phoneNumber);
  };

  const clearInput = async () => {
    setSearchQuery('');
  };

  const handleChange = (e: any) => {
    const textMessage = e.target.value;
    setTextMessage(textMessage);

    const metaData = calculateMessageCount(textMessage.length);

    setMessageMetaData(metaData);
  };

  const searchInputChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);

    if (event.target.value === '') {
      clearInput();
    }
  };

  const onSearchClick = (e: any) => {
    if (searchQuery === '') {
      return;
    }
    setIsSearching(true);
    getMessagesByPhone(searchQuery);
  };

  useEffect(() => {
    getUnreadMessages();
  }, [getUnreadMessages]);

  let searchInput = (
    <div className='row'>
      <input
        type='text'
        className='form-control col-md-3'
        value={searchQuery}
        onChange={searchInputChange()}
        placeholder='Search Older Messages'
      ></input>
      <i onClick={clearInput} className='bi bi-x-circle-fill close-icon'></i>
      <button
        type='button'
        className='btn btn-primary'
        style={{ marginLeft: '10px' }}
        onClick={onSearchClick}
      >
        Search
        {isSearching ? <ButtonLoader /> : ''}
      </button>
    </div>
  );

  let inbox = (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-12'>
          {inboxMessages.map((inbox) => (
            <div key={inbox.inboxId} onClick={viewMessage(inbox.inboxId)}>
              <div className='wella-card card'>
                <div className='card-body' style={{ cursor: 'pointer' }}>
                  <div className='font-weight-bold'> {inbox.phoneNumber}</div>
                  <div className='mt-2 mb-2'>{inbox.messageContent}</div>
                  <small className='font-weight-light'>{formatDateTime(inbox.dateReceived)}</small>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  let replyBox = (
    <form id='message-form' onSubmit={sendMessage}>
      <div className='form-group'>
        <label>Send Message</label>
        <textarea
          disabled={isLoading ?? false}
          onChange={handleChange}
          value={textMessage}
          style={{ backgroundColor: 'white' }}
          placeholder='Reply...'
          className='form-control'
          required
          rows={6}
        ></textarea>
        <div style={{ marginTop: '20px' }}>
          <span className='messageMetaData'>
            {messageMetaData.characterCount}/{messageMetaData.totalCount}
          </span>
          <span className='messageMetaData'>Pages : {messageMetaData.pageCount}</span>
        </div>
        <br />
        <button type='submit' className='btn btn-primary'>
          Send
          {isLoading ? <ButtonLoader /> : ''}
        </button>
      </div>
    </form>
  );

  return (
    <Fragment>
      <div style={{ marginBottom: '30px' }}>{searchInput}</div>
      <hr
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          border: '0px',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        }}
      />
      <div className='container'>
        {/* Inbox Grid */}
        <div className='row'>
          <div className='col-md-4' style={{ maxHeight: '800px', overflow: 'auto' }}>
            <i className='bi bi-inbox-fill messages-icon'></i> Inbox ({inboxMessages.length})
            <div className='d-flex justify-content-start'>{inbox}</div>
          </div>

          {/* Message Grid */}
          <div className='col-md-8 messageGrid'>
            <i className='bi bi-envelope-fill messages-icon'></i> Messages ({messages.length}) -
            {messages.length > 0 ? messages[0].phoneNumber : ''}
            <div style={{ marginBottom: '5%' }} className='d-flex justify-content-start'>
              <ChatBox messageList={messages} />
            </div>
            {replyBox}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Messages;
