import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { IEditFulfilmentModal } from '../../../interface/FulfilmentsInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { TrackingStatusNotes } from '../../../utils/constants';

const AddNotesModal = ({ open, close, modalData }: IEditFulfilmentModal) => {
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [trackingNote, setTrackingNote] = useState('');
  const [trackingCode, setTrackingCode] = useState('');

  const handleChange = (event: any) => {
    setTrackingNote(event.target.value);
  };

  const updateFulfilmentNote = async (event: any) => {
    if (!isFormValidated('add-note-form')) {
      addToast('Please fill the notes field!', 'warning');
      return;
    }

    event.preventDefault();

    // create payload
    let payload = {
      fulfilmentStatus: modalData.fulfilmentStatus,
      requestId: modalData.requestId,
      partnerCode: modalData.partnerCode,
      statusNote: trackingNote,
    };

    setButtonLoading(true);

    // api call
    let url = endpoints.RequestTrackingStatus.mainUrl;
    try {
      await axios.post(url, payload);
      addToast('Tracking notes added Successfully', 'success');
    } catch (error: any) {
      addToast('An error occured', 'error');
    } finally {
      setButtonLoading(false);
      close();
    }
  };

  const getTrackingCode = useCallback(async () => {
    if (!modalData) {
      return;
    }
    const url = `${endpoints.RequestTrackingStatus.mainUrl}/link/${modalData.requestId}`;
    const axiosInstance = axios.create();
    setTrackingCode('');
    try {
      const response = await axiosInstance.get(url);
      const trackingCode = response.data;
      setTrackingCode(trackingCode);
    } catch (error) {
      console.error(error);
    }
  }, [modalData]);

  useEffect(() => {
    if (open) {
      getTrackingCode();
    }
  }, [open, modalData, getTrackingCode]);

  return (
    <Modal show={open} onHide={() => close()}>
      {modalData ? (
        <form id='add-note-form'>
          <Modal.Header>
            <Modal.Title>
              Add note for {modalData.enrolleeFirstName} {modalData.enrolleeLastName} -
              {modalData.requestSource}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {trackingCode !== '' ? (
              <div className='form-group'>
                Tracking Link:
                <br />
                <a
                  href={`https://partner.wellahealth.com/fulfilments/tracking?trackingCode=${trackingCode}`}
                  target='_blank'
                  rel='noreferrer'
                  className=''
                >{`https://partner.wellahealth.com/fulfilments/tracking?trackingCode=${trackingCode}`}</a>
              </div>
            ) : (
              ''
            )}
            <fieldset disabled={isButtonLoading}>
              <div className='form-group mt-2'>
                Current status: <label>{modalData.fulfilmentStatus}</label>
              </div>
              <div className='form-group'>
                <label>Notes</label>
                <input
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  required
                  list='tracking-list'
                />
              </div>
              <datalist id='tracking-list'>
                {TrackingStatusNotes.map((x) => (
                  <option>{x}</option>
                ))}
              </datalist>
              <strong className='fs-7'>Note: This information will be visible to the HMO</strong>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <button
              className='btn btn-primary'
              disabled={isButtonLoading}
              onClick={updateFulfilmentNote}
            >
              Submit
              {isButtonLoading ? <ButtonLoader /> : ''}
            </button>
          </Modal.Footer>
        </form>
      ) : (
        'No data selected'
      )}
    </Modal>
  );
};

export default AddNotesModal;
