// export interface IEmailTriggerDTO {
//   emailAddress: string;
//   emailContent: string;
//   emailSubject: string;
// }

// export const initialEmailTriggerDTO: IEmailTriggerDTO = {
//   emailAddress: '',
//   emailContent: '',
//   emailSubject: '',
// };

export interface IEmailTrigDTO {
  requestId: number;
  partnerCode: string;
  pharmacyCode: string;
  emailType: string;
}

export const initialEmailTriggerDTO: IEmailTrigDTO = {
  requestId: 0,
  partnerCode: '',
  pharmacyCode: '',
  emailType: 'PickUp',
};
