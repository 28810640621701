import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  IFulfilmentDetailsProp,
  IFulfilmentTestsData,
} from '../../../interface/FulfilmentsInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import { BOOL_MAPPING, FulfilmentDetailsTab, TESTRESULT } from '../../../utils/mappings';
import LoaderWithText from '../../common/LoaderWithText';

const FulfilmentTests = ({
  fulfilmentRequest,
  tabKey,
  toggleLock,
  getFulfilment,
}: IFulfilmentDetailsProp) => {
  const [isLoading, setLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [testsData, setTestsData] = useState<IFulfilmentTestsData[]>([]);

  const axiosInstance = axios.create();

  const relianceUsername = process.env.REACT_APP_RELIANCE_USERID;
  const reliancePassword = process.env.REACT_APP_RELIANCE_PASSWORD;

  const saveData = async () => {
    //validations:
    if (!isFormValidated('tests-form')) {
      addToast('Please fill all required fields', 'warning');
      return;
    }

    let testWithoutCost = testsData.filter(
      (test: IFulfilmentTestsData) => test.isCarriedOut && test.cost <= 0
    );
    if (testWithoutCost.length > 0) {
      addToast('Please add cost to tests carried out', 'warning');
      return;
    }

    setBtnLoading(true);

    //foreach test, upload file if available, save data to reliance and then to wellahealth

    for (let i = 0; i < testsData.length; i++) {
      //upload file if available
      if (testsData[i].formFile !== null) {
        const result = await uploadFiles(testsData[i].formFile);
        if (result.fileId === 0) {
          continue;
        } else {
          setTestsData(
            testsData.map((test) =>
              test.testId === testsData[i].testId
                ? {
                    ...test,
                    fileId: result.fileId,
                    fileName: result.fileUrl,
                  }
                : test
            )
          );
        }
      }
      //send to Reliance
      //const apiSuccess = await sendDataToReliance(testsData[i]);

      await saveDataToRemote(testsData[i]);

      getFulfilment();
    }
    setBtnLoading(false);
  };

  const saveDataToRemote = async (testData: IFulfilmentTestsData) => {
    try {
      const url = endpoints.FulfilmentTests.mainUrl;
      await axios.put(url, testData);
    } catch (error: any) {
      addToast('An error occured when sending request', 'error');
    }
  };

  const uploadFiles = async (formFile: any) => {
    const result = {
      fileId: 0,
      fileName: '',
      fileUrl: '',
    };

    const formData = new FormData();
    formData.append('file', formFile);
    formData.append('file_use', 'test_result');

    try {
      const response = await axiosInstance.post(endpoints.Reliance.uploadFiles, formData, {
        auth: {
          username: relianceUsername as string,
          password: reliancePassword as string,
        },
      });

      const responseData = response.data;

      if (responseData.status.includes('success')) {
        result.fileId = responseData.data.file_id;
        result.fileName = responseData.data.filename;
        result.fileUrl = responseData.data.file_url;
      } else {
        throw Error();
      }
    } catch (error: any) {
      addToast('Could not upload file', 'error');
    }

    return result;
  };

  // const sendDataToReliance = async (testData: IFulfilmentTestsData) => {
  //   let isSuccess = false;
  //   const payload = {
  //     result: testData.testResult.toLowerCase(),
  //     comment: testData.notes,
  //     test_id: testData.enrollmentTestId,
  //     file_id: testData.fileId,
  //   };
  //   try {
  //     const url = `${endpoints.Reliance.postTestResult}/${testData.requestId}/results`;

  //     const response = await axiosInstance.post(url, payload, {
  //       auth: {
  //         username: relianceUsername as string,
  //         password: reliancePassword as string,
  //       },
  //     });

  //     if (response.data.message.toLowerCase().includes('success')) {
  //       isSuccess = true;
  //     }
  //   } catch (error:any) {
  //     addToast('Failed to send test to reliance api', 'error');
  //   }
  //   return isSuccess;
  // };

  //https://stackoverflow.com/a/55988040/2929906
  //https://stackoverflow.com/a/55788298/2929906
  const handleChange = (input: string, id: number) => (event: any) => {
    let selectedData: any = null;
    if (input === 'formFile') {
      selectedData = event.target.files[0];
      //return;
    } else {
      selectedData = event.target.value;
    }

    setTestsData(
      testsData.map((test) => (test.testId === id ? { ...test, [input]: selectedData } : test))
    );
  };

  const getDetails = useCallback(async () => {
    setLoading(true);
    setTestsData([]);
    try {
      const response = await axios.get(
        endpoints.FulfilmentTests.mainUrl + '/' + fulfilmentRequest!.requestId
      );
      const data = response.data;
      if (data.length > 0) {
        //add a form file object, fileId, and filename
        //if testResult is not positive, then set as negative. reliance only accepts either
        data.forEach((test: any) => {
          test.formFile = null;
          test.fileId = 0;
          test.fileUrl = '';
          test.testResult = test.testResult === 'Positive' ? test.testResult : 'Negative';
        });
      }

      setTestsData(data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [fulfilmentRequest]);

  useEffect(() => {
    if (fulfilmentRequest && tabKey === FulfilmentDetailsTab.tests) {
      getDetails();
    }
  }, [tabKey, fulfilmentRequest, getDetails]);

  return (
    <div className='mt-5'>
      {fulfilmentRequest ? (
        <div>
          {/* Prescribed Drugs for {fulfilment!.enrolleeFirstName} {fulfilment!.enrolleeLastName} -
          {fulfilment!.requestSource} */}
          {isLoading ? (
            <LoaderWithText />
          ) : (
            <div>
              <form id='tests-form' className='row'>
                {testsData.map((test, key: any) => (
                  <div key={key} id='tests-modal' className='col-sm-6 mt-5'>
                    <div className='card' style={{ padding: '10px' }}>
                      <div className='form-group'>
                        <label>Tests {key + 1}</label>
                      </div>

                      <div className='form-group'>
                        <label htmlFor='name' className='form-label'>
                          Test Name
                        </label>
                        <input
                          value={test.testName}
                          required
                          className='form-control '
                          type='text'
                          disabled
                        />
                      </div>
                      <br />

                      <div className='form-group'>
                        <label htmlFor='isCarriedOut' className='form-label'>
                          Is Test Carried Out?
                        </label>
                        <select
                          onChange={handleChange('isCarriedOut', test.testId)}
                          value={test.isCarriedOut.toString()}
                          className='custom-select'
                        >
                          {BOOL_MAPPING.map((option) => (
                            <option key={option.key} value={option.value.toString()}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      <br />

                      <div className='form-group'>
                        <label htmlFor='testResult' className='form-label'>
                          Test Result
                        </label>
                        <select
                          onChange={handleChange('testResult', test.testId)}
                          value={test.testResult.toString()}
                          className='custom-select'
                        >
                          {TESTRESULT.map((option) => (
                            <option key={option.key} value={option.value.toString()}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      <br />

                      <div className='form-group'>
                        <label className='form-label'>Cost of Test</label>
                        <input
                          value={test.cost}
                          min={test.isCarriedOut ? 1 : 0}
                          step={0.01}
                          required={test.isCarriedOut}
                          className='form-control '
                          type='number'
                          onChange={handleChange('cost', test.testId)}
                        />
                      </div>
                      <br />

                      <div className='form-group'>
                        <label>Upload files</label>

                        <input
                          type='file'
                          accept='.jpg, .jpeg, .png, .gif, .pdf'
                          onChange={handleChange('formFile', test.testId)}
                        />

                        <label>{test.uploadStatus}</label>
                      </div>
                      <br />

                      <div className='form-group'>
                        <label htmlFor='name' className='form-label'>
                          Notes
                        </label>
                        <input
                          value={test.notes}
                          className='form-control '
                          type='text'
                          onChange={handleChange('notes', test.testId)}
                        />
                      </div>
                      <br />

                      <br />
                    </div>
                  </div>
                ))}
              </form>
              <button
                type='button'
                className='btn btn-primary mt-5 '
                disabled={isBtnLoading}
                onClick={saveData}
              >
                Save
                {isBtnLoading ? <ButtonLoader /> : ''}
              </button>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FulfilmentTests;
