import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { INotesModal } from '../../../interface/CommonInterface';
import { cancellationReason, noteTags } from '../../../utils/constants';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import { isFormValidated } from '../../../utils/formUtils';

const NotesModal = ({
  open,
  close,
  noteType,
  noteTypeId,
  defaultTags = '',
  callback,
  showReason,
}: INotesModal) => {
  const [noteData, setNoteData] = useState({
    noteContent: '',
    tags: defaultTags,
    reason: '',
  });
  const [isLoading, setLoading] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setNoteData({
      ...noteData,
      [input]: event.target.value,
    });
  };

  const saveNote = async (event: any) => {
    if (!isFormValidated('note-form')) {
      return;
    }

    event.preventDefault();

    const url = `${endpoints.UserNotes.mainUrl}`;

    const payload = {
      noteContent:
        showReason && noteData.noteContent === '' ? noteData.reason : noteData.noteContent,
      noteType: noteType,
      noteTypeId: noteTypeId,
      tags: `${noteData.tags}: ${noteData.reason}`,
    };

    setLoading(true);
    try {
      await axios.post(url, payload);
      addToast('Notes added', 'success');

      setNoteData({
        noteContent: '',
        tags: '',
        reason: '',
      });

      if (callback) {
        callback();
      }
      close();
    } catch (error: any) {
      addToast('Error occured while saving note', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && defaultTags) {
      setNoteData((f) => {
        return { ...f, tags: defaultTags };
      });
    }
  }, [defaultTags, open]);
  return (
    <Modal centered backdrop='static' show={open} onHide={() => close()}>
      <form className='form-group' id='note-form'>
        <Modal.Header closeButton={!showReason}>
          <Modal.Title>Add Note for {noteType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            {showReason ? (
              <div className='form-group mt-3'>
                <label className='form-label'>
                  Reason<span className='text-danger'>*</span>
                </label>
                <select
                  className='custom-select'
                  required
                  value={noteData.reason}
                  onChange={handleChange('reason')}
                >
                  <option value=''>--- Select a Reason ---</option>
                  {cancellationReason.map((x) => (
                    <option value={x}>{x}</option>
                  ))}
                </select>
              </div>
            ) : (
              ''
            )}
            <div className='form-group mt-3'>
              <label className='form-label'>
                Note
                <span className='text-danger'>{showReason ? '' : '*'}</span>
              </label>
              <textarea
                value={noteData.noteContent}
                onChange={handleChange('noteContent')}
                className='form-control'
                rows={5}
                required={!showReason}
                placeholder={showReason ? '(optional)' : 'Enter note here'}
              ></textarea>
            </div>
            <div className='form-group mt-3'>
              <label>Tags</label>
              <input
                type='text'
                list='tag-list'
                value={noteData.tags}
                placeholder='tags e.g Onboarding, Cancelling '
                className='form-control'
                title='To help you identify your notes'
                onChange={handleChange('tags')}
              />
              <datalist id='tag-list'>
                {noteTags.map((tag) => (
                  <option id={tag}>{tag}</option>
                ))}
              </datalist>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button type='submit' className='btn btn-primary' onClick={saveNote}>
            Save Note
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NotesModal;
