import { useCallback, useState } from 'react';
import { IFulfilmentPartners } from '../../../interface/FulfilmentPartnersInterface';
import { IPriceList } from '../../../interface/PriceListInterface';
import FulfilmentPartners from '../../common/FulfilmentPartners';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import localforage from 'localforage';
import { addToast } from '../../../utils/toastNotifications';
import { NairaSymbol } from '../../../utils/constants';

const HmoPriceCard = (drugName: { drugName: string }) => {
  const [priceList, setPriceList] = useState<IPriceList[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<IPriceList | undefined>();
  const [tariffCalc, setTariffCalc] = useState({
    quantity: 1,
    totalCost: 0,
  });

  const handleChange = (input: string) => (event: any) => {
    setTariffCalc({
      ...tariffCalc!,
      [input]: event.target.value,
    });
  };

  //load price list from storage
  //on HMO select, check and load
  const handlePartnerChange = (partner: IFulfilmentPartners) => {
    getTariff(partner.partnerCode);
    //console.log(partner);
  };

  //on hmo change, we load and check the medication

  const loadToMemory = useCallback((data: any, partnerCode: string) => {
    //save to memory
    const parsedPriceList: IPriceList[] = data;
    const partnerPriceList = parsedPriceList.filter((x) => x.source.includes(partnerCode));
    setPriceList(partnerPriceList);
  }, []);

  //checks the last update to see if tariff is update for partner
  const setLastUpdate = useCallback(async (partnerCode: string) => {
    const lastUpdateVersion = `pricelist-lastupdate-${partnerCode}`;

    try {
      const response = await axios.get(`${endpoints.Pricelist.mainUrl}/lastupdate`);
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
  }, []);

  //checks the last update to see if tariff is update for partner
  const getLastUpdate = useCallback(async (partnerCode: string) => {
    const lastUpdateVersion = `pricelist-lastupdate-${partnerCode}`;
    const savePriceListVersion = localStorage.getItem(lastUpdateVersion);
    //if no version set, get pricelist
    if (typeof savePriceListVersion === 'undefined' || savePriceListVersion === null) {
      return true;
    }

    let lastupdateValue = new Date().toISOString();
    try {
      const response = await axios.get(`${endpoints.Pricelist.mainUrl}/lastupdate`);
      lastupdateValue = response.data;
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
    const previousUpdate = new Date(savePriceListVersion!);
    const lastUpdate = new Date(lastupdateValue);

    if (lastUpdate > previousUpdate) {
      return true;
    }

    return false;
  }, []);

  //loads from remote, save to localdb and also in-memory
  const loadFromRemote = useCallback(
    async (partnerCode: string) => {
      try {
        const url = `${endpoints.Pricelist.mainUrl}/partner/${partnerCode}`;
        const response = await axios.get(url);

        //save price list response to indexDB
        localforage
          .setItem(`pricelist-${partnerCode}`, response.data)
          .then(async () => {
            //addToast('Price list saved successfully', 'success');
            await setLastUpdate(partnerCode);
          })
          .catch((err) => {
            console.log(err);
            //addToast('Price list not saved', 'error');
          });

        //save to memory
        loadToMemory(response.data, partnerCode);
      } catch (error: any) {
        console.error(error);
        addToast('There was error getting pricelist', 'error');
      }
    },
    [setLastUpdate, loadToMemory]
  );

  const getTariff = useCallback(
    (partnerCode: string) => {
      const priceListName = `pricelist-${partnerCode}`;
      localforage
        .getItem(priceListName)
        .then(async (value) => {
          if (value === null || typeof value === 'undefined') {
            await loadFromRemote(partnerCode);
          } else {
            //check if new version available
            const isNewVersionAvailable = await getLastUpdate(partnerCode);

            if (isNewVersionAvailable) {
              await loadFromRemote(partnerCode);
            } else {
              loadToMemory(value, partnerCode);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [loadFromRemote, loadToMemory, getLastUpdate]
  );

  const checkTariffPrice = () => {
    const selectedPrice = priceList.filter(
      (x) => x.drugName.trim().toLowerCase() === drugName.drugName.trim().toLowerCase()
    )[0];

    if (selectedPrice) {
      setSelectedPrice(selectedPrice);
    }
  };

  // const logUsage = () => {};

  return (
    <div className='card px-5 py-4'>
      <div>
        <label>Select HMO</label>
        <FulfilmentPartners callback={handlePartnerChange} />
        <div className='mt-2'>
          <button className='btn btn-primary' onClick={checkTariffPrice}>
            Check Tariff Price
          </button>
        </div>
        {selectedPrice ? (
          <div>
            <h6 className='mt-3'>
              Unit price: {NairaSymbol}
              {selectedPrice.unitPrice.toLocaleString()}
            </h6>
            <div className='mt-3'>
              <label>Quantity to dispense(tabs, capsule e.t.c)</label>
              <input
                type='number'
                className='form-control col-sm-6'
                min={1}
                onChange={handleChange('quantity')}
                value={tariffCalc.quantity}
              />
            </div>
            <div className='mt-3'>
              <label>Total amount:</label> {NairaSymbol}
              <label>{(tariffCalc.quantity * selectedPrice.unitPrice).toLocaleString()}</label>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default HmoPriceCard;
