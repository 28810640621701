import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { IBeneficiary } from '../../../interface/BeneficiaryInterface';
import { addToast } from '../../../utils/toastNotifications';
import AddEditBeneficiaryModal from '../beneficiary/AddEditBeneficiaryModal';
import { formatDate } from '../../../utils/dateTimeUtils';
// import { Link } from 'react-router-dom';
import { ISubscriptionDetailsProp } from '../../../interface/SubscriptionInterface';
import { SubscriptionDetailsTab } from '../../../utils/mappings';
import TableLoader from '../../common/TableLoading';
import { AddClaimModal } from './AddClaimModal';

const SubscriptionBeneficiaries = ({ subscription, tabKey }: ISubscriptionDetailsProp) => {
  const initialState: IBeneficiary = {
    dateCreated: '',
    dateOfBirth: '',
    dateUpdated: '',
    firstName: '',
    lastName: '',
    location: '',
    lga: '',
    stateOfResidence: '',
    streetName: '',
    area: '',
    insuranceBeneficiaryId: 0,
    phone: '',
    email: '',
    gender: 'Female',
    isActive: true,
    subscriptionCode: '',
  };

  const [tableData, setTableData] = useState<IBeneficiary[] | undefined>();
  const [beneficiaryModalData, setBeneficiaryModalData] = useState(initialState);
  const [isBeneficiaryModalOpen, setBeneficiaryModalOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const [beneficiary, setBeneficiary] = useState(initialState);

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setBeneficiaryModalData({
      ...beneficiaryModalData,
      [input]: event.target.value,
    });
  };

  const openBeneficiaryModal = (data: IBeneficiary, edit: boolean) => {
    if (!isEdit) {
      data.subscriptionCode = subscription!.subscriptionCode;
    }
    setEdit(edit);

    setBeneficiaryModalData(data);
    setBeneficiaryModalOpen(true);
  };

  const closeBeneficiaryModal = () => {
    setBeneficiaryModalOpen(false);
  };

  const getBeneficiaryList = useCallback(async () => {
    setTableData(undefined);
    try {
      const response = await axios.get(
        endpoints.InsuranceBeneficiary.mainUrl + subscription?.subscriptionCode
      );
      setTableData(response.data);
    } catch (error: any) {
      addToast('Error getting beneficiary details', 'error');
    }
  }, [subscription]);

  useEffect(() => {
    if (subscription && tabKey === SubscriptionDetailsTab.beneficiaries) {
      getBeneficiaryList();
    }
  }, [getBeneficiaryList, tabKey, subscription]);

  let beneficiaryModal: JSX.Element = (
    <AddEditBeneficiaryModal
      open={isBeneficiaryModalOpen}
      close={closeBeneficiaryModal}
      modalData={beneficiaryModalData}
      getBeneficiaryData={getBeneficiaryList}
      handleChange={handleChange}
      isEdit={isEdit}
    />
  );

  let claimModal = (
    <AddClaimModal
      open={claimModalOpen}
      close={() => setClaimModalOpen(false)}
      beneficiary={beneficiary}
    />
  );

  let tableHead = (
    <thead>
      <tr>
        <th scope='col'>Action</th>
        <th scope='col'>Subscriber ID</th>

        <th scope='col'>First Name</th>

        <th scope='col'>Last Name</th>

        <th scope='col'>Phone</th>
        <th scope='col'>Email</th>

        <th scope='col'>State of Premise</th>

        <th scope='col'>Location</th>

        <th scope='col'>Date of Birth</th>
      </tr>
    </thead>
  );

  let tableBody = tableData?.map((beneficiary) => {
    return (
      <tr key={beneficiary.insuranceBeneficiaryId}>
        <td>
          {/* <Link
            to={`/subscriptions/${subscription?.subscriptionCode}/${beneficiary.insuranceBeneficiaryId}/claims/add`}
          > */}
          <i
            className='bi bi-plus-square-fill'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setBeneficiary(beneficiary);
              setClaimModalOpen(true);
            }}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Add Claim'
          />
          {/* </Link> */}
          <i
            className='bi bi-pencil-fill ml-2'
            style={{ cursor: 'pointer' }}
            onClick={() => openBeneficiaryModal(beneficiary, true)}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Edit Beneficiary'
          />
        </td>
        <td>{beneficiary.insuranceBeneficiaryId}</td>
        <td>{beneficiary.firstName}</td>
        <td>{beneficiary.lastName}</td>
        <td>{beneficiary.phone}</td>
        <td>{beneficiary.email}</td>
        <td>{beneficiary.stateOfResidence}</td>
        <td>{beneficiary.location}</td>
        <td>{formatDate(beneficiary.dateOfBirth)}</td>
      </tr>
    );
  });

  return (
    <div className='container-fluid mt-5'>
      {claimModal}
      <div className='d-flex'>
        <button
          className='btn btn-primary'
          onClick={() => openBeneficiaryModal(initialState, false)}
        >
          Add Beneficiary
        </button>
      </div>
      <br />
      <div className='table-responsive'>
        {tableData ? (
          <table className='table table-bordered table-hover'>
            {tableHead}
            <tbody>{tableBody}</tbody>

            {beneficiaryModal}
          </table>
        ) : (
          <TableLoader />
        )}
      </div>
    </div>
  );
};

export default SubscriptionBeneficiaries;
