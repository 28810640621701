import React, { useState, useCallback, useEffect } from 'react';
import { IHealthSendUser } from '../../../interface/HealthSendInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import TableLoader from '../../common/TableLoading';
import { addToast } from '../../../utils/toastNotifications';
import { useHistory } from 'react-router-dom';

const HealthSendUsersTable = () => {
  const [users, setUsers] = useState<IHealthSendUser[]>([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const getUsers = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get(endpoints.HealthSend.users);
      setUsers(response.data);
    } catch (error: any) {
      addToast('Error getting healthsend users', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>First Name</th>
        <th scope='col'>Last Name</th>
        <th scope='col'>Email</th>
        <th scope='col'>Phone</th>
        <th scope='col'>Address</th>
        <th scope='col'>Gender</th>
        <th scope='col'>D.O.B</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {users.map((user) => (
        <tr key={user.email}>
          <td>
            <i
              className='bi bi-info-circle-fill m-2'
              onClick={() => {
                sessionStorage.setItem('healthSendUser', JSON.stringify(user));
                history.push(`/healthsend/user/${user.userId}/details`);
              }}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='View Details'
            />
            <i
              className='bi bi-headphones m-2'
              onClick={() => history.push(`/healthsend/requests/${user.userId}`)}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='View Requests'
            />
          </td>
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>{user.email}</td>
          <td>
            ({user.countryCode}) {user.phoneNumber}
          </td>
          <td>{user.address}</td>
          <td>{user.gender}</td>
          <td>{user.dateOfBirth}</td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div>
      <div className='d-flex'>
        <h3 className='mr-auto'>Users ({users.length})</h3>
      </div>
      <br />
      {loading && (
        <div className='d-flex justify-content-center'>
          <div className='mt-5'>
            <TableLoader />
          </div>
        </div>
      )}

      {!loading && (
        <div className='table-responsive'>
          <table className='table table-bordered table-hover'>
            {tableHead}
            {tableBody}
          </table>
        </div>
      )}
    </div>
  );
};

export { HealthSendUsersTable };
