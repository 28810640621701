import { Fragment, useCallback, useEffect, useState } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import axios from 'axios';
import { isFormValidated } from '../../../utils/formUtils';
import {
  initialFulfilmentFormData,
  IDrugsInfo,
  IFulfilmentDrugsData,
  IFulfilmentDraftSection,
  IFulfilmentData,
} from '../../../interface/FulfilmentsInterface';
import { IPharmacyListData } from '../../../interface/PharmacyInterface';
import Select from 'react-select';
import {
  FulfilmentServiceMap,
  FulfilmentStatusMap,
  PharmacyStatusMap,
} from '../../../utils/mappings';
import AddDrugsModal from '../add_fulfilment/AddDrugsModal';
import { IFulfilmentPartners } from '../../../interface/FulfilmentPartnersInterface';
import { NairaSymbol } from '../../../utils/constants';
import { decimalParser } from '../../../utils/numberUtils';
import { ReactSelectOption } from '../../../interface/CommonInterface';

const AddFulfilmentFormSection = (props: IFulfilmentDraftSection) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fulfilmentFormData, setFulfilmentFormData] =
    useState<IFulfilmentData>(initialFulfilmentFormData);

  const [pharmacyListSelect, setPharmacyListSelect] = useState<ReactSelectOption[]>([]);
  const [defaultPharmacy, setDefaultPharmacy] = useState({
    label: '',
    value: '',
  });
  const [partnersList, setPartnersList] = useState<IFulfilmentPartners[]>([]);
  const [partnersListSelect, setPartnersListSelect] = useState<ReactSelectOption[]>([]);
  const [defaultPartner, setDefaultPartner] = useState({
    label: '',
    value: '',
  });

  const [newPharmacyCode, setNewPharmacyCode] = useState(initialFulfilmentFormData.pharmacyCode);
  const [newPartnerCode, setNewPartnerCode] = useState(initialFulfilmentFormData.partnerCode);

  const [drugs, setDrugs] = useState<IDrugsInfo[]>([]);
  const [selectedDrug, setSelectedDrug] = useState<IDrugsInfo | undefined>();

  const getPharmacyList = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/list`;

    try {
      const response = await axios.get(url);

      let selectData: ReactSelectOption[] = response.data
        .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
        .map((val: IPharmacyListData) => {
          return {
            label: val.pharmacyName,
            value: val.pharmacyCode,
          };
        });
      //set to state
      setPharmacyListSelect(selectData);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('pharmacyList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const getPartnersList = async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;

    try {
      const response = await axios.get(url);

      let selectData = response.data.map((val: IFulfilmentPartners) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      //set to state
      setPartnersListSelect(selectData);
      setPartnersList(response.data);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const selectChange = (selectedValue: any) => {
    setNewPharmacyCode(selectedValue.value);
    setFulfilmentFormData({
      ...fulfilmentFormData,
      pharmacyCode: selectedValue.value,
    });
    //so that it shows on the select element since we are setting the 'value' props manually
    setDefaultPharmacy({
      ...defaultPharmacy,
      label: selectedValue.label,
      value: selectedValue.value,
    });
  };

  const selectPartnerCodeChange = (selectedValue: any) => {
    setNewPartnerCode(selectedValue.value);
    //if partnercode is changed, get partner config and apply to processing fee

    // const partnerConfig = FulfilmentConfigurations.filter(
    //   (x) => x.partnerCode === selectedValue.value
    // )[0];
    const selectedPartner = partnersList.filter((x) => x.partnerCode === selectedValue.value)[0];
    setFulfilmentFormData({
      ...fulfilmentFormData,
      serviceCharge: selectedPartner.serviceCharge,
    });

    setDefaultPartner({
      ...defaultPharmacy,
      label: selectedValue.label,
      value: selectedValue.value,
    });
  };

  const submit = async (e: React.FormEvent) => {
    if (!isFormValidated('fulfilment-form')) {
      addToast('Please fill all fields');
      return;
    }
    e.preventDefault();

    if (drugs.length < 1) {
      addToast('Please add atleast one drug', 'warning');
      return;
    }
    if (newPartnerCode === '') {
      addToast('Please select a HMO', 'warning');
      setLoading(false);
      return;
    }
    if (newPharmacyCode === '') {
      addToast('Please select a pharmacy', 'warning');
      setLoading(false);
      return;
    }

    //check if all drug info completed
    const drugStatus = drugs
      .map((drug) => {
        return isDrugInfoComplete(drug);
      })
      .filter((x) => x === false);

    if (drugStatus.length > 0) {
      addToast('Please complete all drug information', 'warning');
      return;
    }

    setLoading(true);
    const payload = fulfilmentFormData;
    payload.pharmacyCode = newPharmacyCode;
    payload.partnerCode = newPartnerCode;
    payload.drugs = drugs;

    //payload.rating = rating;

    if (payload.drugs.length < 1) {
      addToast('Add an entry for a drug', 'warning');
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(endpoints.FulfilmentRequests.mainUrl, payload);
      if (response.status === 200) {
        addToast('Fulfilment data saved successfully', 'success');
      }
      clearForm();
      if (props.callback) {
        props.callback(props.draft?.id);
      }
      clearForm();
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setFulfilmentFormData({
      ...fulfilmentFormData,
      [input]: event.target.value,
    });
  };

  const clearForm = () => {
    setLoading(false);
    drugs.length = 0;
    setFulfilmentFormData({
      enrolleeFirstName: '',
      enrolleeLastName: '',
      enrollmentCode: '',
      enrolleePhone: '',
      enrolleeEmail: '',
      enrolleeAddress: '',
      pharmacyCode: '',
      partnerCode: '',
      fulfilmentService: 'Telemedicine',
      fulfilmentStatus: 'Pending',
      isPharmacyPaid: false,
      pickUpDate: new Date().toISOString().split('T')[0],
      serviceCharge: 200,
      notes: '',
      tests: [],
      drugs: [],
      useSortingCenter: false,
      diagnosis: '',
      preAuthorizationCode: '',
    });
  };

  const getMedications = async (draftId: number) => {
    const url = `${endpoints.FulfilmentDrafts.mainUrl}/${draftId}/medications`;

    try {
      const response = await axios.get(url);
      const data = response.data as IFulfilmentDrugsData[];
      let id = 1;
      const draftDrugs = data.map((x) => {
        const localDrug: IDrugsInfo = {
          localId: id++,
          name: x.name,
          dose: x.dose,
          tariffCost: x.tariffCost,
          tariffDrugName: x.tariffDrugName,
          newTariffDrugName: x.tariffDrugName,
          duration: x.duration,
          useSortingCenter: false,
          notes: '',
          strength: x.strength,
          frequency: x.frequency,
          unitCost: x.cost,
          quantity: x.quantity,
          isDispensed: false,
          cost: x.cost,
          dosageForm: x.dose,
        };
        return localDrug;
      });
      setDrugs(draftDrugs);
    } catch (error) {
      addToast('Error getting the medications', 'error');
    }
  };

  //all drugs methods
  const handleDrugsOpen = () => {
    setSelectedDrug(undefined);
    setOpen(true);
  };

  const editDrug = (drug: IDrugsInfo) => (event: any) => {
    setSelectedDrug(drug);
    setOpen(true);
  };

  const handleDrugsClose = () => {
    setOpen(false);
  };

  const addDrug = (drugData: IDrugsInfo) => {
    //if medicationId > 0 then we are editing
    if (drugData.localId > 0) {
      //delete existing medication with the id
      const savedDrugs = drugs.filter((x) => x.localId !== drugData.localId);

      //add updated medication
      savedDrugs.push(drugData);
      setDrugs(savedDrugs);
    } else {
      //else we are adding
      let medicationId = drugs.length + 1;

      const lastMedication = drugs[drugs.length - 1];

      if (lastMedication) {
        medicationId = lastMedication.localId + 1;
      }

      drugData.localId = medicationId;
      drugs.push(drugData);
      setDrugs(drugs);
    }

    let fulfilmentStatus = fulfilmentFormData.fulfilmentStatus;
    if (fulfilmentStatus === 'Pending') {
      let dispensedDrugs: IDrugsInfo[] = drugs.filter((x) => x.isDispensed);
      if (dispensedDrugs !== null && dispensedDrugs.length > 0) {
        fulfilmentStatus = 'Processing';
      }
      setFulfilmentFormData({
        ...fulfilmentFormData,
        fulfilmentStatus: fulfilmentStatus,
      });
    }

    addToast('Drugs added successfully', 'success');
  };

  const deleteDrug = (drugId: number) => {
    const existingMedication = drugs.filter((x) => x.localId !== drugId);
    drugs.length = existingMedication.length;
    //add updated medication
    setDrugs(existingMedication);
    addToast('Medication deleted', 'success');
  };

  const isDrugInfoComplete = (drug: IDrugsInfo) => {
    if (drug.name === '' || drug.dose === '') {
      return false;
    }

    if (drug.isDispensed) {
      return drug.cost > 0 && drug.tariffCost > 0;
    }

    return true;
  };

  //drug methods

  useEffect(() => {
    const savedPharmacyList = sessionStorage.getItem('pharmacyList');

    if (savedPharmacyList !== null) {
      const pharmacyList = JSON.parse(savedPharmacyList);
      let selectData: ReactSelectOption[] = pharmacyList
        .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
        .map((val: { pharmacyName: string; pharmacyCode: string }) => {
          return {
            label: val.pharmacyName,
            value: val.pharmacyCode,
          };
        });
      //set to state
      setPharmacyListSelect(selectData);
    } else {
      //call api
      getPharmacyList();
    }
  }, []);

  useEffect(() => {
    const savedPartnersList = sessionStorage.getItem('partnersList');

    if (savedPartnersList !== null && typeof savedPartnersList !== 'undefined') {
      const partnersList = JSON.parse(savedPartnersList);
      let selectData = partnersList.map((val: { partnerName: string; partnerCode: string }) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      //set to state
      setPartnersListSelect(selectData);
      setPartnersList(partnersList);
    } else {
      //call api
      getPartnersList();
    }
  }, []);

  const checkSortingCenter = useCallback(() => {
    let useSortingCenter = false;
    let pharmacyCode = fulfilmentFormData.pharmacyCode;
    if (
      fulfilmentFormData.enrolleeAddress.toLowerCase().includes('lagos') &&
      fulfilmentFormData.fulfilmentService === 'Chronic'
    ) {
      const sortingCenterDrugs = fulfilmentFormData.drugs.filter(
        (x) => x.useSortingCenter === true
      );
      if (sortingCenterDrugs.length > 0) {
        useSortingCenter = true;
        pharmacyCode = 'WHP11988';
      }
    }
    //https://reactjs.org/docs/hooks-reference.html#functional-updates
    setFulfilmentFormData((f) => {
      return { ...f, useSortingCenter: useSortingCenter, pharmacyCode: pharmacyCode };
    });
  }, [
    fulfilmentFormData.drugs,
    fulfilmentFormData.enrolleeAddress,
    fulfilmentFormData.fulfilmentService,
    fulfilmentFormData.pharmacyCode,
  ]);

  useEffect(() => {
    checkSortingCenter();
  }, [checkSortingCenter]);

  //map draft to local state
  useEffect(() => {
    if (props.draft !== null && typeof props.draft !== 'undefined') {
      const draft = props.draft!;

      //map fulfilment patient info
      setFulfilmentFormData((f) => {
        return {
          ...f,
          enrolleeFirstName: draft.patientInfo?.firstName!,
          enrolleeLastName: draft.patientInfo?.lastName!,
          enrolleePhone: draft.patientInfo?.phoneNumber!,
          enrolleeAddress: draft.patientInfo?.address!,
          enrollmentCode: draft.patientInfo?.hmoId!,
          enrolleeEmail: draft.patientInfo?.email!,

          preAuthorizationCode: draft.preAuthorizationCode!,
          fulfilmentService: draft.patientInfo?.fulfilmentService!,
          fulfilmentStatus: 'Pending',
          pharmacyCode: draft.patientInfo?.pharmacyCode!,
          partnerCode: draft.partnerCode!,
          notes: draft.notes!,
          diagnosis: draft.diagnosis!,
        };
      });

      let primaryPharmacy: ReactSelectOption = {
        label: draft.patientInfo!.pharmacyName,
        value: draft.patientInfo!.pharmacyCode,
      };
      setDefaultPharmacy(primaryPharmacy);
      setNewPharmacyCode(draft.patientInfo?.pharmacyCode!);

      let draftPartner: ReactSelectOption = {
        label: draft.partnerName,
        value: draft.partnerCode,
      };
      setDefaultPartner(draftPartner);
      setNewPartnerCode(draft.partnerCode);

      let id = 1;
      //drug info
      if (draft.medicationInfo && draft.medicationInfo.length > 0) {
        const draftDrugs = draft.medicationInfo.map((x) => {
          const localDrug: IDrugsInfo = {
            localId: id++,
            name: x.name,
            dose: x.dose,
            tariffCost: x.tariffCost,
            tariffDrugName: x.tariffDrugName,
            newTariffDrugName: x.tariffDrugName,
            duration: x.duration,
            useSortingCenter: false,
            notes: '',
            strength: x.strength,
            frequency: x.frequency,
            unitCost: x.cost,
            quantity: x.quantity,
            isDispensed: false,
            cost: x.cost,
            dosageForm: x.dose,
          };
          return localDrug;
        });
        setDrugs(draftDrugs);
        return;
      }
      getMedications(draft.id);

      //setNewPharmacyCode(fulfilmentPatient.pharmacyCode);
    }
  }, [props.draft]);

  let drugsModal = (
    <AddDrugsModal
      open={open}
      close={handleDrugsClose}
      partnerCode={newPartnerCode}
      pharmacyCode={fulfilmentFormData.pharmacyCode}
      addToFulfilmentPlan={addDrug}
      //pharmacyCodesData={pharmacyListSelect}
      fulfilmentService={fulfilmentFormData.fulfilmentService}
      initialDrug={selectedDrug}
    />
  );

  return (
    <Fragment>
      <div className='container'>
        {/* <div className='row'>
          <i className='bi bi-arrow-left'></i> Back
        </div> */}

        <h4 className='text-center'>Add Fulfilment Data From Draft</h4>
        <br />
        {fulfilmentFormData.useSortingCenter ? (
          <div className='text-justify text-danger'>
            This request matches sorting center medications and will be forwarded to the Sorting
            Center. Please inform HMO and Enrollee that this will be done via delivery
          </div>
        ) : (
          ''
        )}
        <br />
        <form id='fulfilment-form' className='mb-3'>
          <fieldset disabled={isLoading}>
            <div className='row'>
              <div className='col-md-3'>
                <label className='form-label'>First Name</label>
                <input
                  value={fulfilmentFormData.enrolleeFirstName}
                  required
                  className='form-control'
                  type='text'
                  onChange={handleChange('enrolleeFirstName')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>Last Name</label>
                <input
                  value={fulfilmentFormData.enrolleeLastName}
                  required
                  className='form-control '
                  type='text'
                  onChange={handleChange('enrolleeLastName')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>Enrollee Code</label>
                <input
                  value={fulfilmentFormData.enrollmentCode}
                  required
                  className='form-control '
                  type='text'
                  onChange={handleChange('enrollmentCode')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>Phone Number</label>
                <input
                  value={fulfilmentFormData.enrolleePhone}
                  maxLength={13}
                  minLength={11}
                  pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                  required
                  className='form-control '
                  type='tel'
                  onChange={handleChange('enrolleePhone')}
                />
              </div>

              <div className='col-md-3 form-group'>
                <label className='form-label'> Email</label>
                <input
                  value={fulfilmentFormData.enrolleeEmail}
                  className='form-control '
                  type='email'
                  placeholder='name@example.com'
                  onChange={handleChange('enrolleeEmail')}
                />
              </div>
              <div className='col-md-6 form-group'>
                <label className='form-label'> Address</label>
                <input
                  value={fulfilmentFormData.enrolleeAddress}
                  required
                  multiple
                  className='form-control '
                  type='text'
                  onChange={handleChange('enrolleeAddress')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>Pharmacy</label>
                <Select
                  options={pharmacyListSelect}
                  onChange={selectChange}
                  className='react-select'
                  classNamePrefix='react-select'
                  value={{ label: defaultPharmacy.label, value: defaultPharmacy.value }}
                />
              </div>

              <div className='col-md-3 form-group'>
                <label className='form-label'>Partner</label>
                <Select
                  options={partnersListSelect}
                  onChange={selectPartnerCodeChange}
                  className='react-select'
                  classNamePrefix='react-select'
                  value={{ label: defaultPartner.label, value: defaultPartner.value }}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label htmlFor='FulfilmentService' className='form-label'>
                  Service
                </label>
                <select
                  required
                  onChange={handleChange('fulfilmentService')}
                  value={fulfilmentFormData.fulfilmentService}
                  className='custom-select '
                >
                  {/* <option value=""></option> */}
                  {FulfilmentServiceMap.map((option) => (
                    <option key={option.key} value={option.value.toString()}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3 form-group'>
                <label htmlFor='FulfilmentStatus' className='form-label'>
                  Status
                </label>
                <select
                  required
                  onChange={handleChange('fulfilmentStatus')}
                  value={fulfilmentFormData.fulfilmentStatus}
                  className='custom-select'
                >
                  {/* <option value=""></option> */}

                  {FulfilmentStatusMap.map((option) => (
                    <option key={option.key} value={option.value.toString()}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-md-3'>
                <label className='form-label'>Diagnosis</label>
                <input
                  value={fulfilmentFormData.diagnosis}
                  required
                  className='form-control'
                  type='text'
                  onChange={handleChange('diagnosis')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>PA Code</label>
                <input
                  value={fulfilmentFormData.preAuthorizationCode}
                  className='form-control '
                  type='text'
                  onChange={handleChange('preAuthorizationCode')}
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Notes</label>
                <input
                  value={fulfilmentFormData.notes}
                  multiple
                  className='form-control '
                  type='text'
                  onChange={handleChange('notes')}
                />
              </div>
            </div>
            {drugs.length > 0 ? (
              <div className='row mt-3'>
                <div className='col-12'>Medications </div>
                {drugs.map((medication) => (
                  <div className='col-lg-6 mt-2' key={medication.name}>
                    <div
                      className={
                        isDrugInfoComplete(medication)
                          ? 'card prescription-card'
                          : 'card prescription-card border-danger'
                      }
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <h6 className='card-title col-8 col-sm-8 font-weight-bold'>
                            {medication.name}
                          </h6>
                          <div className='col-4 col-sm-4' style={{ marginTop: '-10px' }}>
                            <div className='row'>
                              <div className='col-6'>
                                <span className='btn' onClick={editDrug(medication)} title='Edit'>
                                  <span className='bi bi-pencil text-success'></span>
                                </span>
                              </div>
                              <div className='col-6'>
                                <span
                                  className='btn'
                                  onClick={() => deleteDrug(medication.localId)}
                                  title='Delete'
                                >
                                  <span className='bi bi-trash text-danger'></span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 card-text prescription-card-text'>
                            {medication.dose}
                          </div>
                        </div>

                        <div className='row justify-content-center card-text prescription-card-text'>
                          <div className='col-lg-6 mt-1'>
                            <span>Cost</span>:
                            <span className='ml-1 font-weight-bold'>
                              {NairaSymbol} {decimalParser(medication.cost, 2).toLocaleString()}
                            </span>
                          </div>
                          <div className='col-lg-6 mt-1'>
                            <span>Tariff</span>:
                            <span className='ml-1 font-weight-bold'>
                              {NairaSymbol}
                              {medication.tariffCost.toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}

            <div className='row mt-3'>
              <div className='col-sm-6 col-md-3'>
                <div>
                  <button className='btn btn-info' type='button' onClick={handleDrugsOpen}>
                    Add drug ({drugs.length})
                  </button>
                </div>
                {drugsModal}
              </div>

              <div className='col-sm-6 col-md-3'>
                <button className='btn btn-block  btn-primary' type='submit' onClick={submit}>
                  {isLoading ? <ButtonLoader /> : 'Submit'}
                </button>
              </div>
              {/* <div className='col-sm-6 col-md-3'>
                <button className='btn btn-block btn-warning' type='reset' onClick={clearForm}>
                  CLEAR
                </button>
              </div> */}
            </div>
          </fieldset>
        </form>
      </div>
    </Fragment>
  );
};

export default AddFulfilmentFormSection;
