import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { endpoints } from '../../../utils/URL';
import { Modal } from 'react-bootstrap';
import {
  ItemAvailabilityModalDTO,
  ItemAvailabilityDTO,
} from '../../../interface/PriceListInterface';
import LoaderWithText from '../../common/LoaderWithText';
import { NairaSymbol } from '../../../utils/constants';

const ItemAvailabilityModal = ({ item, open, close }: ItemAvailabilityModalDTO) => {
  const [itemAvailabilityData, setItemAvailabilityData] = useState<
    ItemAvailabilityDTO[] | undefined
  >();

  const getItemAvailability = useCallback(async () => {
    setItemAvailabilityData(undefined);

    let url = `${endpoints.PharmacyPricelist.mainUrl}/${item!.pharmacyCode}/${
      item!.referenceId
    }/availability`;

    try {
      const response = await axios.get(url);
      setItemAvailabilityData(response.data);
    } catch (error: any) {
      console.error(error);
    }
  }, [item]);

  useEffect(() => {
    if (open) {
      getItemAvailability();
    }
  }, [open, getItemAvailability]);
  //use effect to load all pharmacies if not already in session

  return (
    <Modal show={open} onHide={() => close()}>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          <div>{item?.drugName}</div>
          <div>
            {NairaSymbol}
            {item?.drugCost.toLocaleString()}
          </div>
        </Modal.Title>
      </Modal.Header>

      {itemAvailabilityData != null ? (
        <Modal.Body className='modal-body'>
          <div className='table-responsive'>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Quantity available</th>
                </tr>
              </thead>
              <tbody>
                {itemAvailabilityData.map((item) => (
                  <tr key={item.quantity}>
                    <td>{item.warehouse}</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      ) : (
        <LoaderWithText />
      )}
    </Modal>
  );
};

export default ItemAvailabilityModal;
