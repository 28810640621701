export interface INextOfKinInformation {
  nextOfKinId: number;
  subscriptionCode: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  relationship: string;
  dateCreated?: string;
  dateUpdated?: string;
}

export const initialNextOfKin: INextOfKinInformation = {
  nextOfKinId: 0,
  subscriptionCode: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  relationship: '',
};
