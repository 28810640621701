import axios from 'axios';
import { useState } from 'react';
import { ISupportRequestsModalProps } from '../../../interface/RequestsInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { Modal } from 'react-bootstrap';
import { SupportRequestStatus } from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';

const SupportRequestsModal = (supportRequestModalProps: ISupportRequestsModalProps) => {
  const [isLoading, setLoading] = useState(false);

  const updateSupportRequest = async (event: any) => {
    if (!isFormValidated('support-request-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }
    event.preventDefault();
    let url = `${endpoints.SupportRequests.mainUrl}/${supportRequestModalProps.modalData.requestId}`;
    let message = 'Request Successfully Updated';
    let errorMessage = 'Error Updating  Request';
    setLoading(true);

    try {
      await axios.put(url, supportRequestModalProps.modalData);
      addToast(message, 'success');
      await supportRequestModalProps.getRequests();
      supportRequestModalProps.close();
      //   }
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={supportRequestModalProps.open} onHide={supportRequestModalProps.close}>
      <form id='support-request-modal'>
        <Modal.Header>
          <Modal.Title>Edit Request</Modal.Title>
          {/* <i
          className='bi bi-pencil-square ml-5'
          onClick={editDebitRequest}
          style={{ cursor: 'pointer', fontSize: '1.4rem' }}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Edit'
        >
          Edit
        </i> */}
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label>Request Status</label>
              <select
                className='custom-select'
                value={supportRequestModalProps.modalData.requestStatus}
                onChange={supportRequestModalProps.handleChange('requestStatus')}
              >
                {SupportRequestStatus.map((states) => (
                  <option key={states.key} value={states.value.toString()}>
                    {states.text}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label>Notes</label>
              <textarea
                value={supportRequestModalProps.modalData.notes}
                onChange={supportRequestModalProps.handleChange('notes')}
                className='form-control'
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={updateSupportRequest}>
            Update
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SupportRequestsModal;
