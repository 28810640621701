import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { endpoints } from '../../utils/URL';
import { addToast } from '../../utils/toastNotifications';
import {
  IPriceList,
  ITariffSearchResult,
  ITariffSection,
} from '../../interface/PriceListInterface';
import localforage from 'localforage';
import { SortingCenterPharmacy } from '../../utils/constants';
import TariffSuggestion from './TariffSuggestion';

/**
 * Make sure to wrap this component in a form tag
 * @returns
 */

const TariffSection = ({
  fulfilmentService,
  partnerCode,
  pharmacyCode,
  isActive,
  drugId,
  callback,
  required,
}: ITariffSection) => {
  const [data, setData] = useState<ITariffSearchResult>({
    searchTerm: '',
    tariffCost: 0,
    notes: '',
    quantity: 0,
    unitCost: 0,
    priceListId: 0,
    tariffDrugName: '',
    newTariffDrugName: '',
    strength: '',
    dosageForm: '',
  });

  const [priceList, setPriceList] = useState<IPriceList[]>([]);

  const handleChange = (input: string) => (event: any) => {
    setData({
      ...data,
      [input]: parseInt(event.target.value),
    });
  };

  const handleSuggestion = (pricelistId: number, selectedPrice?: IPriceList) => {
    if (!selectedPrice) {
      return;
    }

    setData((f) => {
      return {
        ...f,
        priceListId: selectedPrice.priceListId,
        tariffDrugName: selectedPrice.drugName,
        newTariffDrugName: selectedPrice.newDrugName,
        unitCost: selectedPrice.unitPrice,
        notes: selectedPrice.notes,
        quantity: data.quantity === 0 ? 1 : data.quantity,
        strength: selectedPrice.strength,
        dosageForm: selectedPrice.dosageForm,
      };
    });
  };

  /* API Call to load Tariff */

  //checks the last update to see if tariff is update for partner
  const setLastUpdate = useCallback(async (priceSource: string) => {
    const lastUpdateVersion = `pricelist-lastupdate-${priceSource}`;

    try {
      const response = await axios.get(`${endpoints.Pricelist.mainUrl}/lastupdate`);
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
  }, []);

  //checks the last update to see if tariff is update for partner
  const getLastUpdate = useCallback(async (priceSource: string) => {
    const lastUpdateVersion = `pricelist-lastupdate-${priceSource}`;
    const savePriceListVersion = localStorage.getItem(lastUpdateVersion);
    //if no version set, get pricelist
    if (typeof savePriceListVersion === 'undefined') {
      return true;
    } else if (savePriceListVersion === null) {
      return true;
    }

    let lastupdateValue = new Date().toISOString();
    try {
      const response = await axios.get(`${endpoints.Pricelist.mainUrl}/lastupdate`);
      lastupdateValue = response.data;
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
    const previousUpdate = new Date(savePriceListVersion!);
    const lastUpdate = new Date(lastupdateValue);

    if (lastUpdate > previousUpdate) {
      return true;
    }

    return false;
  }, []);

  const loadToMemory = useCallback(
    (data: any, priceSource: string) => {
      //save to memory
      let pricelist: IPriceList[] = [];

      const parsedPriceList: IPriceList[] = data;

      const partnerPriceList = parsedPriceList.filter((x) => x.source.includes(priceSource));

      if (fulfilmentService !== '') {
        let fulfilmentServicePrices = partnerPriceList.filter((x) =>
          x.services.includes(fulfilmentService)
        );

        pricelist = fulfilmentServicePrices.length > 0 ? fulfilmentServicePrices : partnerPriceList;
      }

      setPriceList(pricelist);
    },
    [fulfilmentService]
  );

  //loads from remote, save to localdb and also in-memory
  const loadFromRemote = useCallback(
    async (priceSource: string) => {
      try {
        const url = `${endpoints.Pricelist.mainUrl}/partner/${priceSource}`;
        const response = await axios.get(url);

        //save price list response to indexDB
        localforage
          .setItem(`pricelist-${priceSource}`, response.data)
          .then(async () => {
            //addToast('Price list saved successfully', 'success');
            await setLastUpdate(priceSource);
          })
          .catch((error: any) => {
            console.log(error);
            //addToast('Price list not saved', 'error');
          });

        //save to memory
        setPriceList(response.data);
      } catch (error: any) {
        console.error(error);
        addToast('There was error getting pricelist', 'error');
      }
    },
    [setLastUpdate]
  );

  const loadPricelist = useCallback(() => {
    //let priceList: IPriceList[] = [];

    const priceSource = pharmacyCode === SortingCenterPharmacy ? pharmacyCode : partnerCode;

    const priceListName =
      pharmacyCode === SortingCenterPharmacy
        ? `pricelist-${pharmacyCode}` //load sorting center pricelist if sorting center selected
        : `pricelist-${partnerCode}`;
    // get price list from indexDB
    localforage
      .getItem(priceListName)
      .then(async (value) => {
        if (value === null || typeof value === 'undefined') {
          await loadFromRemote(priceSource);
        } else {
          //check if new version available
          const isNewVersionAvailable = await getLastUpdate(priceSource);

          if (isNewVersionAvailable) {
            await loadFromRemote(priceSource);
          } else {
            loadToMemory(value, priceSource);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        addToast('Error getting price list', 'error');
      });
  }, [loadFromRemote, loadToMemory, getLastUpdate, pharmacyCode, partnerCode]);

  //https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately
  //Feels wrong using this but hey....
  useEffect(() => {
    if (isActive && callback) {
      callback(drugId, data);
    }
  }, [data, isActive, drugId, callback]);

  useEffect(() => {
    //load all pricelist from session where source is partner

    if (isActive) {
      loadPricelist();
    }
  }, [isActive, fulfilmentService, partnerCode, pharmacyCode, loadPricelist]);

  return (
    <div className='tariff-box mt-3'>
      {/* <div className='col-sm-12' style={{ fontSize: '12px' }}>
                  Tariff Cost Calculation. Note, the information in this box is saved and
                  <span className='ml-1'>required</span>
                </div> */}
      {priceList.length > 0 ? (
        <div className='row mt-2'>
          <div className='col-sm-12'>
            <TariffSuggestion
              data={priceList}
              callback={handleSuggestion}
              drugId={1}
              searchTerm={data.searchTerm}
              required={required}
            />
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Unit Cost </label>
            <input
              value={data.unitCost}
              required
              disabled
              className='form-control '
              type='number'
              style={{
                minWidth: '130px',
                marginBottom: '20px',
                marginRight: '20px',
              }}
              min={0}
              step={0.01}
              onChange={handleChange('unitCost')}
            />
          </div>

          <div className='col-sm-6'>
            <label className='form-label'>Quantity dispensed</label>
            <input
              value={data.quantity}
              className='form-control '
              type='number'
              style={{
                minWidth: '150px',
                marginBottom: '20px',
                marginRight: '20px',
              }}
              min={1}
              onChange={handleChange('quantity')}
              required
            />
          </div>

          <div className='col-md-12'>
            <label className='form-label'>Notes </label>
            <input
              value={data.notes}
              disabled
              className='form-control '
              type='text'
              style={{ marginBottom: '20px', marginRight: '20px' }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default TariffSection;
