import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import {
  initialHospicashClaim,
  IClaimEligibility,
  IPostHospicashClaims,
} from '../../../interface/ClaimsInterface';
import { IBeneficiaryListProp } from '../../../interface/BeneficiaryInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { ClaimStatusMap, ClaimTypeMap } from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';
import { useParams } from 'react-router-dom';
import { validClaimFileType } from '../../../utils/fileHelper';
import { IFileInfo } from '../../../interface/CommonInterface';

const AddHospicashClaimForm = (props: any) => {
  let progress = 0;
  const { subscriptionCode } = useParams() as any;
  const [beneficiaryList, setBeneficiaryList] = useState<IBeneficiaryListProp[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState(initialHospicashClaim);
  const [localSubscriptionCode, setSubscriptionCode] = useState('');
  const [claimEligibity] = useState<IClaimEligibility | null>({
    reason: 'Eligible',
    eligible: true,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [progressState, setProgressState] = useState('progress-bar');
  const [fileList, setFileList] = useState<IFileInfo[]>([]);

  const uploadFile = async (event: any) => {
    let uploadedFiles = event.currentTarget.files;

    if (uploadedFiles.length === 0) {
      alert('No files selected. Please select an image');
      return;
    }
    if (!validClaimFileType(uploadedFiles[0])) {
      alert('Wrong file. Upload only .jpg, .jpeg and .png .doc .docx .pdf only');
      return;
    }

    if (uploadedFiles[0].size > 2097152) {
      //if exceeding 2mb
      alert('Exceed file limit. Max limit is 2MB');
      return;
    }

    let response = window.confirm('Do you want to upload this file?');

    if (!response) {
      return;
    }

    setIsUploading(true);
    setProgressState('progress-bar');
    setLoading(true);

    // //trick to show some sort of loading. To properly implement this, we can use
    // //axios and then handle the uploadProgressEvent of axios
    let progressBar = document.getElementById('progress-bar') as HTMLElement;
    var intervalId = window.setInterval(() => {
      progress += 5;
      progressBar.style.width = `${progress}%`;
      if (progress >= 85) {
        window.clearInterval(intervalId);
      }
    }, 1000);

    //if yes, upload image to remote server
    const formData = new FormData();
    formData.append('formFile', uploadedFiles[0]);
    formData.append('subscriptionCode', subscriptionCode);

    //call internal api
    let url = `${endpoints.InsuranceClaims.uploadFile}`;

    try {
      let response = await axios.post(url, formData);

      let fileInfo: IFileInfo = {
        fileName: uploadedFiles[0].name,
        fileUrl: response.data,
      };

      fileList.push(fileInfo);
      setFileList(fileList);

      progressBar.style.width = '100%';
      progress = 100;
      setProgressState('progress-bar bg-success');
    } catch (error: any) {
      progressBar.style.width = '0px';
      setProgressState('progress-bar bg-danger');
    } finally {
      setIsUploading(false);
      setLoading(false);
    }
  };

  const deleteFile = (fileUrl: string) => async (event: any) => {
    event.preventDefault();
    let response = window.confirm('Do you want to delete this file?');

    if (!response) {
      return;
    }
    const url = `${endpoints.InsuranceClaims.deleteFile}`;
    const payload = fileList.filter((x) => x.fileUrl === fileUrl)[0];
    setLoading(true);
    try {
      await axios.delete(url, { data: payload });

      fileList.filter((x) => x.fileUrl !== fileUrl);
      setFileList(fileList);
    } catch (error: any) {
      addToast('File could not be deleted');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: any) => (event: any) => {
    if (input === 'beneficiaryId') {
      setValues({
        ...values,
        beneficiaryId: event.target.value,
      });
    } else {
      setValues({
        ...values,
        [input]: event.target.value,
      });
    }
  };

  const clearForm = () => {
    // e.preventDefault();
    setLoading(false);
    setValues(initialHospicashClaim);
    setFileList([]);
  };

  const postClaim = async (event: any) => {
    values.subscriptionCode = localSubscriptionCode;

    if (!isFormValidated('claims-form')) {
      addToast('Please fill all required fields and select pharmacy/beneficiary', 'warning');
      return;
    }
    event.preventDefault();

    if (!claimEligibity?.eligible) {
      values.notes += `. Claims is not eligible. Reason: ${claimEligibity?.reason}`;
      return;
    }

    const documentsUploaded = fileList.map((x) => x.fileUrl);
    values.documentsUploaded = documentsUploaded;

    //if HospicashPayout, then we must attach files
    if (values.claimType === ClaimTypeMap[0].value && fileList.length === 0) {
      addToast('Please attach files for the claim', 'warning');
      return;
    }

    setLoading(true);
    const url = `${endpoints.InsuranceClaims.mainUrl}/hospicash`;

    try {
      await axios.post(url, values as IPostHospicashClaims);

      addToast('Claim successfully added', 'success');
      clearForm();
      //getClaimEligibility();
    } catch (error: any) {
      if (error.response.status === 422) {
        addToast(
          'An error occured when saving the claim. Check that subscription is valid and Active',
          'error'
        );
      } else {
        addToast('An error occured when saving the claim', 'error');
      }
      setLoading(false);
    }
  };

  // const getClaimEligibility = useCallback(async () => {
  //   const url = `${endpoints.InsuranceClaims.mainUrl}/claimeligibility/${subscriptionCode}`;

  //   try {
  //     const response = await axios.get(url);

  //     setClaimEligibility(response.data);
  //   } catch (error:any) {
  //     console.error(error);
  //   }
  // }, [subscriptionCode]);

  useEffect(() => {
    const getBeneficiaryList = async () => {
      //https://reactrouter.com/web/example/url-params
      //we put the setstate here to ensure subscription code is obtained on load
      setSubscriptionCode(subscriptionCode);

      if (beneficiaryList.length === 0) {
        try {
          const response = await axios.get(
            endpoints.InsuranceBeneficiary.getBeneficiaryListByCode + subscriptionCode
          );
          const beneficiaryData = response.data.map((x: any) => {
            return {
              value: x.insuranceBeneficiaryId,
              label: x.firstName + ' ' + x.lastName,
            };
          });
          setBeneficiaryList(beneficiaryData);

          setValues({
            ...values,
            beneficiaryId: beneficiaryData[0].value,
          });
        } catch (error: any) {
          addToast('An error occured while loading list of beneficiaries', 'error');
        }
      }
    };
    getBeneficiaryList();
  }, [localSubscriptionCode, subscriptionCode, values, beneficiaryList.length]);

  // useEffect(() => {
  //   getClaimEligibility();
  // }, [getClaimEligibility]);

  return (
    <Fragment>
      <div className='container'>
        <h4 style={{ textAlign: 'center', color: '#000000' }}>Add Hospicash Claims</h4>
        <br />
        <div>
          Claim Eligibility:
          {claimEligibity?.eligible ? (
            <span className='text-success'>Eligble</span>
          ) : (
            <span className='text-danger'>Ineligible</span>
          )}
        </div>
        <div>
          Reason:
          <span className={claimEligibity?.eligible ? 'text-success' : 'text-danger'}>
            {claimEligibity?.reason}
          </span>
        </div>
        <br />
        <form id='claims-form'>
          <fieldset disabled={!claimEligibity?.eligible || isLoading ? true : false}>
            <div className='row'>
              <div className='col-md-3'>
                <label className='form-label'>Subscription Code</label>
                <input
                  value={localSubscriptionCode}
                  required
                  className='form-control '
                  type='text'
                  disabled
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Beneficiary making claim</label>

                <select
                  value={values.beneficiaryId}
                  required
                  className='custom-select'
                  onChange={handleChange('beneficiaryId')}
                >
                  {beneficiaryList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Claim Type</label>
                <select
                  value={values.claimType}
                  required
                  className='custom-select'
                  onChange={handleChange('claimType')}
                >
                  {ClaimTypeMap.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Claim Status?</label>
                <select
                  value={values.claimStatus}
                  required
                  className='custom-select'
                  onChange={handleChange('claimStatus')}
                >
                  {ClaimStatusMap.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-3'>
                <label className='form-label'>Total Claim Amount</label>
                <input
                  value={values.claimAmount}
                  className='form-control '
                  type='number'
                  required
                  min={100}
                  step={0.01}
                  onChange={handleChange('claimAmount')}
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Date of Claim</label>
                <input
                  value={values.claimDate}
                  required
                  className='form-control '
                  type='date'
                  onChange={handleChange('claimDate')}
                />
              </div>

              <div className='col-md-3'>
                <label className='form-label'>NPS (0 - 10)</label>
                <input
                  value={values.nps}
                  className='form-control '
                  type='number'
                  required
                  onChange={handleChange('nps')}
                  min={0}
                  max={10}
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Notes</label>
                <input
                  value={values.notes}
                  className='form-control '
                  type='text'
                  aria-multiline
                  onChange={handleChange('notes')}
                />
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-3'>
                <label className='form-label'>Clinic / Hospital</label>
                <input
                  value={values.pharmacyName}
                  className='form-control '
                  type='text'
                  required
                  onChange={handleChange('pharmacyName')}
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Upload files</label>
                <input
                  className='btn btn-primary'
                  type='file'
                  accept='.jpg, .jpeg, .png .pdf .doc .docx'
                  onChange={uploadFile}
                />
                <div
                  className={isUploading ? 'progress' : 'd-none'}
                  style={{ height: '3px', marginTop: '10px' }}
                >
                  <div
                    id='progress-bar'
                    className={progressState}
                    role='progressbar'
                    style={{ width: '10%' }}
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>
                <strong style={{ fontSize: 'unset' }}>Uploaded files</strong>
                <div>
                  {fileList.length === 0
                    ? 'No files'
                    : fileList.map((file, key) => (
                        <div key={file.fileUrl}>
                          <a href={file.fileUrl} key={file.fileUrl}>
                            {file.fileName}
                          </a>
                          <button
                            className='btn btn-sm ml-3'
                            title='delete file'
                            onClick={deleteFile(file.fileUrl)}
                          >
                            <span className='bi bi-trash text-danger '></span>
                          </button>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className='row'>
              <div className='col-md-4'>
                <button className='btn btn-block btn-primary' type='submit' onClick={postClaim}>
                  SUBMIT
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
              <div className='col-md-4'>
                <button className='btn btn-block btn-danger' type='reset' onClick={clearForm}>
                  CLEAR
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </Fragment>
  );
};

export default AddHospicashClaimForm;
