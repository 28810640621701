import axios from 'axios';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { isFormValidated } from '../../../utils/formUtils';
import ButtonLoader from '../../common/ButtonLoader';
import { validFileType } from '../../../utils/fileHelper';
import {
  IClaimJobDTO,
  IMedismarBatchResponse,
  IVeriClaimConfig,
} from '../../../interface/VericlaimInterface';
import { FulfilmentServiceMap } from '../../../utils/mappings';
import BatchNumberModal from './BatchNumberModal';
import { VeriClaimJobType } from '../../../utils/constants';
import { formatDateTime } from '../../../utils/dateTimeUtils';

const AddNewVeriClaimJob = () => {
  const [isLoading, setLoading] = useState(false);
  const [batchNumbers, setBatchNumbers] = useState<IMedismarBatchResponse[]>([]);
  const [initialExportData, setInitialExportData] = useState<IClaimJobDTO>({
    partnerCode: '',
    partnerName: '',
    fileUrls: [],
    queueDate: '',
    jobType: VeriClaimJobType.RelianceRobot,
    batchNumber: '',
    additionalRecipients: '',
    fulfilmentService: 'Telemedicine',
    requiresBatchNumber: false,
  });

  const [vericlaimConfig, setVericlaimConfig] = useState<IVeriClaimConfig[]>([]);

  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const [progressState, setProgressState] = useState('progress-bar');
  const [showModal, setShowModal] = useState(false);

  const clearForm = () => {
    setLoading(false);
    setFileUrls([]);
    setInitialExportData({ ...initialExportData });
    const form = document.getElementById!('vericlaim-form') as HTMLFormElement;
    form.reset();
    let progressBar = document.getElementById('progress-bar') as HTMLElement;
    progressBar.style.width = `0%`;
  };

  const uploadFile = async (event: any) => {
    let fileUpload: any = document.getElementById('claim-file');

    let isFormValid = document.getElementById('claim-file');
    if (!isFormValid) {
      addToast('Select a file');
      return;
    }

    let uploadedFiles = fileUpload.files;

    if (uploadedFiles.length === 0) {
      addToast('No files selected. Please select a csv file', 'warning');
      return;
    }
    if (!validFileType(uploadedFiles[0])) {
      addToast('Wrong file. Upload text or csv file', 'warning');
      return;
    }

    if (uploadedFiles[0].size > 2097152) {
      //if exceeding 2mb
      addToast('Exceed file limit. Max limit is 2MB', 'warning');
      return;
    }

    event.preventDefault();

    const formData = new FormData();
    formData.append('formFile', uploadedFiles[0]);

    let url = `${endpoints.Jobs.backgroundQueue}/files`;
    let progressBar = document.getElementById('progress-bar') as HTMLElement;
    progressBar.style.width = `0%`;

    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressBar.style.width = `${[percentCompleted]}%`;
      },
    };
    try {
      const response = await axios.post(url, formData, config);
      setProgressState('progress-bar bg-success');

      setFileUrls([response.data]);
      addToast('File uploaded successfully', 'success');
      //addT
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        addToast('Please check the file type', 'error');
      } else {
        addToast('An Error occured. Check your network', 'error');
      }
      setProgressState('progress-bar bg-danger');
      progressBar.style.width = `0%`;
      setProgressState('progress-bar');
    } finally {
    }
  };

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValidated('vericlaim-form') || fileUrls.length === 0) {
      addToast('Please fill all fields and upload a csv', 'warning');
      return;
    }

    const payload = initialExportData;

    payload.fileUrls = fileUrls;

    let url = `${endpoints.VeriClaim.jobQueue}`;

    try {
      setLoading(true);
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        addToast('Job queued successfully', 'success');
      }
      clearForm();
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const loadBatchNumber = useCallback(async () => {
    const url = `${endpoints.Medismart.mainUrl}/batch?partnerCode=${initialExportData.partnerCode}`;

    try {
      const response = await axios.get(url);
      const data = response.data as IMedismarBatchResponse[];
      setBatchNumbers(data);
      setInitialExportData((x) => {
        return { ...x, batchNumber: data[0].batchNumber };
      });
    } catch (error) {
      addToast('Error occurred when getting batch number', 'error');
    } finally {
    }
  }, [initialExportData.partnerCode]);

  const handleChange = (input: string) => (event: any) => {
    const inputValue = event.target.value;

    if (input === 'hmoCode') {
      let hmoData = vericlaimConfig.filter((x) => x.partnerCode === inputValue)[0];

      setInitialExportData({
        ...initialExportData,
        partnerCode: inputValue,
        partnerName: hmoData!.partnerName,
        requiresBatchNumber: hmoData.requiresBatchNumber,
      });

      return;
    }

    if (input === 'jobType') {
      let hmoData = vericlaimConfig.filter((x) => x.jobType === inputValue)[0];

      setInitialExportData({
        ...initialExportData,
        partnerCode: hmoData.partnerCode,
        partnerName: hmoData!.partnerName,
        requiresBatchNumber: hmoData.requiresBatchNumber,
        jobType: inputValue,
      });

      return;
    }

    setInitialExportData({
      ...initialExportData,
      [input]: event.target.value,
    });
  };

  const loadConfiguration = useCallback(async () => {
    let url = `${endpoints.ConfigSettings.vericlaimConfig}`;

    try {
      setLoading(true);
      const response = await axios.get(url);
      const data = response.data as IVeriClaimConfig[];
      setVericlaimConfig(data);

      //set init values
      setInitialExportData((x) => {
        return { ...x, partnerCode: data[0].partnerCode, partnerName: data[0].partnerName };
      });
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (initialExportData.jobType === VeriClaimJobType.MedismartJob) {
      loadBatchNumber();
    }
  }, [initialExportData.jobType, loadBatchNumber]);

  useEffect(() => {
    loadConfiguration();
  }, [loadConfiguration]);

  const batchModal = (
    <BatchNumberModal
      open={showModal}
      close={() => setShowModal(!showModal)}
      hmoName={initialExportData.partnerName}
      hmoCode={initialExportData.partnerCode}
    />
  );

  return (
    <Fragment>
      {batchModal}
      <div className='row justify-content-center '>
        <div className='card col-sm-8 pt-5 pb-5'>
          <h3 style={{ textAlign: 'center' }}>Schedule Claims For Processing</h3>
          <br />
          <form className='row justify-content-center mt-4' id='vericlaim-form'>
            <fieldset id='form-fields' disabled={isLoading ? true : false} className='col-md-6'>
              <div className='form-group'>
                <label>Claim provider</label>
                <select
                  className='form-control'
                  value={initialExportData.jobType}
                  onChange={handleChange('jobType')}
                >
                  {vericlaimConfig.length > 0
                    ? vericlaimConfig.map((x) => (
                        <option key={x.jobType} value={x.jobType}>
                          {x.jobType}
                        </option>
                      ))
                    : ''}
                </select>

                {/* // <select
                //   className='form-control'
                //   value={initialExportData.jobType}
                //   onChange={handleChange('jobType')}
                // >
                //   {VeriClaimJobTypeMap.map((job) => (
                //     <option key={job.value} value={job.value}>
                //       {job.text}
                //     </option>
                //   ))}
                // </select> */}
              </div>

              <div className='form-group'>
                <label htmlFor='email' className='form-label'>
                  File URL
                </label>
                <input
                  id='claim-file'
                  className='form-control'
                  type='file'
                  accept='.csv'
                  onChange={uploadFile}
                />
                <div className={'progress'} style={{ height: '3px', marginTop: '10px' }}>
                  <div
                    id='progress-bar'
                    className={progressState}
                    role='progressbar'
                    style={{ width: '0%' }}
                    aria-valuenow={0}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='email' className='form-label'>
                  Select Partner Name
                </label>
                <select
                  value={initialExportData.partnerCode}
                  onChange={handleChange('partnerCode')}
                  className='custom-select'
                  required
                >
                  {vericlaimConfig.length > 0
                    ? vericlaimConfig
                        .filter((x) => x.jobType === initialExportData.jobType)
                        .map((x) => (
                          <option key={x.partnerName} value={x.partnerName}>
                            {x.partnerName}
                          </option>
                        ))
                    : ''}

                  {/* {VeriClaimHMOs.filter((x) => x.jobType === initialExportData.jobType).map(
                    (hmo) => (
                      <option value={hmo.value}>{hmo.text}</option>
                    )
                  )} */}
                </select>
              </div>
              <div className='form-group'>
                <label htmlFor='email' className='form-label'>
                  Select service
                </label>
                <select
                  value={initialExportData.fulfilmentService}
                  onChange={handleChange('fulfilmentService')}
                  className='custom-select'
                  required
                >
                  {FulfilmentServiceMap.map((service) => (
                    <option value={service.value}>{service.text}</option>
                  ))}
                </select>
              </div>

              {initialExportData.requiresBatchNumber ? (
                <div className='form-group'>
                  <label htmlFor='email' className='form-label'>
                    Select a batch number
                  </label>
                  <div className='input-group '>
                    <select
                      value={initialExportData.batchNumber}
                      onChange={handleChange('batchNumber')}
                      className='custom-select'
                      required={initialExportData.jobType === VeriClaimJobType.MedismartJob}
                    >
                      {batchNumbers.map((batch) => (
                        <option
                          value={batch.batchNumber}
                          title={'Added on: ' + formatDateTime(batch.dateAdded)}
                        >
                          {batch.batchNumber}
                        </option>
                      ))}
                    </select>
                    {/* </div> */}
                    <div className='input-group-append'>
                      <button
                        className='btn btn-primary'
                        type='button'
                        title='Create a new batch'
                        onClick={() => setShowModal(!showModal)}
                      >
                        New +
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='mt-5'>
                <button
                  className='btn btn-block btn-primary'
                  disabled={fileUrls.length === 0}
                  onClick={submit}
                >
                  Queue
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddNewVeriClaimJob;
