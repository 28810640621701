import { useState } from 'react';
import FulfilmentDraftTableLayout from './FulfilmentDraftTableLayout';
import FulfilmentDraftCardLayout from './FulfilmentDraftCardLayout';
import { IFulfilmentDraftResponse } from '../../../interface/FulfilmentsInterface';
import { endpoints } from '../../../utils/URL';
import useSWR from 'swr';
import fetcher from '../../../utils/axiosFetcher';
import { useTimer } from '../../../hooks/TimerHook';
import AddFulfilmentFormSection from './AddFulfilmentFormSection';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { Link } from 'react-router-dom';

const FulfilmentDrafts = () => {
  const [collapse, setCollapse] = useState(false);
  // const [drafts, setDrafts] = useState<IFulfilmentDraftResponse[]>([]);
  const [selectedDraft, setSelectedDraft] = useState<IFulfilmentDraftResponse | undefined>();
  const { data, mutate } = useSWR<IFulfilmentDraftResponse[], any>(
    endpoints.FulfilmentDrafts.mainUrl,
    fetcher,
    {
      fallbackData: [],
      errorRetryCount: 1,
      revalidateOnFocus: false,
    }
  );

  const { startTimer, stopTimer } = useTimer('draft');

  const openSection = (draft: IFulfilmentDraftResponse) => {
    setSelectedDraft(draft);
    setCollapse(!collapse);
    startTimer();
  };

  const closeSection = () => {
    stopTimer();
    setCollapse(!collapse);
  };

  const markDraftCompleted = async (draftId: number) => {
    //remove from list,
    const pending = data?.filter((x) => x.id !== draftId);
    mutate(pending, false);
    setSelectedDraft(undefined);
    // //call api to mark as done
    try {
      //setLoading(true);
      const url = `${endpoints.FulfilmentDrafts.mainUrl}/${draftId}/completed`;
      await axios.put(url);
      //close side modal and remove draft from list
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      //setLoading(false);
    }
  };

  const selectDraft = (draft: IFulfilmentDraftResponse) => {
    setSelectedDraft(draft);
  };

  return (
    <div className='mt-5'>
      <div className='col-sm-12 mt-3 mb-3 mt-sm-0 text-right'>
        <button className='ml-2 btn btn-primary'>
          <Link
            to='/fulfilments/drafts/add'
            style={{ textDecoration: 'none', color: 'black' }}
            title='Add fulfilment draft'
          >
            <span className='d-none d-sm-block'>Add Fulfilment Drafts</span>
            <span className='bi bi-plus d-block d-sm-none'></span>
          </Link>
        </button>
      </div>
      <div className='row'>
        <div className={collapse ? 'col-3' : 'col-lg-12'}>
          {collapse ? (
            <FulfilmentDraftCardLayout selectDraft={selectDraft} drafts={data!} />
          ) : (
            <FulfilmentDraftTableLayout
              open={openSection}
              drafts={data!}
              callback={markDraftCompleted}
            />
          )}
        </div>
        {collapse ? (
          <div className='col-9 border' style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
            <div>
              <button className='btn btn-muted text-right' onClick={closeSection}>
                Close<span className='bi bi-x bi-x-lg'></span>
              </button>
            </div>
            <AddFulfilmentFormSection draft={selectedDraft!} callback={markDraftCompleted} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
    // show list. On Click of each list, collapse list to cards and open working tab
    //initial list shuold be md-12. collapse becomes, m-3 and working side m-9
    // allows user to open each and complete without moving away
  );
};

export default FulfilmentDrafts;
