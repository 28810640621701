export interface IPartnerData {
  insurancePartnerId: number;
  partnerCode: string;
  partnerName: string;
  phoneNumber: string;
  contactEmail: string;
  isActive: boolean;
  partnerType: string;
  dateCreated: string;
  numberOfSubscribers: number;
}

export interface IPartnerDataDTO {
  partnerName: string;
  partnerCode: string;
}

export interface IAddPartnerModalData {
  partnerName: string;
  contactName: string;
  phoneNumber: string;
  contactEmail: string;
  location: string;
  partnerType: string;
  isActive: boolean;
  sendApiKeys: boolean;
}

export interface IClaimDataExport {
  name: string;
  partnerCode: string;
  startDate: string;
  endDate: string;
  email: string;
}

export interface IPartnerBulkPayment {
  partnerCode: string;
  productType: string;
  paymentPlan: string;
  startDate: string;
  name: string;
}

export interface IAddPartnerModal {
  open: boolean;
  close: () => void;
  modalData: IAddPartnerModalData;
  handleChange: (input: string) => (e: any) => void;
  getPartners: () => Promise<void>;
}

export const initialClaimDataExportData: IClaimDataExport = {
  name: '',
  partnerCode: '',
  startDate: '',
  endDate: '',
  email: '',
};

export const initialPartnerBulkPaymentData: IPartnerBulkPayment = {
  partnerCode: '',
  productType: 'Malaria',
  paymentPlan: 'Monthly',
  startDate: '',
  name: '',
};

export const initialAddPartnerModalData: IAddPartnerModalData = {
  partnerName: '',
  contactName: '',
  phoneNumber: '',
  contactEmail: '',
  location: '',
  partnerType: 'Company',
  isActive: false,
  sendApiKeys: false,
};

export const initialPartnerData: IPartnerData = {
  insurancePartnerId: 0,
  partnerCode: '',
  partnerName: '',
  phoneNumber: '',
  contactEmail: '',
  isActive: false,
  partnerType: 'Company',
  dateCreated: '',
  numberOfSubscribers: 0,
};

export const initialPartnerDTO: IPartnerDataDTO = {
  partnerName: '',
  partnerCode: '',
};
