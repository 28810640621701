import { useCallback, useContext, useEffect, useState } from 'react';
import { IProps } from '../../../interface/AppInterface';
import { ILeadsData } from '../../../interface/LeadsInterface';
import { AppContext } from '../../../context/AppContext';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';

// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ButtonLoader from '../../common/ButtonLoader';
import './styles.css';

// const { SearchBar } = Search;

const LeadsTable = (props: IProps) => {
  const { dispatch } = useContext(AppContext);

  const [leadsData, setLeadsData] = useState<ILeadsData[]>([]);
  const [tableData, setTableData] = useState<ILeadsData[]>([]);
  const [searchButtonClicked, setsearchButtonClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  let tableBody;

  const setOneClickData = (data: ILeadsData) => {
    dispatch({
      type: 'SET_ONE_CLICK_LEADS_DATA',
      payload: data,
    });
    props.history.push('/subscriptions/add');
  };

  const getInsuranceLeads = useCallback(async (pageSize: number) => {
    let size = 200;
    if (pageSize != null || undefined) {
      size = pageSize;
    }
    try {
      const response = await axios.get(endpoints.InsuranceLeads.getInsuranceLeads + size);
      setLeadsData(response.data);
      setTableData(response.data);
    } catch (error: any) {
      addToast('There was an error getting leads data', 'error');
    }
  }, []);

  const clearInput = async () => {
    setSearchQuery('');
    setTableData(leadsData);
  };
  const searchInputChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);

    let searchParam = event.target.value;

    if (searchParam === '') {
      setTableData(leadsData);
      //setAdvSearch(false);
      return;
    }

    //if not found, search remote?

    searchParam = searchParam.toLowerCase();
    const searchResult = leadsData.filter(
      (x) =>
        x.firstName?.toLowerCase().includes(searchParam) ||
        x.lastName?.toLowerCase().includes(searchParam) ||
        x.phone?.toLowerCase().includes(searchParam) ||
        x.email?.toLowerCase().includes(searchParam)
    );
    // //set table data to search result
    setTableData(searchResult);

    // if (searchParam.length >= 3) {
    //   //setAdvSearch(true);
    // } else {
    //   //setIsLoading(false);
    // }
  };

  const onSearchClick = async () => {
    setsearchButtonClicked(true);
    // const leads: ILeadsData[] = leadsData.filter(
    //   (lead) =>
    //     lead.firstName.toLowerCase().includes(searchQuery) ||
    //     lead.lastName.toLowerCase().includes(searchQuery) ||
    //     lead.email.toLowerCase().includes(searchQuery)
    // );

    // if (leads.length > 0) {
    //   setTableData(leads);
    //   setsearchButtonClicked(false);
    //   return;
    // }

    const query = searchQuery;
    try {
      const response = await axios.get(endpoints.InsuranceLeads.searchLeads + query);
      const data = response.data;
      // setLeadsData(data);
      setTableData(data);
      setsearchButtonClicked(false);
    } catch (error: any) {
      addToast('There was an error getting search data', 'error');
    } finally {
      setsearchButtonClicked(false);
    }
  };

  // const addIcon = (cell: any, row: any) => {
  //   return (
  //     <div className='row'>
  //       <div className='col'>
  //         <i
  //           style={{ cursor: 'pointer' }}
  //           onClick={() => setOneClickData(row)}
  //           className='bi bi-people-fill export-icon'
  //           data-bs-toggle='tooltip'
  //           data-bs-placement='top'
  //           title='Add Subscription'
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  // const options = {
  //   onSizePerPageChange: (sizePerPage: any, page: any) => {},
  //   onPageChange: (page: any, sizePerPage: any) => {},
  // };

  useEffect(() => {
    getInsuranceLeads(200);
  }, [getInsuranceLeads]);

  let searchInput = (
    <div className='d-flex justify-content-start'>
      <div className='input-group'>
        <input
          type='text'
          className='form-control col-md-3'
          value={searchQuery}
          onChange={searchInputChange()}
          placeholder='Search Older Leads'
        ></input>
        <div className='input-group-append'>
          <i onClick={clearInput} className='input-group-text bi bi-x-circle-fill close-icon'></i>
        </div>
        <div className=''>
          <button
            type='button'
            className='btn btn-primary'
            style={{ marginLeft: '10px' }}
            onClick={onSearchClick}
          >
            Search
            {searchButtonClicked ? <ButtonLoader /> : ''}
          </button>
        </div>
      </div>
    </div>
  );

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Date Created</th>
        <th scope='col'>First Name</th>
        <th scope='col'>Last Name</th>
        <th scope='col'>Phone</th>
        <th scope='col'>Email</th>
        <th scope='col'>Gender</th>
        <th scope='col'>Product</th>
        <th scope='col'>Date of Birth</th>
        <th scope='col'>Location</th>
        <th scope='col'>Amount to pay</th>
        <th scope='col'>Monnify Account</th>
        <th scope='col'>Plan Name</th>
        <th scope='col'>Months</th>
        <th scope='col'>People in Plan</th>
        <th scope='col'>Hospicash Amount</th>
        <th scope='col'>Acquistion Channel</th>
        <th scope='col'>Reffered By</th>
        <th scope='col'>Notes</th>
      </tr>
    </thead>
  );

  if (tableData.length === 0) {
    tableBody = (
      <tbody>
        <tr className='text-center'>
          <td colSpan={100}> No data found </td>
        </tr>
      </tbody>
    );
  } else {
    tableBody = (
      <tbody>
        {tableData.map((item: ILeadsData) => {
          return (
            <tr key={item.leadId}>
              <td>
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOneClickData(item)}
                  className='bi bi-people-fill export-icon'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Add Subscription'
                ></i>
              </td>
              <td>{new Date(item.dateCreated).toLocaleString()}</td>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.phone}</td>
              <td>{item.email}</td>
              <td>{item.gender}</td>
              <td>{item.productType}</td>
              <td>{item.dateOfBirth !== null ? item.dateOfBirth.split('T')[0] : ''}</td>
              <td>{item.location}</td>
              <td>{item.amount}</td>
              <td>{item.accountNumber}</td>
              <td>{item.planName}</td>
              <td>{item.paymentPlan}</td>
              <td>{item.peopleInPlan}</td>
              <td>{item.hospicashAmount}</td>
              <td>{item.leadSource}</td>
              <td>{item.referredBy}</td>
              <td>{item.notes}</td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <>
      <div>
        <div className='searchOlderLeads'>{searchInput}</div>
      </div>

      <div className='d-flex'>
        <h3 className='mr-auto'>Leads</h3>
      </div>

      <div className='table-responsive'>
        <table className='table table-striped table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </>
  );
};

export default LeadsTable;
