export interface ISupportRequest {
  requestId: number;
  phoneNumber: string;
  email: string;
  productType: string;
  requestType: string;
  requestStatus: string;
  supportSource: string;
  notes: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface ISupportRequestsModalProps {
  open: boolean;
  close: () => void;
  modalData: ISupportRequest;
  getRequests: Function;
  handleChange: Function;
}

export const initialSupportRequestData: ISupportRequest = {
  requestId: 0,
  phoneNumber: '',
  email: '',
  productType: 'Malaria',
  requestType: 'Call',
  requestStatus: 'Pending',
  supportSource: 'Call',
  notes: '',
  dateCreated: '',
  dateUpdated: '',
};
