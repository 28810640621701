import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const Callback = () => {
  const auth = useAuth();
  //track authentication to avoid repeated calls
  const [authComplete, setAuthComplete] = useState(false);

  const checkAuth = () => {
    if (authComplete) {
      //to avoid repeated calls
      return <div>Please wait while we complete your login...</div>;
    }
    if (auth.isLoading) {
      return <div>Please wait while we complete your login...</div>;
    }
    if (auth.isAuthenticated) {
      let isAllowed = true;

      //check if they have access to this dashboard
      const roles = auth.user?.profile.role; // as string[];

      const allowedRoles = ['Admin', 'SysAdmin', 'Support', 'Finance'];
      if (roles !== null && typeof roles !== 'undefined') {
        if (Array.isArray(roles)) {
          isAllowed = roles.some((x) => allowedRoles.includes(x));
        } else {
          isAllowed = allowedRoles.includes(roles);
        }
      }

      if (!isAllowed) {
        setAuthComplete(true);

        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem('rememberMe', 'false');
        window.location.href = '/?access=false';
      } else {
        const token = auth.user?.access_token;

        //set axios header
        axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;

        //set remember me
        localStorage.setItem('rememberMe', 'true'); //

        setAuthToken(token);

        setAuthComplete(true);

        //check if we have a return url, if yes get the return url and reset
        const returnUrl = sessionStorage.getItem('return-url');
        if (returnUrl === null || typeof returnUrl === 'undefined') {
          window.location.href = '/home';
        } else {
          window.location.href = returnUrl!;
        }
      }
    }
  };

  checkAuth();

  return <div>Please wait while we complete your login...</div>;
};

export default Callback;
