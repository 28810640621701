export interface UserNotes {
  noteContent: string;
  noteType: 'Fulfilment' | 'Insurance' | 'Pharmacy';
  noteTypeId: string;
  createdBy: string;
  dateCreated: string;
}

export const inituserNotes: UserNotes = {
  noteContent: '',
  noteType: 'Fulfilment',
  noteTypeId: '',
  createdBy: '',
  dateCreated: '',
};
