export interface IWalletAccountDTO {
  providerName: string;
  providerCode: string;
  walletBalance: number;
  bankAccountNumber: string;
  bankAccountName: string;
  bankCode: string;
  discount: number;
  bankName: string;
  accountId: string;
}

export interface IPharmacyWallet {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankCode: string;
  discount: number;
  accountId: string;
}

export const initialPharmacyWallet: IPharmacyWallet = {
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankCode: '',
  discount: 0,
  accountId: '',
};

export const initialWalletAccountDTOData: IWalletAccountDTO = {
  providerName: '',
  providerCode: '',
  walletBalance: 0,
  bankAccountNumber: '',
  bankAccountName: '',
  bankCode: '044', //access bank
  discount: 0,
  bankName: 'Access Bank',
  accountId: '',
};

export interface IWalletTransactionUpdate {
  transactionId: string;
  transactionStatus: string;
  notes: string;
}

export interface IWalletTransactionData {
  transactionId: string;
  accountId: string;
  providerCode: string;
  providerName: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  amount: number;
  balance: number;
  transactionStatus: string;
  createdBy: string;
  approvedBy: string;
  processedDate: string;
  transactionDate: string;
  descriptionType: string;
  transactionType: string;
  notes: string;
}

export interface PayoutUpdateDTO {
  payoutStatus: string;
  payoutId: string;
  notes?: string;
}

export const initialTransactionData: IWalletTransactionData = {
  transactionId: '',
  providerCode: '',
  providerName: '',
  bankName: 'Access Bank',
  bankAccountName: '',
  bankAccountNumber: '0000000000',
  amount: 0,
  balance: 0,
  transactionStatus: 'Pending',
  createdBy: '',
  approvedBy: '',
  processedDate: '',
  transactionDate: '',
  descriptionType: 'Claims',
  transactionType: 'Debit',
  accountId: '',
  notes: '',
};

export interface IWalletTransactionsModalProps {
  open: boolean;
  close: () => void;
  modalData: IWalletTransactionData;
  getTransactions: Function;
  handleChange: Function;
}

export interface IWalletTransactionDTO {
  pharmacyCode: string;
  amount: number;
  transactionType: string;
  transactionStatus: string;
  paymentDescription: string;
  notes: string;
  isPayout: boolean;
}

export interface IWalletAccountModal {
  open: boolean;
  close: () => void;
  modalData: IWalletAccountDTO;
  edit: boolean;
  newPharmacy: boolean;
  addWallet?: Function;
}

export interface IBankList {
  id: number;
  name: string;
  code: string;
}

//For Payout Requests
export interface IWalletPayoutDTO {
  payoutId: string;
  accountId: string;
  paymentGateway: string;
  payoutStatus: string;
  amount: number;
  balance: number;
  isPaymentTransferred: boolean;
  createdBy: string;
  approvedBy: string;
  notes: string;
  processedDate: string;
  dateCreated: string;
  providerCode: string;
  providerName: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  descriptionType: string;
  transactionType: string;
}
