import React from 'react';

import { IBeneficiaryModalProps } from '../../../interface/BeneficiaryInterface';
import { Modal } from 'react-bootstrap';
import { GENDER_MAPPING } from '../../../utils/mappings';
import './styles.css';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { formatInputDate } from '../../../utils/dateTimeUtils';

const AddBeneficiaryModal = (props: IBeneficiaryModalProps) => {
  const addBeneficiaryToPlan = (event: any) => {
    if (!isFormValidated('add-beneficiary-modal')) {
      addToast('Please fill all fields!!', 'warning');
      return;
    }
    event.preventDefault();
    props.addToPlan();
  };
  return (
    <Modal show={props.open} onHide={props.close}>
      <form id='add-beneficiary-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Beneficiaries</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <div className='col-md-12'>
            <label htmlFor='firstName' className='form-label'>
              First Name
            </label>
            <input
              value={props.modalData.firstName}
              required
              className='form-control '
              type='text'
              onChange={props.handleChange('firstName')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='lastName' className='form-label'>
              Last Name
            </label>
            <input
              value={props.modalData.lastName}
              className='form-control '
              type='text'
              required
              onChange={props.handleChange('lastName')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='phone' className='form-label'>
              Phone
            </label>
            <input
              value={props.modalData.phone}
              maxLength={13}
              pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
              className='form-control '
              type='text'
              onChange={props.handleChange('phone')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='email' className='form-label'>
              Email address
            </label>
            <input
              value={props.modalData.email}
              className='form-control '
              type='email'
              placeholder='name@example.com'
              onChange={props.handleChange('email')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='gender' className='form-label'>
              Gender
            </label>
            <select
              required
              onChange={props.handleChange('gender')}
              value={props.modalData.gender}
              className='custom-select'
            >
              {GENDER_MAPPING.map((option) => (
                <option key={option.key} value={option.value.toString()}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='gender' className='form-label'>
              Date of Birth
            </label>
            <input
              required
              value={formatInputDate(props.modalData.dateOfBirth)}
              className='form-control '
              type='date'
              onChange={props.handleChange('dateOfBirth')}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='submit' className='btn btn-primary' onClick={addBeneficiaryToPlan}>
            Add To Plan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddBeneficiaryModal;
