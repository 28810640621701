import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { Link } from 'react-router-dom';

const InsuranceView = ({ setActiveNav }: { setActiveNav: any }) => {
  const [subscription, setSubscription] = useState(0);
  const [claim, setClaim] = useState(0);

  const getNewSubscriptionCount = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.InsuranceSubscriptions.getNewSubscriptionsCount);
      const pendingCount = response.data;
      setSubscription(pendingCount);
    } catch (error: any) {
      addToast('There was an error getting pending subscription count', 'error');
    }
  }, []);

  const getPendingClaimsCount = useCallback(async () => {
    try {
      await axios.get(endpoints.InsuranceClaims.getPendingClaimsCount).then((response) => {
        const pendingCount = response.data;
        setClaim(pendingCount);
      });
    } catch (error: any) {
      addToast('There was an error getting pending telemedicine count', 'error');
    }
  }, []);

  useEffect(() => {
    getNewSubscriptionCount();
    getPendingClaimsCount();
  }, [getNewSubscriptionCount, getPendingClaimsCount]);

  return (
    <>
      <li className='nav-item'>
        <Link to='/subscriptions' className='nav-link position-relative' onClick={setActiveNav}>
          Subscription
          <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
            {subscription}
          </h3>
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/partners' className='nav-link' onClick={setActiveNav}>
          Businesses and Partners
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/pharmacies' className='nav-link' onClick={setActiveNav}>
          Pharmacy
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/claims' className='nav-link position-relative' onClick={setActiveNav}>
          Claims
          <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
            {claim}
          </h3>
        </Link>
      </li>

      <li className='nav-item'>
        <Link to='/axa-lookup' className='nav-link' onClick={setActiveNav}>
          AXA Lookup
        </Link>
      </li>

      <li className='nav-item'>
        <Link to='/leads' className='nav-link' onClick={setActiveNav}>
          Leads
        </Link>
      </li>
    </>
  );
};

export default InsuranceView;
