import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { IFulfilments } from '../../../interface/FulfilmentsInterface';
import { FulfilmentDetailsTab, PharmacyStatusMap } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import FulfilmentDrugs from './FulfilmentDrugs';
import FulfilmentInfo from './FulfilmentInfo';
import FulfilmentMiscComms from './FulfilmentMiscComms';
import FulfilmentPharmacy from './FulfilmentPharmacy';
import FulfilmentTests from './FulfilmentTests';
import NotesModal from '../comms/NotesModal';
import { IPharmacyListData } from '../../../interface/PharmacyInterface';
import AddNotesModal from './AddNotesModal';

const FulfilmentDetails = () => {
  const auth = useAuth();
  const [fulfilmentRequest, setFulfilmentRequest] = useState<IFulfilments | undefined>();
  const [isEditing, setIsEditing] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [trackingModalOpen, setTrackingModalOpen] = useState(false);
  const [showNoteReason, setShowNoteReason] = useState(false);
  const [defaultNoteTags, setDefaultNoteTags] = useState<string | undefined>();
  const [pharmacy, setPharmacy] = useState<IPharmacyListData | undefined>();

  const [key, setKey] = useState('Info');
  const { requestId } = useParams() as any;

  const getFulfilment = useCallback(async () => {
    if (typeof requestId === 'undefined' && requestId === '') {
      addToast('Select a fulfilment');
      return;
    }
    let url = `${endpoints.FulfilmentRequests.mainUrl}/${requestId}`;
    toggleLock(true);
    try {
      const response = await axios.get(url);
      const data = response.data;
      setFulfilmentRequest(data);
    } catch (error: any) {
      addToast('Error occured');
    } finally {
      toggleLock(false);
    }
  }, [requestId]);

  const toggleLock = (state: boolean) => {
    setIsEditing(state);
  };

  const getPharmacyStatus = useCallback(() => {
    //get pharmacy from storage
    const savedPharmacyList = sessionStorage.getItem('pharmacyList');

    if (savedPharmacyList && savedPharmacyList !== null) {
      const pharmacyList = JSON.parse(savedPharmacyList) as IPharmacyListData[];

      const pharmacy = pharmacyList.filter(
        (x) => x.pharmacyCode === fulfilmentRequest?.pharmacyCode
      )[0];
      if (pharmacy !== null) {
        setPharmacy(pharmacy);
      }
    }
  }, [fulfilmentRequest]);

  const toggleNoteModal = (defaultTags?: string, showReason = false) => {
    setShowNoteModal(!showNoteModal);
    setDefaultNoteTags(defaultTags);
    setShowNoteReason(showReason);
  };

  let noteModal = (
    <NotesModal
      open={showNoteModal}
      close={toggleNoteModal}
      noteType={'Fulfilment'}
      noteTypeId={requestId}
      defaultTags={defaultNoteTags}
      showReason={showNoteReason}
    />
  );

  let statusTrackingNote = (
    <AddNotesModal
      open={trackingModalOpen}
      close={() => setTrackingModalOpen(!trackingModalOpen)}
      modalData={fulfilmentRequest!}
    />
  );

  //load partner
  useEffect(() => {
    const getFulfilmentPartners = async () => {
      let url = endpoints.FulfilmentPartners.mainUrl;
      try {
        const response = await axios.get(url);
        localStorage.setItem('partnersList', JSON.stringify(response.data));
      } catch (error: any) {
        console.error(error);
        addToast('Could not load partner list', 'error');
      } finally {
      }
    };

    getFulfilmentPartners();
  }, []);

  useEffect(() => {
    getFulfilment();
  }, [getFulfilment]);

  useEffect(() => {
    getPharmacyStatus();
  }, [getPharmacyStatus]);

  return (
    <div className='mt-5'>
      {noteModal}
      {statusTrackingNote}
      <h4 className='text-center'>
        {fulfilmentRequest?.enrolleeFirstName} {fulfilmentRequest?.enrolleeLastName} -
        {fulfilmentRequest?.requestSource}
      </h4>
      <div className='text-center'>
        {fulfilmentRequest?.enrolleeDateOfBirth !== null
          ? `${
              new Date().getUTCFullYear() -
              new Date(fulfilmentRequest?.enrolleeDateOfBirth!).getUTCFullYear()
            } years old`
          : ''}
      </div>
      <br />
      <div>
        <span className='mr-3 ml-3'>
          <label>Status:</label>{' '}
          {isEditing ? (
            <span className='text-danger'>Busy</span>
          ) : (
            <span className='text-success'>Ready</span>
          )}
        </span>
        <span className='mr-3 ml-3'>
          <label className='text-info'>Assigned to:</label> {fulfilmentRequest?.assignedTo}
        </span>
        <span className='mr-3 ml-3'>
          <label className='text-info'>Agent currently working it:</label>
          {auth?.user?.profile.email}
        </span>
        {key !== FulfilmentDetailsTab.misc ? (
          <div className='ml-auto float-right'>
            <span className=''>
              <button
                className='btn btn-primary'
                onClick={() => setTrackingModalOpen(!trackingModalOpen)}
              >
                Add HMO Update
              </button>
            </span>
            <span className='ml-2'>
              <button className='btn btn-outline-primary' onClick={() => toggleNoteModal()}>
                Add note
              </button>
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
      {fulfilmentRequest?.useSortingCenter ? (
        <div className='text-justify text-info mt-5 ml-3'>
          This request uses WellaHealth Sorting Center. Please inform HMO and Enrollee that this
          will be done via delivery
        </div>
      ) : (
        ''
      )}
      {pharmacy?.pharmacyStatus === PharmacyStatusMap.Cancelled ? (
        <div className='text-justify text-danger mt-5 ml-3'>
          This pharmacy has been deactivated by the Pharmacy Ops team. Please change to another
          pharmacy
        </div>
      ) : (
        ''
      )}
      <br />
      <fieldset disabled={isEditing} className='mt-5'>
        <Tabs
          fill
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
        >
          <Tab eventKey={FulfilmentDetailsTab.info} title={FulfilmentDetailsTab.info}>
            <FulfilmentInfo
              fulfilmentRequest={fulfilmentRequest!}
              toggleLock={toggleLock}
              getFulfilment={getFulfilment}
              tabKey={key}
              openNoteModal={toggleNoteModal}
            />
          </Tab>
          <Tab eventKey={FulfilmentDetailsTab.medications} title={FulfilmentDetailsTab.medications}>
            <FulfilmentDrugs
              fulfilmentRequest={fulfilmentRequest!}
              tabKey={key}
              toggleLock={toggleLock}
              getFulfilment={getFulfilment}
            />
          </Tab>
          <Tab eventKey={FulfilmentDetailsTab.tests} title={FulfilmentDetailsTab.tests}>
            <FulfilmentTests
              fulfilmentRequest={fulfilmentRequest!}
              tabKey={key}
              toggleLock={toggleLock}
              getFulfilment={getFulfilment}
            />
          </Tab>
          <Tab eventKey={FulfilmentDetailsTab.pharmacy} title={FulfilmentDetailsTab.pharmacy}>
            <FulfilmentPharmacy
              fulfilmentRequest={fulfilmentRequest!}
              tabKey={key}
              toggleLock={toggleLock}
              getFulfilment={getFulfilment}
            />
          </Tab>
          <Tab eventKey={FulfilmentDetailsTab.misc} title={FulfilmentDetailsTab.misc}>
            <FulfilmentMiscComms
              fulfilmentRequest={fulfilmentRequest!}
              tabKey={key}
              toggleLock={toggleLock}
              getFulfilment={getFulfilment}
            />
          </Tab>
          {/* <Tab eventKey={FulfilmentDetailsTab.messages} title='Messages'></Tab> */}
        </Tabs>
      </fieldset>
    </div>
  );
};

export default FulfilmentDetails;
