export interface IFulfilmentPartners {
  partnerId: number;
  partnerCode: string;
  partnerName: string;
  phoneNumber: string;
  supportEmail: string;
  contactEmail: string;
  location: string;
  isActive: boolean;
  dateCreated: string;
  dateUpdated: string;
  serviceCharge: number;
  chargeValueType: string;
}

export interface IFulfilmentConfiguration {
  partnerCode: string;
  partnerName: string;
  dispenseTests: boolean;
  dispenseDrugs: boolean;
  changePharmacy: boolean;
  sendDrugsDispensed: boolean;
  services: string[];
  serviceCharge: number;
  chargeValueType: string;
  lockTariff: boolean;
}

export interface AddFulfilmentPartner {
  partnerName: string;
  phoneNumber: string;
  supportEmail: string;
  contactEmail: string;
  contactName: string;
  location: string;
  isActive: boolean;
  serviceCharge: number;
  chargeValueType: string;
  partnerEmailTemplate: { pickupEmail: string; statusEmail: string };
  sendApiKeys: boolean;
  createCredentials: boolean;
}

export const addFulfilmentPartnerData: AddFulfilmentPartner = {
  partnerName: '',
  phoneNumber: '',
  supportEmail: '',
  contactEmail: '',
  contactName: '',
  location: '',
  isActive: false,
  serviceCharge: 0,
  chargeValueType: '',
  partnerEmailTemplate: { pickupEmail: 'Pickup', statusEmail: 'Status' },
  sendApiKeys: false,
  createCredentials: false,
};

export const initialFulfilmentPartnersData: IFulfilmentPartners = {
  partnerId: 0,
  partnerCode: '',
  partnerName: '',
  phoneNumber: '',
  supportEmail: '',
  contactEmail: '',
  location: '',
  isActive: false,
  dateCreated: '',
  dateUpdated: '',
  serviceCharge: 0,
  chargeValueType: 'Absolute',
};
