import { useCallback, useEffect, useState } from 'react';
import { IFulfilments, IPreAuthClaims } from '../../../interface/FulfilmentsInterface';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { NairaSymbol } from '../../../utils/constants';

import LoaderWithText from '../../common/LoaderWithText';

const MedismartSidebar = ({
  showModal,
  close,
  fulfilmentInfo,
}: {
  showModal: boolean;
  close: Function;
  fulfilmentInfo: IFulfilments;
}) => {
  const [preAuthClaims, setPreAuthClaims] = useState<IPreAuthClaims[]>([]);
  const [loading, setLoading] = useState(false);

  const getPreAuthClaims = useCallback(async () => {
    const url = `${endpoints.Medismart.mainUrl}/preauthclaims`;
    const payload = {
      preAuthorizationCode: fulfilmentInfo?.preAuthorizationCode,
      partnerCode: fulfilmentInfo?.partnerCode,
    };
    setLoading(true);
    try {
      const response = await axios.post(url, payload);
      const data = response.data;

      setPreAuthClaims(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [fulfilmentInfo?.preAuthorizationCode, fulfilmentInfo?.partnerCode]);

  const closeModal = () => {
    if (close) {
      close();
    }
  };

  useEffect(() => {
    if (showModal) {
      getPreAuthClaims();
    }
  }, [getPreAuthClaims, showModal]);

  return (
    <div
      id='sidebarMenu'
      className={
        showModal
          ? 'col-md-8 col-lg-6 right-sidebar sideshow'
          : 'col-md-8 col-lg-6 right-sidebar sidehide'
      }
    >
      <div className='right-sidebar-sticky pt-3 mt-2'>
        <div onClick={closeModal} className='float-right btn btn-default btn-outline-danger btn-sm'>
          Close <span className='bi bi-x'></span>
        </div>
        {/* Collect patient address */}

        <div className='mt-5 mb-3'>
          <h4>PA Information</h4>

          {loading && <LoaderWithText />}
          {!loading && (
            <div>
              {preAuthClaims.length > 0 ? (
                <div className='mt-4'>
                  <h3>
                    Total Amount: {NairaSymbol}
                    {preAuthClaims[0].amountRequested.toLocaleString()}
                  </h3>
                  <div className='table-responsive mt-5'>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {preAuthClaims.map((claim) => (
                          <tr key={claim.procedureCode}>
                            <td>{claim.proceduredesc}</td>
                            <td>{claim.qty}</td>
                            <td>
                              {NairaSymbol}
                              {claim.endorsementValue.toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                'No Pre-Auth claims found for the PA code. '
              )}{' '}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MedismartSidebar;
