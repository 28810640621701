import React, { useEffect, useState, useCallback } from 'react';
import ButtonLoader from '../common/ButtonLoader';
import PageHeader from '../layout/PageHeader';
import { addToast } from '../../utils/toastNotifications';
import { useAuth } from 'react-oidc-context';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const searchParam = new URLSearchParams(window.location.search);
  const accessState = searchParam.get('access');

  const loginOidc = useCallback(async () => {
    setLoading(true);
    await auth
      .signinRedirect()
      .then((response) => {})
      .catch((error) => {
        addToast('Network error occured', 'error');
        console.log(error);
      });
  }, [auth]);

  useEffect(() => {
    //check localstorage if value exist
    const rememberMe = localStorage.getItem('rememberMe');

    //if value does not exist, save new value as false
    if (typeof rememberMe === 'undefined' || rememberMe == null) {
      localStorage.setItem('rememberMe', 'false');
      return;
    }
    //else redirect to login if value is tru
    if (rememberMe === 'true') {
      loginOidc();
    }

    //check if access is already denied
    if (accessState === 'false') {
      addToast(
        'You do not have access to this application. Please logout and login again',
        'error',
        false
      );
      // auth
      //   .signoutRedirect()
      //   .then((response) => {})
      //   .catch((error) => {
      //     addToast('error occured while logging out');
      //   });
    }
  }, [loginOidc, accessState]);

  return (
    <div>
      <PageHeader />
      <div className='container' style={{ marginTop: '100px' }}>
        <h3 className='text-center'>WellaHealth Admin Portal</h3>

        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-md-4 col-sm-6 mt-5'>
            <br />
            <button
              onClick={loginOidc}
              disabled={loading}
              type='submit'
              className='btn btn-primary btn-block'
            >
              Login
              {loading ? <ButtonLoader /> : ''}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
