import React, { useState, useEffect, useCallback, useContext } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { ITelemedicineRequest } from '../../../interface/TelemedicineInterface';
import { addToast } from '../../../utils/toastNotifications';
import { formatDate } from '../../../utils/dateTimeUtils';
import { AppContext } from '../../../context/AppContext';

import PaginationBar from '../../common/Pagination';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { IRealTimeUpdateProp } from '../../../interface/AppInterface';

const TelemedicineRequests = () => {
  const [tableData, setTableData] = useState<ITelemedicineRequest[]>([]);
  const { appState } = useContext(AppContext);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);

  const getTelemedicineRequests = useCallback(async (pageIndex = 1) => {
    try {
      const url = `${endpoints.Telemedicine.mainUrl}?pageIndex=${pageIndex}&pageSize=200`;
      const response = await axios.get(url);
      setTableData(response.data.data);
      //set pagination info
      const responsePageInfo = {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        pageCount: response.data.pageCount,
      };
      //set page info
      setPageInfo(responsePageInfo);
      setShowPagination(true);
    } catch (error: any) {
      addToast('There was error getting telemedicine requests', 'error');
    }
  }, []);

  const getUpdatedRequests = async (requestId: number) => {
    //get the requests using requestId
    //filter current data where requestId == updatedFulfilment request Id
    //if found, get index of found object from current data
    //update the current data by replacing the old data with new data
    let url = `${endpoints.SupportRequests.mainUrl}/${requestId}`;

    await axios
      .get(url)
      .then((response) => {
        const updatedData = response.data;

        const oldData = tableData.filter((x) => x.requestId === requestId)[0];
        const parsedOldData = oldData || null;
        const filteredData: any = [...tableData];

        if (parsedOldData !== null) {
          filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
        } else {
          filteredData.unshift(updatedData);
        }
        setTableData(filteredData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getTelemedicineRequests();
  }, [getTelemedicineRequests]);

  useEffect(() => {
    //use the request id to update
    const realTimeData = appState.supportRequests as IRealTimeUpdateProp;

    //all efforts to move this out of the useEffect was not possible without
    //resorting to suppressing es-lint warnings

    if (realTimeData != null) {
      const dataId = parseInt(realTimeData.dataId);
      getUpdatedRequests(dataId);
    }
  }, [appState.supportRequests]); // eslint-disable-line react-hooks/exhaustive-deps

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Date Created</th>
        <th scope='col'>Status</th>
        <th scope='col'>Phone</th>
        <th scope='col'>Name</th>
        <th scope='col'>Practitioner</th>
        <th scope='col'>Notes</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((request: ITelemedicineRequest) => {
        return (
          <tr key={request.requestId}>
            <td>Action</td>
            <td>{formatDate(request.dateCreated)}</td>
            <td>{request.status}</td>
            <td>{request.phoneNumber}</td>
            <td>
              {request.patientFirstName} {request.patientLastName}
            </td>
            <td>{request.practitionerName}</td>
            <td>{request.notes}</td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div>
      <div className='d-flex'>
        <h3 className=' mr-auto'>Telemedicine Requests</h3>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={getTelemedicineRequests}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default TelemedicineRequests;
