import axios from 'axios';
import { useState } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { validFileType } from '../../../utils/fileHelper';
import './styles.css';
import { IClaimUploadStatus } from '../../../interface/ClaimsInterface';

const ClaimsUploadForm = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<IClaimUploadStatus[] | undefined>();

  const uploadClaim = async (event: any) => {
    let fileUpload: any = document.getElementById('claim-file');

    let isFormValid = document.getElementById('claim-file');
    if (!isFormValid) {
      addToast('Select a file');
      return;
    }

    let uploadedFiles = fileUpload.files;

    if (uploadedFiles.length === 0) {
      addToast('No files selected. Please select a csv file', 'warning');
      return;
    }
    if (!validFileType(uploadedFiles[0])) {
      addToast('Wrong file. Upload text or csv file', 'warning');
      return;
    }

    if (uploadedFiles[0].size > 2097152) {
      //if exceeding 2mb
      addToast('Exceed file limit. Max limit is 2MB', 'warning');
      return;
    }

    event.preventDefault();

    const formData = new FormData();
    formData.append('claimList', uploadedFiles[0]);

    let url = `${endpoints.InsuranceClaims.mainUrl}/upload`;
    setUploadStatus(undefined);
    try {
      setIsUploading(true);
      const response = await axios.post(url, formData);
      setUploadStatus(response.data);
      if (response.data.length > 0) {
        addToast('Some claims were not uploaded successfully', 'warning');
      } else {
        addToast('Claims uploaded successfully', 'success');
      }
      //addT
    } catch (error: any) {
      addToast('Error occured. Check the format of the csv file', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <div className='row justify-content-center'>
        <form className='form m-4 col-md-6' id='upload-claims'>
          <h3 className='text-center mt-5'>Upload Claim List</h3>

          <fieldset
            id='form-fields'
            disabled={isUploading ? true : false}
            className={isUploading ? 'opacity-blend' : ''}
          >
            <div className='mt-4'>
              <label htmlFor='fileUpload' className='form-label'>
                Upload .csv file
              </label>
              <div>
                Please follow the format in this
                <a
                  target='blank'
                  className='mr-1 ml-1'
                  href='https://drive.google.com/file/d/1ZwkKOqhi4vEOb5tiDKXtbEFO1clhtdXW/view?usp=sharing'
                >
                  link
                </a>
                else, the file upload will fail
              </div>
              <br />

              <input id='claim-file' className='form-control' required type='file' accept='.csv' />
            </div>
            <br />
            <br />

            <button type='submit' className='btn btn-block btn-primary' onClick={uploadClaim}>
              Upload
              {isUploading ? <ButtonLoader /> : ''}
            </button>
          </fieldset>
        </form>
      </div>
      <div className='row justify-content-center mt-4'>
        <div className='col-md-10 table-responsive'>
          {uploadStatus && uploadStatus.length > 0 ? (
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <td>Phone number</td>
                  <td>Pharmacy code</td>
                  <td>Status</td>
                  <td>Message</td>
                </tr>
              </thead>
              <tbody>
                {uploadStatus.map((claim) => (
                  <tr>
                    <td>{claim.phoneNumber}</td>
                    <td>{claim.pharmacyCode}</td>
                    <td>{claim.isUploadSuccessful ? 'Success' : 'Failed'}</td>
                    <td>{claim.statusMessage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimsUploadForm;
