import React, { useContext, useEffect, useCallback, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { HomeTiles } from '../../../utils/mappings';
import { timeOfDayGreeting } from '../../../utils/dateTimeUtils';
import { Link } from 'react-router-dom';

const Home = () => {
  const { dispatch } = useContext(AppContext);
  const [user, setUser] = useState('');

  const updateCount = (id: string, count: number) => {
    for (var i = 0; i < HomeTiles.length; i++) {
      if (HomeTiles[i].id === id) {
        HomeTiles[i].count = count;
        return;
      }
    }
  };

  const getPendingFulfilmentCount = useCallback(async () => {
    try {
      await axios.get(endpoints.FulfilmentRequests.getPendingCount).then((response) => {
        const pendingCount = response.data;
        updateCount('fulfilment', pendingCount);
      });
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const getNewSubscriptionCount = useCallback(async () => {
    try {
      await axios
        .get(endpoints.InsuranceSubscriptions.getNewSubscriptionsCount)
        .then((response) => {
          const pendingCount = response.data;
          updateCount('subscription', pendingCount);
        });
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const getPendingClaimsCount = useCallback(async () => {
    try {
      await axios.get(endpoints.InsuranceClaims.getPendingClaimsCount).then((response) => {
        const pendingCount = response.data;
        updateCount('claim', pendingCount);
      });
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const getUnreadMessagesCount = useCallback(async () => {
    try {
      await axios.get(endpoints.InsuranceMessages.getUnreadMessagesCount).then((response) => {
        const pendingCount = response.data;
        updateCount('message', pendingCount);
      });
    } catch (error: any) {
      addToast('There was an error getting unread messages count', 'error');
    }
  }, []);

  const getPendingPayoutCount = useCallback(async () => {
    try {
      await axios.get(endpoints.WalletPayouts.getPendingCount).then((response) => {
        const pendingCount = response.data;
        updateCount('wallet', pendingCount);
      });
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const getUserInfo = useCallback(async () => {
    const url = `${process.env.REACT_APP_OIDC_AUTHORITY}/connect/userinfo`;
    try {
      await axios.get(url).then((response) => {
        let username = response.data.email.split('@')[0];
        username = username[0].toUpperCase() + username.slice(1);
        setUser(username);
      });
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const getPartnersList = useCallback(async () => {
    try {
      await axios.get(endpoints.FulfilmentPartners.mainUrl).then((response) => {
        const partnerList = response.data;
        dispatch({
          type: 'SET_PARTNER_LIST',
          payload: partnerList,
        });
      });
    } catch (error: any) {
      addToast('There was an error getting partner lists', 'error');
    }
  }, [dispatch]);

  const getPharmacyList = useCallback(async () => {
    try {
      const pharmacyListResult = await axios.get(endpoints.InsurancePharmacy.getPharmacyList);
      const listData = pharmacyListResult.data;
      dispatch({
        type: 'SET_PHARMACY_LIST',
        payload: listData,
      });
    } catch (error: any) {
      addToast('There was an error getting pharmacies list', 'error');
    }
  }, [dispatch]);

  useEffect(() => {
    getPartnersList();
  }, [getPartnersList]);

  useEffect(() => {
    getPharmacyList();
  }, [getPharmacyList]);

  useEffect(() => {
    getPendingFulfilmentCount();
  }, [getPendingFulfilmentCount]);

  useEffect(() => {
    getNewSubscriptionCount();
  }, [getNewSubscriptionCount]);

  useEffect(() => {
    getPendingClaimsCount();
  }, [getPendingClaimsCount]);

  useEffect(() => {
    getUnreadMessagesCount();
  }, [getUnreadMessagesCount]);

  useEffect(() => {
    getPendingPayoutCount();
  }, [getPendingPayoutCount]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <div>
      <h5>{timeOfDayGreeting(user)}</h5>
      {/* <br />
      <input
        type='text'
        className='form-control'
        placeholder='What will you like to do today. Search for a task e.g Add fulfilment'
      /> */}
      <br />
      <div className='container'>
        <div className='row'>
          {HomeTiles.map((tile, key) => (
            <div key={tile.link} className='wella-card card col-md-3'>
              <Link key={key} to={tile.link} className='position-relative'>
                <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white'>
                  {tile.count}
                </h3>
                <div className='card-body text-center'>
                  <h5 className='card-title'>
                    <div className={tile.icons} style={{ fontSize: '3rem', color: 'blue' }}></div>
                  </h5>
                  <h6 className='card-subtitle mb-2 text-muted'>{tile.title}</h6>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
