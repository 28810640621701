import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { UserNotes } from '../../../interface/UserNotesInterface';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import NotesModal from './NotesModal';

const Notes = ({
  noteTypeId,
  noteType,
  tabOpen,
  counter,
}: {
  noteTypeId: string;
  noteType: string;
  tabOpen: boolean;
  counter: number;
}) => {
  const [notes, setNotes] = useState<UserNotes[]>([]);
  const [showNoteModal, setShowNoteModal] = useState(false);

  const getUserNotes = useCallback(async () => {
    const url = `${endpoints.UserNotes.mainUrl}?noteTypeId=${noteTypeId}&noteType=${noteType}`;

    try {
      const response = await axios.get(url);
      setNotes(response.data);
    } catch (error: any) {
      addToast('Error getting fulfilment notes', 'error');
    } finally {
    }
  }, [noteTypeId, noteType]);

  const toggleNoteModal = () => {
    setShowNoteModal(!showNoteModal);
  };

  useEffect(() => {
    if (tabOpen) {
      getUserNotes();
    }
  }, [getUserNotes, tabOpen, counter]);

  let noteModal = (
    <NotesModal
      open={showNoteModal}
      close={toggleNoteModal}
      noteType={noteType}
      noteTypeId={noteTypeId}
      callback={getUserNotes}
    />
  );

  let noteCards = (
    <div className='row'>
      {notes?.map((note: UserNotes) => {
        return (
          <div className='col-md-10 mt-4'>
            <div className='card borderless rounded'>
              <div className='card-body'>
                <div className='row card-text subscription-card-text'>
                  <div className='col-sm-12 fw-bold'>{note.noteContent}</div>
                </div>
                <div className='row card-text subscription-card-text mt-4'>
                  <div className='col-6 fw-light fs-7'>Created by: {note.createdBy}</div>
                  <div className='col-6 text-end fw-light fs-7'>
                    Date: {formatDateTime(note.dateCreated)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div>
      {noteModal}
      <div className='row'>
        <button className='btn btn-primary ml-auto float-right' onClick={() => toggleNoteModal()}>
          Add note
        </button>
      </div>
      <br />
      <div className='row'>
        {!notes || notes.length === 0 ? (
          <div className='mt-5 text-center h3'>No Notes Found</div>
        ) : (
          <div className='col-12'>{noteCards}</div>
        )}
      </div>
    </div>
  );
};

export default Notes;
