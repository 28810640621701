/** 
Agnostic search input, accepts a data source in form  {label:string, value:string}
*/
import React, { useState, useRef } from 'react';
import { useOutsideAlerter } from './OutsideClick';
import { ITariffSuggestion } from '../../interface/PriceListInterface';
import { IPriceList } from '../../interface/PriceListInterface';
import { NairaSymbol } from '../../utils/constants';

const TariffSuggestion = (props: ITariffSuggestion) => {
  const [inputSearchResult, setInputSearchResult] = useState<IPriceList[]>(props.data);

  const [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const [showSearch, setShowSearch] = useState(false);

  const hideSearchResult = (event: any) => {
    setShowSearch(false);
  };

  const inputFocus = () => {
    setShowSearch(true);
  };

  /*To handle when we clck outside the search */

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideSearchResult);

  /* end click outside */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputSearch = event.target.value;
    setSearchTerm(inputSearch);
    searchData(searchTerm);
  };

  const searchData = (searchTerm: string) => {
    // if (searchTerm.length < 3) {
    //   return;
    // }

    const payload: IPriceList = {
      drugName: '',
      newDrugName: '',
      dosageForm: '',
      strength: '',
      dateUpdated: '',
      drugPrice: 0,
      unitPrice: 0,
      priceListId: 0,
      services: 'Telemedicine',
      genericName: '',
      source: '',
      notes: '',
    };

    setInputSearchResult([payload]);
    setShowSearch(true);

    try {
      const result = props.data.filter(
        (x) =>
          x.drugName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          x.newDrugName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())
      );
      setInputSearchResult(result);

      //if no result, trigger callback with undefined, so calling component can handle
      if (result.length === 0 && props.callback) {
        props.callback(props.drugId, payload);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const selectResult = (selectedResult: IPriceList) => {
    setSearchTerm(selectedResult.drugName);
    setShowSearch(false);

    //callback set, call the callback passing the selected patient data
    if (props.callback) {
      props.callback(props.drugId, selectedResult);
    }
  };

  return (
    <div>
      <label className='form-label'>Tariff Drug Name </label>
      <div className='form-group ' ref={wrapperRef} onFocus={inputFocus}>
        {/* onFocus={toggleSearchResult} onBlur={toggleSearchResult}> */}
        <span className='bi bi-search search-icon'></span>
        <input
          type='text'
          className={'form-control pl-5 pr-4'}
          placeholder='Search. Start typing drug name'
          onChange={handleChange}
          value={searchTerm}
          required={props.required}
          id={'tarrifDrug' + props.drugId}
        />
        {showSearch && inputSearchResult.length > 0 ? (
          <div className='search-result-panel'>
            <ul style={{ marginLeft: '-30px', backgroundColor: 'white' }}>
              {inputSearchResult.map((data) => (
                <li
                  key={data.priceListId}
                  className='search-result my-1'
                  onClick={() => selectResult(data)}
                >
                  <div className='w-100'>
                    {data.drugName} - {NairaSymbol}
                    {data.unitPrice.toLocaleString()}
                  </div>
                  <small className='fs-8 border-bottom w-100 d-block pb-1'>
                    <span className='text-info'>{data.newDrugName}</span> -
                    <span className='text-success'>{data.genericName}</span>
                  </small>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TariffSuggestion;
