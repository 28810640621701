import { IEditPriceList, IPriceList } from '../../../interface/PriceListInterface';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { PricelistFulfilmentServiceMap } from '../../../utils/mappings';

const PriceListEditModal = ({ open, close, modalData, callback, partnerCode }: IEditPriceList) => {
  const [pricelist, setPricelist] = useState<IPriceList | undefined>(modalData!);
  const [isBtnLoading, setBtnLoading] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setPricelist({
      ...pricelist!,
      [input]: event.target.value,
    });
  };

  const savePriceList = async (event: any) => {
    //validate form
    if (!isFormValidated('tariff-form')) {
      addToast('Please fill all forms', 'warning');
      return;
    }
    event.preventDefault();

    //set loader
    setBtnLoading(true);

    //call api
    const url = `${endpoints.Pricelist.mainUrl}/partner/${partnerCode}`;

    try {
      await axios.put(url, pricelist);
      addToast('Drug item edited successfully', 'success');

      close();
      if (callback) {
        callback(pricelist);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setPricelist(modalData);
    }
  }, [open, modalData]);

  return (
    <Modal show={open} onHide={() => close()} backdrop={isBtnLoading ? 'static' : true}>
      <Modal.Header closeButton={!isBtnLoading}>
        <Modal.Title>Editing the tariff Drugs for {pricelist?.drugName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body'>
        <form id='tariff-form'>
          <div className='form-group'>
            <label htmlFor='drugName' className='form-label'>
              Drug Name
            </label>
            <input
              value={pricelist?.drugName}
              className='form-control '
              type='text'
              required
              onChange={handleChange('drugName')}
            />
          </div>
          <br />

          <div className='form-group'>
            <label htmlFor='drugPrice' className='form-label'>
              Unit Price
            </label>
            <input
              value={pricelist?.unitPrice}
              className='form-control '
              type='number'
              onChange={handleChange('unitPrice')}
              min={0}
              step={0.01}
              required
            />
          </div>
          <br />

          <div className='form-group'>
            <label htmlFor='drugPrice' className='form-label'>
              Drug Price
            </label>
            <input
              value={pricelist?.drugPrice}
              className='form-control '
              type='number'
              required
              onChange={handleChange('drugPrice')}
              min={0}
              step={0.01}
            />
          </div>
          <br />
          <div className='form-group'>
            <label htmlFor='fulfilmentService' className='form-label'>
              Fulfilment Service
            </label>
            <select
              required
              onChange={handleChange('services')}
              value={pricelist?.services}
              className='custom-select'
            >
              <option value=''></option>
              {PricelistFulfilmentServiceMap.map((option) => (
                <option key={option.key} value={option.value.toString()}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <br />

          <div className='form-group'>
            <label htmlFor='notes' className='form-label'>
              Notes
            </label>
            <input
              value={pricelist?.notes}
              className='form-control '
              type='text'
              onChange={handleChange('notes')}
            />
          </div>
          <br />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='submit'
          className='btn  btn-primary'
          disabled={isBtnLoading}
          onClick={savePriceList}
        >
          Save
          {isBtnLoading ? <ButtonLoader /> : ''}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PriceListEditModal;
