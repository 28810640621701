import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { IBeneficiary } from '../../../interface/BeneficiaryInterface';
import { addToast } from '../../../utils/toastNotifications';
import AddEditBeneficiaryModal from './AddEditBeneficiaryModal';
import { formatDate } from '../../../utils/dateTimeUtils';
import { useParams, Link } from 'react-router-dom';

const BeneficiariesTable = () => {
  const { subscriptioncode } = useParams() as any;

  const initialState: IBeneficiary = {
    dateCreated: '',
    dateOfBirth: '',
    dateUpdated: '',
    firstName: '',
    lastName: '',
    location: '',
    lga: '',
    stateOfResidence: '',
    streetName: '',
    area: '',
    insuranceBeneficiaryId: 0,
    phone: '',
    email: '',
    gender: 'Female',
    isActive: true,
    subscriptionCode: '',
  };

  const [tableData, setTableData] = useState<IBeneficiary[]>([]);
  const [beneficiaryModalData, setBeneficiaryModalData] = useState(initialState);
  const [isBeneficiaryModalOpen, setBeneficiaryModalOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setBeneficiaryModalData({
      ...beneficiaryModalData,
      [input]: event.target.value,
    });
  };

  const openBeneficiaryModal = (data: IBeneficiary, edit: boolean) => {
    setBeneficiaryModalData(data);
    setEdit(edit);
    setBeneficiaryModalOpen(true);
  };

  const closeBeneficiaryModal = () => {
    setBeneficiaryModalOpen(false);
  };

  const getBeneficiaryList = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.InsuranceBeneficiary.mainUrl + subscriptioncode);
      setTableData(response.data);
    } catch (error: any) {
      addToast('Error getting beneficiary details', 'error');
    }
  }, [subscriptioncode]);

  useEffect(() => {
    getBeneficiaryList();
  }, [getBeneficiaryList]);

  let beneficiaryModal: JSX.Element = (
    <AddEditBeneficiaryModal
      open={isBeneficiaryModalOpen}
      close={closeBeneficiaryModal}
      modalData={beneficiaryModalData}
      getBeneficiaryData={getBeneficiaryList}
      handleChange={handleChange}
      isEdit={isEdit}
    />
  );

  let tableHead = (
    <thead>
      <tr>
        <th scope='col'>Action</th>
        <th scope='col'>Subscriber ID</th>

        <th scope='col'>First Name</th>

        <th scope='col'>Last Name</th>

        <th scope='col'>Phone</th>
        <th scope='col'>Email</th>

        <th scope='col'>State of Premise</th>

        <th scope='col'>Location</th>

        <th scope='col'>Date of Birth</th>
      </tr>
    </thead>
  );

  let tableBody = tableData.map((beneficiary) => {
    return (
      <tr key={beneficiary.insuranceBeneficiaryId}>
        <td>
          {/* <i
            className='bi bi-bar-chart-fill'
            style={{ cursor: 'pointer' }}
            onClick={() => openClaimModal(beneficiary)}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Open Claim'
          /> */}
          <Link to={`/subscriptions/${subscriptioncode}/claims/add`}>
            <i
              className='bi bi-plus-square-fill'
              style={{ cursor: 'pointer' }}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Add Claim'
            />
          </Link>
          <i
            className='bi bi-pencil-fill ml-2'
            style={{ cursor: 'pointer' }}
            onClick={() => openBeneficiaryModal(beneficiary, true)}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Edit Beneficiary'
          />
        </td>
        <td>{beneficiary.insuranceBeneficiaryId}</td>
        <td>{beneficiary.firstName}</td>
        <td>{beneficiary.lastName}</td>
        <td>{beneficiary.phone}</td>
        <td>{beneficiary.email}</td>
        <td>{beneficiary.stateOfResidence}</td>
        <td>{beneficiary.location}</td>
        <td>{formatDate(beneficiary.dateOfBirth)}</td>
      </tr>
    );
  });

  return (
    <>
      <div className='d-flex'>
        <h4 className='mr-auto'>Beneficiaries of Subscription</h4>
        <button
          className='btn btn-primary'
          onClick={() => openBeneficiaryModal(initialState, false)}
        >
          Add Beneficiary
        </button>
      </div>
      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          <tbody>{tableBody}</tbody>

          {/* {claimModal} */}
          {beneficiaryModal}
        </table>
      </div>
    </>
  );
};

export default BeneficiariesTable;
