import React from 'react';
import { INextOfKinModalProps } from '../../../interface/SubscriptionInterface';
import { Modal } from 'react-bootstrap';
import './styles.css';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';

const AddNextOfKinModal = (props: INextOfKinModalProps) => {
  const addNextOfKin = (event: any) => {
    if (!isFormValidated('add-nok-modal')) {
      addToast('Please fill all fields', 'warning');
      return;
    }
    event.preventDefault();
    props.addToPlan();
  };

  return (
    <Modal show={props.open} onHide={props.close}>
      <form id='add-nok-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Next of Kin information.</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <div className='col-md-12'>
            <label htmlFor='firstName' className='form-label'>
              First Name
            </label>
            <input
              value={props.modalData.firstName}
              required
              className='form-control '
              type='text'
              onChange={props.handleChange('firstName')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='lastName' className='form-label'>
              Last Name
            </label>
            <input
              value={props.modalData.lastName}
              required
              className='form-control '
              type='text'
              onChange={props.handleChange('lastName')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='phoneNumber' className='form-label'>
              Phone
            </label>
            <input
              value={props.modalData.phoneNumber}
              maxLength={13}
              pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
              className='form-control '
              type='tel'
              required
              onChange={props.handleChange('phoneNumber')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='email' className='form-label'>
              Email address
            </label>
            <input
              value={props.modalData.email}
              className='form-control '
              type='email'
              placeholder='name@example.com'
              onChange={props.handleChange('email')}
            />
          </div>
          <br />

          <div className='col-md-12'>
            <label htmlFor='relationship' className='form-label'>
              Relationship
            </label>
            <input
              value={props.modalData.relationship}
              required
              className='form-control '
              type='text'
              onChange={props.handleChange('relationship')}
            />
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button type='submit' className='btn btn-primary' onClick={addNextOfKin}>
            Add To Plan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddNextOfKinModal;
