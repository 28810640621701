import { useState } from 'react';
import { IHealthSendTest } from '../../../interface/HealthSendInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { BOOL_MAPPING, TESTRESULT } from '../../../utils/mappings';
import { Modal } from 'react-bootstrap';

const HealthSendTestModal = ({
  open,
  close,
  addTestToClaim,
}: {
  open: boolean;
  close: Function;
  addTestToClaim: Function;
}) => {
  const initialTestData: IHealthSendTest = {
    testId: 0,
    enrollmentTestId: 0,
    testName: '',
    isCarriedOut: false,
    testResult: '',
    cost: 0,
    tariffCost: 0,
    requestId: 0,
    fileName: '',
    notes: '',
    pharmacyCode: '',
    isPharmacyPaid: false,
    hasPartnerPaid: false,
  };

  // const [isLoading, setLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [testData, setTestData] = useState<IHealthSendTest>(initialTestData);

  const saveData = async () => {
    //validations:
    if (!isFormValidated('tests-form')) {
      addToast('Please fill all required fields', 'warning');
      return;
    }

    if (testData.isCarriedOut && testData.cost <= 0) {
      addToast('Please add cost to tests carried out', 'warning');
      return;
    }

    setBtnLoading(true);

    await addTestToClaim(testData);
    setTestData(initialTestData);

    addToast('Test added', 'success');

    setBtnLoading(false);
  };

  // const uploadFiles = async (formFile: any) => {
  //   const result = {
  //     fileId: 0,
  //     fileName: '',
  //     fileUrl: '',
  //   };

  //   const formData = new FormData();
  //   formData.append('file', formFile);
  //   formData.append('file_use', 'test_result');

  //   try {
  //     const response = await axiosInstance.post(endpoints.Reliance.uploadFiles, formData, {
  //       auth: {
  //         username: relianceUsername as string,
  //         password: reliancePassword as string,
  //       },
  //     });

  //     const responseData = response.data;

  //     if (responseData.status.includes('success')) {
  //       result.fileId = responseData.data.file_id;
  //       result.fileName = responseData.data.filename;
  //       result.fileUrl = responseData.data.file_url;
  //     } else {
  //       throw Error();
  //     }
  //   } catch (error: any) {
  //     addToast('Could not upload file', 'error');
  //   }

  //   return result;
  // };

  //https://stackoverflow.com/a/55988040/2929906
  //https://stackoverflow.com/a/55788298/2929906

  const handleChange = (input: string) => (event: any) => {
    setTestData({
      ...testData,
      [input]: event.target.value,
    });
  };

  return (
    <Modal show={open} onHide={() => close()} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#1489C8' }}>Add Test</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form id='tests-form' className=''>
            <fieldset disabled={isBtnLoading}>
              <div className='card' style={{ padding: '10px', width: '100%' }}>
                <div className='form-group'>
                  <label htmlFor='name' className='form-label'>
                    Test Name
                  </label>
                  <input
                    value={testData.testName}
                    required
                    onChange={handleChange('testName')}
                    className='form-control '
                    type='text'
                  />
                </div>
                <br />

                <div className='form-group'>
                  <label htmlFor='isCarriedOut' className='form-label'>
                    Is Test Carried Out?
                  </label>
                  <select
                    onChange={handleChange('isCarriedOut')}
                    value={testData.isCarriedOut.toString()}
                    className='custom-select'
                  >
                    {BOOL_MAPPING.map((option) => (
                      <option key={option.key} value={option.value.toString()}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                <br />

                <div className='form-group'>
                  <label htmlFor='testResult' className='form-label'>
                    Test Result
                  </label>
                  <select
                    onChange={handleChange('testResult')}
                    value={testData.testResult.toString()}
                    className='custom-select'
                  >
                    {TESTRESULT.map((option) => (
                      <option key={option.key} value={option.value.toString()}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                <br />

                <div className='form-group'>
                  <label className='form-label'>Cost of Test</label>
                  <input
                    value={testData.cost}
                    min={testData.isCarriedOut ? 1 : 0}
                    step={0.01}
                    required={testData.isCarriedOut}
                    className='form-control '
                    type='number'
                    onChange={handleChange('cost')}
                  />
                </div>
                <br />

                {/* <div className='form-group'>
                      <label>Upload file</label>

                      <input
                        type='file'
                        accept='.jpg, .jpeg, .png, .gif, .pdf'
                        onChange={handleChange('fileName')}
                      />
                    </div> */}
                <br />

                <div className='form-group'>
                  <label htmlFor='name' className='form-label'>
                    Notes
                  </label>
                  <input
                    value={testData.notes}
                    className='form-control'
                    type='text'
                    onChange={handleChange('notes')}
                  />
                </div>
                <br />

                <br />
              </div>
            </fieldset>
          </form>
          <button
            type='button'
            className='btn btn-primary mt-5 '
            disabled={isBtnLoading}
            onClick={saveData}
          >
            Save
            {isBtnLoading ? <ButtonLoader /> : ''}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HealthSendTestModal;
