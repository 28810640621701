import { IBeneficiaryList } from './BeneficiaryInterface';
import { InsuranceClaimDrug, InsuranceClaimTest } from './ClaimsInterface';

export interface IAXALookupResult {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  policyStartDate: string;
  policyEndDate: string;
  localGovernment: string;
  address: string;
  dependants: Array<IBeneficiaryList>;
  isSuccessful: boolean;
  message: string;
}

export interface IAXALookup {
  trackingNumber: string;
  trackingType: string; //TrackingNumber, PhoneNumber
}

export interface IAXAClaimDTO {
  trackingNumber: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  location: string;
  emailAddress: string;
  claimDate: string;
  pharmacyCode: string;
  pharmacyName: string;
  notes: string;
  claimStatus: string;
  drugs: Array<InsuranceClaimDrug>;
  tests: Array<InsuranceClaimTest>;
}

export const initialAXALookupData: IAXALookupResult = {
  phoneNumber: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  policyStartDate: '',
  policyEndDate: '',
  localGovernment: '',
  address: '',
  dependants: [],
  isSuccessful: false,
  message: '',
};

export const initialAxaLookup: IAXALookup = {
  trackingNumber: '',
  trackingType: 'PhoneNumber',
};
