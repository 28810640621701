import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import Select from 'react-select';
import axios from 'axios';
import {
  // IWalletAccountDTO,
  IWalletAccountModal,
  IBankList,
} from '../../../interface/WalletsInterface';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';

const AddWalletAccountModal = ({
  open,
  close,
  modalData,
  edit,
  newPharmacy,
  addWallet,
}: IWalletAccountModal) => {
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState(modalData);
  // const [walletAccounts, setWalletAccounts] = useState<IWalletAccountDTO[]>([]);
  const [pharmacyListSelect, setPharmacyListSelect] = useState([]);
  // const [walletListSelect, setWalletListSelect] = useState([]);
  const [bankList, setBankList] = useState<IBankList[]>([]);
  const [pharmacyDetails, setPharmacyDetails] = useState({
    pharmacyName: '',
    pharmacyCode: '',
  });

  const selectChange = (selectedValue: any) => {
    setPharmacyDetails({
      ...pharmacyDetails,
      pharmacyCode: selectedValue.value,
      pharmacyName: selectedValue.label,
    });
  };

  const bankNameChange = (event: any) => {
    const selectedCode = event.target.value;
    const selectedBank = bankList.filter((x) => x.code === selectedCode)[0];

    setWalletData({
      ...walletData,
      bankCode: selectedBank.code,
      bankName: selectedBank.name,
    });
  };

  // const selectWalletChange = (selectedValue: any) => {
  //   setWalletData({
  //     ...walletData,
  //     accountId: selectedValue.value,
  //   });

  //   let existingWallet = walletAccounts.filter((x) => x.accountId === selectedValue.value)[0];
  //   if (existingWallet !== null && typeof existingWallet !== 'undefined') {
  //     setWalletData(existingWallet);
  //   }
  // };

  const getBankList = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.WalletAccounts.getBankList);

      setBankList(response.data);
    } catch (error: any) {
      addToast('Error getting bank list', 'error');
    }
  }, []);

  const validateAccount = async () => {
    let accountName = '';
    if (
      walletData.bankAccountNumber === '' ||
      walletData.bankCode === '' ||
      walletData.bankAccountNumber.length !== 10
    ) {
      addToast('Please input bank account number and/or bank name');
      setLoading(false);
      return accountName;
    }

    const payload = {
      accountNumber: walletData.bankAccountNumber,
      accountBank: walletData.bankCode,
    };
    try {
      const response = await axios.post(endpoints.WalletAccounts.resolveAccount, payload);
      setWalletData({
        ...walletData,
        bankAccountName: response.data.accountName,
      });
      accountName = response.data.accountName;
    } catch (error: any) {
      return '';
    } finally {
      setLoading(false);
    }
    return accountName;
  };

  const saveAccount = async (event: any) => {
    if (!isFormValidated('wallet-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }
    event.preventDefault();

    setLoading(true);
    //validate account name
    const result = await validateAccount();

    setWalletData({
      ...walletData,
      bankAccountName: result as string,
    });

    if (result === '') {
      addToast('Please validate your account number before submitting');
      return;
    }

    if (newPharmacy && typeof addWallet !== 'undefined') {
      addWallet(walletData, result);
      close();
      return;
    }

    walletData.providerCode = pharmacyDetails.pharmacyCode;
    walletData.providerName = pharmacyDetails.pharmacyName;
    walletData.bankAccountName = result;

    //setLoading(true);

    let url = endpoints.WalletAccounts.mainUrl;
    let message = 'Wallet added successfully';
    let errorMessage = 'Error adding wallet';

    try {
      await axios.post(url, walletData);
      addToast(message, 'success');
      close();
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateAccount = async (event: any) => {
    if (!isFormValidated('wallet-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }
    event.preventDefault();
    setLoading(true);

    const result = await validateAccount();

    setWalletData({
      ...walletData,
      bankAccountName: result as string,
    });

    walletData.bankAccountName = result as string;

    if (result === '') {
      addToast('Please validate your account number before submitting');
      return;
    }

    let url = endpoints.WalletAccounts.mainUrl;
    let message = 'Wallet updated successfully';
    let errorMessage = 'Error updating wallet';

    //walletData.providerCode = newPharmacyCode;

    try {
      await axios.put(url, walletData);
      addToast(message, 'success');
      close();
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setWalletData({
      ...walletData,
      [input]: event.target.value,
    });
  };

  const getPharmacyList = useCallback(async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/list`;

    try {
      const response = await axios.get(url);
      const pharmacyList = response.data;

      const newList = pharmacyList.map((pharmacy: any) => {
        return {
          label: pharmacy.pharmacyName,
          value: pharmacy.pharmacyCode,
        };
      });
      setPharmacyListSelect(newList);
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getPharmacyList();
    getBankList();
  }, [getPharmacyList, getBankList]);

  useEffect(() => {
    if (open) {
      setWalletData(modalData);
      // setNewPharmacyCode(modalData.providerCode);
    }
  }, [open, modalData]);

  // useEffect(() => {
  //   validateAccount();
  //   //walletData.bankAccountNumber, walletData.bankCode]);
  // }, [walletData.bankCode]);

  // useEffect(() => {
  //   const loadExistingWalletList = async () => {
  //     await axios
  //       .get(endpoints.Wallets.accounts)
  //       .then((response) => {
  //         setWalletAccounts(response.data);

  //         //https://stackoverflow.com/a/34199330/2929906
  //         let distinctWallets = response.data.filter(
  //           (value: IWalletAccountDTO, index: number, self: IWalletAccountDTO[]) =>
  //             self.map((x) => x.accountId).indexOf(value.accountId) === index
  //         );

  //         let selectData = distinctWallets.map((val: IWalletAccountDTO, key: number) => {
  //           return {
  //             label: `${val.providerName} (${val.bankAccountName} - ${val.bankAccountNumber} )`,
  //             value: val.accountId,
  //           };
  //         });

  //         setWalletListSelect(selectData);
  //       })
  //       .catch((error) => {
  //         addToast('Error fetching wallets', 'error');
  //       });
  //   };
  //   if (open) {
  //     loadExistingWalletList();
  //   }
  // }, [open]);

  return (
    <Modal show={open} onHide={close}>
      <form id='wallet-modal'>
        <Modal.Header closeButton>
          <Modal.Title>
            {edit ? `Edit Wallet Account for ${walletData.providerName}` : 'Add Wallet Account'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={loading}>
            {!newPharmacy ? (
              <div className='form-group'>
                <label>Pharmacy Name</label>
                <Select
                  options={pharmacyListSelect}
                  onChange={selectChange}
                  className='react-select'
                  classNamePrefix='react-select'
                  isDisabled={newPharmacy || edit}
                  isLoading={pharmacyListSelect.length === 0}
                  inputValue={edit ? walletData.providerName : undefined}
                />
              </div>
            ) : (
              ''
            )}
            {/* <div className='form-group'>
              <label>Use Existing Wallet</label>
              <Select
                options={walletListSelect}
                onChange={selectWalletChange}
                className='react-select'
                classNamePrefix='react-select'
                isDisabled={edit}
              />
            </div> */}
            <div className='form-group'>
              <label>Discount</label>
              <input
                type='number'
                min={0}
                max={99.99}
                step={0.01}
                className='form-control'
                required={walletData.accountId === '' ? true : false}
                disabled={walletData.accountId === '' || edit ? false : true}
                value={walletData.discount}
                onChange={handleChange('discount')}
              />
            </div>

            <div className='form-group'>
              <label>Account Number</label>
              <input
                type='text'
                minLength={10}
                maxLength={10}
                className='form-control'
                required={walletData.accountId === '' ? true : false}
                disabled={walletData.accountId === '' || edit ? false : true}
                value={walletData.bankAccountNumber}
                onChange={handleChange('bankAccountNumber')}
                //onBlur={validateAccount}
              />
            </div>
            <div className='form-group'>
              <label>Bank Name</label>

              {walletData.accountId === '' || edit ? (
                <select
                  className='custom-select'
                  value={walletData.bankCode}
                  onChange={bankNameChange}
                  disabled={walletData.accountId === '' || edit ? false : true}
                >
                  {bankList.map((bank) => (
                    <option key={bank.id} value={bank.code}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type='text'
                  minLength={10}
                  maxLength={10}
                  className='form-control'
                  disabled={true}
                  value={walletData.bankName}
                />
              )}
            </div>

            <div className='form-group'>
              <label>Account Name</label>
              <input
                type='text'
                className='form-control'
                disabled={true}
                value={walletData.bankAccountName}
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          {edit ? (
            <button className='btn btn-primary' onClick={updateAccount}>
              Update {loading ? <ButtonLoader /> : ''}
            </button>
          ) : (
            <button className='btn btn-primary' onClick={saveAccount}>
              Submit {loading ? <ButtonLoader /> : ''}
            </button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddWalletAccountModal;
