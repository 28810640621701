import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
// Component imports
import Login from './components/auth/Login';
import SubscriptionTable from './components/pages/subscription/SubscriptionTable';
import PharmacyTable from './components/pages/pharmacy/PharmacyTable';
import Home from './components/pages/home/Home';

import PaymentTable from './components/pages/payment/PaymentTable';
import Layout from './components/layout/Layout';
import PartnersTable from './components/pages/partners/PartnersTable';
import WalletTransactionsTable from './components/pages/wallet_transactions/WalletTransactionsTable';
import SupportRequestsTable from './components/pages/support_requests/SupportRequestsTable';
import AddSubscriptionForm from './components/pages/subscription/AddSubscriptionForm';
import BeneficiariesTable from './components/pages/beneficiary/BeneficiariesTable';
import AddClaimForm from './components/pages/subscription/AddClaimForm';
import LeadsTable from './components/pages/leads/LeadsTable';
import Messages from './components/pages/messages/Messages';
import ExportFulfilmentData from './components/pages/fulfilments/ExportFulfilmentData';
import PriceListTable from './components/pages/pricelist/PriceListTable';
import RefreshToast from './components/common/RefreshToast';
import FulfilmentPartnersTable from './components/pages/fulfilment_partners/FulfilmentPartnersTable';
import ClaimsTable from './components/pages/claims/ClaimsTable';
import WalletAccountsTable from './components/pages/wallets/WalletAccountsTable';
import PriceListingForm from './components/pages/pricelist/PriceListingForm';
import NotificationTable from './components/pages/notifications/NotificationTable';
import AXALookup from './components/pages/axa_lookup/AXALookup';
import ZoiOrdersTable from './components/pages/zoi/ZoiOrdersTable';
import ZoiUsersTable from './components/pages/zoi/ZoiUsersTable';
import LoanApplicationTable from './components/pages/loans/LoanApplicationTable';
import LoanRepaymentTable from './components/pages/loans/LoanRepaymentTable';
import AgentsTable from './components/pages/agents/AgentsTable';
import AddPharmacyForm from './components/pages/pharmacy/AddPharmacyForm';
import AddHospicashClaimForm from './components/pages/subscription/AddHospicashClaimForm';

import ErrorBoundary from './utils/ErrorBoundary';

// stylesheet imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';
import './App.css';

// Other imports
import Callback from './components/auth/Callback';
import { AuthProvider } from 'react-oidc-context';
//import { WebStorageStateStore } from 'oidc-client';
import localforage from 'localforage';
import FulfilmentDetails from './components/pages/fulfilments/FulfilmentDetails';
import PharmacyPriceList from './components/pages/pricelist/PharmacyPriceList';
import SubscriptionDetails from './components/pages/subscription/SubscriptionDetails';
import TelemedicineRequests from './components/pages/telemedicine/TelemedicineRequests';
import ClaimsUploadForm from './components/pages/claims/ClaimsUploadForm';
import PatientRequestTable from './components/pages/patient_requests/PatientRequestTable';
import FulfilmentsTableTabs from './components/pages/fulfilments/FulfilmentsTableTabs';
import HmoReportForm from './components/pages/misc/HmoReports';
import { HealthSendUsersTable } from './components/pages/healthsend/HealthSendUsersTable';
import { HealthSendRequestsTable } from './components/pages/healthsend/HealthSendRequestsTable';
import { HealthSendUserDetails } from './components/pages/healthsend/HealthSendUserDetails';
import { AddHealthSendBeneficiary } from './components/pages/healthsend/AddHealthSendBeneficiary';
import { AddHealthSendRequest } from './components/pages/healthsend/AddHealthSendRequest';
import { AddHealthSendClaims } from './components/pages/healthsend/AddHealthSendClaims';
import WalletPayoutsTable from './components/pages/wallet_payouts/WalletPayoutsTable';
import PharmacyMapSidebar from './components/pages/fulfilments/PharmacyMapSidebar';
import AddFulfilmentFormNew from './components/pages/add_fulfilment/AddFulfilmentFormNew';
import FulfilmentDrafts from './components/pages/fulfilment_drafts/FulfilmentDrafts';
import AddFulfilmentDraft from './components/pages/fulfilment_drafts/AddFulfilmentDraft';
import VeriClaimJobList from './components/pages/vericlaim/VeriClaimJobList';
import AddNewVeriClaimJob from './components/pages/vericlaim/AddNewVeriClaimJob';
import PriceChecker from './components/pages/pricechecker/PriceChecker';

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENTID,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
  response_type: 'code',
  scope: 'openid profile email roles',
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_POSTLOGOUT_URL,
  filterProtocolClaims: true,
  //userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_OIDC_RENEWAL_URL,
  revokeAccessTokenOnSignout: true,
};
// //adds the context to window so we can access anywhere
declare global {
  interface Window {
    __react_toast_provider: any;
  }
}

window.__react_toast_provider = React.createRef();
// create a default container so we can override the styles
const ToastContainer = (props: any) => (
  <DefaultToastContainer style={{ zIndex: '1900' }} {...props} />
);

// local forage config
localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'Admin Portal',
  version: 3,
  storeName: 'Price List',
  description: 'Price list for each HMO',
});

const App = (): JSX.Element => {
  return (
    <AuthProvider {...oidcConfig}>
      <ToastProvider components={{ ToastContainer }} ref={window.__react_toast_provider}>
        <AppProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <Switch>
                <Route exact path='/' component={Login} />
                <Route exact path='/signin-callback' component={Callback} />
                <Layout exact path='/home' component={Home} />
                <Layout exact path='/partners' component={PartnersTable} />
                <Layout exact path='/subscriptions/add' component={AddSubscriptionForm} />
                <Layout exact path='/subscriptions' component={SubscriptionTable} />
                <Layout exact path='/subscriptions/:partnercode' component={SubscriptionTable} />
                <Layout
                  exact
                  path='/subscriptions/:subscriptioncode/beneficiaries'
                  component={BeneficiariesTable}
                />
                <Layout exact path='/pharmacies' component={PharmacyTable} />
                <Layout exact path='/pharmacies/map' component={PharmacyMapSidebar} />

                <Layout exact path='/wallets/payouts' component={WalletPayoutsTable} />
                <Layout exact path='/wallets/:accountId/payouts' component={WalletPayoutsTable} />
                <Layout
                  exact
                  path='/wallets/:accountId/transactions'
                  component={WalletTransactionsTable}
                />
                <Layout exact path='/support-requests' component={SupportRequestsTable} />
                <Layout exact path='/claims/upload' component={ClaimsUploadForm} />

                <Layout exact path='/claims/:subscriptionCode' component={ClaimsTable} />
                <Layout exact path='/claims' component={ClaimsTable} />

                <Layout exact path='/received-payments' component={PaymentTable} />
                <Layout
                  exact
                  path='/subscriptions/:subscriptionCode/:beneficiaryId/claims/add'
                  component={AddClaimForm}
                />
                <Layout
                  exact
                  path='/subscriptions/:subscriptionCode/:beneficiaryId/claims/hospicash/add'
                  component={AddHospicashClaimForm}
                />
                <Layout exact path='/subscriptions/claims/add' component={AddClaimForm} />
                {/* <Layout exact path='/leads' component={LeadsTable} /> */}

                <Layout
                  exact
                  path='/subscriptions/:subscriptionCode/details'
                  component={SubscriptionDetails}
                />
                <Layout exact path='/messages' component={Messages} />
                <Layout exact path='/fulfilments/export' component={ExportFulfilmentData} />
                <Layout exact path='/tariff' component={PriceListTable} />
                <Layout exact path='/tariff/add' component={PriceListingForm} />
                <Layout exact path='/tariff/:pricelistId/edit' component={PriceListingForm} />
                <Layout exact path='/pharmacypricing' component={PharmacyPriceList} />
                <Layout exact path='/pharmacypricing/check' component={PriceChecker} />
                <Layout exact path='/fulfilments/add' component={AddFulfilmentFormNew} />
                <Layout exact path='/fulfilments/drafts/add' component={AddFulfilmentDraft} />
                <Layout exact path='/fulfilments/drafts' component={FulfilmentDrafts} />
                <Layout exact path='/fulfilments/partners' component={FulfilmentPartnersTable} />

                <Layout exact path='/vericlaim/new' component={AddNewVeriClaimJob} />
                <Layout exact path='/vericlaim/report' component={VeriClaimJobList} />

                <Layout exact path='/fulfilments/partners/report' component={HmoReportForm} />

                <Layout exact path='/fulfilments' component={FulfilmentsTableTabs} />
                <Layout
                  exact
                  path='/fulfilments/:requestId/details'
                  component={FulfilmentDetails}
                />
                <Layout exact path='/wallets' component={WalletAccountsTable} />
                <Layout exact path='/notifications' component={NotificationTable} />
                <Layout exact path='/axa-lookup' component={AXALookup} />
                <Layout exact path='/zoi/orders' component={ZoiOrdersTable} />
                <Layout exact path='/zoi/orders/:zoiUserId' component={ZoiOrdersTable} />
                <Layout exact path='/zoi/users' component={ZoiUsersTable} />
                <Layout exact path='/loans/applications' component={LoanApplicationTable} />
                <Layout
                  exact
                  path='/loans/:applicationId/repayments'
                  component={LoanRepaymentTable}
                />
                <Layout exact path='/agents' component={AgentsTable} />
                <Layout exact path='/pharmacies/add' component={AddPharmacyForm} />
                <Layout exact path='/telemedicine-requests' component={TelemedicineRequests} />
                <Layout
                  exact
                  path='/fulfilments/patient-requests'
                  component={PatientRequestTable}
                />
                <Layout exact path='/healthsend/users' component={HealthSendUsersTable} />
                <Layout
                  exact
                  path='/healthsend/users/:userId/details'
                  component={HealthSendUserDetails}
                />
                <Layout exact path='/healthsend/requests' component={HealthSendRequestsTable} />
                <Layout
                  exact
                  path='/healthsend/requests/:userId'
                  component={HealthSendRequestsTable}
                />
                <Layout exact path='/leads' component={LeadsTable} />
                <Layout
                  exact
                  path='/healthsend/users/:userId/beneficiaries/new'
                  component={AddHealthSendBeneficiary}
                />
                <Layout
                  exact
                  path='/healthsend/users/:userId/request/new'
                  component={AddHealthSendRequest}
                />
                <Layout
                  exact
                  path='/healthsend/user/:userId/claims/new'
                  component={AddHealthSendClaims}
                />
              </Switch>
              <RefreshToast />
            </BrowserRouter>
          </ErrorBoundary>
        </AppProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;
