import { useState, useEffect, Fragment } from 'react';
import { addToast } from '../../../utils/toastNotifications';
import { IAlternativePriceList, IPriceList } from '../../../interface/PriceListInterface';
import { Modal } from 'react-bootstrap';
import localforage from 'localforage';

const AlternativeDrugsModal = ({
  open,
  close,
  pricelistId,
  partnerCode,
  quantity,
  fulfilmentService,
  drugId,
  callback,
}: IAlternativePriceList) => {
  const [priceList, setPriceList] = useState<IPriceList[]>([]);
  const [selectedPricelist, setSelectedPricelist] = useState<IPriceList | undefined>();

  const selectAlternative = (drugPricelistId: number, drugName: string) => {
    if (callback) {
      callback(drugId, drugName, quantity, drugPricelistId);
      close();
    }
  };

  useEffect(() => {
    //load all pricelist from session where source is partner
    const loadPricelist = () => {
      const priceListName = `pricelist-${partnerCode}`;
      let selectedDrugPricelist: IPriceList;
      // get price list from indexDB
      localforage
        .getItem(priceListName)
        .then((value) => {
          const savedPricelist: any = value;
          if (savedPricelist) {
            const parsedPriceList: IPriceList[] = savedPricelist;

            let partnerPriceList = parsedPriceList.filter((x) => x.source.includes(partnerCode));

            selectedDrugPricelist = partnerPriceList?.filter(
              (x) => x.priceListId === pricelistId
            )[0];

            setSelectedPricelist(selectedDrugPricelist);
            //get alternatives
            if (
              selectedDrugPricelist.genericName !== null &&
              typeof selectedDrugPricelist.genericName !== 'undefined'
            ) {
              const alternatives = partnerPriceList.filter(
                (x) => x.genericName === selectedDrugPricelist.genericName
              );

              setPriceList(alternatives);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          addToast('Error getting price list', 'error');
        });
    };
    if (open) {
      loadPricelist();
    }
  }, [open, fulfilmentService, partnerCode, pricelistId]);

  return (
    <Fragment>
      <Modal show={open} onHide={() => close()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Alternative medications for {selectedPricelist?.drugName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body table-responsive'>
          {priceList && priceList.length > 0 ? (
            <table className='table'>
              <thead>
                <tr>
                  <th>Drug name</th>
                  <th>Unit price</th>
                  <th>Cost of {quantity} units</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {priceList.map((x) => (
                  <tr>
                    <td>{x.drugName}</td>
                    <td>{x.unitPrice}</td>
                    <td>{(x.unitPrice * quantity).toFixed(2)}</td>
                    <td>
                      <button
                        className='btn btn-sm btn-primary'
                        onClick={() => selectAlternative(x.priceListId, x.drugName)}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No alternatives</div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default AlternativeDrugsModal;
