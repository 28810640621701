import React, { useState } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import { BOOL_MAPPING, PARTNER_MAPPING } from '../../../utils/mappings';
import { IAddPartnerModal } from '../../../interface/PartnerInterface';
import ButtonLoader from '../../common/ButtonLoader';

const AddPartnerModal = ({
  open,
  close,
  modalData,
  handleChange,
  getPartners,
}: IAddPartnerModal) => {
  const [isLoading, setLoading] = useState(false);

  const savePartner = async (event: any) => {
    let url = endpoints.InsurancePartners.mainUrl;
    let message = 'Partner Successfully Created';
    let errorMessage = 'Error Creating Partner';

    if (!isFormValidated('partner-modal')) {
      //addToast('Fill in all required fields', 'warning');
      return;
    }
    event.preventDefault();

    setLoading(true);
    try {
      const response = await axios.post(url, modalData);
      if (response.status === 200) {
        addToast(message, 'success');
        await getPartners();
        close();
      }
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={close}>
      <form id='partner-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add New Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Partner Name
              </label>
              <input
                value={modalData.partnerName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('partnerName')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Contact Name
              </label>
              <input
                value={modalData.contactName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('contactName')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Phone</label>
              <input
                value={modalData.phoneNumber}
                maxLength={13}
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                minLength={11}
                required
                className='form-control '
                type='text'
                onChange={handleChange('phoneNumber')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Email address</label>
              <input
                value={modalData.contactEmail}
                className='form-control '
                type='email'
                placeholder='name@example.com'
                onChange={handleChange('contactEmail')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Location</label>
              <input
                value={modalData.location}
                required
                className='form-control '
                type='text'
                onChange={handleChange('location')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Partner Type</label>
              <select
                required
                onChange={handleChange('partnerType')}
                value={modalData.partnerType}
                className='custom-select'
              >
                {PARTNER_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Active</label>
              <select
                required
                onChange={handleChange('isActive')}
                value={modalData.isActive.toString()}
                className='custom-select'
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Send Api Keys?</label>
              <select
                required
                onChange={handleChange('sendApiKeys')}
                value={modalData.sendApiKeys.toString()}
                className='custom-select'
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='submit'
            className='btn btn-primary'
            onClick={savePartner}
            disabled={isLoading}
          >
            Submit
            {isLoading ? <ButtonLoader /> : ' '}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddPartnerModal;
