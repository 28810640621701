import { RatingServiceType } from '../utils/mappings';
import { GenericDetailsModalProps } from './GenericInterface';
import { IPharmacyRatingDTO } from './PharmacyRatingInterface';
import { IPharmacyWallet } from './WalletsInterface';

export interface IPharmaciesData {
  pharmacyId: number;
  pharmacyName: string;
  pharmacyCode: string;
  phoneNumber: string;
  email: string;
  stateOfPremise: string;
  lga: string;
  locationArea: string;
  locationLongitude: string;
  locationLatitude: string;
  pharmacyAddress: string;
  googleAddress: string;
  postalCode: string;
  plusCode: string;
  streetName: string;
  streetNumber: string;
  placeId?: string;
  contacts: string;
  status: string;
  accountId: string;
  payInstantly: boolean;
  walletAccount: IPharmacyWallet | null;
  // features: IPharmacyFeature | null;
  relationshipOfficer: string;
  providerType: string;
  clientId: number;
  clientContact: string;
  //local use
  showNote: boolean;
}

export interface IPharmacySharedDTO {
  pharmacyName: string;
  phoneNumber: string;
  email: string;
  contacts: string;
  clientId: number;
  clientContact: string;
  status: string;
  accountId?: string;
  relationshipOfficer: string;
  providerType: string;
  payInstantly: boolean;
  //local use
  showNote: boolean;
  addressInformation?: IPharmacyAddressDTO;
}

export interface IPharmacyAddressDTO {
  pharmacyCode?: string;
  pharmacyAddress: string;
  googleAddress: string;
  stateOfPremise: string;
  lga: string;
  locationArea: string;
  longitude: string;
  latitude: string;
  streetNumber?: string;
  streetName?: string;
  postalCode?: string;
  plusCode?: string;
  placeId?: string;
}

export interface IPharmacyData extends IPharmacySharedDTO {
  pharmacyId: number;
  pharmacyCode: string;
}

export interface IPharmacyPostData extends IPharmacySharedDTO {
  walletAccount: IPharmacyWallet | null;
}

export interface IPharmacyUpdateData extends IPharmacySharedDTO {
  pharmacyId: number;
  pharmacyCode: string;
}

export interface IAddEditPharmacy {
  open: boolean;
  close: () => void;
  modalData: IPharmacyData;
  getPharmacies: () => void;
}

export interface IPharmacyList {
  pharmacyName: string;
  pharmacyCode: string;
  address: string;
  pharmacyId: number;
}

export interface IPharmacyListData {
  pharmacyId: number;
  pharmacyCode: string;
  pharmacyName: string;
  address: string;
  pharmacyStatus: string;
}

export interface IPharmacyMapData {
  pharmacyName: string;
  address: string;
  geolocation: any;
  isWellaPharmacy: boolean;
  status: 'Active' | 'InActive' | 'Cancelled';
  rankId: number;
}

export interface PharmacyDetailsModalProps extends GenericDetailsModalProps {
  pharmacyCode: string;
}

export interface IPharmacyListProp {
  value: string;
  label: string;
}

export interface IPharmacyFeature {
  rdtCost: string;
  rdtBrand: string;
  malariaDrug: string;
  malariaDrugCost: string;
  isPharmacyTrained: string;
}

export interface IPharmacyWalletDTO {
  pharmacyName: string;
  accountId: string;
  payInstantly: boolean;
}

export interface IPharmacyAddressingDTO {
  pharmacyName: string;
  pharmacyCode: string;
  address: string;
  pharmacyId: string;
  pharmacyStatus: string;
  addressInformation: IPharmacyAddressDTO;
}

export interface PharmacyClientDTO {
  clientId: string;
  pharmacyName: string;
  pharmacyCode: string;
  pharmacyId: string;
}

export interface IPharmacyClientGroup {
  clientName: string;
  clientId: string;
  pharmacies: PharmacyClientDTO[];
}

export interface IGoogleInputProps {
  inputString: string;
  changeCallback?: Function; //(value: string) => void;
  //selectCallback?: Function;
  selectCallback?: Function; //(selectedAddress: IAddressComponent) => void;
}
export interface IAddressComponent {
  plus_code: string;
  street_number: string; //street number
  route: string; // street name
  locality: string; //Name of big town/City e.g Lagos
  administrative_area_level_1: string; //State
  administrative_area_level_2: string; //LGA
  sublocality_level_1: string; //Area
  neighborhood: string; //Area
  country: string; //Country
  postal_code: string; //Postal code
  colloquial_area: string; //Area
  fullAddress: string;
}

export const addressComponents: IAddressComponent = {
  plus_code: '',
  street_number: '', //street number
  route: '', // street name
  locality: '', //Name of big town/City e.g Lagos
  administrative_area_level_1: '', //State
  administrative_area_level_2: '', //LGA
  sublocality_level_1: '', //Area
  neighborhood: '', //Area
  country: '', //Country
  postal_code: '', //Postal code
  colloquial_area: '', //Area
  fullAddress: '', //`${place!.name} ${place!.formatted_address}`;
};

//Initialization

export const initialPharmacyListSelect: IPharmacyListProp = {
  value: '',
  label: '',
};

export const initialPharmacyList: IPharmacyList = {
  pharmacyName: '',
  pharmacyCode: '',
  address: '',
  pharmacyId: 0,
};

export const initialFeatures: IPharmacyFeature = {
  rdtCost: '0',
  rdtBrand: '',
  malariaDrug: '',
  malariaDrugCost: '0',
  isPharmacyTrained: 'No',
};

export const initialPharmacyData: IPharmaciesData = {
  pharmacyId: 0,
  pharmacyName: '',
  phoneNumber: '',
  email: '',
  accountId: '',
  stateOfPremise: 'Abia',
  lga: 'Aba North',
  locationArea: '',
  locationLongitude: '',
  locationLatitude: '',
  pharmacyAddress: '',
  googleAddress: '',
  postalCode: '',
  plusCode: '',
  streetName: '',
  streetNumber: '',
  placeId: '',
  providerType: 'Pharmacy',
  contacts: '',
  showNote: false,
  pharmacyCode: '',
  status: 'Active',
  payInstantly: true,
  walletAccount: null, //initialPharmacyWallet,
  // features: null,
  relationshipOfficer: '',
  clientId: 0,
  clientContact: '',
};

export const defaultPharmData: IPharmacyData = {
  pharmacyId: 0,
  pharmacyName: '',
  phoneNumber: '',
  email: '',
  accountId: '',
  providerType: 'Pharmacy',
  contacts: '',
  showNote: false,
  pharmacyCode: '',
  status: 'Active',
  payInstantly: true,
  relationshipOfficer: '',
  clientId: 0,
  clientContact: '',
  addressInformation: {
    stateOfPremise: 'Abia',
    lga: 'Aba North',
    locationArea: '',
    pharmacyAddress: '',
    longitude: '',
    latitude: '',
    googleAddress: '',
    streetName: '',
    streetNumber: '',
  },
};

export const pharmacyRating: IPharmacyRatingDTO = {
  communication: 0,
  customerService: 0,
  pharmaceuticalCare: 0,
  payment: 0,
  nps: 0,
  serviceType: RatingServiceType.Claims,
};
