import React, { useState, useContext } from 'react';
import {
  IAXALookup,
  IAXALookupResult,
  initialAxaLookup,
} from '../../../interface/AXALookupInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { formatDate } from '../../../utils/dateTimeUtils';
import ButtonLoader from '../../common/ButtonLoader';
import { AppContext } from '../../../context/AppContext';
import { isFormValidated } from '../../../utils/formUtils';

const AXALookup = ({ history }: any) => {
  const { dispatch } = useContext(AppContext);

  const [subscriptionData, setSubscriptionData] = useState<IAXALookupResult>();
  const [axaLookup, setAxaLookup] = useState<IAXALookup>(initialAxaLookup);
  const [loading, setLoading] = useState(false);

  let display;

  const handleChange = (input: string) => (event: any) => {
    //setSearchText(event.target.value);
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setAxaLookup({
      ...axaLookup,
      [input]: value,
    });
  };

  const makeClaim = () => {
    if (subscriptionData?.policyEndDate === null) {
      addToast('Policy expiration date is missing', 'error');
      return;
    }

    const today = new Date();
    const expirationDate = new Date(subscriptionData?.policyEndDate as string);
    if (expirationDate.getTime() > today.getTime()) {
      // push to add claim form
      history.push('/subscriptions/axa/0/claims/add');
    } else {
      addToast('Cannot make claim. Policy has expired', 'error');
      return;
    }
  };

  const lookupTrackingNumber = async (event: any) => {
    if (!isFormValidated('axa-track-form')) {
      return;
    }
    event.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(endpoints.AXA.checkTrackingNumber, axaLookup);
      setSubscriptionData(response.data);
      dispatch({
        type: 'SET_TRACKING_NUMBER',
        payload: axaLookup.trackingNumber,
      });
      dispatch({
        type: 'SET_AXA_DATA',
        payload: response.data,
      });
    } catch (error: any) {
      addToast('Could not get subscription for tracking number');
    } finally {
      setLoading(false);
    }
  };

  if (subscriptionData === undefined) {
    display = <div></div>;
  } else {
    display = (
      <div className='mt-5'>
        <div className='wella-card card'>
          <div className='card-header'>Subscription Details</div>
          <div className='card-body table-responsive'>
            <div className='text-center'>{subscriptionData.message}</div>
            <br />
            <table className='table'>
              <tr>
                <td>Name</td>
                <td>
                  {subscriptionData.firstName} {subscriptionData.lastName}
                </td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{subscriptionData.phoneNumber}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{subscriptionData.emailAddress}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{subscriptionData.address}</td>
              </tr>
              <tr>
                <td>Policy Start Date</td>
                <td>{formatDate(subscriptionData.policyStartDate)}</td>
              </tr>
              <tr>
                <td>Policy End Date</td>
                <td>{formatDate(subscriptionData.policyEndDate)}</td>
              </tr>
            </table>

            <button type='button' className='btn-block btn btn-primary' onClick={makeClaim}>
              Make Claim
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='container mt-5'>
        <div className='row justify-content-center'>
          <div className='col-md-5'>
            <form id='axa-track-form'>
              <div className='form-group'>
                <label>Enter Tracking Code</label>
                <input
                  type='text'
                  className='form-control'
                  value={axaLookup.trackingNumber}
                  onChange={handleChange('trackingNumber')}
                />
              </div>
              <div className='form-group form-inline mt-4'>
                <label>
                  <input
                    type='radio'
                    className='custom-radio'
                    style={{ width: '20px' }}
                    checked={axaLookup.trackingType === 'TrackingNumber'}
                    value='TrackingNumber'
                    onChange={handleChange('trackingType')}
                  />
                  Tracking Code
                </label>
                <label className='ml-2'>
                  <input
                    type='radio'
                    className='custom-radio'
                    style={{ width: '20px' }}
                    value='PhoneNumber'
                    checked={axaLookup.trackingType === 'PhoneNumber'}
                    onChange={handleChange('trackingType')}
                  />
                  Phone number
                </label>
              </div>
              <button
                type='submit'
                className='btn btn-block btn-primary mt-3'
                onClick={lookupTrackingNumber}
              >
                Submit
                {loading ? <ButtonLoader /> : ''}
              </button>
            </form>
            {display}
          </div>
        </div>
      </div>
    </>
  );
};

export default AXALookup;
