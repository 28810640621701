//IMPORTANT: These methods are for display purposes only .not to be used for date in forms.

export const formatDate = (dateInput?: string) => {
  if (dateInput === null || typeof dateInput === 'undefined') {
    return '';
  } else {
    return new Date(dateInput).toLocaleDateString();
  }
};

export const formatDateTime = (dateTimeInput?: string) => {
  if (dateTimeInput === null || typeof dateTimeInput === 'undefined') {
    return '';
  } else {
    return new Date(dateTimeInput).toLocaleString('en-GB', { hour12: true }); //toLocaleString([], { hour12: true });
  }
};

export const formatTime = (dateTimeInput?: string) => {
  if (dateTimeInput === null || typeof dateTimeInput === 'undefined') {
    return '';
  } else {
    return new Date(dateTimeInput).toLocaleTimeString('en-GB', {
      hour12: true,
    }); //toLocaleString([], { hour12: true });
  }
};

export const getTodayDateInput = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};

export const formatInputDate = (dateTimeInput?: string) => {
  if (dateTimeInput === null || typeof dateTimeInput === 'undefined') {
    return '';
  } else {
    return dateTimeInput.split('T')[0];
  }
};

export const timeOfDayGreeting = (username: string) => {
  const hours = new Date().getHours();
  if (hours < 12) {
    return `🌞 Good morning ${username} !!`;
  } else if (hours < 16) {
    return `☀️ Good afternoon ${username} !!`;
  } else {
    return `🌙 Good evening ${username} !!`;
  }
};

export const formatDisplayDate = (dateString?: string) => {
  let date = new Date().toLocaleTimeString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  if (dateString !== undefined) {
    date = new Date(dateString).toLocaleTimeString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  return date;
};
export const formatDisplayDateTime = (dateString?: string) => {
  let date = new Date().toLocaleTimeString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });

  if (dateString !== undefined) {
    date = new Date(dateString).toLocaleTimeString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  }

  return date;
};
