import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  ISubscriptionData,
  SubscriptionDetailsModalProps,
} from '../../../interface/SubscriptionInterface';
import { endpoints } from '../../../utils/URL';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { formatDate } from '../../../utils/dateTimeUtils';

const SubscriptionDetailsModal = (props: SubscriptionDetailsModalProps) => {
  const initialState: ISubscriptionData | any = {
    initialPayment: '',
    lastPayment: '',
    dateCreated: '',
    location: '',
    stateOfResidence: '',
    notes: '',
    subscriptionCode: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    status: '',
    amount: 0,
    paymentPlan: '',
    numberOfSubscribers: 0,
    subscriptionAccount: '',
    healthSavingsAccount: 0,
    nextPayment: '',
    product: '',
  };

  const [subscriptionData, setSubscriptionData] = useState(initialState);
  const [isLoading, setLoading] = useState(false);

  const getDetails = useCallback(async () => {
    setSubscriptionData(null);
    setLoading(true);
    try {
      const response = await axios.get(
        endpoints.InsuranceSubscriptions.getBySubscriptionCode + props.subscriptionCode
      );
      const data = response.data;
      setSubscriptionData(data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [props.subscriptionCode]);

  useEffect(() => {
    if (props.open) {
      getDetails();
    }
  }, [getDetails, props.open]);

  return (
    <Modal show={props.open} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>
          Subscription Details for
          {subscriptionData !== null
            ? `${subscriptionData.firstName} ${subscriptionData.lastName}`
            : ''}
          {isLoading ? <ButtonLoader /> : ''}
        </Modal.Title>
      </Modal.Header>

      {subscriptionData != null ? (
        <Modal.Body className='modal-body'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>First Name</div>
              <div className='col-md-5 offset-1'>{subscriptionData.firstName}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Last Name</div>
              <div className='col-md-5 offset-1'>{subscriptionData.lastName}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Phone Number</div>
              <div className='col-md-5 offset-1'> {subscriptionData.phone}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Email</div>
              <div className='col-md-5 offset-1'> {subscriptionData.email}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Status</div>
              <div className='col-md-5 offset-1'>{subscriptionData.status}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Payment Plan</div>
              <div className='col-md-5 offset-1'>{subscriptionData.paymentPlan}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Beneficiaries</div>
              <div className='col-md-5 offset-1'>{subscriptionData.numberOfSubscribers}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Amount to pay</div>
              <div className='col-md-5 offset-1'>{subscriptionData.amount}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Subscription Code</div>
              <div className='col-md-5 offset-1'>{subscriptionData.subscriptionCode}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Account Number</div>
              <div className='col-md-5 offset-1'>{subscriptionData.subscriptionAccount}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>State Of Residence</div>
              <div className='col-md-5 offset-1'>{subscriptionData.stateOfResidence}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Location</div>
              <div className='col-md-5 offset-1'>{subscriptionData.location}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Notes</div>
              <div className='col-md-5 offset-1'>{subscriptionData.notes}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Date Created</div>
              <div className='col-md-5 offset-1'>{formatDate(subscriptionData.dateCreated)}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Initial Payment</div>
              <div className='col-md-5 offset-1'>{formatDate(subscriptionData.initialPayment)}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Last Payment</div>
              <div className='col-md-5 offset-1'>{formatDate(subscriptionData.lastPayment)}</div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6'>Next Payment</div>
              <div className='col-md-5 offset-1'>{formatDate(subscriptionData.nextPayment)}</div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default SubscriptionDetailsModal;
