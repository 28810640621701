export interface ILeadsData {
  leadId: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  location: string;
  amount: number;
  accountNumber: string;
  dateCreated: string;
  dateUpdated: string;
  isSync: boolean;
  planName: string;
  paymentPlan: number;
  peopleInPlan: number;
  hospicashAmount: number;
  leadSource: number;
  gender: number;
  isPaymentVerified: boolean;
  dateOfBirth: string;
  isConverted: boolean;
  paymentMethod: number;
  notes: string;
  referredBy: string;
  healthSavingsAccount: number;
  primaryPharmacy: string;
  productType: number;
}

export interface ILeadsDTO {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  numberOfMonths: number;
  amount: number;
  peopleInPlan: number;
  productType: string;
  paymentMethod: string;
  partnerCode: string;
}

export const initialLeadsData: ILeadsData = {
  leadId: 0,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  location: '',
  amount: 0,
  accountNumber: '',
  dateCreated: '',
  dateUpdated: '',
  isSync: false,
  planName: '',
  paymentPlan: 0,
  peopleInPlan: 0,
  hospicashAmount: 0,
  leadSource: 0,
  gender: 0,
  isPaymentVerified: false,
  dateOfBirth: '',
  isConverted: false,
  paymentMethod: 0,
  notes: '',
  referredBy: '',
  healthSavingsAccount: 0,
  primaryPharmacy: '',
  productType: 0,
};

export const initialleadDto: ILeadsDTO = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  numberOfMonths: 1,
  peopleInPlan: 1,
  amount: 600,
  productType: 'Malaria',
  partnerCode: '',
  paymentMethod: 'Paystack',
};
