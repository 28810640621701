import React, { useState, useEffect, useCallback, useContext } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { ISupportRequest, initialSupportRequestData } from '../../../interface/RequestsInterface';
import { addToast } from '../../../utils/toastNotifications';
import SupportRequestsModal from './UpdateSupportRequestsModal';
import { formatDate } from '../../../utils/dateTimeUtils';
import { AppContext } from '../../../context/AppContext';

import PaginationBar from '../../common/Pagination';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { IRealTimeUpdateProp } from '../../../interface/AppInterface';

const SupportRequestsTable = () => {
  const [modalData, setModalData] = useState(initialSupportRequestData);
  const [tableData, setTableData] = useState<ISupportRequest[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const { appState } = useContext(AppContext);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);

  const getSupportRequests = useCallback(async (pageIndex = 1) => {
    try {
      const url = `${endpoints.SupportRequests.mainUrl}?pageIndex=${pageIndex}&pageSize=200`;
      const response = await axios.get(url);
      setTableData(response.data.data);
      //set pagination info
      const responsePageInfo = {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        pageCount: response.data.pageCount,
      };
      //set page info
      setPageInfo(responsePageInfo);
      setShowPagination(true);
    } catch (error: any) {
      addToast('There was error getting call requests', 'error');
    }
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleChange = (input: string) => (e: any) => {
    setModalData({
      ...modalData,
      [input]: e.target.value,
    });
  };
  const getUpdatedRequests = async (requestId: number) => {
    //get the requests using requestId
    //filter current data where requestId == updatedFulfilment request Id
    //if found, get index of found object from current data
    //update the current data by replacing the old data with new data
    let url = `${endpoints.SupportRequests.mainUrl}/${requestId}`;

    await axios
      .get(url)
      .then((response) => {
        const updatedData = response.data;

        const oldData = tableData.filter((x) => x.requestId === requestId)[0];
        const parsedOldData = oldData || null;
        const filteredData: any = [...tableData];

        if (parsedOldData !== null) {
          filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
        } else {
          filteredData.unshift(updatedData);
        }
        setTableData(filteredData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSupportRequests();
  }, [getSupportRequests]);

  useEffect(() => {
    //use the request id to update
    const realTimeData = appState.supportRequests as IRealTimeUpdateProp;

    //all efforts to move this out of the useEffect was not possible without
    //resorting to suppressing es-lint warnings

    if (realTimeData != null) {
      const dataId = parseInt(realTimeData.dataId);
      getUpdatedRequests(dataId);
    }
  }, [appState.supportRequests]); // eslint-disable-line react-hooks/exhaustive-deps

  const editSupportRequestModal = (item: ISupportRequest) => {
    setModalData(item);
    setModalOpen(true);
  };

  let UpdateRequest = (
    <SupportRequestsModal
      open={isModalOpen}
      close={closeModal}
      modalData={modalData}
      getRequests={getSupportRequests}
      handleChange={handleChange}
    />
  );

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Date Created</th>
        <th scope='col'>Phone</th>
        <th scope='col'>Email</th>
        <th scope='col'>Source</th>
        {/* <th scope='col'>Product</th> */}
        <th scope='col'>Request Type</th>
        <th scope='col'>Request Status</th>
        <th scope='col'>Notes</th>
        <th scope='col'>Date Updated</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((callRequests: ISupportRequest) => {
        return (
          <tr key={callRequests.requestId}>
            <td>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => editSupportRequestModal(callRequests)}
                className='bi bi-pencil-square export-icon'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Edit'
              ></i>
            </td>
            <td>{formatDate(callRequests.dateCreated)}</td>
            <td>{callRequests.phoneNumber}</td>
            <td>{callRequests.email}</td>
            <td>{callRequests.supportSource}</td>
            <td>{callRequests.requestType}</td>
            <td>{callRequests.requestStatus}</td>
            <td>{callRequests.notes}</td>
            <td>{formatDate(callRequests.dateUpdated)}</td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div>
      {UpdateRequest}
      <div className='d-flex'>
        <h3 className=' mr-auto'>Support Requests</h3>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={getSupportRequests}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default SupportRequestsTable;
