import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  IFulfilmentDrugsData,
  IEditFulfilmentModal,
} from '../../../interface/FulfilmentsInterface';
import { endpoints } from '../../../utils/URL';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { addToast } from '../../../utils/toastNotifications';
import { initialEmailTriggerDTO } from '../../../interface/EmailTriggerInterface';
import { FULFILMENT_EMAIL_TYPE, HMOReviewURLs } from '../../../utils/mappings';
import { IPharmacyData } from '../../../interface/PharmacyInterface';
import { formatDisplayDate } from '../../../utils/dateTimeUtils';
import { useAuth } from 'react-oidc-context';
import { IFulfilmentPartners } from '../../../interface/FulfilmentPartnersInterface';

const MessageModal = ({ open, close, modalData }: IEditFulfilmentModal) => {
  const [isLoading, setLoading] = useState(false);
  const [drugsDispensed, setDrugsDispensed] = useState('');
  const [emailButtonLoading, setEmailButtonLoading] = useState(false);
  const [localState, setLocalState] = useState(initialEmailTriggerDTO);
  const [buttonState, toggleButtonState] = useState(true);
  const [smsContent, setSmsContent] = useState('Loading SMS....');
  //const [partnerName, setPartnerName] = useState('HMO');
  const auth = useAuth();

  const triggerEmail = async (event: React.FormEvent) => {
    event.preventDefault();
    setEmailButtonLoading(true);
    localState.requestId = modalData.requestId;
    localState.pharmacyCode = modalData.pharmacyCode;
    localState.partnerCode = modalData.partnerCode;
    const payload = localState;
    setEmailButtonLoading(true);
    try {
      let url = `${endpoints.FulfilmentRequests.mainUrl}/email`;
      await axios.post(url, payload);
      addToast('Email sent', 'success');
      close();
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your email', 'error');
      }
    } finally {
      setEmailButtonLoading(false);
    }
  };

  const handleChange = (state: string) => (event: any) => {
    setLocalState({
      ...localState,
      [state]: event.target.value,
    });
  };

  const copyMessage = () => {
    let pharmacyMessage = `
        Enrollee HMO ID:....... ${modalData.enrollmentCode}
        Prescription:....... ${drugsDispensed}
        Notes:....... ${modalData.notes === null ? '' : modalData.notes}
        Date:........${formatDisplayDate(modalData.dateCreated)}
        Please ask Enrollee for Pick-up code and ensure you label the medication with directions for use..
        Also, Please don't give the receipt to the Enrollee..
        \n
        Thank you.`;

    const allowedStatuses = ['Approved', 'Completed'];
    const fulfilmentStatus = modalData.fulfilmentStatus;
    if (allowedStatuses.includes(fulfilmentStatus)) {
      pharmacyMessage = `
        Enrollee Name:....... ${modalData.enrolleeFirstName} ${modalData.enrolleeLastName}
        Enrollee HMO ID:....... ${modalData.enrollmentCode}
        Phone Number:....... ${modalData.enrolleePhone}
        Enrollee Address:....... ${modalData.enrolleeAddress}
        Prescription:....... ${drugsDispensed}
        Notes:....... ${modalData.notes === null ? '' : modalData.notes}
        Date:........${formatDisplayDate(modalData.dateCreated)}
        Please ask Enrollee for Pick-up code and ensure you label the medication with directions for use..
        Also, Please don't give the receipt to the Enrollee..
        \n
        Thank you.`;
    }
    navigator.clipboard
      .writeText(pharmacyMessage)
      .then(() => {
        addToast('Copied to clipboard');
      })
      .catch((err) => {
        console.error('Async: Could not copy text: ', err);
      });
  };

  const changeSmsContent = (e: any) => {
    setSmsContent(e.target.value);
  };

  // const sendSms = async (event: React.FormEvent) => {
  //   event.preventDefault();

  //   if (smsContent.length === 0) {
  //     addToast('Message missing', 'warning');
  //     return;
  //   }

  //   if (modalData.enrolleePhone === '') {
  //     addToast('No Phone number selected.');
  //     return;
  //   }

  //   setEmailButtonLoading(true);

  //   const messages = [];

  //   const pickupSms: ITransactionalMessageDTO = {
  //     phoneNumber: modalData.enrolleePhone,
  //     messageContent: smsContent,
  //     messageChannel: 'SMS',
  //     queueDays: 0,
  //   };
  //   messages.push(pickupSms);

  //   //queue up messages for PSP

  //   // if (modalData.fulfilmentService !== 'Telemedicine') {
  //   //   let followupMessage = `Dear ${modalData.enrolleeFirstName} ${modalData.enrolleeLastName}, on behalf of ${partnerName}`;
  //   //   followupMessage += ` we'd like to check that you are adhering to your medications as prescribed and monitoring your vitals often.`;
  //   //   followupMessage += ` If you notice anything unusual, reach out to your care team/hospital ASAP. WeFill Africa (WELLAHEALTH) for ${partnerName}`;

  //   //   const followUpSms: ITransactionalMessageDTO = {
  //   //     phoneNumber: modalData.enrolleePhone,
  //   //     messageContent: followupMessage,
  //   //     messageChannel: 'SMS',
  //   //     queueDays: 3,
  //   //   };

  //   //   messages.push(followUpSms);
  //   // }

  //   const url = `${endpoints.InsuranceMessages.mainUrl}/transactional`;

  //   try {
  //     await axios.post(url, messages);
  //     addToast('Message Sent Successfully', 'success');
  //   } catch (error:any) {
  //     addToast('An error occured when sending your message', 'error');
  //   } finally {
  //     setLoading(false);
  //     setEmailButtonLoading(false);
  //   }
  // };

  useEffect(() => {
    const getDrugDetails = async () => {
      setLoading(true);
      setDrugsDispensed('');
      toggleButtonState(true);

      try {
        const response = await axios.get(
          endpoints.FulfilmentDrugs.mainUrl + '/' + modalData.requestId
        );
        const data: IFulfilmentDrugsData[] = response.data;
        if (data.length > 0) {
          let drugAndDose = data.reduce((acc, current) => {
            let drugName = current.name;
            let drugDose = current.dose === null ? '' : current.dose.trim();
            let drugStrength = current.strength === null ? '' : current.strength.trim();
            let drugFrequency = current.frequency === null ? '' : current.frequency.trim();
            let drugDuration = current.duration === null ? '' : current.duration.trim();
            let drugNotes = current.notes === null ? '' : current.notes.trim();

            acc += `${drugName} ${drugDose} ${drugStrength} ${drugFrequency} ${drugDuration} ${drugNotes}; `;
            return acc;
          }, '');
          setDrugsDispensed(drugAndDose);
          toggleButtonState(false);
        }
        setLoading(false);
      } catch (error: any) {
        addToast('An error occured', 'error');
        console.error(error);
      }
    };

    if (open) {
      getDrugDetails();
    }
  }, [modalData.requestId, open]);

  //use effect to load sms
  useEffect(() => {
    const getPharmacyDetails = async () => {
      setLoading(true);
      setSmsContent('');

      try {
        const response = await axios.get(
          endpoints.InsurancePharmacy.mainUrl + '/' + modalData.pharmacyCode
        );
        const pharmacyData: IPharmacyData = response.data;

        //get partner
        let partnerName = 'HMO';
        const partnerList = localStorage.getItem('partnersList');
        if (partnerList !== null && typeof partnerList !== 'undefined') {
          const parsedPartnerList = JSON.parse(partnerList) as IFulfilmentPartners[];
          const partnerDetails = parsedPartnerList.filter(
            (x) => x.partnerCode === modalData.partnerCode
          );
          if (partnerDetails !== null && typeof partnerDetails !== 'undefined') {
            partnerName = partnerDetails[0].partnerName;
          }
        }
        // get partner information using partnercode from modalData
        let selectedPartner = HMOReviewURLs.find(
          (hmo) => hmo.partnerCode === modalData.partnerCode
        );

        //if request is approved or completed, add Pickup code
        const allowedStatuses = ['Approved', 'Completed'];
        const pickupCode = `Use ${modalData.pickupCode} as pick up code`;

        //if not WellaHealth, use different format.
        let sms = `Hello, ${modalData.enrolleeFirstName} ${modalData.enrolleeLastName} \n${modalData.enrollmentCode}.`;
        sms += `\nKindly pick up your medications at ${pharmacyData.pharmacyName} located at ${pharmacyData.addressInformation?.pharmacyAddress} `;

        if (allowedStatuses.includes(modalData.fulfilmentStatus)) {
          sms += pickupCode;
        }
        sms += `\nPlease go with your HMO ID card.\nThank you. \nPlease, leave us a review here \n ${
          selectedPartner === undefined ? 'https://rb.gy/lunq3q' : selectedPartner.reviewURL
        }.\n Wefill Africa (WellaHealth) for ${partnerName}.`;

        //for WellaHealth
        if (
          modalData.partnerCode === 'WHPX10001' &&
          modalData.fulfilmentService === 'Telemedicine'
        ) {
          sms = `Hello, ${modalData.enrolleeFirstName} ${modalData.enrolleeLastName} ${modalData.enrolleePhone}. `;
          sms += `Kindly pick up your medications from ${pharmacyData.pharmacyName} located at ${pharmacyData.addressInformation?.pharmacyAddress}. `;
          sms += `Call 09087482264, ${auth?.user?.profile.phone_number} for more information.  Please, leave us a review here https://rb.gy/lunq3q. WELLAHEALTH`;
        }
        setSmsContent(sms);
      } catch (error: any) {
        addToast('An error occured', 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      getPharmacyDetails();
    }
  }, [
    modalData.enrolleePhone,
    modalData.enrolleeFirstName,
    modalData.enrolleeLastName,
    modalData.pharmacyCode,
    modalData.enrollmentCode,
    modalData.partnerCode,
    modalData.fulfilmentService,
    modalData.fulfilmentStatus,
    modalData.pickupCode,
    open,
    auth,
  ]);

  return (
    <div>
      <Modal show={open} onHide={() => close()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Messaging
            {isLoading ? <ButtonLoader /> : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    disabled={buttonState}
                    onClick={copyMessage}
                  >
                    Click to Copy Pharmacy Message
                  </button>
                </div>
                <br />
                <div className='form-group'>
                  <label className='form-label'>Select Email Type</label>
                  <select
                    onChange={handleChange('emailType')}
                    value={localState.emailType}
                    className='custom-select'
                  >
                    {FULFILMENT_EMAIL_TYPE.map((option) => (
                      <option key={option.key} value={option.value.toString()}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                  <br />
                  <button type='button' className='btn btn-primary mt-3' onClick={triggerEmail}>
                    Send Mail
                    {emailButtonLoading ? <ButtonLoader /> : ''}
                  </button>
                </div>
                <hr />
                <div className='form-group mt-3'>
                  <label className='form-label'>Pick Up SMS</label>
                  {/* <select
                    onChange={handleChange('emailType')}
                    value={localState.emailType}
                    className='custom-select'
                  >
                    {FULFILMENT_EMAIL_TYPE.map((option) => (
                      <option key={option.key} value={option.value.toString()}>
                        {option.text}
                      </option>
                    ))}
                  </select> */}
                  <textarea
                    className='form-control'
                    value={smsContent}
                    onChange={changeSmsContent}
                    rows={5}
                  >
                    Loading sms .....
                  </textarea>
                  <br />
                  {/* <button type='button' className='btn btn-primary mt-3' onClick={sendSms}>
                    Send SMS
                    {emailButtonLoading ? <ButtonLoader /> : ''}
                  </button> */}
                </div>
              </div>
            </div>
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default MessageModal;
