import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { IMedismarBatchResponse } from '../../../interface/VericlaimInterface';
import { addToast } from '../../../utils/toastNotifications';
import { isFormValidated } from '../../../utils/formUtils';

const BatchNumberModal = ({
  hmoCode,
  hmoName,
  open,
  close,
  callback,
}: {
  hmoCode: string;
  hmoName: string;
  open: boolean;
  close: Function;
  callback?: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [batch, setBatch] = useState<IMedismarBatchResponse>({
    batchNumber: '',
    dateAdded: `${new Date().toUTCString().split('T')[0]}`,
  });

  const handleChange = (input: string) => (event: any) => {
    setBatch({
      ...batch,
      [input]: event.target.value,
    });
  };

  const createBatchNumber = async (event: any) => {
    if (!isFormValidated('batch-form')) {
      return;
    }

    event.preventDefault();

    const url = `${endpoints.Medismart.mainUrl}/batch`;

    const payload = {
      hmoName: hmoCode,
      batchName: batch.batchNumber,
      batchDate: batch.dateAdded,
    };

    setLoading(true);
    try {
      const response = await axios.post(url, payload);
      if (callback) {
        callback(response.data);
      }
    } catch (error) {
      addToast('An error occured while creating batch number', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setBatch((f) => {
        return {
          ...f,
          batchNumber: `${hmoName}-${new Date().toLocaleString('en-us', {
            month: 'long',
          })}-${new Date().getFullYear()}`,
        };
      });
    }
  }, [open, hmoName]);

  return (
    <Modal centered backdrop='static' show={open} onHide={() => close()}>
      <form className='form-group' id='batch-form'>
        <Modal.Header closeButton>
          <Modal.Title>Create a new batch for {hmoName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={loading}>
            <div className='form-group'>
              <label>Batch name</label>
              <input
                type='text'
                className='form-control'
                required
                value={batch.batchNumber}
                onChange={handleChange('batchNumber')}
              />
            </div>
            <div className='form-group'>
              <label>Batch Month / Year </label>
              <input
                type='date'
                className='form-control'
                required
                value={batch.dateAdded}
                onChange={handleChange('dateAdded')}
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button type='submit' className='btn btn-primary' onClick={createBatchNumber}>
            Create Batch
            {loading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BatchNumberModal;
