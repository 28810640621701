import { IFulfilmentDraftResponse } from '../../../interface/FulfilmentsInterface';
import NoTableData from '../../common/NoTableData';
const FulfilmentDraftTableLayout = ({
  open,
  drafts,
  callback,
}: {
  open: any;
  drafts: IFulfilmentDraftResponse[];
  callback?: Function;
}) => {
  const selectDraft = (draft: IFulfilmentDraftResponse) => (event: any) => {
    event.preventDefault();
    if (open) {
      open(draft);
    }
  };
  const deleteDraft = (draftId: number) => (event: any) => {
    event.stopPropagation();
    if (callback) {
      callback(draftId);
    }
  };

  return (
    <div className='table-responsive'>
      {drafts.length > 0 ? (
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th>Action</th>
              <th>Name</th>
              <th>Phone number</th>
              <th>Partner name</th>
            </tr>
          </thead>
          <tbody>
            {drafts.map((draft, key) => (
              <tr className='pointer' onClick={selectDraft(draft)}>
                <div className='mt-2'>
                  <button className=' btn btn-sm' title='delete' onClick={deleteDraft(draft.id)}>
                    <span className='bi bi-trash text-danger'></span>
                  </button>
                </div>
                <td>
                  {draft.patientInfo?.firstName} {draft.patientInfo?.lastName}
                </td>
                <td>{draft.patientInfo?.phoneNumber}</td>
                <td>{draft.partnerName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoTableData />
      )}
    </div>
  );
};

export default FulfilmentDraftTableLayout;
