import { Modal } from 'react-bootstrap';
import { InsuranceClaimDrug } from '../../../interface/ClaimsInterface';
import Select from 'react-select';
import drugs from '../../../assets/drugs.json';
import { useState, useEffect, useCallback } from 'react';
import { addToast } from '../../../utils/toastNotifications';

const AddDrugModal = ({
  open,
  close,
  data,
  handleChange,
  addToClaim,
  selectChange,
  drugList,
  deleteDrug,
}: {
  open: boolean;
  close: Function;
  data: InsuranceClaimDrug;
  handleChange: Function;
  addToClaim: Function;
  selectChange: Function;
  drugList: Array<InsuranceClaimDrug>;
  deleteDrug: Function;
}) => {
  const [selectObject, setSelectObject] = useState<any>([]);

  const loadDrugs = useCallback(async () => {
    try {
      const drugArray = drugs.drugs;
      const drugData = drugArray.map((drug) => {
        return {
          id: drug.id,
          value: drug.cost,
          label: drug.name,
        };
      });
      setSelectObject(drugData);
    } catch (error: any) {
      addToast('Error getting drugs and cost', 'error');
    }
  }, []);

  const dropdownChange = (selectedValue: any) => {
    selectChange(selectedValue);
  };

  useEffect(() => {
    if (open) {
      loadDrugs();
    }
  }, [open, loadDrugs]);

  //    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

  return (
    <Modal show={open} onHide={() => close()}>
      <Modal.Header closeButton>Add Drug to Claim</Modal.Header>
      <Modal.Body>
        <form>
          <div className='form-group'>
            <label className='form-label'>Name</label>
            <Select
              options={selectObject}
              onChange={dropdownChange}
              className='react-select mb-3'
              classNamePrefix='react-select'
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Dose</label>
            <input
              type='text'
              className='form-control'
              value={data.dose}
              onChange={handleChange('dose')}
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Cost</label>
            <input
              type='number'
              className='form-control'
              value={data.cost}
              onChange={handleChange('cost')}
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Notes</label>
            <textarea
              className='form-control'
              value={data.notes}
              onChange={handleChange('notes')}
            ></textarea>
          </div>
          <div className='form-group'>
            <label className='form-label'>Drug Dispensed?</label>
            <input
              type='checkbox'
              className='ml-5'
              checked={data.isDispensed}
              onChange={handleChange('isDispensed')}
            />
          </div>
          <button className='mt-3 btn btn-primary' type='button' onClick={() => addToClaim()}>
            Add to Claim
          </button>
        </form>
        <hr />
        <div className=' mt-2'>
          <h6 className='text-center'>Drugs Entered</h6>
          <table className='table'>
            <tbody>
              {drugList.map((drug) => {
                return (
                  <tr key={drug.localId}>
                    <td>{drug.name}</td>
                    <td>{drug.cost}</td>
                    <td>
                      <i
                        className='bi bi-trash-fill'
                        style={{ color: 'red' }}
                        onClick={() => deleteDrug(drug.localId)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddDrugModal;
