import React, { useState, useEffect } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import {
  initialFulfilmentPartnersData,
  IFulfilmentPartners,
} from '../../../interface/FulfilmentPartnersInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import Button from 'react-bootstrap/Button';
import AddPartnerModal from './AddPartnerModal';

const FulfilmentPartnersTable = () => {
  const [tableData, setTableData] = useState([initialFulfilmentPartnersData]);
  const [addPartnerModalOpen, setAddPartnerModalOpen] = useState(false);

  useEffect(() => {
    const getFulfilmentPartners = async () => {
      let url = endpoints.FulfilmentPartners.mainUrl;

      await axios
        .get(url)
        .then((response) => {
          setTableData(response.data);
        })
        .catch((error) => {
          addToast('An error occured while getting data', 'error');
        });
    };

    getFulfilmentPartners();
  }, []);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Date Created</th>
        <th scope='col'>Partner Code</th>
        <th scope='col'>Partner Name</th>
        <th scope='col'>Phone</th>
        <th scope='col'>Email</th>
        <th scope='col'>Location</th>
        <th scope='col'>Active</th>
        <th scope='col'>Date Updated</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((fulfilmentPartner: IFulfilmentPartners) => {
        return (
          <tr key={fulfilmentPartner.partnerCode}>
            <td>{formatDate(fulfilmentPartner.dateCreated)}</td>
            <td>{fulfilmentPartner.partnerCode}</td>
            <td>{fulfilmentPartner.partnerName}</td>
            <td>{fulfilmentPartner.phoneNumber}</td>
            <td>{fulfilmentPartner.supportEmail}</td>
            <td>{fulfilmentPartner.location}</td>
            <td>{fulfilmentPartner.isActive}</td>
            <td>{formatDate(fulfilmentPartner.dateUpdated)}</td>
          </tr>
        );
      })}
    </tbody>
  );

  let addPartnerModal = (
    <AddPartnerModal open={addPartnerModalOpen} close={() => setAddPartnerModalOpen(false)} />
  );

  return (
    <>
      {addPartnerModal}
      <div className='d-flex'>
        <h4 className='mr-auto'>Fulfilment Partners</h4>
      </div>
      <br />
      <div className='d-flex'>
        <Button
          onClick={() => setAddPartnerModalOpen(true)}
          className='ml-auto mb-3'
          variant='primary'
        >
          ADD PARTNER
        </Button>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </>
  );
};

export default FulfilmentPartnersTable;
