import React, { useState } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import { PAYMENT_PLAN_MAPPING, PRODUCT_TYPE } from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';
import { formatInputDate } from '../../../utils/dateTimeUtils';
import { validFileType } from '../../../utils/fileHelper';

const SubscriberUploadModal = ({ open, close, modalData }: any) => {
  const [isLoading, setLoading] = useState(false);
  const [localState, setLocalState] = useState({
    paymentPlan: 'Monthly',
    productType: 'Malaria',
    startDate: new Date().toString(),
  });

  const handleChange = (state: string) => (event: any) => {
    setLocalState({
      ...localState,
      [state]: event.target.value,
    });
  };

  const uploadSubscribers = async (event: any) => {
    if (!isFormValidated('subscriber-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }

    event.preventDefault();

    let url = endpoints.InsurancePartners.uploadSubscribers;

    let fileUpload: any = document.getElementById('subscriber-file');
    let uploadedFiles = fileUpload.files;

    if (uploadedFiles.length === 0) {
      addToast('No files selected. Please select a csv file', 'warning');
      return;
    }
    if (!validFileType(uploadedFiles[0])) {
      addToast('Wrong file. Upload csv file', 'warning');
      return;
    }

    if (uploadedFiles[0].size > 2097152) {
      //if exceeding 2mb
      addToast('Exceed file limit. Max limit is 2MB', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('formFile', uploadedFiles[0]);
    formData.append('partnerCode', modalData.partnerCode);
    formData.append('paymentPlan', localState.paymentPlan);
    formData.append('productType', localState.productType);
    formData.append('startDate', localState.startDate);

    setLoading(true);
    try {
      const response = await axios.post(url, formData);
      if (response.status === 200) {
        addToast('Subscriber successfully uploaded', 'success');
        close();
      }
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        addToast('CSV could not be parsed', 'error');
      } else {
        addToast('Error uploading subscriber list', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={close}>
      <form id='subscriber-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Subscriber upload {modalData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label className='form-label'>Payment plan</label>
              <select
                onChange={handleChange('paymentPlan')}
                value={localState.paymentPlan}
                className='custom-select'
              >
                {PAYMENT_PLAN_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='form-group'>
              <label className='form-label'>Product Type</label>
              <select
                onChange={handleChange('productType')}
                value={localState.productType}
                className='custom-select'
              >
                {PRODUCT_TYPE.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='form-group'>
              <label className='form-label'>Start Date</label>
              <input
                value={formatInputDate(localState.startDate)}
                required
                className='form-control '
                type='date'
                onChange={handleChange('startDate')}
              />
            </div>
            <div className='form-group'>
              <input className='form-control' id='subscriber-file' type='file' accept='.csv' />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={uploadSubscribers} disabled={isLoading}>
            SUBMIT
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SubscriberUploadModal;
