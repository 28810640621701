import React, { useState } from 'react';
import { initialClaimDataExportData } from '../../../interface/PartnerInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { formatInputDate } from '../../../utils/dateTimeUtils';

const ClaimExportModal = ({ open, close, modalData }: any) => {
  const [isLoading, setLoading] = useState(false);
  const [localState, setLocalState] = useState(initialClaimDataExportData);

  const handleChange = (state: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalState({
      ...localState,
      [state]: event.target.value,
    });
  };

  const exportClaims = async (event: any) => {
    if (!isFormValidated('claim-export-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }

    event.preventDefault();

    let url = `${endpoints.InsuranceClaims.mainUrl}/export`;

    const payload = {
      partnerCode: modalData.partnerCode,
      endDate: localState.endDate,
      startDate: localState.startDate,
      email: localState.email,
    };

    setLoading(true);
    try {
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        addToast('Claim data sent to the email', 'success');
        close();
      }
    } catch (error: any) {
      addToast('There was an error sending claims data', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={close}>
      <form id='claim-export-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Claim Export for {modalData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='col-md-12'>
              <label className='form-label'>Email address</label>
              <input
                value={localState.email}
                className='form-control '
                type='email'
                placeholder='name@example.com'
                onChange={handleChange('email')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Start Date</label>
              <input
                value={formatInputDate(localState.startDate)}
                required
                className='form-control '
                type='date'
                onChange={handleChange('startDate')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>End Date</label>
              <input
                value={formatInputDate(localState.endDate)}
                required
                className='form-control '
                type='date'
                onChange={handleChange('endDate')}
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={exportClaims} disabled={isLoading}>
            SUBMIT
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ClaimExportModal;
