import React, { useState, useCallback, useEffect } from 'react';
import { IHealthSendRequest } from '../../../interface/HealthSendInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import TableLoader from '../../common/TableLoading';
import { addToast } from '../../../utils/toastNotifications';
import { useParams } from 'react-router-dom';
import { formatDateTime } from '../../../utils/dateTimeUtils';

const HealthSendRequestsTable = () => {
  const { userId } = useParams() as any;

  const [requests, setRequests] = useState<IHealthSendRequest[]>([]);
  const [loading, setLoading] = useState(false);

  const getRequests = useCallback(async () => {
    setLoading(true);

    let url = endpoints.HealthSend.requests;

    if (typeof userId !== 'undefined' && userId !== '') {
      url = `${endpoints.HealthSend.requests}/${userId}`;
    }

    try {
      const response = await axios.get(url);
      setRequests(response.data);
    } catch (error: any) {
      addToast('Error getting healthsend requests', 'error');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>First Name</th>
        <th scope='col'>Last Name</th>
        <th scope='col'>Email</th>
        <th scope='col'>Phone</th>
        <th scope='col'>Services</th>
        <th scope='col'>Request Date</th>
        <th scope='col'>Total Amount Sent</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {requests.map((request) => (
        <tr>
          <td>{request.firstName}</td>
          <td>{request.lastName}</td>
          <td>{request.email}</td>
          <td>
            ({request.countryCode}) {request.phoneNumber}
          </td>
          <td>{request.services}</td>
          <td>{formatDateTime(request.requestDate)}</td>
          <td>{request.totalAmountSent}</td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div>
      <div className='d-flex'>
        <h3 className='mr-auto'>Requests ({requests.length})</h3>
      </div>
      <br />
      {loading && (
        <div className='d-flex justify-content-center'>
          <div className='mt-5'>
            <TableLoader />
          </div>
        </div>
      )}

      {!loading && (
        <div className='table-responsive'>
          <table className='table table-bordered table-hover'>
            {tableHead}
            {tableBody}
          </table>
        </div>
      )}
    </div>
  );
};

export { HealthSendRequestsTable };
