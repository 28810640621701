import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { BOOL_MAPPING, GENDER_MAPPING } from '../../../utils/mappings';
import { isFormValidated } from '../../../utils/formUtils';
import { IAddEditBeneficiaryModal } from '../../../interface/BeneficiaryInterface';
import { STATES_LGA } from '../../../utils/states_lga';
import ButtonLoader from '../../common/ButtonLoader';
import { formatInputDate } from '../../../utils/dateTimeUtils';

const AddEditBeneficiaryModal = ({
  open,
  close,
  modalData,
  getBeneficiaryData,
  handleChange,
  isEdit,
}: IAddEditBeneficiaryModal) => {
  const [isLoading, setLoading] = useState(false);

  const upsertBeneficiary = async (event: any) => {
    if (!isFormValidated('edit-beneficiary-modal')) {
      addToast('Please Fill in required fields.', 'warning');
      return;
    }
    event.preventDefault();
    setLoading(true);

    let payload; // = [modalData];

    if (!isEdit) {
      //modalData.subscriptionCode = appState.subscriptionCode;
      payload = [modalData];
    } else {
      payload = modalData;
    }

    try {
      if (!isEdit) {
        await axios.post(endpoints.InsuranceBeneficiary.mainUrl, payload);
      } else {
        await axios.put(endpoints.InsuranceBeneficiary.mainUrl, payload);
      }

      addToast('Beneficiary Successfully added', 'success');
      getBeneficiaryData();
      close();
    } catch (error: any) {
      addToast('Error adding beneficiary', 'error');
    } finally {
      setLoading(false);
    }
  };

  const statesType = STATES_LGA;
  const statesMap = statesType.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const state_filter = STATES_LGA.filter((selected_state) => {
    return selected_state.state === modalData.stateOfResidence;
  });

  let lgas = state_filter[0]?.locals.map((lga) => {
    return {
      key: lga.id,
      text: lga.name,
      value: lga.name,
    };
  });

  return (
    <Modal show={open} onHide={close}>
      <form id='edit-beneficiary-modal'>
        <fieldset disabled={isLoading}>
          <Modal.Header>
            <Modal.Title>{isEdit ? 'Edit Beneficiary' : 'Add Beneficiary'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <label>First Name</label>
              <input
                type='text'
                className='form-control'
                value={modalData.firstName}
                onChange={handleChange('firstName')}
                required
              />
            </div>
            <div className='form-group'>
              <label>Last Name</label>
              <input
                type='text'
                className='form-control'
                value={modalData.lastName}
                onChange={handleChange('lastName')}
                required
              />
            </div>
            <div className='form-group'>
              <label>Phone</label>
              <input
                type='text'
                className='form-control'
                value={modalData.phone}
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                onChange={handleChange('phone')}
              />
            </div>
            <div className='form-group'>
              <label>Email</label>
              <input
                type='email'
                className='form-control'
                value={modalData.email}
                onChange={handleChange('email')}
              />
            </div>
            <div className='form-group'>
              <label>Location</label>
              <input
                type='text'
                className='form-control'
                value={modalData.location}
                onChange={handleChange('location')}
              />
            </div>
            <div className='form-group'>
              <label>State Location</label>
              <select
                required
                className='custom-select'
                value={modalData.stateOfResidence}
                onChange={handleChange('stateOfResidence')}
              >
                {statesMap.map((states) => (
                  <option key={states.key} value={states.value}>
                    {states.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label>LGA</label>
              <select
                required
                className='custom-select'
                value={modalData.lga}
                onChange={handleChange('lga')}
              >
                {lgas ? (
                  lgas.map((lga) => (
                    <option key={lga.key} value={lga.value}>
                      {lga.text}
                    </option>
                  ))
                ) : (
                  <option>No LGAs</option>
                )}
              </select>
            </div>
            <div className='form-group'>
              <label>Area</label>
              <input
                type='text'
                className='form-control'
                value={modalData.area}
                onChange={handleChange('area')}
              />
            </div>
            <div className='form-group'>
              <label>Street Name</label>
              <input
                type='text'
                className='form-control'
                value={modalData.streetName}
                onChange={handleChange('streetName')}
              />
            </div>
            <div className='form-group'>
              <label>D.O.B</label>
              <input
                type='date'
                className='form-control'
                value={formatInputDate(modalData.dateOfBirth)}
                onChange={handleChange('dateOfBirth')}
                required
              />
            </div>
            <div className='form-group'>
              <label>Gender</label>
              <select
                required
                className='custom-select'
                value={modalData.gender}
                onChange={handleChange('gender')}
              >
                {GENDER_MAPPING.map((gender) => (
                  <option key={gender.key} value={gender.value}>
                    {gender.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label>Active?</label>
              <select
                required
                className='custom-select'
                value={modalData.isActive.toString()}
                onChange={handleChange('isActive')}
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='btn btn-primary' onClick={upsertBeneficiary}>
              {isEdit ? 'Update' : 'Save'}
              {isLoading ? <ButtonLoader /> : ''}
            </button>
            {/* {edit ? (
              <button type='submit' className='btn btn-primary' onClick={upsertBeneficiary(false)}>
                Update
                {isLoading ? <ButtonLoader /> : ''}
              </button>
            ) : (
              <button type='submit' className='btn btn-primary' onClick={upsertBeneficiary(false)}>
                Save
                {isLoading ? <ButtonLoader /> : ''}
              </button>
            )} */}
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default AddEditBeneficiaryModal;
