import React, { useState } from 'react';
import axios from 'axios';
import { IEditFulfilmentModal } from '../../../interface/FulfilmentsInterface';
import { endpoints } from '../../../utils/URL';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';

const PickupCodeModal = ({ open, close, modalData }: IEditFulfilmentModal) => {
  const [isLoading, setLoading] = useState(false);

  const submit = async (event: any) => {
    if (!isFormValidated('pickup-form')) {
      return;
    }

    event.preventDefault();
    const pickupCode = document.getElementById!('pickup-code') as HTMLFormElement;

    const payload = {
      pickupCode: pickupCode.value,
      requestId: modalData.requestId,
      pharmacyCode: modalData.pharmacyCode,
    };

    const url = `${endpoints.FulfilmentRequests.mainUrl}/${modalData.requestId}/pickupcode`;
    setLoading(true);
    try {
      await axios.put(url, payload);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        addToast('Request or pharmacy not found', 'error');
      }
      if (error.response && error.response.status === 422) {
        addToast('Invalid pickup code supplied', 'error');
      } else {
        addToast('An error occured verifying pickup code', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal show={open} onHide={() => close()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Pickup Code for {modalData.enrolleeFirstName} {modalData.enrolleeLastName}
            {isLoading ? <ButtonLoader /> : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <form className='container' id='pickup-form'>
            <fieldset className='row' disabled={isLoading}>
              <div className='col-md-12'>
                <div className='form-group mt-3'>
                  <label className='form-label'>Pick Up Code</label>
                  <input id='pickup-code' className='form-control' type='text' required />
                  <br />
                  <button type='submit' className='btn btn-primary mt-3' onClick={submit}>
                    Submit
                    {isLoading ? <ButtonLoader /> : ''}
                  </button>
                </div>
              </div>
            </fieldset>
            <br />
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default PickupCodeModal;
