import { useCallback, useEffect, useState } from 'react';
import { endpoints } from '../../utils/URL';
import { IFulfilmentPartners } from '../../interface/FulfilmentPartnersInterface';
import axios from 'axios';
import { ISelectBar } from '../../interface/CommonInterface';

const FulfilmentPartners = (props: ISelectBar) => {
  const [partnersList, setPartnersList] = useState<IFulfilmentPartners[]>([]);
  const [selectedValue, setSelectedValue] = useState<IFulfilmentPartners | undefined>();

  const handleChange = (event: any) => {
    const value = partnersList.filter((x) => x.partnerCode === event.target.value)[0];
    if (value) {
      setSelectedValue(value!);

      if (props.callback) {
        props.callback(value);
      }
    }
  };

  const getPartnersList = useCallback(async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;

    try {
      const response = await axios.get(url);

      //set to state
      setPartnersList(response.data);
      localStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const partnerList = localStorage.getItem('partnersList');

    if (partnerList !== null && typeof partnerList !== 'undefined') {
      const parsedPartnerList = JSON.parse(partnerList);
      setPartnersList(parsedPartnerList);
    } else {
      //call api
      getPartnersList();
    }
  }, [getPartnersList]);

  return (
    <div>
      <select
        required
        value={selectedValue?.partnerCode}
        placeholder='Partner'
        onChange={handleChange}
        className='custom-select mb-2 mr-sm-2'
      >
        <option value=''>All</option>
        {partnersList.map((option) => (
          <option value={option.partnerCode} key={option.partnerId}>
            {option.partnerName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FulfilmentPartners;
