import { useEffect, useState } from 'react';
import {
  IAddEditPharmacy,
  IPharmacyAddressDTO,
  IPharmacyData,
} from '../../../interface/PharmacyInterface';
import { Modal } from 'react-bootstrap';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { PHARMACY_STATUS, PharmacyType } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import { STATES_LGA } from '../../../utils/states_lga';
import ButtonLoader from '../../common/ButtonLoader';
import { PharmacyOperationOfficers } from '../../../utils/constants';
import { UserNotes, inituserNotes } from '../../../interface/UserNotesInterface';
//import { usePlacesWidget } from 'react-google-autocomplete';

const AddEditPharmacyModal = ({ open, close, modalData, getPharmacies }: IAddEditPharmacy) => {
  const [loading, setLoading] = useState(false);
  // const [localState] = useState(initialPharmacyData);
  const [userNote, setUserNote] = useState<UserNotes>(inituserNotes);
  const [localState, setLocalState] = useState<IPharmacyData>(modalData);
  const [addressInfo, setAddressInfo] = useState<IPharmacyAddressDTO>(
    modalData.addressInformation!
  );
  const [showNote, setShowNote] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setUserNote({
      ...userNote,
      [input]: event.target.value,
    });
  };

  const handleModalChange = (input: string) => (e: any) => {
    if (e.target.type === 'checkbox') {
      setLocalState({
        ...localState,
        payInstantly: e.target.checked,
      });
      return;
    }

    if (input === 'status') {
      let selectedState = e.target.value;

      if (selectedState === 'Cancelled') {
        setShowNote(true);
      } else {
        setShowNote(false);
      }
    }

    setLocalState({
      ...localState,
      [input]: e.target.value,
    });
  };

  const handleAddress = (input: string) => (e: any) => {
    if (input === 'stateOfPremise') {
      let selectedState = e.target.value;
      let selectedLgas = STATES_LGA.filter((x) => x.state === selectedState)[0].locals;
      //set first lga as the selected

      setAddressInfo({
        ...addressInfo,
        lga: selectedLgas[0].name,
        stateOfPremise: selectedState,
      });
      //setLgas(lgas);
      return;
    }

    setAddressInfo({
      ...addressInfo,
      [input]: e.target.value,
    });
  };

  const saveNote = async () => {
    userNote.noteType = 'Pharmacy';
    userNote.noteTypeId = modalData.pharmacyId.toString();

    const url = `${endpoints.UserNotes.mainUrl}`;
    setLoading(true);
    try {
      await axios.post(url, userNote);

      addToast('Notes added successfully', 'success');
      setUserNote(inituserNotes);
    } catch (error) {
      addToast('An error occured when adding note', 'error');
    } finally {
      setLoading(false);
    }
  };

  const updatePharmacy = async (event: any) => {
    if (!isFormValidated('pharmacy-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }

    event.preventDefault();

    let url = endpoints.InsurancePharmacy.mainUrl;
    let message = 'Pharmacy Successfully Updated';
    let errorMessage = 'Error Updating Pharmacy';

    // set values from google place api

    // modalData.pharmacyAddress = localState.pharmacyAddress;
    // modalData.stateOfPremise = localState.stateOfPremise;
    // modalData.locationArea = localState.locationArea;
    // modalData.lga = localState.lga;
    // modalData.locationLatitude = localState.locationLatitude;
    // modalData.locationLongitude = localState.locationLongitude;

    // modalData.pharmacyAddress =
    //   localState.pharmacyAddress === '' ? modalData.pharmacyAddress : localState.pharmacyAddress;
    // modalData.stateOfPremise =
    //   localState.stateOfPremise === '' ? modalData.stateOfPremise : localState.stateOfPremise;
    // modalData.locationArea =
    //   localState.locationArea === '' ? modalData.locationArea : localState.locationArea;
    // modalData.lga = localState.lga === '' ? modalData.lga : localState.lga;
    // modalData.locationLatitude =
    //   localState.locationLatitude === '' ? modalData.locationLatitude : localState.locationLatitude;
    // modalData.locationLongitude =
    //   localState.locationLongitude === ''
    //     ? modalData.locationLongitude
    //     : localState.locationLongitude;

    setLoading(true);

    if (!addressInfo.longitude || addressInfo.longitude === null) {
      addressInfo.longitude = '0';
    }
    if (!addressInfo.googleAddress || addressInfo.googleAddress === null) {
      addressInfo.googleAddress = '0';
    }
    if (!addressInfo.latitude || addressInfo.latitude === null) {
      addressInfo.latitude = '0';
    }

    localState.addressInformation = addressInfo;

    try {
      await axios.put(url, localState);
      addToast(message, 'success');

      if (showNote) {
        await saveNote();
      }

      getPharmacies();
      close();
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  // const { ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //   onPlaceSelected: (place) => {
  //     const selectedAddress = place.address_components;
  //     var placeLng = place.geometry.location.lng();
  //     var placeLat = place.geomatry.location.lat();
  //     setLocalState({
  //       ...localState,
  //       pharmacyAddress: `${selectedAddress[0].long_name} ${selectedAddress[1].long_name} ${selectedAddress[2].long_name} ${selectedAddress[3].long_name}`,
  //       stateOfPremise: `${selectedAddress[5].short_name}`,
  //       lga: `${selectedAddress[4].short_name}`,
  //       locationArea: `${selectedAddress[3].short_name}`,
  //       locationLatitude: placeLat,
  //       locationLongitude: placeLng,
  //     });
  //   },
  //   options: {
  //     types: ['address'],
  //     componentRestrictions: { country: 'ng' },
  //   },
  // });

  const statesType = STATES_LGA;
  const statesMap = statesType.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const stateFilter = STATES_LGA.filter((selectedState) => {
    let stateOfPremise = 'Abia';
    if (addressInfo!.stateOfPremise !== null && addressInfo!.stateOfPremise !== '') {
      stateOfPremise = addressInfo!.stateOfPremise;
    }
    addressInfo.stateOfPremise! = stateOfPremise;
    return selectedState.state.toLowerCase() === stateOfPremise.toLowerCase(); //modalData.stateOfPremise?.toLowerCase();
  });

  let lgas = stateFilter[0]?.locals.map((lga) => {
    return {
      key: lga.id,
      text: lga.name,
      value: lga.name,
    };
  });

  useEffect(() => {
    setLocalState(modalData);
    setAddressInfo(modalData.addressInformation!);
  }, [modalData]);

  return (
    <Modal show={open} onHide={close} size='lg'>
      <form id='pharmacy-modal'>
        <fieldset disabled={loading}>
          <Modal.Header>
            <Modal.Title>Pharmacy Details</Modal.Title>
            {/* <button className='btn btn-default' onClick={editForm}>
          <i className='bi bi-pencil-square ' style={{ cursor: 'pointer', fontSize: '1.2rem' }}>
            Edit
          </i>
        </button> */}
          </Modal.Header>
          <Modal.Body>
            <div className='row mt-2'>
              <div className='form-group col-sm-6'>
                <label>Pharmacy Name</label>
                <input
                  required
                  type='text'
                  value={localState.pharmacyName}
                  onChange={handleModalChange('pharmacyName')}
                  className='form-control'
                />
              </div>
              <div className='form-group col-sm-6'>
                <label>Phone</label>
                <input
                  required
                  type='text'
                  value={localState.phoneNumber}
                  onChange={handleModalChange('phoneNumber')}
                  className='form-control'
                  maxLength={13}
                  pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                  minLength={11}
                />
              </div>

              <div className='form-group col-sm-3'>
                <label>Contact</label>
                <input
                  type='text'
                  required
                  value={localState.contacts}
                  onChange={handleModalChange('contacts')}
                  className='form-control'
                />
              </div>
              <div className='form-group col-sm-3'>
                <label>Client Contact</label>
                <input
                  type='text'
                  required
                  value={localState.clientContact}
                  onChange={handleModalChange('clientContact')}
                  className='form-control'
                />
              </div>
              <div className='form-group col-sm-6'>
                <label>Pharmacy Address</label>
                <input
                  required
                  type='text'
                  value={addressInfo.pharmacyAddress}
                  onChange={handleAddress('pharmacyAddress')}
                  className='form-control'
                  //ref={(ref as unknown) as React.LegacyRef<HTMLInputElement>}
                />
              </div>

              <div className='form-group col-sm-6'>
                <label>State Location</label>
                <select
                  required
                  className='custom-select'
                  value={addressInfo.stateOfPremise}
                  onChange={handleAddress('stateOfPremise')}
                >
                  {statesMap.map((states) => (
                    <option key={states.key} value={states.value}>
                      {states.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group col-sm-6'>
                <label>LGA</label>
                <select
                  required
                  className='custom-select'
                  value={addressInfo.lga}
                  onChange={handleAddress('lga')}
                >
                  {lgas ? (
                    lgas.map((lga) => (
                      <option key={lga.key} value={lga.value}>
                        {lga.text}
                      </option>
                    ))
                  ) : (
                    <option>No LGAs</option>
                  )}
                </select>
              </div>

              <div className='form-group col-sm-4'>
                <label>Activity Status</label>
                <select
                  className='custom-select'
                  value={localState.status}
                  onChange={handleModalChange('status')}
                >
                  {PHARMACY_STATUS.map((status) => (
                    <option key={status.key} value={status.value}>
                      {status.text}
                    </option>
                  ))}
                </select>
              </div>
              {showNote ? (
                <div className='form-group col-sm-8'>
                  <label>Reason for deactivation</label>
                  <input
                    type='text'
                    value={userNote.noteContent}
                    onChange={handleChange('noteContent')}
                    className='form-control'
                    required
                  />
                </div>
              ) : (
                ''
              )}
              <div className='form-group col-sm-4'>
                <label>Provider Type</label>
                <select
                  className='custom-select'
                  value={localState.providerType}
                  onChange={handleModalChange('providerType')}
                >
                  {PharmacyType.map((status) => (
                    <option key={status.key} value={status.value}>
                      {status.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-check m-auto col-sm-4'>
                <input
                  className='form-check-input'
                  checked={localState.payInstantly}
                  onChange={handleModalChange('payInstantly')}
                  type='checkbox'
                  defaultChecked={localState.payInstantly}
                />

                <label className='form-check-label'>Pay Instantly via Wallet?</label>
              </div>

              <div className='form-group col-sm-4'>
                <label>Email</label>
                <input
                  type='text'
                  value={localState.email}
                  onChange={handleModalChange('email')}
                  className='form-control'
                />
              </div>

              <div className='form-group col-sm-4'>
                <label>Relationship Officer</label>
                <select
                  className='custom-select'
                  onChange={handleModalChange('relationshipOfficer')}
                  value={localState.relationshipOfficer}
                >
                  {PharmacyOperationOfficers.map((x) => (
                    <option value={x}>{x}</option>
                  ))}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-primary' onClick={updatePharmacy}>
              Update
              {loading ? <ButtonLoader /> : ''}
            </button>
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default AddEditPharmacyModal;
