import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { addToast } from '../../../utils/toastNotifications';
// import axios from 'axios';

const ComplianceFormModal = ({ open, close }: { open: boolean; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [rcNumber, setRCNumber] = useState('');
  const [bvn, setBVN] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();

  const bvnChange = async (bvnNumber: string) => {
    setBVN(bvnNumber);

    if (bvn.length === 11) {
      // api call to verify BVN
      setLoading(true);
      try {
      } catch (error: any) {
        addToast('Error verifying BVN', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const fileChange = async (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadFile = async () => {
    try {
      if (selectedFile) {
        // create formdata object
        const fileData = new FormData();

        // update formdata object
        fileData.append('CAC Certificate', selectedFile, selectedFile.name);
        // axios call below
      } else {
        addToast('No File Selected', 'warning');
      }
    } catch (error: any) {
      addToast('Error uploading CAC Document', 'error');
    }
  };

  const saveInformation = async () => {
    setLoading(true);
    try {
      // upload CAC Document
      await uploadFile();

      // add compliance info to new pharmacy payload object
    } catch (error: any) {
      addToast('Error saving compliance information', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={() => close()}>
      <form id='compliance-form'>
        <fieldset disabled={loading}>
          <Modal.Header>
            <Modal.Title>Compliance Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <label>BVN</label>
              <input
                className='form-control'
                type='text'
                value={bvn}
                onChange={(e) => bvnChange(e.target.value)}
                maxLength={11}
              />
            </div>
            <div className='form-group'>
              <label>RC Number</label>
              <input
                className='form-control'
                type='text'
                value={rcNumber}
                onChange={(e) => setRCNumber(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>CAC Certificate</label>
              <input className='form-control' type='file' onChange={fileChange} />
              <span>*Allowed file formats - (jpg, pdf, doc)</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <ButtonLoader />
            ) : (
              <button className='btn btn-primary' onClick={saveInformation}>
                Save
              </button>
            )}
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default ComplianceFormModal;
