import React, { useState, useEffect, useContext, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import { AppContext } from '../../../context/AppContext';
import axios from 'axios';
import {
  initialSubscriptionResponse,
  ISubscriptionResponse,
} from '../../../interface/SubscriptionInterface';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { addToast } from '../../../utils/toastNotifications';
import MoreInfoModal from './MoreInfoModal';
import { Link } from 'react-router-dom';
import PaginationBar from '../../common/Pagination';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import TableLoading from '../../common/TableLoading';
// import { useParams } from 'react-router-dom';
import ButtonLoader from '../../common/ButtonLoader';
import { IRealTimeUpdateProp } from '../../../interface/AppInterface';
import PaymentLinkModal from './PaymentLinkModal';

const SubscriptionTable = ({ history }: any) => {
  const { dispatch } = useContext(AppContext);

  // get partner code from url
  // const { partnercode } = useParams() as any;

  const searchParam = new URLSearchParams(window.location.search);
  const agentType = searchParam.get('type');
  const code = searchParam.get('code');

  const [allData, setAllData] = useState<ISubscriptionResponse[]>([]); //to hold all table result in unfiltered format
  const [tableData, setTableData] = useState<ISubscriptionResponse[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(initialSubscriptionResponse);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [advSearch, setAdvSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [showPagination, setShowPagination] = useState(false);
  const { appState } = useContext(AppContext);

  const loadSubscriptions = useCallback(
    async (pageIndex: number) => {
      let url;
      let urlType;

      if (agentType !== null && code !== null) {
        url = `${endpoints.InsuranceSubscriptions.getByPartnerCode}/${code}?acquisitionChannel=${agentType}`;
        urlType = 'external';
      } else {
        url = `${endpoints.InsuranceSubscriptions.mainUrl}?pageSize=200&pageIndex=${pageIndex}`;
        urlType = 'internal';
      }
      try {
        setIsLoading(true);
        const response = await axios.get(url);

        if (urlType === 'external') {
          setTableData(response.data);
          setAllData(response.data);
          setShowPagination(false);
        } else {
          setTableData(response.data.data);
          setAllData(response.data.data);
          setShowPagination(true);
        }

        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);

        if (response.data.length === 0) {
          addToast('No subscriptions found', 'info');
        }
      } catch (error: any) {
        addToast('Error Getting Subscriptions', 'error');
        // setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [agentType, code]
  );

  const goToPage = async (subscription: ISubscriptionResponse, route: string) => {
    await dispatch({
      type: 'SET_SUBSCRIPTION_CODE',
      payload: subscription.subscriptionCode,
    });
    await dispatch({
      type: 'SET_PAYMENT_PLAN',
      payload: subscription.paymentPlan,
    });
    await dispatch({
      type: 'SET_PEOPLE_IN_PLAN',
      payload: subscription.numberOfSubscribers,
    });
    if (route === 'claims') {
      history.push(`/claims/${subscription.subscriptionCode}`);
    } else if (route === 'beneficiary') {
      history.push(`/subscriptions/${subscription.subscriptionCode}/beneficiaries`);
    } else if (route === 'claims/add') {
      history.push(`/subscriptions/${subscription.subscriptionCode}/claims/add`);
    } else {
      history.push(`/subscriptions/${subscription.subscriptionCode}/payments`);
    }
  };

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setTableData(allData);
      setShowPagination(true);
      setAdvSearch(false);
      return;
    }

    setShowPagination(false);

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.firstName?.toLowerCase().includes(searchParam) ||
        x.lastName?.toLowerCase().includes(searchParam) ||
        x.phone?.toLowerCase().includes(searchParam) ||
        x.email?.toLowerCase().includes(searchParam)
    );

    //set table data to search result
    setTableData(searchResult);

    if (searchParam.length >= 3) {
      //addToast('Searching data remote');
      //add delay if user is stil typing
      setAdvSearch(true);
    } else {
      setIsLoading(false);
    }
  };

  const statusColor = (status: string) => {
    switch (status) {
      case 'Active':
        return 'text-success';
      case 'MissedPayment':
        return 'text-warning';
      default:
        return 'text-danger';
    }
  };

  const searchRemoteData = async () => {
    let searchParamInput = document.getElementById('sub-search-param') as HTMLInputElement;
    const advSearchParam = searchParamInput.value;

    if (advSearchParam === '') {
      return;
    }
    //call remote api
    //set table data to result. allData still holds initial result? (or not)
    const url = `${endpoints.InsuranceSubscriptions.mainUrl}/search?searchparam=${advSearchParam}`;
    setIsSearch(true);
    try {
      const response = await axios.get(url);
      setTableData(response.data);
    } catch (error: any) {
      addToast('An error occured');
    } finally {
      setIsSearch(false);
    }
  };

  // const openInfoModal = async (data: ISubscriptionResponse) => {
  //   setModalData(data);
  //   setModalOpen(true);
  // };

  const closeInfoModal = () => {
    setModalOpen(false);
  };

  const handleModalChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalData({
      ...modalData,
      [input]: event.target.value,
    });
  };

  const getUpdatedSubscriptions = async (subscriptionCode: string) => {
    let url = `${endpoints.InsuranceSubscriptions.mainUrl}/subscriptioncode/${subscriptionCode}`;

    await axios
      .get(url)
      .then((response) => {
        const updatedData = response.data;

        const oldData = tableData.filter((x) => x.subscriptionCode === subscriptionCode)[0];
        const parsedOldData = oldData || null;
        const filteredData: any = [...tableData];

        if (parsedOldData !== null) {
          filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
        } else {
          filteredData.unshift(updatedData);
        }
        setTableData(filteredData);
        setAllData(filteredData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const openPaymentLink = () => {
    setShowPaymentModal(!showPaymentModal);
  };

  useEffect(() => {
    const runOnComponentMount = async () => {
      await loadSubscriptions(1);
    };
    runOnComponentMount();
  }, [loadSubscriptions]);

  useEffect(() => {
    //use the request id to update
    const realTimeData = appState.insuranceSubscriptions as IRealTimeUpdateProp;

    //all efforts to move this out of the useEffect was not possible without
    //resorting to suppressing es-lint warnings

    if (realTimeData != null) {
      getUpdatedSubscriptions(realTimeData.dataId);
    }
  }, [appState.insuranceSubscriptions]); // eslint-disable-line react-hooks/exhaustive-deps

  let paymentModal: JSX.Element = (
    <PaymentLinkModal open={showPaymentModal} close={setShowPaymentModal} />
  );

  let infoModal: JSX.Element = (
    <MoreInfoModal
      open={modalOpen}
      close={closeInfoModal}
      modalData={modalData}
      handleModalChange={handleModalChange}
    />
  );

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col-4'>Actions</th>
        <th scope='col-4'>Onboarding status</th>
        <th scope='col'>First Name</th>
        <th scope='col'>Last Name</th>
        <th scope='col'>Phone</th>
        <th scope='col'>Product</th>
        <th scope='col'>Status</th>
        <th scope='col'>Amount</th>
        <th scope='col'>Payment Plan</th>
        <th scope='col'>No. of Subscribers</th>
        <th scope='col'>Subscription Code</th>
        <th scope='col'>Date Created</th>
      </tr>
    </thead>
  );

  let noDataView = (
    <tbody className='text-center'>
      <tr>
        <td colSpan={11}>No Data found</td>
      </tr>
    </tbody>
  );

  let tableBody: JSX.Element = (
    <>
      {tableData ? (
        <tbody>
          {tableData.map((subscription: ISubscriptionResponse) => {
            return (
              <tr
                key={subscription.subscriptionCode}
                className={statusColor(subscription.status)}
                // onClick={() => openInfoModal(subscription)}
              >
                <td>
                  <i
                    className='bi bi-bar-chart-fill group-icon d-sm-none '
                    onClick={() => goToPage(subscription, 'claims')}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='View Subscription Claims'
                  />
                  <i
                    className='bi bi-people-fill group-icon d-sm-none'
                    onClick={() => goToPage(subscription, 'beneficiary')}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='View Beneficiaries'
                  />
                  <i
                    className='bi bi-credit-card-fill group-icon d-sm-none'
                    onClick={() => goToPage(subscription, 'payment')}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='View Payments'
                  />
                  <i
                    className='bi bi-plus-square-fill group-icon d-sm-none'
                    onClick={() => goToPage(subscription, 'claims/add')}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Add Claim'
                  />
                  <span className='d-none d-sm-block'>
                    <Link
                      to={`/subscriptions/${subscription.subscriptionCode}/details`}
                      className='btn btn-primary btn-block btn-sm'
                    >
                      View
                    </Link>
                  </span>
                </td>
                <td>{subscription.onboardingStatus}</td>
                <td>{subscription.firstName}</td>
                <td>{subscription.lastName}</td>
                <td>{subscription.phone}</td>
                <td>{subscription.product}</td>
                <td>{subscription.status}</td>
                <td>{subscription.amount}</td>
                <td>{subscription.paymentPlan}</td>
                <td>{subscription.numberOfSubscribers}</td>
                <td>{subscription.subscriptionCode}</td>
                <td>{formatDateTime(subscription.dateCreated)}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        noDataView
      )}
    </>
  );

  // const toCSV = (table: HTMLElement) => {
  //   // query rows
  //   const rows = table.querySelectorAll('tr');

  //   return [].slice
  //     .call(rows)
  //     .map((row: any) => {
  //       // query all cells
  //       const cells = row.querySelectorAll('th,td');
  //       return [].slice
  //         .call(cells)
  //         .map((cell: any) => {
  //           return cell.textContent;
  //         })
  //         .join(',');
  //     })
  //     .join('\n');
  // };

  // const download = (text: any, filename: string) => {
  //   const link = document.createElement('a');
  //   link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
  //   link.setAttribute('download', filename);

  //   link.style.display = 'none';
  //   document.body.appendChild(link);

  //   link.click();

  //   document.body.removeChild(link);
  // };

  // const exportToCSV = () => {
  //   const table = document.getElementById('table');

  //   // export to csv
  //   const csv = toCSV(table as HTMLElement);
  //   // download file
  //   download(csv, 'subscription.csv');
  // };

  return (
    <div>
      {paymentModal}
      {infoModal}
      <br />
      <div className='row'>
        <div className='col'>
          <h3 className=''>
            Subscriptions {showPagination ? `(${pageInfo.pageCount * pageInfo.pageSize})` : ''}
          </h3>
        </div>
        {/* <div className='col text-right'>
          <button onClick={exportToCSV} id='export-button' className='btn btn-outline-primary'>
            Export Table to CSV
          </button>
        </div> */}
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='form-inline'>
            <input
              type='search'
              className='form-control'
              placeholder='Search by name and phone'
              onChange={searchTable}
              id='sub-search-param'
            />
            {advSearch ? (
              <button className='btn btn-success' onClick={searchRemoteData}>
                {isSearch ? <ButtonLoader /> : 'Search'}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className='col-6 text-right'>
          <button className='btn btn-primary' onClick={openPaymentLink}>
            Create Payment link
          </button>
          <button className='ml-2 btn btn-primary'>
            <Link to='/subscriptions/add' style={{ textDecoration: 'none', color: 'black' }}>
              Add Subscription
            </Link>
          </button>
        </div>
      </div>
      <br />
      {isLoading ? (
        <TableLoading />
      ) : (
        <div className='table-responsive'>
          <table id='table' className='table table-bordered table-hover'>
            {tableHead}
            {tableBody}
          </table>
        </div>
      )}
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={loadSubscriptions}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default SubscriptionTable;
