import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ILeadsDTO, initialleadDto } from '../../../interface/LeadsInterface';
import { addToast } from '../../../utils/toastNotifications';
import { insurancePlans } from '../../../utils/insurancePlans';
import { PAYMENT_PLAN_MAPPING, PRODUCT_TYPE } from '../../../utils/mappings';
import { isFormValidated } from '../../../utils/formUtils';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { NairaSymbol } from '../../../utils/constants';
import { IPaymentLinkDTO } from '../../../interface/ReceivedPaymentInterface';

const PaymentLinkModal = ({ open, close }: { open: boolean; close: Function }) => {
  const [lead, setLead] = useState<ILeadsDTO>(initialleadDto);
  const [amount, setAmount] = useState(600);
  const [paymentLink, setPaymentLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (inputName: string) => (event: any) => {
    const value = event.target.value;

    setLead({
      ...lead,
      [inputName]: value,
    });
  };

  const getPaymentLink = async (leadId: number) => {
    const url = endpoints.Website.generatePaymentLink;
    const payload: IPaymentLinkDTO = {
      email: lead.email,
      amount: amount * 100,
      reference: `Insurance-${lead.productType}-${new Date().getTime()}`,
      callback_url: '',
      metaData: {
        phoneNumber: lead.phoneNumber,
        orderId: leadId.toString(),
        totalAmount: amount,
      },
    };
    try {
      const response = await axios.post(url, payload);
      setPaymentLink(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      addToast('An error occured generating payment link', 'error');
    }
  };

  const submit = async (event: any) => {
    if (!isFormValidated('paymentlink-modal')) {
      return;
    }

    event.preventDefault();
    const url = endpoints.Website.createLead;
    setIsLoading(true);
    try {
      const response = await axios.post(url, lead);
      getPaymentLink(response.data);
    } catch (error) {
      console.error(error);
      addToast('An error occured', 'error');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const calculatePayment = () => {
      const paymentPlan = parseInt(lead.numberOfMonths.toString());
      const totalBeneficiaries = parseInt(lead.peopleInPlan.toString());
      const product = lead.productType;

      if (totalBeneficiaries < 1) {
        addToast('You must add atleast one beneficiary ', 'warning');
        return;
      }

      const selectedPayment = insurancePlans.filter(
        (x) =>
          x.numberOfPersons === totalBeneficiaries &&
          x.numberOfMonths === paymentPlan &&
          x.product === product
      );

      if (selectedPayment && selectedPayment.length > 0) {
        const price = selectedPayment[0].price;
        setAmount(price);
      }
    };
    calculatePayment();
  }, [lead.numberOfMonths, lead.peopleInPlan, lead.productType]);

  useEffect(() => {
    if (open) {
      setLead(initialleadDto);
      setIsLoading(false);
    }
  }, [open]);

  return (
    <Modal show={open} onHide={() => close()} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Generate Payment Link</Modal.Title>
      </Modal.Header>
      <form id='paymentlink-modal'>
        <fieldset disabled={isLoading}>
          <Modal.Body className='modal-body'>
            <div className='col-md-12'>
              <label htmlFor='firstName' className='form-label'>
                First Name
              </label>
              <input
                value={lead.firstName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('firstName')}
              />
            </div>

            <div className='col-md-12 mt-3'>
              <label htmlFor='lastName' className='form-label'>
                Last Name
              </label>
              <input
                value={lead.lastName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('lastName')}
              />
            </div>

            <div className='col-md-12 mt-3'>
              <label htmlFor='phoneNumber' className='form-label'>
                Phone number
              </label>
              <input
                value={lead.phoneNumber}
                maxLength={13}
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                className='form-control '
                type='tel'
                required
                onChange={handleChange('phoneNumber')}
              />
            </div>

            <div className='col-md-12 mt-3'>
              <label htmlFor='email' className='form-label'>
                Email address
              </label>
              <input
                value={lead.email}
                className='form-control '
                type='email'
                required
                placeholder='name@example.com'
                onChange={handleChange('email')}
              />
            </div>
            <div className='col-md-12 mt-3'>
              <label htmlFor='email' className='form-label'>
                Number of beneficiaries
              </label>
              <select
                className='custom-select'
                value={lead.peopleInPlan}
                onChange={handleChange('peopleInPlan')}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
            </div>
            <div className='col-md-12 mt-3'>
              <label className='form-label'>Product</label>
              <select
                value={lead.productType}
                required
                onChange={handleChange('productType')}
                className='custom-select'
              >
                {PRODUCT_TYPE.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-12 mt-3'>
              <label className='form-label'>Payment Plan</label>
              <select
                required
                value={lead.numberOfMonths}
                onChange={handleChange('numberOfMonths')}
                className='custom-select'
              >
                {PAYMENT_PLAN_MAPPING.map((option) => (
                  <option key={option.key} value={option.numberOfMonths}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-12 mt-3'>
              <label className='form-label'>
                Amount to pay: {NairaSymbol}
                {amount}
              </label>
            </div>
            <div className='col-md-12 mt-3'>
              <label className='form-label'>
                Payment link:
                <a href={paymentLink} className='ml-2' target='_blank' rel='noreferrer'>
                  {paymentLink}
                </a>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='btn btn-primary' onClick={submit}>
              Generate Link
            </button>
          </Modal.Footer>
        </fieldset>
      </form>
    </Modal>
  );
};

export default PaymentLinkModal;
