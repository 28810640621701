import { useCallback, useEffect, useRef, useState } from 'react';
import { IPharmacyAddressingDTO, IPharmacyMapData } from '../../../interface/PharmacyInterface';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { MapMarkerColors } from '../../../utils/mappings';

//for google types: https://www.npmjs.com/package/@types/google.maps

const PharmacyMapSidebar = ({
  showModal,
  close,
  setModalPharmacy,
  startingAddress,
}: {
  showModal: boolean;
  close: Function;
  setModalPharmacy?: Function;
  startingAddress?: string;
}) => {
  const [searchResult, setSearchResult] = useState<IPharmacyMapData[]>([]);
  const [pharmacyListData, setPharmacyListData] = useState<IPharmacyAddressingDTO[]>([]);

  let autocomplete = useRef<google.maps.places.Autocomplete | null>(null);
  let map = useRef<google.maps.Map | null>(null);
  let advancedMarker = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  let distanceMatrix = useRef<google.maps.DistanceMatrixService | null>(null);

  // const { AdvancedMarkerElement } = (google.maps.importLibrary(
  //   'marker'
  // )) as google.maps.MarkerLibrary;

  //   let fullAddress = '';

  //   const componentForm = {
  //     plus_code: 'long_name', //google plus code
  //     street_number: 'long_name', //street number
  //     route: 'long_name', //street name
  //     locality: 'long_name', //Name of big town/City e.g Lagos
  //     administrative_area_level_1: 'long_name', //STate
  //     administrative_area_level_2: 'long_name', //LGA
  //     sublocality_level_1: 'long_name', //Area
  //     neighborhood: 'long_name', //Area
  //     country: 'long_name', //
  //     postal_code: 'short_name',
  //     // latitude: "lat",
  //     // longitude: "lng",
  //   };

  const createMarker = (
    place: google.maps.places.PlaceResult,
    id: number,
    userLocation: google.maps.LatLng,
    isWellaPharmacy = false
  ) => {
    const pinBackground = new google.maps.marker.PinElement({
      background: MapMarkerColors.InternalPharmacy,
      borderColor: MapMarkerColors.InternalPharmacy,
      glyphColor: '#fff',
    });

    const idName = `place-${id}`;
    const idIcon = `icon-${id}`;

    const infoContent = `<h6 class='fs-7'>${place.name}</h6><strong class='fs-8'>${place.vicinity}</div>
      <div id=${idName} style='margin-top: 10px;border-top: 1px solid #e0e0e0;padding-top: 5px;'>
      <span id=${idIcon} class='bi bi-person-walking'>checking...</span></div>`;

    const infowindow = new google.maps.InfoWindow({
      content: infoContent,
      ariaLabel: place.name,
      maxWidth: 200,
    });

    const marker = new google.maps.marker.AdvancedMarkerElement({
      map: map.current,
      position: {
        lat: place.geometry?.location?.lat()! + getRandomOffset(),
        lng: place.geometry?.location?.lng()! + getRandomOffset(),
      },
      title: place.name,
      content: isWellaPharmacy ? pinBackground.element : null,
    });

    marker.addListener('click', () => {
      infowindow.open({
        anchor: marker,
        map: map.current,
      });
      //loadDistance(idName);
      getRoutes(userLocation, place.geometry?.location!, idName);
    });
  };

  const searchPharmaciesNearby = useCallback(
    (userLocation: google.maps.LatLng) => {
      const service = new google.maps.places.PlacesService(map.current!);
      service.nearbySearch(
        {
          location: userLocation,
          //radius: 10000, // Search within a 5km radius
          //type: 'pharmacy | health',
          type: 'pharmacy',
          rankBy: google.maps.places.RankBy.DISTANCE,
        },
        function (results, status) {
          if (status === 'OK' && results && results?.length > 0) {
            //center the patient location
            const latlng = new google.maps.LatLng(userLocation.lat(), userLocation.lng());

            map.current!.setCenter(latlng);

            const pinBackground = new google.maps.marker.PinElement({
              background: MapMarkerColors.StartingLocation,
              borderColor: MapMarkerColors.StartingLocation,
              glyphColor: '#fff',
            });

            new google.maps.marker.AdvancedMarkerElement({
              map: map.current!,
              position: latlng,
              title: `Starting location`,
              content: pinBackground.element,
              gmpDraggable: true,
            });

            const pharmacySearchResult: IPharmacyMapData[] = [];

            //if search result  not zero, compute route info
            //Display markers for each pharmacy
            for (let i = 0; i < results.length; i++) {
              //clear state
              setSearchResult([]);

              // Check if the found hospital is in the known hospitals list
              //if (knownHospitals.includes(hospitalName)) {
              try {
                const rxLocation = results[i].geometry!.location;
                //check if that location exists on our network, if yes, color differently
                let isWellaPharmacy = false;
                if (pharmacyListData && pharmacyListData.length > 0) {
                  isWellaPharmacy =
                    pharmacyListData.filter(
                      (x) =>
                        x.addressInformation.longitude === rxLocation?.lng().toString() &&
                        x.addressInformation.latitude === rxLocation.lat().toString()
                    ).length > 0;
                }

                createMarker(results[i], i, userLocation!, isWellaPharmacy);

                const pharmacyMapInfo: IPharmacyMapData = {
                  geolocation: rxLocation,
                  pharmacyName: results[i].name!,
                  address: results[i].vicinity!,
                  isWellaPharmacy: isWellaPharmacy,
                  status: 'Active',
                  rankId: i,
                };
                pharmacySearchResult.push(pharmacyMapInfo);
                //populate array
              } catch (error) {
                console.error(error);
              }
              //}
            }
            setSearchResult(pharmacySearchResult);
          }
        }
      );
    },
    [pharmacyListData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getRoutes = (origin: google.maps.LatLng, destination: google.maps.LatLng, id: string) => {
    if (typeof origin === 'undefined' || typeof destination === 'undefined') {
      return;
    }

    //optimize. check if the id has text value already: id=icon-${id}
    const idIcon = document.getElementById(`icon${id}`) as HTMLElement;
    if (idIcon !== null && typeof idIcon !== 'undefined') {
      if (idIcon.innerText !== '') {
        return;
      }
    }

    const request: google.maps.DistanceMatrixRequest = {
      destinations: [origin],
      origins: [destination],
      travelMode: google.maps.TravelMode.WALKING,
    };

    distanceMatrix.current?.getDistanceMatrix(request, function (results) {
      //check nulls and empty
      const distanceResult = results?.rows[0].elements[0];
      if (distanceResult?.status === google.maps.DistanceMatrixElementStatus.OK) {
        //
        const walking = document.getElementById(id) as HTMLElement;
        if (walking && typeof walking !== 'undefined') {
          //walking.innerText = 'Run';
          const icon = `<span id=icon-${id} class='bi bi-person-walking'>Time: ${distanceResult.duration.text}. Distance: ${distanceResult.distance.text}</span>`;
          walking.innerHTML = icon;
        }
      }
    });
  };

  const fillInAddress = useCallback(() => {
    try {
      // Get the place details from the autocomplete object.
      const place = autocomplete.current!.getPlace();

      //// TODO: Save / Update Patient information using the commented out code

      //// to get the address info, we need to set :
      //// autocomplete.setFields(['address_component', 'geometry', 'formatted_address', 'place_id']); //"name","adr_address"
      //// in the initAutoComplete function

      // let placeId = place.place_id;
      // fullAddress = place.formatted_address;

      // let addressInformation = {
      //   plus_code: '',
      //   street_number: '', //street number
      //   route: '', // street name
      //   locality: '', //Name of big town/City e.g Lagos
      //   administrative_area_level_1: '', //State
      //   administrative_area_level_2: '', //LGA
      //   sublocality_level_1: '', //Area
      //   neighborhood: '', //Area
      //   country: '', //Country
      //   postal_code: '', //Postal code
      //   colloquial_area: '', //Area
      // };

      // for (const component of place.address_components) {
      //   const addressType = component.types[0]; // as string;

      //   //if proprerty exist
      //   if (addressType in addressInformation) {
      //     const addressValue = component['long_name']; // as string;

      //     //https://stackoverflow.com/a/62438434/2929906
      //     let addressKey = addressType as keyof typeof addressInformation;
      //     addressInformation[addressKey] = addressValue;
      //   }
      // }

      // //TODO: Save so we don't have to search again
      // const locationArea =
      //   addressInformation.neighborhood.trim() ||
      //   addressInformation.sublocality_level_1.trim() ||
      //   addressInformation.colloquial_area.trim();

      // const lga =
      //   addressInformation.administrative_area_level_2.trim() || addressInformation.locality.trim();
      // let stateOfPremise = addressInformation.administrative_area_level_1;
      // let streetInfo = `${addressInformation.street_number} ${addressInformation.route}`;
      // const postalCode = addressInformation.postal_code;
      // const plusCode = addressInformation.plus_code;

      if (place.geometry && place.geometry.location) {
        const locationData = place.geometry.location;

        searchPharmaciesNearby(locationData);
      }
    } catch (error) {
      console.error(error);
    }
  }, [searchPharmaciesNearby]);

  const initAutocomplete = useCallback(async () => {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    try {
      const { Autocomplete } = (await google.maps.importLibrary(
        'places'
      )) as google.maps.PlacesLibrary;

      const autoCompleteComponent = document.getElementById('autocomplete') as HTMLInputElement;

      autocomplete.current = new Autocomplete(
        autoCompleteComponent,
        { types: [], componentRestrictions: { country: 'ng' } }
        //{ types: ['geocode'] , componentRestrictions: {country: "ng"}}
      );
      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      autocomplete.current.setFields(['geometry', 'formatted_address']); //"name","adr_address"

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.current.addListener('place_changed', fillInAddress);
      if (startingAddress) {
        autoCompleteComponent.value = startingAddress;
      }
    } catch (error) {
      console.error('Could not load auto complete library');
    }
  }, [fillInAddress, startingAddress]);

  /**
   * Initialize map and marker elements. Note Marker element requires MapId. we use Raster currently
   */
  const initMap = useCallback(async () => {
    const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    const mapArea = document.getElementById('map') as HTMLInputElement;
    try {
      map.current = new Map(mapArea, {
        center: { lat: 9.051117099999999, lng: 7.4927032 }, //Abuja CBD
        zoom: 16,
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID_RASTER,
      });

      //store for use
      advancedMarker.current = new AdvancedMarkerElement();
    } catch (error) {
      console.error(error);
      mapArea.innerText = 'Map could not be loaded';
    }
  }, []);

  const initRoutes = useCallback(async () => {
    const { DistanceMatrixService } = (await google.maps.importLibrary(
      'routes'
    )) as google.maps.RoutesLibrary;

    try {
      distanceMatrix.current = new DistanceMatrixService();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const zoomIntoPharmacy = (userLocation: any) => (event: any) => {
    try {
      const latlng = new google.maps.LatLng(userLocation.lat(), userLocation.lng());
      map.current!.setCenter(latlng);

      //scroll up
      document.getElementById('map')?.scrollIntoView();
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    if (close) {
      close();
    }
  };

  //add a random offset for cases where we have same coordinates. it happens
  const getRandomOffset = () => {
    // Generate a random number between -0.000001 and 0.000001 (adjust as needed)
    return (Math.random() - 0.5) * 0.000002;
  };

  //use effect to load all pharmacies if not already in session

  const getPharmacyList = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/list/detail`;

    try {
      const response = await axios.get(url);
      setPharmacyListData(response.data);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('pharmacy-addresses', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    //load pharmacy list and save in session storage if not available
    const savedPharmacyList = sessionStorage.getItem('pharmacy-addresses');

    if (savedPharmacyList !== null) {
      const pharmacyList = JSON.parse(savedPharmacyList);

      setPharmacyListData(pharmacyList);
    } else {
      //call api
      getPharmacyList();
    }
  }, []);

  useEffect(() => {
    initAutocomplete();
  }, [initAutocomplete]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useEffect(() => {
    initRoutes();
  }, [initRoutes]);

  return (
    <div
      id='sidebarMenu'
      className={
        showModal
          ? 'col-md-8 col-lg-8 right-sidebar sideshow'
          : 'col-md-8 col-lg-8 right-sidebar sidehide'
      }
    >
      <div className='right-sidebar-sticky pt-3 mt-2'>
        <div onClick={closeModal} className='float-right btn btn-default btn-outline-danger btn-sm'>
          Close <span className='bi bi-x'></span>
        </div>
        {/* Collect patient address */}
        <div className='form-group mt-4'>
          <label>Enter patient address</label>
          <input required type='text' id='autocomplete' className='form-control' />
        </div>
        <div className='mt-5 mb-3'>
          <div id='map' style={{ height: '400px', width: '100%' }}></div>
        </div>

        {searchResult.length > 0 ? (
          <div className='mt-4 mb-5'>
            <div>Powered by Google, ©{new Date().getFullYear()} Google</div>
            <div> Search results order by closest to starting location</div>
            <div className='row mt-3'>
              {searchResult.map((rx, key) => (
                <div className=' col-lg-6 p-1' title={rx.pharmacyName}>
                  <div
                    className='card p-3 pharmacy-card'
                    onClick={zoomIntoPharmacy(rx.geolocation)}
                  >
                    <strong className='fs-7'>
                      <span className='mr-1'>{rx.rankId + 1}.</span>
                      {rx.pharmacyName}
                    </strong>
                    <div className='mt-1'>{rx.address}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default PharmacyMapSidebar;
