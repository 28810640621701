import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { FulfilmentCommunicationTabs, SubscriptionDetailsTab } from '../../../utils/mappings';
import Notes from '../comms/Notes';
import { useEffect, useState } from 'react';
import { ISubscriptionDetailsProp } from '../../../interface/SubscriptionInterface';

const MiscCommunication = ({ subscription, tabKey }: ISubscriptionDetailsProp) => {
  const [tabOpen, setTabOpen] = useState(false);
  const [key, setKey] = useState(FulfilmentCommunicationTabs.notes);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (tabKey === SubscriptionDetailsTab.misc) {
      setCounter((x) => x + 1);
      setTabOpen(true);
    }
  }, [tabKey]);

  return (
    <div className='mt-5'>
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k as string)}>
        <Row>
          <Col sm={2}>
            <Nav
              variant='tabs'
              className='flex-column'
              activeKey={FulfilmentCommunicationTabs.notes}
            >
              {/* <Nav.Item>
                <Nav.Link eventKey={FulfilmentCommunicationTabs.sms}>
                  {FulfilmentCommunicationTabs.sms}
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey={FulfilmentCommunicationTabs.notes}>
                  {FulfilmentCommunicationTabs.notes}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey={FulfilmentCommunicationTabs.email}>
                  {FulfilmentCommunicationTabs.email}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={FulfilmentCommunicationTabs.whatsapp}>
                  {FulfilmentCommunicationTabs.whatsapp}
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {/* <Tab.Pane eventKey={FulfilmentCommunicationTabs.sms}>
                <SMS />
              </Tab.Pane> */}
              <Tab.Pane eventKey={FulfilmentCommunicationTabs.notes}>
                <Notes
                  noteType='Insurance'
                  noteTypeId={subscription ? subscription.subscriptionCode : '0'}
                  // tabKey={FulfilmentCommunicationTabs.notes}
                  counter={counter}
                  tabOpen={tabOpen}
                />
              </Tab.Pane>
              {/* <Tab.Pane eventKey={FulfilmentCommunicationTabs.email}>
                <Emails />
              </Tab.Pane>
              <Tab.Pane eventKey={FulfilmentCommunicationTabs.whatsapp}>
                <WhatsApp />
              </Tab.Pane> */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default MiscCommunication;
