import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { IChatBoxMessages, IRequeueMessage } from '../../../interface/MessagesInterface';
import axios from 'axios';
import React, { Fragment } from 'react';
import './styles.css';
//used Card from react bootstrap as normal bootstrap card wasnt working...
import { Card } from 'react-bootstrap';
import { formatDate } from '../../../utils/dateTimeUtils';

const ChatBox = ({ messageList }: any) => {
  const requeueMessage = async (messageId: number) => {
    let response = window.confirm('Are you sure you want to resend this message');

    if (!response) {
      return;
    }

    const url = `${endpoints.InsuranceMessages.requeueMessages}`;
    const payload: IRequeueMessage = {
      selectedId: [messageId],
      deliveryStatus: 'Queued',
    };

    try {
      await axios.put(url, payload);
      addToast('Message queued up for delivery', 'success');
    } catch (error: any) {
      console.error(error);
      addToast('There was an error resending message', 'error');
    }
  };

  const deleteMessage = async (messageId: number) => {
    let response = window.confirm(
      'Are you sure you want to delete this message. This cannot be undone'
    );

    if (!response) {
      return;
    }

    const url = `${endpoints.InsuranceMessages.mainUrl}/${messageId}`;
    try {
      await axios.delete(url);
      addToast('Message deleted', 'success');
    } catch (error: any) {
      console.error(error);
      addToast('There was an error deleting the selected message', 'error');
    }
  };

  return (
    <Fragment>
      <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: '400px', width: '200%' }}>
        <div className='row'>
          <div className='col-md-12'>
            {messageList.map((message: IChatBoxMessages) => (
              <Card
                bg='light'
                text='dark'
                style={{ width: '100%', border: '1px solid #E0E0E0', marginBottom: '30px' }}
              >
                <Card.Body>
                  <p style={{ fontWeight: 'bold' }}>{message.messageContent}</p>

                  <div className='messageMetaData'>{message.deliveryMessage || ''}</div>
                  <span className='messageMetaData'>{message.deliveryStatus}</span>
                  <span className='messageMetaData'>
                    {message.messageDate ? formatDate(message.messageDate) : ''}
                  </span>
                  <span className='messageMetaData'>{message.messageChannel}</span>
                  <span className='messageMetaData'>{message.messageType}</span>
                  <span>
                    {message.messageType === 'Outbox' ? (
                      <i className='bi bi-telephone-outbound outboxIcon'></i>
                    ) : (
                      <i className='bi bi-telephone-inbound inboxIcon'></i>
                    )}
                  </span>
                  <span className='messageMetaData'>
                    {message.deliveryStatus === 'Queued' ? (
                      <i
                        className='bi bi-trash-fill'
                        onClick={() => deleteMessage(message.messageId)}
                      ></i>
                    ) : (
                      ''
                    )}
                  </span>
                  <span className='messageMetaData'>
                    {message.deliveryStatus === 'Sent' || message.deliveryStatus === 'Delivered' ? (
                      <i
                        onClick={() => requeueMessage(message.messageId)}
                        className='bi bi-archive'
                      ></i>
                    ) : (
                      ''
                    )}
                  </span>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatBox;
