import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { formatDate } from '../../../utils/dateTimeUtils';

import { PatientRequestDTO } from '../../../interface/PatientRequestInterface';
import PatientRequestModal from './PatientRequestModal';

const PatientRequestTable = () => {
  const [tableData, setTableData] = useState<PatientRequestDTO[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<PatientRequestDTO | undefined>();

  const toggleModal = (order?: PatientRequestDTO) => {
    setModalOpen(!modalOpen);
    if (order) {
      setSelectedOrder(order);
    }
  };

  const getPatientRequests = useCallback(async (pageIndex = 1) => {
    try {
      const url = `${endpoints.PatientRequest.mainUrl}/orders`;
      const response = await axios.get(url);
      setTableData(response.data);
      //set pagination info

      //set page info
    } catch (error: any) {
      addToast('There was error getting call requests', 'error');
    }
  }, []);

  useEffect(() => {
    getPatientRequests();
  }, [getPatientRequests]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Date Created</th>
        <th scope='col'>Status</th>
        <th scope='col'>Name</th>
        <th scope='col'>Phone number</th>
        <th scope='col'>Email</th>
        <th scope='col'>Address</th>
        <th scope='col'>Notes</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((request: PatientRequestDTO) => {
        return (
          <tr key={request.orderId}>
            <td>
              <button
                style={{ cursor: 'pointer' }}
                className='btn btn-primary btn-sm'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Edit'
                onClick={() => toggleModal(request)}
              >
                Edit
              </button>
            </td>
            <td>{formatDate(request.dateCreated)}</td>
            <td>{request.status}</td>
            <td>
              {request.firstName} {request.lastName}
            </td>
            <td>{request.phoneNumber}</td>
            <td>{request.email}</td>
            <td>{request.address}</td>
            <td>{request.notes}</td>
          </tr>
        );
      })}
    </tbody>
  );

  let requestModal = (
    <PatientRequestModal open={modalOpen} close={toggleModal} modalData={selectedOrder!} />
  );

  return (
    <div>
      {requestModal}
      <div className='d-flex'>
        <h3 className=' mr-auto'>Patient Requests</h3>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
      <br />
    </div>
  );
};

export default PatientRequestTable;
