import React, { useState, useEffect, Fragment, useCallback } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import {
  fulfilmentdrugsFormData,
  IEditFulfilmentModal,
} from '../../../interface/FulfilmentsInterface';
import { ITariffSearchResult } from '../../../interface/PriceListInterface';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { decimalParser } from '../../../utils/numberUtils';
import TariffSection from '../../common/TariffSection';

const AddDrugsToRequestModal = ({ open, close, modalData }: IEditFulfilmentModal) => {
  const [isLoading, setLoading] = useState(false);
  const [drugsData, setDrugsData] = useState(fulfilmentdrugsFormData);

  const [lockTariff] = useState(true);

  const submit = async (event: React.FormEvent) => {
    if (!isFormValidated('fulfilment-drug-form')) {
      //addToast('Please fill required fields!', 'warning');
      return;
    }

    event.preventDefault();

    //show confirmation modal
    if (modalData.isPharmacyPaid) {
      const answer = window.confirm(
        'Are you sure you want to perform this action. It CANNOT be undone'
      );

      if (!answer) {
        return;
      }
    }

    setLoading(true);
    const payload = drugsData;
    payload.requestId = modalData.requestId;
    payload.pharmacyCode = modalData.pharmacyCode;
    try {
      setLoading(true);
      await axios.post(endpoints.FulfilmentDrugs.mainUrl, payload);

      addToast('Fulfilment drugs added successfully', 'success');
      clearDrugsForm();

      close();
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setDrugsData({
      ...drugsData,
      [input]: value,
    });
  };

  const handleTariffSection = useCallback((drugId: number, selectedPrice?: ITariffSearchResult) => {
    if (!selectedPrice) {
      return;
    }

    let newTariffCost = decimalParser(selectedPrice.unitCost * selectedPrice.quantity, 2);

    setDrugsData((drugInfo) => {
      return {
        ...drugInfo,
        //name: price.drugName,
        tariffDrugName: selectedPrice.tariffDrugName,
        newTariffDrugName: selectedPrice.newTariffDrugName,
        pricelistId: selectedPrice.priceListId,
        quantity: selectedPrice.quantity,
        unitCost: selectedPrice.unitCost,
        tariffCost: newTariffCost,
      };
    });
  }, []);

  const clearDrugsForm = () => {
    setLoading(false);
    setDrugsData({ ...fulfilmentdrugsFormData });
  };

  useEffect(() => {
    if (open) {
      setDrugsData(fulfilmentdrugsFormData);
    }
  }, [open, modalData.fulfilmentService, modalData.partnerCode, modalData.pharmacyCode]);

  return (
    <Fragment>
      <Modal show={open} onHide={() => close()}>
        <form id='fulfilment-drug-form' style={{ paddingBottom: '30px' }}>
          <fieldset id='form-fields' disabled={isLoading ? true : false}>
            <Modal.Header closeButton>
              <Modal.Title>
                Add Drugs to Existing Fulfilment Request
                {isLoading ? <ButtonLoader /> : ''}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
              <div className='col-md-12'>
                <label htmlFor='name' className='form-label'>
                  Drug Name
                </label>
                <input
                  value={drugsData.name}
                  required
                  className='form-control '
                  type='text'
                  // disabled
                  id='name'
                  name='Name'
                  onChange={handleChange('name')}
                  // list='tariffs'
                />
              </div>
              <br />

              <div className='col-md-12'>
                <label className='form-label'>Dose </label>
                <input
                  name='Dose'
                  value={drugsData.dose}
                  className='form-control '
                  type='text'
                  onChange={handleChange('dose')}
                />
              </div>

              {/* <div className='col-md-12 mt-4'>
                <input
                  type='checkbox'
                  id={uid}
                  checked={drugsData.isDispensed}
                  onChange={handleChange('isDispensed')}
                  className='check-md'
                />
                <label htmlFor={uid} className='ml-2 form-label'>
                  Is Dispensed
                </label>
              </div> */}
              <div>
                <TariffSection
                  isActive={open}
                  fulfilmentService={modalData.fulfilmentService}
                  partnerCode={modalData.partnerCode}
                  pharmacyCode={modalData.pharmacyCode}
                  drugId={1}
                  callback={handleTariffSection}
                  required={drugsData.isDispensed}
                />
              </div>

              <div className='col-md-12 mt-3'>
                <label className='form-label'>Drugs Cost </label>
                <input
                  value={drugsData.cost}
                  required
                  className='form-control '
                  name='cost'
                  type='number'
                  id='cost'
                  onChange={handleChange('cost')}
                  min={1}
                  step={0.01}
                />
              </div>

              <div className='col-md-12 mt-3'>
                <label className='form-label'>Tariff Cost </label>
                <input
                  type='number'
                  required
                  value={drugsData.tariffCost}
                  onChange={handleChange('tariffCost')}
                  className='form-control'
                  id='tariffCost'
                  min={1}
                  step={0.01}
                  disabled={lockTariff && drugsData.name.trim().toLowerCase() !== 'delivery'}
                />
              </div>
              <div className='col-md-12'>
                {parseFloat(drugsData.cost.toString()) >
                parseFloat(drugsData.tariffCost.toString()) ? (
                  <div className=' form-group mb-4 text-danger'>
                    Drug cost is Higher than tarrif cost
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className='col-md-12 mt-3'>
                <label htmlFor='name' className='form-label'>
                  Notes
                </label>
                <input
                  value={drugsData.notes}
                  onChange={handleChange('notes')}
                  multiple
                  className='form-control '
                  type='text'
                  id='notes'
                  name='Notes'
                />
              </div>
              {modalData.isPharmacyPaid ? (
                <div className='text-danger col-md-12 mt-4 border-danger'>
                  <strong>WARNING !!</strong>
                  <div>
                    You are about to add a drug to a fulfilment for which the pharmacy is already
                    <strong className='fs-7 ml-1'>PAID</strong>.
                  </div>
                  <br />
                  <div>
                    This will increase the overall cost of the fulfilment by
                    <strong className='fs-7 ml-1'>₦{drugsData.cost}</strong>
                  </div>
                  <div title="If pharmacy has been paid outside their wallet, check this box. If you wish to pay pharmacy via wallet don't check this box">
                    <input
                      type='checkbox'
                      className='mt-3 mr-2'
                      checked={drugsData.payViaWallet}
                      defaultChecked={drugsData.payViaWallet}
                      onChange={handleChange('payViaWallet')}
                    />
                    Pay pharmacy via Wallet
                    {drugsData.payViaWallet ? (
                      <div>
                        <input
                          type='checkbox'
                          className='mt-3 mr-2'
                          checked={drugsData.payInstantly}
                          defaultChecked={drugsData.payInstantly}
                          onChange={handleChange('payInstantly')}
                        />
                        Pay Instantly
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    <input type='checkbox' className='mt-3 mr-2' required />I understand the
                    implications of this
                  </div>
                </div>
              ) : (
                ''
              )}
              <br />
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={submit}
                disabled={isLoading}
                type='submit'
                className='btn btn-primary'
              >
                {isLoading ? <ButtonLoader /> : 'SUBMIT'}
              </button>
              <button type='reset' className='btn btn-secondary' onClick={clearDrugsForm}>
                CLEAR
              </button>
            </Modal.Footer>
          </fieldset>
        </form>
      </Modal>
    </Fragment>
  );
};

export default AddDrugsToRequestModal;
