import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  IWalletTransactionUpdate,
  IWalletTransactionsModalProps,
} from '../../../interface/WalletsInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { Modal } from 'react-bootstrap';
import {
  TransactionStatus,
  TransactionStatusMap,
  TransactionTypeMap,
} from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';

const EditTransactionRequestsModal = (modalProps: IWalletTransactionsModalProps) => {
  const [isLoading, setLoading] = useState(false);
  const [modalState, setModalState] = useState<IWalletTransactionUpdate | undefined>();

  const handleChange = (input: string) => (e: any) => {
    setModalState({
      ...modalState!,
      [input]: e.target.value,
    });
  };

  const updateTransaction = async (event: any) => {
    const url = endpoints.WalletTransactions.mainUrl;
    let message = 'Transaction Successfully Updated';
    let errorMessage = 'Error Updating Transaction';

    if (!isFormValidated('debit-request-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }

    event.preventDefault();

    if (!modalState || modalState === null) {
      addToast('Cannot load transaction, close modal and open again', 'warning');
      return;
    }
    event.preventDefault();

    setLoading(true);

    try {
      await axios.put(url, modalState);
      addToast(message, 'success');
      await modalProps.getTransactions();
      modalProps.close();
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // setFulfilmentFormData((f) => {
    //   return { ...f, useSortingCenter: useSortingCenter, pharmacyCode: pharmacyCode };
    // });
    if (modalProps.open) {
      const updateData: IWalletTransactionUpdate = {
        transactionId: modalProps.modalData.transactionId,
        transactionStatus: TransactionStatusMap.Completed,
        notes: '',
      };
      setModalState(updateData);
    }
  }, [modalProps.open, modalProps?.modalData?.transactionId]);

  return (
    <Modal show={modalProps.open} onHide={modalProps.close}>
      <form id='debit-request-modal'>
        <Modal.Header closeButton>
          <Modal.Title>
            Editing Transaction : <small>{modalProps?.modalData?.transactionId}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label>Transaction Amount</label>
              <input value={modalProps?.modalData?.amount} className='form-control' disabled />
            </div>
            <div className='form-group'>
              <label>Transaction Status</label>
              <select
                className='custom-select'
                value={modalState?.transactionStatus}
                onChange={handleChange('transactionStatus')}
                disabled={modalProps?.modalData?.transactionType === TransactionTypeMap.Debit}
              >
                {modalProps?.modalData?.transactionType === TransactionTypeMap.Credit &&
                modalProps?.modalData?.transactionStatus === TransactionStatusMap.Pending
                  ? TransactionStatus.filter((x) => x.value !== TransactionStatusMap.Pending).map(
                      (status) => (
                        <option key={status.key} value={status.value.toString()}>
                          {status.text}
                        </option>
                      )
                    )
                  : ''}
              </select>
            </div>

            <div className='form-group'>
              <label>Notes</label>
              <input
                type='text'
                value={modalState?.notes}
                onChange={handleChange('notes')}
                className='form-control'
                required={modalState?.transactionStatus === TransactionStatusMap.Cancelled}
              />
            </div>
            <div className='form-group'>
              <label>Transaction Initiated by</label>
              <input value={modalProps?.modalData?.createdBy} className='form-control' disabled />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn  btn-primary' onClick={updateTransaction} disabled={isLoading}>
            Update
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditTransactionRequestsModal;
