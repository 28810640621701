let BASE_URL = process.env.REACT_APP_BASE_URL;
let WELLAHEALTH_URL = process.env.REACT_APP_WELLAHEALTH_URL;
let PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
let RELIANCE_URL = process.env.REACT_APP_RELIANCE_URL;
let RELIANCE_UPLOAD = process.env.REACT_APP_RELIANCE_UPLOAD;

let endpoints = {
  Auth: {
    getToken: BASE_URL + '/auth/token',
  },
  AXA: {
    checkTrackingNumber: BASE_URL + '/insurance/axa',
  },
  BenefitRequests: {
    mainUrl: BASE_URL + '/insurance/benefitrequests',
  },
  RealTime: {
    coreApi: BASE_URL + '/notify',
    publicApi: PUBLIC_URL + '/api/notify',
    website: WELLAHEALTH_URL + '/hubs/notify',
  },

  Website: {
    createLead: WELLAHEALTH_URL + '/api/leads',
    generatePaymentLink: WELLAHEALTH_URL + '/api/paystack/paymentlink',
  },
  SupportRequests: {
    mainUrl: BASE_URL + '/insurance/supportrequests',
    getSupportRequestsCount: BASE_URL + '/insurance/supportrequests/pending/count',
  },
  InsuranceLeads: {
    getInsuranceLeads: BASE_URL + '/insurance/insuranceleads?pageSize=',
    searchLeads: BASE_URL + '/insurance/insuranceleads/',
  },
  InsuranceNotifications: {
    mainUrl: BASE_URL + '/insurance/insurancenotifications',
    getLatest: BASE_URL + '/insurance/insurancenotifications/latest/?count=5',
    getAllUnread: BASE_URL + '/insurance/insurancenotifications/unread',
    getUnreadCount: BASE_URL + '/insurance/insurancenotifications/unreadCount',
    markRead: BASE_URL + '/insurance/insurancenotifications/read/',
  },
  InsurancePartners: {
    getPartnerList: BASE_URL + '/insurance/insurancepartners/list',
    mainUrl: BASE_URL + '/insurance/insurancepartners',
    postBulkPayment: BASE_URL + '/insurance/insurancepartners/bulkpayments',
    uploadSubscribers: BASE_URL + '/jobs/task/',
  },
  InsurancePayments: {
    mainUrl: BASE_URL + '/insurance/insurancepayments/',
  },
  InsuranceSubscriptions: {
    mainUrl: BASE_URL + '/insurance/insurancesubscriptions',
    getBySubscriptionCode: BASE_URL + '/insurance/insurancesubscriptions/subscriptioncode/',
    getByPartnerCode: BASE_URL + '/insurance/insurancesubscriptions/partnercode',
    getNewSubscriptionsCount: BASE_URL + '/insurance/insurancesubscriptions/pending/count',
  },
  InsuranceNextOfKin: {
    mainUrl: BASE_URL + '/insurance/nextOfKin',
  },
  InsurancePharmacy: {
    //add v1 for testing purposes
    // getPharmacy: BASE_URL + '/insurance/v1/insurancepharmacy',
    // updatePharmacy: BASE_URL + '/insurance/v1/insurancepharmacy',
    // postPharmacy: BASE_URL + '/insurance/v1/insurancepharmacy',
    // getPharmacyList: BASE_URL + '/insurance/v1/insurancepharmacy/list',
    mainUrl: BASE_URL + '/insurance/insurancepharmacy',
    getPharmacyList: BASE_URL + '/insurance/insurancepharmacy/list',
    getPharmacyClientList: BASE_URL + '/insurance/insurancepharmacy/clients',
  },
  InsuranceBeneficiary: {
    mainUrl: BASE_URL + '/insurance/insurancebeneficiary/',
    getBeneficiaryByPhone: BASE_URL + '/insurance/insurancebeneficiary/phone/',
    getBeneficiaryListByCode: BASE_URL + '/insurance/insurancebeneficiary/list/',
  },
  InsurancePayment: {
    getAllPayment: BASE_URL + '/insurance/receivedpayment',
  },

  InsuranceClaims: {
    mainUrl: BASE_URL + '/insurance/insuranceclaims',
    getPendingClaimsCount: BASE_URL + '/insurance/insuranceclaims/pending/count',
    uploadFile: BASE_URL + '/jobs/task/files/hospicash',
    deleteFile: BASE_URL + '/jobs/task/files',
  },
  InsuranceReports: {
    getReports: BASE_URL + '/insurance/insurancereports',
  },
  InsuranceMessages: {
    getUnreadMessages: BASE_URL + '/insurance/insurancemessages/unread',
    updateReadMessages: BASE_URL + '/insurance/insurancemessages/isread',
    mainUrl: BASE_URL + '/insurance/insurancemessages',
    requeueMessages: BASE_URL + '/insurance/insurancemessages/queued',
    getUnreadMessagesCount: BASE_URL + '/insurance/insurancemessages/unread/count',
  },
  FulfilmentRequests: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmentrequests',
    getPendingCount: BASE_URL + '/fulfilments/fulfilmentrequests/pending/count',
  },
  FulfilmentDrafts: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmentdrafts',
  },
  FulfilmentPatients: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmentpatients',
  },
  FulfilmentExport: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmentexport',
  },
  FulfilmentDrugs: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmentdrugs',
  },
  FulfilmentTests: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmenttests',
  },
  FulfilmentPartners: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmentpartners',
  },
  RequestTrackingStatus: {
    mainUrl: BASE_URL + '/fulfilments/fulfilmentTracking',
  },
  Pricelist: {
    mainUrl: BASE_URL + '/fulfilments/pricelist',
  },
  PharmacyPricelist: {
    mainUrl: BASE_URL + '/pricingformulary/',
  },
  PriceCheck: {
    mainUrl: PUBLIC_URL + '/api/pricecheck',
  },
  Reliance: {
    postTestResult: RELIANCE_URL + '/prescriptions',
    uploadFiles: RELIANCE_UPLOAD + '/upload',
  },
  PharmacyRating: {
    mainUrl: BASE_URL + '/pharmacyrating',
  },
  WalletAccounts: {
    mainUrl: BASE_URL + '/walletaccounts',
    getBankList: BASE_URL + '/walletaccounts/banks/list',
    resolveAccount: BASE_URL + '/walletaccounts/bank/resolve',
  },
  WalletTransactions: {
    mainUrl: BASE_URL + '/wallettransactions',
  },
  WalletPayouts: {
    mainUrl: BASE_URL + '/walletpayouts',
    getPendingCount: BASE_URL + '/walletpayouts/pending/count',
  },
  ZoiUsers: {
    mainUrl: BASE_URL + '/zoi/zoiusersadmin',
  },
  ZoiOrders: {
    mainUrl: BASE_URL + '/zoi/zoiordersadmin',
    getByUserId: BASE_URL + '/zoi/zoiordersadmin/user',
    getNewZoiOrders: BASE_URL + '/zoi/zoiordersadmin/orders/count',
  },
  InsuranceAgents: {
    mainUrl: BASE_URL + '/agents/agentadmin',
    changeStatus: BASE_URL + '/agents/agentadmin/status',
  },
  LoanApplications: {
    getnewLoanApplications: BASE_URL + '/loanapplications/pending/count',
    mainUrl: BASE_URL + '/loanapplications',
  },
  LoanRepayments: {
    mainUrl: BASE_URL + '/loanrepayments',
  },
  Telemedicine: {
    mainUrl: BASE_URL + '/telemedicine',
    pendingCount: BASE_URL + '/telemedicine/pending/count',
  },
  PatientRequest: {
    mainUrl: BASE_URL + '/chroniccare',
  },
  UserNotes: {
    mainUrl: BASE_URL + '/common/usernotes',
  },
  Jobs: {
    tasks: BASE_URL + '/jobs/task',
    backgroundQueue: BASE_URL + '/jobs/backgroundjobqueue',
  },
  HealthSend: {
    users: BASE_URL + '/healthsend/users',
    requests: BASE_URL + '/healthsend/requests',
    beneficiaries: BASE_URL + '/healthsend/beneficiaries',
    claims: BASE_URL + '/healthsend/beneficiaries/fulfilment',
  },
  VeriClaim: {
    jobQueue: BASE_URL + '/vericlaim/jobqueue',
  },
  ConfigSettings: {
    mainUrl: BASE_URL + '/configurationsetting/vericlaim',
    vericlaimConfig: BASE_URL + '/configurationsettings/vericlaim',
  },
  Medismart: {
    mainUrl: BASE_URL + '/medismart',
  },
};

//removed modules.exports for Typescript due to --isolatedModules error
export { endpoints, BASE_URL };
