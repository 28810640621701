import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { Link } from 'react-router-dom';

const LoanView = ({ setActiveNav }: { setActiveNav: any }) => {
  const [loanCount, setLoanCount] = useState(0);

  const getNewLoanApplicationsCount = useCallback(async () => {
    try {
      await axios.get(endpoints.LoanApplications.getnewLoanApplications).then((response) => {
        const pendingCount = response.data;
        setLoanCount(pendingCount);
      });
    } catch (error: any) {
      addToast('There was an error getting new loan applications count', 'error');
    }
  }, []);

  useEffect(() => {
    getNewLoanApplicationsCount();
  }, [getNewLoanApplicationsCount]);

  return (
    <>
      <li className='nav-item'>
        <Link
          to='/loans/applications'
          className='nav-link position-relative'
          onClick={setActiveNav}
        >
          Loan Applications
          <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
            {loanCount}
          </h3>
        </Link>
      </li>
    </>
  );
};

export default LoanView;
