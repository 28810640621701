import React, { useContext, useState, useCallback, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';
import { PRODUCT_VIEW } from '../../utils/mappings';
import { endpoints } from '../../utils/URL';
import InsuranceView from './side_menu_views/InsuranceView';
import LoanView from './side_menu_views/LoanView';
import WalletView from './side_menu_views/WalletView';
import FulfilmentView from './side_menu_views/FulfilmentView';
import axios from 'axios';
import { addToast } from '../../utils/toastNotifications';
import MiscTaskView from './side_menu_views/MiscTaskView';
import { HealthSendView } from './side_menu_views/HealthSendView';

const SideMenu = () => {
  const { appState, dispatch } = useContext(AppContext);

  const [requestCount, setRequestCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);

  const productView: string = appState.productView;
  let menuList;

  const getUnreadMessagesCount = useCallback(async () => {
    try {
      await axios.get(endpoints.InsuranceMessages.getUnreadMessagesCount).then((response) => {
        const pendingCount = response.data;
        setMessageCount(pendingCount);
      });
    } catch (error: any) {
      addToast('There was an error getting unread messages count', 'error');
    }
  }, []);

  const getSupportRequestsCount = useCallback(async () => {
    try {
      await axios.get(endpoints.SupportRequests.getSupportRequestsCount).then((response) => {
        const pendingCount = response.data;
        setRequestCount(pendingCount);
      });
    } catch (error: any) {
      addToast('There was an error getting support requests count', 'error');
    }
  }, []);

  const viewChange = (event: any) => {
    dispatch({
      type: 'SET_PRODUCT_VIEW',
      payload: event.target.value,
    });
  };

  const setActiveNav = (event: any) => {
    //remove all active navs
    let navLinks = document.getElementsByClassName('nav-link');
    for (let i = 0; i < navLinks.length; i++) {
      navLinks[i].classList.remove('active');
    }
    //set active for the current one
    event.target.classList.add('active');

    //scroll to top.  <span id='scroll-here'></span> is found in index.tsx
    let scrollHere: any = document.getElementById('scroll-here');
    scrollHere.scrollIntoView();

    //close side menu if open

    //close side menu if open
    let sideBar: any = document.getElementById('sidebarMenu');
    sideBar.classList['toggle']('show');

    let mainView = document.getElementById('main-view') as HTMLElement;
    mainView.classList.remove('col-md-9', 'col-lg-10');
    mainView.classList.add('col-md-12', 'col-lg-12');
  };

  const logOut = (event: any) => {
    event.preventDefault();

    dispatch({
      type: 'LOG_OUT',
    });
    sessionStorage.clear();
    window.location.href = '/';
  };

  useEffect(() => {
    getUnreadMessagesCount();
    getSupportRequestsCount();
  }, [getUnreadMessagesCount, getSupportRequestsCount]);

  let malariaView = <InsuranceView setActiveNav={setActiveNav} />;

  let fulfilmentView = <FulfilmentView setActiveNav={setActiveNav} />;

  let walletView = <WalletView setActiveNav={setActiveNav} />;

  let healthSendView = <HealthSendView setActiveNav={setActiveNav} />;

  //let zoiView = <ZoiView setActiveNav={setActiveNav} />;

  let loanView = <LoanView setActiveNav={setActiveNav} />;
  let miscView = <MiscTaskView setActiveNav={setActiveNav} />;

  let agentView = (
    <>
      <li className='nav-item'>
        <Link to='/agents' className='nav-link ' onClick={setActiveNav}>
          Agents
        </Link>
      </li>
    </>
  );

  switch (productView) {
    case 'Malaria':
      menuList = malariaView;
      break;
    case 'Fulfilment':
      menuList = fulfilmentView;
      break;
    case 'Wallet':
      menuList = walletView;
      break;
    case 'healthsend':
      menuList = healthSendView;
      break;
    case 'Loan':
      menuList = loanView;
      break;
    case 'Agent':
      menuList = agentView;
      break;
    case 'Misc':
      menuList = miscView;
      break;
    default:
      menuList = malariaView;
  }

  return (
    // col-md-3 col-lg-2 d-md-block sidebar show collapse
    <nav id='sidebarMenu' className='col-md-3 col-lg-2 sidebar show collapse z-index-3'>
      <div className='sidebar-sticky pt-3'>
        {/* Product View Dropdown */}
        <select
          onChange={viewChange}
          value={appState.productView}
          className='custom-select text-center'
          id='view-change'
        >
          {PRODUCT_VIEW.map((option) => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        <br />
        <hr />
        <ul className='nav flex-column'>
          <li className='nav-item'>
            <Link to='/home' className='nav-link active' onClick={setActiveNav}>
              Home
            </Link>
          </li>
          {menuList}
          <li className='nav-item'>
            <Link
              to='/support-requests'
              className='nav-link position-relative'
              onClick={setActiveNav}
            >
              Support Requests
              <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
                {requestCount}
              </h3>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/messages' className='nav-link position-relative' onClick={setActiveNav}>
              Message Hub
              <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
                {messageCount}
              </h3>
            </Link>
          </li>
          <hr />
          <li className='nav-item'>
            <div style={{ cursor: 'pointer' }} onClick={logOut} className='nav-link'>
              Logout
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SideMenu;
