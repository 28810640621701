import { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';

const HealthSendView = ({ setActiveNav }: { setActiveNav: any }) => {
  const [requestCount, setRequestCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const getRequestCount = useCallback(async () => {
    axios
      .get(endpoints.HealthSend.requests)
      .then((response) => {
        const requestArray = response.data;
        const count = requestArray.length;
        setRequestCount(count);
      })
      .catch((error) => {
        addToast('Error getting Healthsend requests', 'error');
      });
  }, []);

  const getUserCount = useCallback(async () => {
    axios
      .get(endpoints.HealthSend.users)
      .then((response) => {
        const userArray = response.data;
        const count = userArray.length;
        setUserCount(count);
      })
      .catch((error) => {
        addToast('Error getting Healthsend users', 'error');
      });
  }, []);

  useEffect(() => {
    getRequestCount();
    getUserCount();
  }, [getRequestCount, getUserCount]);

  return (
    <>
      <li className='nav-item'>
        <Link to='/healthsend/users' className='nav-link position-relative' onClick={setActiveNav}>
          Users
          <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
            {userCount}
          </h3>
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          to='/healthsend/requests'
          className='nav-link position-relative'
          onClick={setActiveNav}
        >
          Requests
          <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
            {requestCount}
          </h3>
        </Link>
      </li>
    </>
  );
};

export { HealthSendView };
