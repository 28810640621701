import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import TableLoading from '../../common/TableLoading';
import { ILoansRepaymentDTO } from '../../../interface/LoansInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import { useParams } from 'react-router-dom';

const LoanRepaymentTable = () => {
  const { applicationId } = useParams() as any;

  const [tableData, setTableData] = useState<ILoansRepaymentDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getLoanRepayments = useCallback(async () => {
    if (typeof applicationId === 'undefined' || applicationId === '') {
      return;
    }

    let url = `${endpoints.LoanRepayments.mainUrl}/${applicationId}`;

    try {
      setIsLoading(true);
      const response = await axios.get(url);

      setTableData(response.data);

      if (response.data.length === 0) {
        addToast('No Loan Repayments', 'info');
      }
    } catch (error: any) {
      addToast('Error getting loan applications', 'error');
      // setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [applicationId]);

  useEffect(() => {
    getLoanRepayments();
  }, [getLoanRepayments]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Date</th>
        <th scope='col'>Application ID</th>
        <th scope='col'>Amount Paid</th>
        <th scope='col'>Transaction Reference</th>
        <th scope='col'>Channel </th>
      </tr>
    </thead>
  );

  let noDataView = (
    <tbody className='text-center'>
      <tr>
        <td colSpan={11}>No Data found</td>
      </tr>
    </tbody>
  );

  let tableBody: JSX.Element = (
    <>
      {tableData ? (
        <tbody>
          {tableData.map((loanData: ILoansRepaymentDTO) => {
            return (
              <tr key={loanData.loanRepaymentId}>
                <td>{formatDate(loanData.repaymentDate)}</td>
                <td>{loanData.loanApplicationId}</td>
                <td>{loanData.amountPaid}</td>
                <td>{loanData.transactionReference}</td>
                <td>{loanData.paymentChannel}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        noDataView
      )}
    </>
  );

  if (isLoading) {
    return <TableLoading />;
  }

  return (
    <div>
      <br />
      <h3 className='mr-auto'>Loan Repayments ({tableData.length})</h3>

      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default LoanRepaymentTable;
