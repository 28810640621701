//import { ReactComponentElement, ReactNode } from 'react';
import { IFulfilmentPartners } from './FulfilmentPartnersInterface';
import { IFulfilmentPatientData } from './FulfilmentsInterface';

export interface IRoute {
  name: string;
  path: string;
  tags: string;
  icon: string;
}

export interface IPageInfo {
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  callback?: (pageIndex: number) => any;
}

export interface PaginationInfo {
  currentPageNumber: number;
  pageSize: number;
  numberOfPages: number;
  //callback?: () => void;
  callback?: (pageInfo: IPageInfo) => any;
}

export interface INotesModal {
  open: boolean;
  close: Function;
  noteTypeId: string;
  noteType: string;
  defaultTags?: string;
  callback?: Function;
  showReason?: boolean;
}

//default initializations

export const defaultPageInfo: IPageInfo = {
  pageIndex: 1,
  pageSize: 100,
  pageCount: 1,
};

//loadSubscription: () => void;

export interface IFileInfo {
  fileName: string;
  fileUrl: string;
}

export interface IPatientSearchBar {
  callback?: (searchTerm: string, patient?: IFulfilmentPatientData) => void;
  inputStyle?: string;
  partnerCode: string;
  // searchParam: string;
}

export interface ReactSelectOption {
  label: string;
  value: string;
}

export interface IInputSearchBar {
  callback?: (searchTerm: string, result?: ReactSelectOption) => void;
  inputStyle?: string;
  data: ReactSelectOption[];
}

export interface ISelectBar {
  callback?: (partner: IFulfilmentPartners) => void;
  inputStyleClass?: string;
}
