import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { ISubscriptionDetailsProp } from '../../../interface/SubscriptionInterface';
import {
  initialSubscriptionPayment,
  ISubscriptionPayment,
} from '../../../interface/SubscriptionPaymentInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import { PaymentChannelDisplay, SubscriptionDetailsTab } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import TableLoader from '../../common/TableLoading';
import SubscriptionPaymentModal from '../payment/SubscriptionPaymentModal';

const SubscriptionPayments = ({ subscription, tabKey }: ISubscriptionDetailsProp) => {
  const { dispatch } = useContext(AppContext);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(initialSubscriptionPayment);
  const [edit, setEdit] = useState(false);
  const [tableData, setTableData] = useState<ISubscriptionPayment[] | undefined>();

  const getPaymentData = useCallback(async () => {
    setTableData(undefined);
    try {
      const response = await axios.get(
        endpoints.InsurancePayments.mainUrl + subscription?.subscriptionCode
      );
      const paymentData = response.data;
      setTableData(paymentData);
    } catch (error: any) {
      addToast('An error occured while fetching subscriptions', 'error');
    }
  }, [subscription]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = (data: ISubscriptionPayment, edit: boolean) => {
    const initPay: ISubscriptionPayment = {
      paymentChannel: PaymentChannelDisplay.Paystack,
      paymentPlan: subscription!.paymentPlan,
      months: 1,
      paymentReference: '',
      isPaymentVerified: false,
      isChangePaymentPlan: true,
      amountPaid: subscription!.amount,
      peopleInPlan: subscription!.numberOfSubscribers,
      firstSubscription: '',
      startDate: new Date().toISOString().split('T')[0],
      endDate: '',
      subscriptionCode: subscription!.subscriptionCode,
      product: subscription!.product,
    };

    if (edit) {
      setModalData(data);
    } else {
      setModalData(initPay);
    }
    setEdit(edit);
    setModalOpen(true);
  };

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (input === 'paymentPlan') {
      dispatch({
        type: 'SET_PAYMENT_PLAN',
        payload: event.target.value,
      });
    }
    setModalData({
      ...modalData,
      [input]: event.target.value,
    });
  };

  useEffect(() => {
    if (subscription && tabKey === SubscriptionDetailsTab.payments) {
      getPaymentData();
    }
  }, [tabKey, getPaymentData, subscription]); //, getPaymentData]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        {/* <th scope='col'>Subscription Code</th> */}
        <th scope='col'>Product</th>
        <th scope='col'>Payment Plan</th>
        <th scope='col'>Amount Paid</th>
        {/* <th scope='col'>Verified</th> */}
        <th scope='col'>Payment Channel</th>
        <th scope='col'>Months</th>
        <th scope='col'>People in Plan</th>
        {/* <th scope='col'>First Subscription</th> */}
        <th scope='col'>Start Date</th>
        <th scope='col'>End Date</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData?.map((subscriptionPayment: ISubscriptionPayment) => {
        return (
          <tr
            key={subscriptionPayment.subscriptionCode}
            onClick={() => openModal(subscriptionPayment, true)}
            style={{ cursor: 'pointer' }}
          >
            {/* <td>{subscriptionPayment.subscriptionCode}</td> */}
            <td>{subscriptionPayment.product}</td>
            <td>{subscriptionPayment.paymentPlan}</td>
            <td>{subscriptionPayment.amountPaid}</td>
            {/* <td>
              {subscriptionPayment.isPaymentVerified === true ? (
                <i className='bi bi-check check-icon'></i>
              ) : (
                <i className='bi bi-x close-icon'></i>
              )}
            </td> */}
            <td>{subscriptionPayment.paymentChannel}</td>
            <td>{subscriptionPayment.months}</td>
            <td>{subscriptionPayment.peopleInPlan}</td>
            {/* <td>{formatDate(subscriptionPayment.firstSubscription)}</td> */}
            <td>{formatDate(subscriptionPayment.startDate)}</td>
            <td>{formatDate(subscriptionPayment.endDate)}</td>
          </tr>
        );
      })}
    </tbody>
  );

  let modal = (
    <SubscriptionPaymentModal
      open={isModalOpen}
      close={closeModal}
      modalData={modalData}
      handleChange={handleChange}
      edit={edit}
      getPayments={getPaymentData}
      subscription={subscription!}
    />
  );

  return (
    <div className='container mt-5'>
      {modal}
      <div className='d-flex'>
        <button
          className='btn btn-primary'
          onClick={() => openModal(initialSubscriptionPayment, false)}
        >
          Add Payment
        </button>
      </div>
      <br />
      <div className='table-responsive'>
        {tableData ? (
          <table className='table table-bordered table-hover'>
            {tableHead}
            {tableBody}
          </table>
        ) : (
          <TableLoader />
        )}
      </div>
    </div>
  );
};

export default SubscriptionPayments;
