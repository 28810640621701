import { useEffect, useState } from 'react';

const PaginationBar = ({
  currentPageNumber,
  numberOfPages,
  callback,
}: {
  currentPageNumber: number;
  numberOfPages: number;
  callback?: (pageIndex: number) => any;
}) => {
  const [currentPage, setCurrentPage] = useState(currentPageNumber);

  //Callback should receive pageIndex and pageCount
  const fireCallback = (pageNumber: number) => (event: any) => {
    if (callback === null || typeof callback === 'undefined') {
      return;
    }
    setCurrentPage(pageNumber);
    callback(pageNumber);
    //scroll to top.  <span id='scroll-here'></span> is found in index.tsx
    let scrollHere: any = document.getElementById('scroll-here');
    scrollHere.scrollIntoView();
  };

  //if current page < 10
  //https://stackoverflow.com/a/33352604/2929906
  let firstTen = Array.from({ length: 10 }, (_, i) => i + 1);
  let firstTenPages = (
    <ul className='pagination'>
      {firstTen.map((x) => (
        <li key={x} className='page-item'>
          <button className='page-link' data-pageid={x} onClick={fireCallback(x)}>
            {x}
          </button>
        </li>
      ))}

      {/* Show last page */}
      <li>
        <button className='page-link'>....</button>
      </li>
      <li className='page-item'>
        <button
          className='page-link'
          data-pageid={numberOfPages}
          onClick={fireCallback(numberOfPages)}
        >
          {numberOfPages}
        </button>
      </li>
    </ul>
  );

  let numberToTen = Array.from({ length: numberOfPages }, (_, i) => i + 1);
  let numberToTenPages = (
    <ul className='pagination'>
      {numberToTen.map((x) => (
        <li key={x} className='page-item'>
          <button className='page-link' data-pageid={x} onClick={fireCallback(x)}>
            {x}
          </button>
        </li>
      ))}
    </ul>
  );

  let currentPageLT10 = <nav>{numberOfPages > 10 ? firstTenPages : numberToTenPages}</nav>;

  //if current page >= 10

  let limit = 0;
  if (currentPage + 5 <= numberOfPages) {
    //means we have more pages. take only five
    limit = 5;
  } else {
    limit = numberOfPages - currentPage;
  } //means we have less than 5 pages. take them

  let previousFive = Array.from({ length: 5 }, (_, i) => i + (currentPage - 5));

  let nextFive = Array.from({ length: limit + 1 }, (_, i) => i + currentPage);

  let combinedPageNum = [...previousFive, ...nextFive];
  let currentPageGTE10 = (
    <nav>
      <ul className='pagination'>
        <li className='page-item'>
          <button className='page-link' data-pageid={1} onClick={fireCallback(1)}>
            1
          </button>
        </li>
        <li>
          <button className='page-link'>....</button>
        </li>
        {combinedPageNum.map((x) => (
          <li key={x} className='page-item'>
            <button className='page-link' data-pageid={x} onClick={fireCallback(x)}>
              {x}
            </button>
          </li>
        ))}
        {/* Add ... if we are not on last page */}
        {currentPage + limit < numberOfPages ? (
          <li>
            <button className='page-link'>....</button>
          </li>
        ) : (
          ''
        )}
        {/* Add the last page number if we are not on last page */}
        {currentPage + limit < numberOfPages ? (
          <li className='page-item'>
            <button
              className='page-link'
              data-pageid={numberOfPages}
              onClick={fireCallback(numberOfPages)}
            >
              {numberOfPages}
            </button>
          </li>
        ) : (
          ''
        )}
      </ul>
    </nav>
  );

  useEffect(() => {
    //remove all active classes
    let allLinks = document.getElementsByClassName('page-item') as any;
    for (let i = 0; i < allLinks.length; i++) {
      allLinks[i].classList.remove('active');
    }

    let elementString = `[data-pageid='${currentPage}']`;
    let selectedElement = document.querySelector(elementString) as any;
    if (selectedElement !== null) {
      selectedElement.parentNode?.classList.add('active');
    }
  }, [currentPage, currentPageNumber]);

  return <div>{currentPage < 10 ? currentPageLT10 : currentPageGTE10}</div>;
};

export default PaginationBar;
