import { ISubscriptionResponse } from './SubscriptionInterface';

export interface ISubscriptionPayment {
  subscriptionCode: string;
  paymentReference: string;
  paymentPlan: string;
  amountPaid: number;
  isPaymentVerified: boolean;
  paymentChannel: string;
  months: number;
  peopleInPlan: number;
  firstSubscription: string;
  endDate: string;
  startDate: string;
  isChangePaymentPlan: boolean;
  product: string;
  discountedAmount?: number;
}

export interface AddEditPayment extends ISubscriptionPayment {
  paymentDate: string;
}

export interface AddEditPaymentModal {
  open: boolean;
  close: () => void;
  modalData: ISubscriptionPayment;
  edit: boolean;
  handleChange: Function;
  getPayments: () => void;
  subscription: ISubscriptionResponse;
}

export const initialSubscriptionPayment: ISubscriptionPayment = {
  subscriptionCode: '',
  paymentReference: '',
  paymentPlan: 'Monthly',
  amountPaid: 600,
  isPaymentVerified: false,
  paymentChannel: 'Paystack',
  months: 1,
  peopleInPlan: 1,
  firstSubscription: '',
  endDate: '',
  startDate: '',
  isChangePaymentPlan: true,
  product: 'Malaria',
};

export const initialAddEditPayment: AddEditPayment = {
  ...initialSubscriptionPayment,
  paymentDate: '',
};
