import { useCallback, useEffect, useState } from 'react';
import GoogleAddressInput from '../../common/GoogleAddressInput';
import { IPriceCheckDTO, IPriceCheckResponse } from '../../../interface/PriceListInterface';
import { IAddressComponent } from '../../../interface/PharmacyInterface';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import localforage from 'localforage';
import { isFormValidated } from '../../../utils/formUtils';
import { ReactSelectOption } from '../../../interface/CommonInterface';
import { NairaSymbol } from '../../../utils/constants';
import InputSearch from '../../common/InputSearch';
import HmoPriceCard from './HmoPriceCard';

const PriceChecker = () => {
  const [searchParams, setSearchParams] = useState<IPriceCheckDTO>({
    drugName: '',
    stateOfPremise: '',
    inputAddress: '',
    lga: '',
    locationArea: '',
  });
  const [drugNames, setDrugNames] = useState<ReactSelectOption[]>([]);
  const [medicationPrice, setMedicationPrice] = useState<IPriceCheckResponse | undefined>();

  const handleInputChange = (inputValue: string, result?: ReactSelectOption) => {
    setSearchParams({
      ...searchParams,
      drugName: result!.value,
    });
  };

  const handleChange = (input: string) => (event: any) => {
    setMedicationPrice({
      ...medicationPrice!,
      [input]: event.target.value,
    });
  };

  const onAddressChange = (inputAddress: string) => {
    setSearchParams({
      ...searchParams,
      inputAddress: inputAddress,
    });
  };
  const onAddressSelected = useCallback((address: IAddressComponent) => {
    const locationArea =
      address?.neighborhood.trim() ||
      address?.sublocality_level_1.trim() ||
      address?.colloquial_area.trim();

    const lga = address?.administrative_area_level_2.trim() || address?.locality.trim();

    const stateOfPremise = address!.administrative_area_level_1;

    // //without using functional updates here, a normal set state resets the entire object state
    // //https://reactjs.org/docs/hooks-reference.html#functional-updates
    setSearchParams((x) => {
      return {
        ...x!,
        stateOfPremise: stateOfPremise,
        lga: lga,
        locationArea: locationArea,
        inputAddress: address.fullAddress,
      };
    });
  }, []);

  const search = async (event: any) => {
    if (!isFormValidated('price-check-form')) {
      return;
    }
    event.preventDefault();
    const url = `${endpoints.PriceCheck.mainUrl}?drugName=${searchParams.drugName}
        &stateOfPremise=${searchParams.stateOfPremise}&lga=${searchParams.lga}
        &locationArea=${searchParams.locationArea}`;

    setMedicationPrice(undefined);
    try {
      const response = await axios.get(url);
      const data = response.data as IPriceCheckResponse;

      setMedicationPrice(data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadFromRemote = useCallback(async () => {
    //check storage
    const pricecheckName = 'price-check-drugs';
    //if not in storage, the call api

    const url = `${endpoints.PriceCheck.mainUrl}/drugNames`;

    try {
      const response = await axios.get(url);
      const data = response.data;

      localforage
        .setItem(pricecheckName, response.data)
        .then(async () => {
          //addToast('Price list saved successfully', 'success');
        })
        .catch((err) => {
          console.log(err);
          //addToast('Price list not saved', 'error');
        });

      let selectData = data.map((val: any) => {
        return {
          label: `${val}`,
          value: val,
        };
      });
      //set to state

      setDrugNames(selectData);
    } catch (error) {
      addToast('Error getting drug names');
    }
  }, []);

  const loadDrugNames = useCallback(() => {
    const pricecheckName = 'price-check-drugs';
    localforage
      .getItem(pricecheckName)
      .then(async (value) => {
        if (value === null || typeof value === 'undefined') {
          await loadFromRemote();
        } else {
          const parsedPriceList = value as string[];

          let selectData = parsedPriceList.map((val: any) => {
            return {
              label: `${val}`,
              value: val,
            };
          });
          //set to state

          setDrugNames(selectData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [loadFromRemote]);

  useEffect(() => {
    loadDrugNames();
  }, [loadDrugNames]);

  return (
    <div className='mt-5 container'>
      <h2 className='mt-5 text-center'>Price Checker</h2>
      <form className='row mt-5' id='price-check-form'>
        <div className='col-sm-5 form-group'>
          <label>Select medication</label>
          <InputSearch data={drugNames} callback={handleInputChange} />
        </div>
        <div className='col-sm-4 form-group'>
          <GoogleAddressInput
            changeCallback={onAddressChange}
            selectCallback={onAddressSelected}
            inputString={searchParams.inputAddress}
          />
        </div>
        <div className='col-sm-2 form-group mt-2'>
          <button type='submit' className='btn btn-block btn-primary mt-4' onClick={search}>
            Check
          </button>
        </div>
      </form>

      {medicationPrice ? (
        <div className='mt-5 row'>
          <div className='col-md-6 mt-3'>
            <div className='card p-4'>
              <h5>{medicationPrice.drugName}</h5>
              <h6 className='mt-3'>
                Estimated Unit price: {NairaSymbol}
                {medicationPrice.unitPrice.toLocaleString()}
              </h6>
              <div className='mt-3'>
                <label>Location:</label>
                {medicationPrice.stateOfResidence}{' '}
                <span className='ml-2'>{medicationPrice.lga}</span>
                <span className='ml-2'>{medicationPrice.locationArea}</span>
              </div>
              <div className='mt-3'>
                <label>Quantity to dispense(tabs, capsule e.t.c)</label>
                <input
                  type='number'
                  className='form-control col-sm-6'
                  min={1}
                  onChange={handleChange('quantity')}
                  value={medicationPrice.quantity}
                />
              </div>
              <div className='mt-3'>
                <label>Total amount:</label> {NairaSymbol}
                <label>
                  {medicationPrice.quantity
                    ? (medicationPrice.quantity * medicationPrice.unitPrice).toLocaleString()
                    : medicationPrice.unitPrice.toLocaleString()}
                </label>
              </div>
            </div>
          </div>
          <div className='col-md-6 mt-3'>
            <HmoPriceCard drugName={medicationPrice.drugName} />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PriceChecker;
