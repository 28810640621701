import { useCallback, useEffect, useState } from 'react';

const RatingBar = ({
  ratingBarId,
  ratingValue,
  callback,
}: {
  ratingBarId: string;
  ratingValue: number;
  callback?: Function;
}) => {
  const [rating, setRating] = useState(0);
  const allValues = ['N/A', 'Poor', 'Bad', 'Average', 'Very Good', 'Excellent'];

  const handleChange = (event: any) => {
    const value = parseInt(event.target.value);

    setRating(value);
    fillStars(value);
    if (callback) {
      callback(ratingBarId, value);
    }
  };

  const fillStars = useCallback(
    (selectedNumber: number) => {
      //get all radio buttons.
      const allStars = document.getElementsByClassName(`rating-star-${ratingBarId}`);

      for (let i = 0; i < allStars.length; i++) {
        //
        const star = allStars[i] as HTMLElement;
        star.classList.remove('bi-star-fill');
        star.classList.add('bi-star');

        const starId = star.getAttribute('data-starId');
        if (parseInt(starId!) > selectedNumber) {
          continue;
        }

        star.classList.remove('bi-star');
        star.classList.add('bi-star-fill');
      }
    },
    [ratingBarId]
  );

  useEffect(() => {
    fillStars(rating);
  }, [rating, fillStars]);

  useEffect(() => {
    setRating(ratingValue);
  }, [ratingValue]);

  return (
    <div className='d-flex'>
      <label htmlFor={`radio1-${ratingBarId}`} className='mr-3 ml-3'>
        <span data-starId={1} className={`rating-star-${ratingBarId} bi bi-star`}></span>
        <input
          className='d-none'
          type='radio'
          id={`radio1-${ratingBarId}`}
          name={`rating-${ratingBarId}`}
          value={1}
          checked={rating === 1}
          onChange={handleChange}
        />
      </label>
      <label htmlFor={`radio2-${ratingBarId}`} className='mr-3 ml-3'>
        <span data-starId={2} className={`rating-star-${ratingBarId} bi bi-star`}></span>
        <input
          className='d-none'
          type='radio'
          id={`radio2-${ratingBarId}`}
          name={`rating-${ratingBarId}`}
          value={2}
          checked={rating === 2}
          onChange={handleChange}
        />
      </label>
      <label htmlFor={`radio3-${ratingBarId}`} className='mr-3 ml-3'>
        <span data-starId={3} className={`rating-star-${ratingBarId} bi bi-star`}></span>
        <input
          className='d-none'
          type='radio'
          id={`radio3-${ratingBarId}`}
          name={`rating-${ratingBarId}`}
          value={3}
          checked={rating === 3}
          onChange={handleChange}
        />
      </label>
      <label htmlFor={`radio4-${ratingBarId}`} className='mr-3 ml-3'>
        <span data-starId={4} className={`rating-star-${ratingBarId} bi bi-star`}></span>
        <input
          className='d-none'
          type='radio'
          id={`radio4-${ratingBarId}`}
          name={`rating-${ratingBarId}`}
          value={4}
          checked={rating === 4}
          onChange={handleChange}
        />
      </label>
      <label htmlFor={`radio5-${ratingBarId}`} className='mr-3 ml-3'>
        <span data-starId={5} className={`rating-star-${ratingBarId} bi bi-star`}></span>
        <input
          className='d-none'
          type='radio'
          id={`radio5-${ratingBarId}`}
          name={`rating-${ratingBarId}`}
          value={5}
          checked={rating === 5}
          onChange={handleChange}
        />
      </label>
      <label className='ml-auto mr-2 mt-3'>{allValues[rating]}</label>
    </div>
  );
};

export default RatingBar;
