import React from 'react';

const PageHeader = () => {
  return (
    <nav className='navbar navbar-light sticky-top flex-md-nowrap p-0 shadow navbar-custom'>
      <div className='' style={{ margin: '10px 0px 15px 70px' }}>
        <img
          src={require('../../assets/images/wellahealth.svg').default}
          alt='wellahealth'
          width='150'
        />
      </div>
    </nav>
  );
};

export default PageHeader;
