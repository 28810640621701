import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { formatDate } from '../../../utils/dateTimeUtils';

import PaginationBar from '../../common/Pagination';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { IVeriClaimJobQueue } from '../../../interface/VericlaimInterface';

const VeriClaimJobList = () => {
  const [tableData, setTableData] = useState<IVeriClaimJobQueue[]>([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);

  const getVericlaimJobs = useCallback(async (pageIndex = 1) => {
    try {
      const url = `${endpoints.VeriClaim.jobQueue}?pageIndex=${pageIndex}&pageSize=200`;
      const response = await axios.get(url);
      setTableData(response.data.data);
      //set pagination info
      const responsePageInfo = {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        pageCount: response.data.pageCount,
      };
      //set page info
      setPageInfo(responsePageInfo);
      setShowPagination(true);
    } catch (error: any) {
      addToast('There was error getting telemedicine requests', 'error');
    }
  }, []);

  useEffect(() => {
    getVericlaimJobs();
  }, [getVericlaimJobs]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Date Created</th>
        <th scope='col'>Job</th>
        <th scope='col'>Status</th>
        <th scope='col'>Uploaded files</th>
        <th scope='col'>Result</th>
        <th scope='col'>Output files</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((request: IVeriClaimJobQueue) => {
        return (
          <tr key={request.jobId}>
            <td>{formatDate(request.dateCreated)}</td>
            <td>{request.jobName}</td>
            <td>{request.jobStatus}</td>

            <td>
              {request.jobFileList.length > 0
                ? request.jobFileList.map((file) => (
                    <div className='mt-2'>
                      <a href={file} target='_blank' rel='noreferrer'>
                        {file}
                      </a>
                    </div>
                  ))
                : ''}
            </td>
            <td>{request.jobResult}</td>
            <td>
              {request.jobOutputList.length > 0
                ? request.jobOutputList.map((file) => (
                    <div className='mt-2'>
                      <a href={file} target='_blank' rel='noreferrer'>
                        {file}
                      </a>
                    </div>
                  ))
                : ''}
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div>
      <div className='d-flex'>
        <h3 className=' mr-auto'>Vericlaim Jobs</h3>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={getVericlaimJobs}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default VeriClaimJobList;
