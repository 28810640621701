import React, { useState } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import { LoanApplicationStatus, RepaymentPlan } from '../../../utils/mappings';
import { ILoanEditModal, ILoansUpdateDTO } from '../../../interface/LoansInterface';
import ButtonLoader from '../../common/ButtonLoader';

const LoanEditModal = ({ open, close, modalData }: ILoanEditModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const processedLoanStatus = ['Disbursed', 'Repaying', 'Completed'];

  const [loanUpdate, setLoanUpdate] = useState<ILoansUpdateDTO>({
    applicationId: modalData.applicationId,
    interestRate: modalData.interestRate,
    chargesPercent: modalData.chargesPercent,
    documentsUrl: modalData.documentsUrl,
    pharmacyCode: modalData.pharmacyCode,
    pharmacyName: modalData.pharmacyName,
    principalApproved: modalData.principalApproved,
    tenorApproved: modalData.tenorApproved,
    moratorium: modalData.moratorium,
    repaymentPlan: modalData.repaymentPlan,
    applicationStatus: modalData.applicationStatus,
    isProcessed: processedLoanStatus.includes(modalData.applicationStatus),
  });

  const saveLoan = async (event: any) => {
    if (!isFormValidated('loan-edit-modal')) {
      return;
    }

    event.preventDefault();

    setIsLoading(true);

    const url = endpoints.LoanApplications.mainUrl;

    try {
      await axios.put(url, loanUpdate);
      addToast('Loan application updated successfully', 'success');
      close();
    } catch (error: any) {
      addToast('An error occured when updating the loan', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setLoanUpdate({
      ...loanUpdate,
      [input]: event.target.value,
    });
  };

  return (
    <Modal show={open} onHide={() => close()}>
      <form id='loan-edit-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Edit Loan</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Pharmacy Name
              </label>
              <input
                value={loanUpdate.pharmacyName}
                required
                className='form-control '
                type='text'
                readOnly={true}
                onChange={handleChange('pharmacyName')}
              />
            </div>
            <br />
            <div className='col-md-12'>
              <label className='form-label'>Loan Application Status</label>
              {loanUpdate.isProcessed ? (
                <input
                  readOnly={true}
                  value={loanUpdate.applicationStatus}
                  className='form-control'
                />
              ) : (
                <select
                  required
                  onChange={handleChange('applicationStatus')}
                  value={loanUpdate.applicationStatus}
                  className='custom-select'
                >
                  {LoanApplicationStatus.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <br />
            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Document URL
              </label>
              <input
                value={loanUpdate.documentsUrl}
                className='form-control '
                type='text'
                onChange={handleChange('documentsUrl')}
              />
            </div>
            <br />
            <div className='col-md-12'>
              <label className='form-label'>Principal Approved</label>
              <input
                value={loanUpdate.principalApproved}
                required
                className='form-control '
                type='number'
                readOnly={loanUpdate.isProcessed}
                onChange={handleChange('principalApproved')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Tenor Approved (Months) </label>
              <input
                value={loanUpdate.tenorApproved}
                className='form-control '
                type='number'
                min={1}
                max={12}
                readOnly={loanUpdate.isProcessed}
                onChange={handleChange('tenorApproved')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Moratorium (Months)</label>
              <input
                value={loanUpdate.moratorium}
                required
                className='form-control '
                type='number'
                min={0}
                max={12}
                readOnly={loanUpdate.isProcessed}
                onChange={handleChange('moratorium')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Interest Rate</label>
              <input
                value={loanUpdate.interestRate}
                required
                className='form-control '
                type='number'
                min={0}
                max={100}
                step={0.01}
                readOnly={loanUpdate.isProcessed}
                onChange={handleChange('interestRate')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Charges Percent</label>
              <input
                value={loanUpdate.chargesPercent}
                required
                className='form-control '
                type='number'
                min={0}
                max={100}
                step={0.01}
                readOnly={loanUpdate.isProcessed}
                onChange={handleChange('chargesPercent')}
              />
            </div>
            <br />
            <div className='col-md-12'>
              <label className='form-label'>Repayment Plan</label>

              <select
                required
                onChange={handleChange('repaymentPlan')}
                value={loanUpdate.repaymentPlan}
                className='custom-select'
                disabled={loanUpdate.isProcessed}
              >
                {RepaymentPlan.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button type='submit' className='btn btn-primary' onClick={saveLoan} disabled={isLoading}>
            Save
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default LoanEditModal;
