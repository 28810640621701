import { IFulfilmentDrugsData } from '../../../interface/FulfilmentsInterface';

const FulfilmentDrugSummary = (data: { data: IFulfilmentDrugsData[] }) => {
  const getStatus = () => {
    if (data.data.length === 0) {
      return;
    }
    const diff =
      data.data.reduce((a, b) => a + b.tariffCost, 0) - data.data.reduce((a, b) => a + b.cost, 0);

    if (diff > 0) {
      return 'text-success';
    }

    return 'text-danger';
  };

  return (
    <div className='table-responsive'>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Drug name</th>
            <th>Cost</th>
            <th>Tariff name</th>
            <th>Tariff cost</th>
            <th>Quantity</th>
            <th>Status</th>
            <th>Cost Diff</th>
          </tr>
        </thead>
        <tbody>
          {data.data.length > 0
            ? data.data.map((x) => (
                <tr>
                  <td>{x.name}</td>
                  <td>{x.cost.toLocaleString()}</td>
                  <td>{x.tariffDrugName}</td>
                  <td>{x.tariffCost.toLocaleString()}</td>
                  <td>{x.quantity.toLocaleString()}</td>
                  <td>
                    {x.isDispensed ? (
                      <span className='text-success'>Dispensed</span>
                    ) : (
                      <span className='text-danger'>Not Dispensed</span>
                    )}
                  </td>
                  <td
                    className={
                      x.tariffCost - x.cost > 0 ? 'text-success fw-bold' : 'text-danger fw-bold'
                    }
                  >
                    {(x.tariffCost - x.cost).toLocaleString()}
                  </td>
                </tr>
              ))
            : ''}
          {data.data.length > 0 ? (
            <tr>
              <th>Total</th>
              <th>
                {data.data.reduce((a, b) => a + parseFloat(b.cost.toString()), 0).toLocaleString()}
              </th>
              <th></th>
              <th>
                {data.data
                  .reduce((a, b) => a + parseFloat(b.tariffCost.toString()), 0)
                  .toLocaleString()}
              </th>
              <th></th>
              <th></th>
              <th className={getStatus()}>
                {(
                  data.data.reduce((a, b) => a + parseFloat(b.tariffCost.toString()), 0) -
                  data.data.reduce((a, b) => a + parseFloat(b.cost.toString()), 0)
                ).toLocaleString()}
              </th>
            </tr>
          ) : (
            ''
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FulfilmentDrugSummary;
