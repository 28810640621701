import { useState } from 'react';

export const useTimer = (label: string) => {
  const [startTime, setStartTime] = useState(0);

  const startTimer = () => {
    const initTime = window.performance.now();
    setStartTime(initTime);
  };

  //useCallback ??
  const stopTimer = () => {
    let endTime = window.performance.now();
    const timeDiff = endTime - startTime;
    setStartTime(0);

    //push to API
    console.log(timeDiff, label);
  };

  return {
    startTimer,
    stopTimer,
  };
};
