import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { Link } from 'react-router-dom';

const WalletView = ({ setActiveNav }: { setActiveNav: any }) => {
  const [debitRequestCount, setDebitRequestCount] = useState(0);

  const getDebitRequestCount = useCallback(async () => {
    try {
      await axios.get(endpoints.WalletPayouts.getPendingCount).then((response) => {
        const pendingCount = response.data;
        setDebitRequestCount(pendingCount);
      });
    } catch (error: any) {
      addToast('There was an error getting debit requests count', 'error');
    }
  }, []);

  useEffect(() => {
    getDebitRequestCount();
  }, [getDebitRequestCount]);

  return (
    <>
      <li className='nav-item'>
        <Link to='/wallets/payouts' className='nav-link position-relative' onClick={setActiveNav}>
          Payout Requests
          <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
            {debitRequestCount}
          </h3>
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/wallets' className='nav-link' onClick={setActiveNav}>
          Wallet Accounts
        </Link>
      </li>
    </>
  );
};

export default WalletView;
