import React, { Fragment, useEffect, useState } from 'react';
import { IPriceList, IPriceListUpdate } from '../../../interface/PriceListInterface';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import { AllFulfilmentServiceMap } from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';
import { Link } from 'react-router-dom';
import { IFulfilmentPartners } from '../../../interface/FulfilmentPartnersInterface';
import PriceListEditModal from './PriceListEditModal';
import { SortingCenterPharmacy, SortingCenterPharmacyName } from '../../../utils/constants';
import { formatDateTime } from '../../../utils/dateTimeUtils';

const PriceListTable = ({ history }: any) => {
  const initialParams = {
    partnerCode: '',
    fulfilmentService: '',
    partnerName: '',
  };

  const [tariffData, setTariffData] = useState<IPriceListUpdate[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchParam, setSearchParam] = useState(initialParams);
  const [partnerList, setPartnersList] = useState<IFulfilmentPartners[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<IPriceListUpdate | undefined>();

  const handleChange = (input: string) => (event: any) => {
    if (input === 'partnerCode') {
      const partnerName = partnerList.filter((x) => x.partnerCode === event.target.value)[0]
        .partnerName;

      setSearchParam({
        ...searchParam,
        partnerName: partnerName,
        partnerCode: event.target.value,
      });
      return;
    }

    setSearchParam({
      ...searchParam,
      [input]: event.target.value,
    });
  };

  const toggleModal = (pricelist?: IPriceListUpdate) => {
    setModalOpen(!isModalOpen);
    if (pricelist) {
      setModalData(pricelist);
    }
  };

  const onSearchClick = async () => {
    if (searchParam.partnerCode === '') {
      addToast('Please select a partner', 'warning');
      return;
    }

    searchParam.fulfilmentService =
      searchParam.fulfilmentService === 'All' ? '' : searchParam.fulfilmentService;

    setIsSearch(true);

    let url = `${endpoints.Pricelist.mainUrl}/partner/${searchParam.partnerCode}/full?serviceType=${searchParam.fulfilmentService}`;

    try {
      const response = await axios.get(url);
      setTariffData(response.data);
      setIsSearch(false);
    } catch (error: any) {
      addToast('There was an error getting data', 'error');
      setIsSearch(false);
    }
  };

  const getPartnersList = async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;

    try {
      const response = await axios.get(url);

      //set to state
      const data = response.data as IFulfilmentPartners[];

      const sortingCenter: IFulfilmentPartners = {
        chargeValueType: '',
        serviceCharge: 0,
        partnerId: 0,
        dateCreated: '2023-07-01',
        dateUpdated: '2023-08-01',
        supportEmail: '',
        contactEmail: '',
        partnerCode: SortingCenterPharmacy,
        partnerName: SortingCenterPharmacyName,
        phoneNumber: '',
        location: 'Lagos',
        isActive: true,
      };

      data.push(sortingCenter);
      setPartnersList(response.data);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const updateTable = (updatedData: IPriceList) => {
    const oldData = tariffData.filter((x) => x.priceListId === updatedData.priceListId)[0];
    const parsedOldData = oldData || null;
    const filteredData: any = [...tariffData];

    if (parsedOldData !== null) {
      filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
    } else {
      filteredData.unshift(updatedData);
    }
    setTariffData(filteredData);
  };

  useEffect(() => {
    const savedPartnersList = sessionStorage.getItem('partnersList');

    if (savedPartnersList !== null) {
      const parsedPartnersList = JSON.parse(savedPartnersList);

      //set to state
      setPartnersList(parsedPartnersList);
    } else {
      //call api
      getPartnersList();
    }
  }, []);

  let searchInput = (
    <Fragment>
      <div className='row'>
        <div className='col-md-4'>
          <label htmlFor='fulfilmentService' className='form-label'>
            Fulfilment Partner
          </label>
          <select
            required
            onChange={handleChange('partnerCode')}
            value={searchParam.partnerCode}
            className='custom-select'
          >
            <option value=''></option>

            {partnerList.map((option) => (
              <option key={option.partnerId} value={option.partnerCode}>
                {option.partnerName}
              </option>
            ))}
          </select>
        </div>

        <div className='col-md-4'>
          <label htmlFor='fulfilmentService' className='form-label'>
            Fulfilment Service
          </label>
          <select
            required
            onChange={handleChange('fulfilmentService')}
            value={searchParam.fulfilmentService}
            className='custom-select'
          >
            {AllFulfilmentServiceMap.map((option) => (
              <option key={option.key} value={option.value.toString()}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-4'>
          <button
            onClick={onSearchClick}
            className='btn btn-block btn-primary'
            style={{ marginTop: '30px' }}
          >
            Search
            {isSearch ? <ButtonLoader /> : ''}
          </button>
        </div>
      </div>
    </Fragment>
  );

  let priceModal = (
    <PriceListEditModal
      open={isModalOpen}
      close={toggleModal}
      modalData={modalData!}
      callback={updateTable}
      partnerCode={searchParam.partnerCode}
    />
  );

  return (
    <Fragment>
      {priceModal}
      <div className='container' style={{ margin: '30px 0px' }}>
        {searchInput}
      </div>
      <div className='d-flex'>
        <h4 className='mr-auto'>
          {tariffData.length > 0 && !isSearch
            ? `${searchParam.partnerName} tariff for ${
                searchParam.fulfilmentService || 'All'
              } services`
            : ''}
        </h4>
        <Link to='/tariff/add' className='btn btn-primary'>
          Add Tariff
        </Link>
      </div>

      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th>Action</th>
              <th scope='col-md-3'>Drug Info</th>
              <th scope='col-md-3'>Drug Price</th>
              <th scope='col-md-3'>Unit Price</th>
              {/* <th scope='col-md-3'>Services</th> */}
              <th scope='col-md-3'>Notes</th>
              <th scope='col-md-3'>Date Updated</th>
            </tr>
          </thead>
          <tbody>
            {tariffData.map((item: IPriceListUpdate) => {
              return (
                <tr
                  style={{ cursor: 'pointer' }}
                  key={item.priceListId}
                  // onClick={() => toggleModal(item)}
                >
                  <td>
                    <Link to={`/tariff/${item.priceListId}/edit`} className='text-decoration-none'>
                      Edit <span className='bi bi-pencil ml-1'></span>
                    </Link>
                  </td>
                  <td>
                    {item.drugName}
                    <div className='fs-8'>
                      <span>
                        Generic: <span className='text-success'>{item.genericName}</span>
                      </span>
                      <span className='ml-2'>
                        Strength: <span className='text-success'>{item.strength || 'nil'}</span>
                      </span>
                      <span className='ml-2'>
                        Form: <span className='text-success'>{item.dosageForm}</span>
                      </span>
                    </div>
                  </td>
                  <td>{item.drugPrice}</td>
                  <td>{item.unitPrice}</td>
                  {/* <td>{item.services}</td> */}
                  <td>{item.notes}</td>
                  <td>{formatDateTime(item.dateUpdated)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PriceListTable;
