import { pharmacyRating } from './PharmacyInterface';
import { IPharmacyRatingDTO } from './PharmacyRatingInterface';

export interface IFulfilments {
  requestId: number;
  enrollmentId: number;
  enrollmentCode: string;
  enrolleeFirstName: string;
  enrolleeLastName: string;
  enrolleePhone: string;
  enrolleeEmail: string;
  enrolleeAddress: string;
  fulfilmentStatus: string;
  fulfilmentService: string;
  drugCost: number;
  drugTariffCost: number;
  testCost: number;
  testTariffCost: number;
  drugsDispensed: boolean[];
  testsDispensed: boolean[];
  pharmacyCode: string;
  isPharmacyPaid: boolean;
  requestSource: string;
  partnerCode: string;
  preAuthorizationCode: string;
  notes: string;
  dateCreated: string;
  assignedTo: string;
  enrolleeDateOfBirth: string;
  pickupCode: string;
  useSortingCenter: boolean;
}

interface SearchParams {
  partnerCode: string;
  fulfilmentStatus: string;
  partnerName: string;
  startDate: string;
  fulfilmentService: string;
}

export const initialFulfilmentsData: IFulfilments = {
  requestId: 0,
  enrollmentId: 0,
  enrollmentCode: '',
  enrolleeFirstName: '',
  enrolleeLastName: '',
  enrolleePhone: '',
  enrolleeEmail: '',
  enrolleeAddress: '',
  fulfilmentStatus: 'Pending',
  fulfilmentService: 'Telemedicine',
  drugCost: 0,
  drugTariffCost: 0,
  testCost: 0,
  testTariffCost: 0,
  drugsDispensed: [],
  testsDispensed: [],
  pharmacyCode: '',
  isPharmacyPaid: false,
  useSortingCenter: false,
  requestSource: '',
  partnerCode: '',
  notes: '',
  dateCreated: '',
  assignedTo: '',
  preAuthorizationCode: '',
  enrolleeDateOfBirth: '',
  pickupCode: '',
};

export const initialSearchParams: SearchParams = {
  partnerCode: '',
  fulfilmentStatus: '',
  partnerName: '',
  startDate: '',
  fulfilmentService: '',
};

export interface IFulfilmentDrugsData {
  drugId: number;
  enrollmentDrugId: number;
  name: string;
  dose: string;
  dosageForm: string;
  frequency: string;
  strength: string;
  duration: string;
  requestId: number;
  isDispensed: boolean;
  cost: number;
  tariffCost: number;
  pharmacyCode: string;
  isPharmacyPaid: boolean;
  notes: string;

  //tariff data
  tariffDrugName: string;
  newtariffDrugName: string;
  unitCost: number;
  quantity: number;
  pricelistId: number;
  tariffNotes?: string;
}

export interface IFulfilmentTestsData {
  testId: number;
  enrollmentTestId: number;
  testName: string;
  isCarriedOut: boolean;
  testResult: string;
  cost: number;
  requestId: number;
  fileName: string;
  notes: string;
  pharmacyCode: string;
  isPharmacyPaid: boolean;
  hasPartnerPaid: boolean;

  uploadStatus?: any;
  formFile?: any;
  fileId?: number;
}

export interface IFulfilmentDataExportDTO {
  email: string;
  partnerCode: string;
  fulfilmentService: string | null;
  fulfilmentStatus: string | null;
  startDate: string;
  endDate: string;
  fulfilmentExportType: string;
}

export interface ITestsInfo {
  testName: string;
  isCarriedOut: boolean;
  testResult: string;
  cost: number;
  tariffCost: number;
  pharmacyCode: string;
  isPharmacyPaid: boolean;
  hasPartnerPaid: boolean;
  notes: string;
}

export interface IDrugsInfo {
  localId: number;
  name: string;
  dose: string;
  isDispensed: boolean;
  cost: number;
  tariffCost: number;
  useSortingCenter: boolean;
  notes: string;
  strength: string;
  frequency: string;
  duration: string;
  dosageForm: string;
  //tariff data
  tariffDrugName: string;
  newTariffDrugName: string;
  unitCost: number;
  quantity: number;
}

export interface IFulfilmentData {
  enrolleeFirstName: string;
  enrolleeLastName: string;
  enrollmentCode: string;
  enrolleePhone: string;
  enrolleeEmail: string;
  enrolleeAddress: string;
  pharmacyCode: string;
  partnerCode: string;
  fulfilmentService: string;
  fulfilmentStatus: string;
  isPharmacyPaid: boolean;
  pickUpDate: string;
  serviceCharge: number;
  notes: string;
  tests: Array<ITestsInfo>;
  drugs: Array<IDrugsInfo>;
  rating?: IPharmacyRatingDTO;
  useSortingCenter: boolean;
  diagnosis: string;
  preAuthorizationCode: string;
}

export interface IFulfilmentDrugsDTO {
  requestId: number;
  name: string;
  dose: string;
  dosageForm: string;
  strength: string;
  frequency: string;
  duration: string;

  cost: number;
  tariffCost: number;
  pharmacyCode: string;
  payViaWallet: boolean;
  payInstantly: boolean;
  isDispensed: boolean;
  notes: string;

  //tariff data
  tariffDrugName: string;
  newTariffDrugName: string;
  unitCost: number;
  quantity: number;
  pricelistId: number;
  drugNotes: string;
}

export interface IFulfilmentDetailsProp {
  tabKey: string;
  fulfilmentRequest: IFulfilments | undefined;
  toggleLock: Function;
  getFulfilment: Function;
  openNoteModal?: Function;
}

// export interface IFulfilmentRequestModalData {
//   open: boolean;
//   close?: any;
//   updateRequest?: any;
//   selectedData: IFulfilments;
// }

export interface IFulfilmentPatientData {
  patientId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  hmoId: string;
  email: string;
  gender: string;
  address: string;
  locationLongitude: string;
  locationLatitude: string;
  pharmacyCode: string;
  pharmacyName: string;
  fulfilmentService: string;
}

export interface IFulfilmentPatientDataDTO extends IFulfilmentPatientData {
  preAuthorizationCode: string;
  diagnosis: string;
  notes: string;
}

export interface AddTestsModalProps {
  open: boolean;
  partnerCode: string;
  close: () => void;
  addToFulfilmentPlan: (testInfo: ITestsInfo) => void;
  //pharmacyCodesData: Array<IPharmacyListData>;
  fulfilmentService: string;
}

export interface AddDrugsModalProps {
  open: boolean;
  close: () => void;
  partnerCode: string;
  pharmacyCode: string;
  addToFulfilmentPlan: (drugInfo: IDrugsInfo) => void;
  //pharmacyCodesData: Array<IPharmacyListData>;
  fulfilmentService: string;
  initialDrug?: IDrugsInfo; //in case we are editing
}

export interface IFulfilmentDraftResponse {
  id: number;
  hmoId: string;
  partnerCode: string;
  partnerName: string;
  preAuthorizationCode: string;
  draftStatus: string;
  diagnosis: string;
  notes: string;
  medicationInfo: Array<IFulfilmentDrugsData>;
  patientInfo?: IFulfilmentPatientDataDTO;
}

export interface IFulfilmentDraft {
  hmoId: string;
  partnerCode: string;
  partnerName: string;
  rawPatientInfo: string;
  rawMedicationInfo: string;
  preAuthorizationCode: string;
  pharmacyCode: string;
  diagnosis: string;
  notes: string;
  fulfilmentService: string;
  patientInfo?: IFulfilmentPatientData;
}

export interface IFulfilmentDraftSection {
  draft?: IFulfilmentDraftResponse;
  callback?: Function;
}

export interface IPreAuthClaims {
  paNumber: string;
  groupName: string;
  enrolleeNumber: string;
  enrollmentCode: string;
  providerId: string;
  amountRequested: number;
  endorsementValue: number;
  requestDate: string;
  completedDate: string;
  qty: number;
  status: string;
  diagnosisCode: string;
  diagnosisdesc: string;
  procedureCode: string;
  proceduredesc: string;
}

export const defaultFulfilmentDraft: IFulfilmentDraft = {
  hmoId: '',
  partnerCode: '',
  partnerName: '',
  pharmacyCode: '',
  preAuthorizationCode: '',
  diagnosis: '',
  notes: '',
  rawMedicationInfo: '',
  rawPatientInfo: '',
  fulfilmentService: 'Chronic',
};

export const defaultFulfilmentPatient: IFulfilmentPatientData = {
  patientId: 0,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  hmoId: '',
  email: '',
  gender: '',
  address: '',
  locationLongitude: '',
  locationLatitude: '',
  pharmacyCode: '',
  pharmacyName: '',
  fulfilmentService: 'Chronic',
};

export const fulfilmentdrugsFormData: IFulfilmentDrugsDTO = {
  requestId: 0,
  name: '',
  dose: '',
  dosageForm: '',
  strength: '',
  frequency: '',
  duration: '',
  cost: 0,
  tariffCost: 0,
  pharmacyCode: '',
  payViaWallet: false,
  payInstantly: false,
  isDispensed: true,
  notes: '',
  //tariff data
  tariffDrugName: '',
  newTariffDrugName: '',
  unitCost: 0,
  quantity: 0,
  pricelistId: 0,
  drugNotes: '',
};

export const exportData: IFulfilmentDataExportDTO = {
  partnerCode: '',
  fulfilmentService: 'All',
  fulfilmentStatus: 'All',
  startDate: '',
  endDate: '',
  email: '',
  fulfilmentExportType: 'ClaimFilingExport',
};

export const initialFulfilmentFormData: IFulfilmentData = {
  enrolleeFirstName: '',
  enrolleeLastName: '',
  enrollmentCode: '',
  enrolleePhone: '',
  enrolleeEmail: '',
  enrolleeAddress: '',
  pharmacyCode: '',
  partnerCode: '',
  fulfilmentService: 'Telemedicine',
  fulfilmentStatus: 'Pending',
  isPharmacyPaid: false,
  pickUpDate: new Date().toISOString().split('T')[0],
  serviceCharge: 200,
  notes: '',
  tests: [],
  drugs: [],
  rating: pharmacyRating,
  useSortingCenter: false,
  diagnosis: '',
  preAuthorizationCode: '',
};

export const testsFormData: ITestsInfo = {
  testName: '',
  isCarriedOut: false,
  testResult: 'NotDone',
  cost: 0,
  tariffCost: 0,
  pharmacyCode: '',
  isPharmacyPaid: false,
  hasPartnerPaid: false,
  notes: '',
};

export const drugsFormData: IDrugsInfo = {
  name: '',
  dose: '',
  dosageForm: '',
  isDispensed: false,
  cost: 0,
  tariffCost: 0,
  notes: '',
  useSortingCenter: false,
  localId: 0,
  strength: '',
  frequency: '',
  duration: '',
  tariffDrugName: '',
  newTariffDrugName: '',
  unitCost: 0,
  quantity: 0,
};

export interface IEditFulfilmentModal {
  open: boolean;
  close: Function;
  modalData: IFulfilments;
  callback?: Function;
}

export interface IFulfilmentTableProps {
  // tabKey: string;
  setAdvSearch?: Function;
  data: IFulfilments[];
  updateTableData?: Function;
  //allData: IFulfilments[];
}

export const initialDrugData: IFulfilmentDrugsData = {
  name: '',
  dose: '',
  dosageForm: '',
  drugId: 0,
  duration: '',
  strength: '',
  frequency: '',
  requestId: 0,
  enrollmentDrugId: 0,
  isDispensed: false,
  isPharmacyPaid: false,
  tariffDrugName: '',
  newtariffDrugName: '',
  tariffCost: 0,
  unitCost: 0,
  cost: 0,
  pharmacyCode: '',
  quantity: 0,
  notes: '',
  pricelistId: 0,
};
