import { getTodayDateInput } from '../utils/dateTimeUtils';

export interface IHmoReportDTO {
  graphImageFileUrl: string;
  ratingFileUrl: string;
  partnerCode: string;
  amountSaved: number;
  satisfactionScore: number;
  additionalRecipients: string;
  queueDate: string;
  startDate: string;
  endDate: string;
}

export const defaultReport: IHmoReportDTO = {
  partnerCode: 'WHPX10001',
  graphImageFileUrl: '',
  ratingFileUrl: '',
  queueDate: getTodayDateInput(),
  amountSaved: 0,
  additionalRecipients: '',
  satisfactionScore: 0,
  startDate: '',
  endDate: '',
};
