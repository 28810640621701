import { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { ClaimTypes } from '../../../utils/mappings';
import { useParams } from 'react-router-dom';
import { IClaimEligibility } from '../../../interface/ClaimsInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import LoaderWithText from '../../common/LoaderWithText';
import { addToast } from '../../../utils/toastNotifications';
import { IBeneficiary } from '../../../interface/BeneficiaryInterface';
import { useHistory } from 'react-router-dom';

const AddClaimModal = ({
  open,
  close,
  beneficiary,
}: {
  open: boolean;
  close: Function;
  beneficiary: IBeneficiary;
}) => {
  const { subscriptionCode } = useParams() as any;

  const history = useHistory();

  const [claimEligibity, setClaimEligibility] = useState<IClaimEligibility | null>();
  const [loading, setLoading] = useState(false);
  const [claimType, setClaimType] = useState('');

  const goToClaim = () => {
    history.push(
      `/subscriptions/${subscriptionCode}/${beneficiary.insuranceBeneficiaryId}/claims/add`
    );
  };

  const checkEligibility = () => {
    if (claimType.trim() === '') {
      addToast('Please select a claim type', 'warning');
      return;
    }

    getClaimEligibility(beneficiary.insuranceBeneficiaryId);
  };

  const getClaimEligibility = useCallback(
    async (beneficiaryId: number) => {
      //if axa, claim is eligible

      if (subscriptionCode === 'axa') {
        const axaClaimsEligibility: IClaimEligibility = {
          eligible: true,
          reason: 'Axa claim',
        };

        setClaimEligibility(axaClaimsEligibility);

        return;
      }

      setClaimEligibility(null);

      const url = `${endpoints.InsuranceClaims.mainUrl}/claimeligibility/${subscriptionCode}/beneficiary/${beneficiaryId}`;
      setLoading(true);

      try {
        const response = await axios.get(url);
        setClaimEligibility(response.data);
      } catch (error: any) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [subscriptionCode]
  );

  return (
    <Modal show={open} onHide={() => close()}>
      <Modal.Header closeButton>
        Add Claim for {beneficiary.firstName} {beneficiary.lastName}
      </Modal.Header>
      <Modal.Body>
        {claimEligibity?.eligible ? (
          <div>
            <div className='text-success mb-3'>{claimEligibity.reason}</div>
            <button className='btn btn-primary btn-block' onClick={goToClaim}>
              Make Claim
            </button>
          </div>
        ) : (
          <div>
            {loading ? (
              <LoaderWithText />
            ) : (
              <div>
                <div className=''>
                  <div className='text-danger mb-3'>{claimEligibity?.reason}</div>
                  <label className='form-label'>Select Claim Type</label>
                  <select
                    value={claimType}
                    required
                    className='custom-select'
                    onChange={(e) => setClaimType(e.target.value)}
                  >
                    <option value=''>-</option>
                    {ClaimTypes.map((option) => (
                      <option key={option.key} value={option.value.toString()}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                <button className='mt-5 btn btn-primary btn-block' onClick={checkEligibility}>
                  Check Eligibility
                </button>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export { AddClaimModal };
