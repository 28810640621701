import axios from 'axios';
import { useEffect, useState } from 'react';
import { IFulfilmentDetailsProp } from '../../../interface/FulfilmentsInterface';
import {
  IFulfilmentConfiguration,
  IFulfilmentPartners,
} from '../../../interface/FulfilmentPartnersInterface';
import {
  initialPharmacyData,
  IPharmacyData,
  IPharmacyAddressingDTO,
} from '../../../interface/PharmacyInterface';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import Select from 'react-select';
import { FulfilmentDetailsTab, PharmacyStatusMap } from '../../../utils/mappings';
import PharmacyMapSidebar from './PharmacyMapSidebar';
import { IPharmacyRatingData } from '../../../interface/PharmacyRatingInterface';
import PharmacyRatingsModal from '../claims/PharmacyRatingsModal';

const FulfilmentPharmacy = ({
  fulfilmentRequest,
  tabKey,
  toggleLock,
  getFulfilment,
}: IFulfilmentDetailsProp) => {
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState<IPharmacyRatingData>({
    serviceId: 0,
    serviceType: 'Fulfilment',
    pharmacyName: '',
    pharmacyCode: '',
  });
  const [pharmacyData, setPharmacyData] = useState<IPharmacyData>(initialPharmacyData);

  const [isChangePharm, setChangePharm] = useState(false);
  const [pharmacyListSelect, setPharmacyListSelect] = useState([]);
  //const [pharmacyListData, setPharmacyListData] = useState<IPharmacyListData[]>([]);

  const [showSidemodal, setShowSideModal] = useState(false);

  const [newPharmacyCode, setNewPharmacyCode] = useState(fulfilmentRequest?.pharmacyCode);

  const [partnerConfiguration, setPartnerConfiguration] = useState<IFulfilmentConfiguration>({
    partnerCode: '',
    partnerName: '',
    dispenseTests: false,
    dispenseDrugs: false,
    changePharmacy: false,
    sendDrugsDispensed: false,
    services: [],
    serviceCharge: 200,
    chargeValueType: 'Absolute',
    lockTariff: true,
  });

  const [usedPharmacies, setUsedPharmacies] = useState<IPharmacyAddressingDTO[]>([]);

  // const relianceUsername = process.env.REACT_APP_RELIANCE_USERID;
  // const reliancePassword = process.env.REACT_APP_RELIANCE_PASSWORD;
  // const axiosInstance = axios.create();

  const getPharmacyList = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/list`;

    try {
      const response = await axios.get(url);
      let selectData = response.data
        .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
        .map((val: any) => {
          return {
            label: `${val.pharmacyName} ${val.address}`,
            value: val.pharmacyCode,
          };
        });
      //set to state
      setPharmacyListSelect(selectData);
      //setPharmacyListData(response.data);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('pharmacyList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const selectChange = (selectedValue: any) => {
    setNewPharmacyCode(selectedValue.value);
  };

  //fetch pharmacy details from api
  //set new pharmacy code and send details to reliance

  const savePharmacyCode = async (pharmacyCode: string) => {
    //save to reliance
    try {
      setIsSaving(true);

      //Moved to Webhooks
      // if (partnerConfiguration !== null && partnerConfiguration.partnerCode === 'WHPX10002') {
      //   //if (partnerConfiguration.partnerCode === 'WHPX10002') {
      //   await sendPharmacyCodeToReliance(pharmacyCode!);
      // }
      await updateFulfilmentPharmacy(pharmacyCode!);
      getFulfilment();
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const saveBtnClick = async () => {
    await savePharmacyCode(newPharmacyCode!);
  };

  const changePharmacyFromCard = (pharmacyCode: string, pharmacyName: string) => (event: any) => {
    const response = window.confirm(
      `Please confirm that you want to change the pharmacy to ${pharmacyName}`
    );

    if (!response) {
      return;
    }
    setNewPharmacyCode(pharmacyCode);
    savePharmacyCode(pharmacyCode);
  };

  //save to remote
  const updateFulfilmentPharmacy = async (pharmacyCode: string) => {
    const payload = {
      pharmacyCode: pharmacyCode,
      requestId: fulfilmentRequest!.requestId,
    };
    toggleLock(true);

    let url = `${endpoints.FulfilmentRequests.mainUrl}/${fulfilmentRequest!.requestId}/pharmacy`;
    try {
      await axios.put(url, payload);
      addToast('Pharmacy changed successfully', 'success');
      getFulfilment();
      setChangePharm(false);
      getPharmacyData(pharmacyCode);
    } catch (error: any) {
      addToast('An error occured while updating pharmacy');
    } finally {
      toggleLock(false);
    }
  };

  // const sendPharmacyCodeToReliance = async (pharmacyCode: string) => {
  //   const selectedPharmacy = pharmacyListData.filter((x) => x.pharmacyCode === pharmacyCode)[0];

  //   if (selectedPharmacy === null || typeof selectedPharmacy === 'undefined') {
  //     return false;
  //   }

  //   let payload = {
  //     provider: {
  //       pharmacy_code: pharmacyCode,
  //       name: selectedPharmacy.pharmacyName,
  //       address: selectedPharmacy.address,
  //     },
  //     service_type: fulfilmentRequest!.fulfilmentService.toLowerCase(),
  //     extra_details: 'Please pick up between 8am and 8pm',
  //   };

  //   let updateResponse = false;
  //   let url = `${endpoints.Reliance.postTestResult}/${fulfilmentRequest!.enrollmentId}/provider`;
  //   await axiosInstance
  //     .put(url, payload, {
  //       auth: {
  //         username: relianceUsername as string,
  //         password: reliancePassword as string,
  //       },
  //     })
  //     .then((response) => {
  //       addToast('Pharmacy change sent to Reliance', 'success');
  //       updateResponse = true;
  //     })
  //     .catch((error) => {
  //       //stop button loading
  //       addToast('An error occured while sending requests', 'error');
  //     });

  //   return updateResponse;
  // };

  const getPharmacyData = async (pharmacyCode: string) => {
    setLoading(true);
    //setPharmacyData(initialPharmacyData);

    let url = `${endpoints.InsurancePharmacy.mainUrl}/${pharmacyCode}`;
    try {
      const response = await axios.get(url);
      setPharmacyData(response.data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changePharmacy = () => {
    setChangePharm(!isChangePharm);
  };

  const confirmPharmacy = () => {
    setNewPharmacyCode(fulfilmentRequest!.pharmacyCode);
    //sendPharmacyCodeToReliance(fulfilmentRequest!.pharmacyCode);
  };

  const getUsedPharmacies = async (phoneNumber: string) => {
    const url = `${endpoints.FulfilmentRequests.mainUrl}/${phoneNumber}/pharmacy/history`;

    try {
      const response = await axios.get(url);
      setUsedPharmacies(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setShowSideModal(!showSidemodal);
  };

  const toggleRatingModal = () => {
    if (fulfilmentRequest) {
      setRating({
        ...rating,
        pharmacyCode: fulfilmentRequest?.pharmacyCode!,
        serviceType: 'Fulfilment',
        serviceId: fulfilmentRequest?.requestId!,
        pharmacyName: fulfilmentRequest?.pharmacyCode,
      });
    }
    setShowRatingModal(!showRatingModal);
  };

  //use effect to load all pharmacies if not already in session
  useEffect(() => {
    //load pharmacy list and save in session storage if not available
    if (fulfilmentRequest && tabKey === FulfilmentDetailsTab.pharmacy) {
      const savedPharmacyList = sessionStorage.getItem('pharmacyList');

      if (savedPharmacyList !== null) {
        const pharmacyList = JSON.parse(savedPharmacyList);
        let selectData = pharmacyList
          .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
          .map((val: { pharmacyName: string; pharmacyCode: string; address: string }) => {
            return {
              label: `${val.pharmacyName} ${val.address}`,
              value: val.pharmacyCode,
            };
          });
        //set to state
        setPharmacyListSelect(selectData);
        //setPharmacyListData(pharmacyList);
      } else {
        //call api
        getPharmacyList();
      }
    }
  }, [tabKey, fulfilmentRequest]);

  //use effect to load pharmacy data
  useEffect(() => {
    if (tabKey === FulfilmentDetailsTab.pharmacy) {
      getPharmacyData(fulfilmentRequest!.pharmacyCode);
    }
  }, [tabKey, fulfilmentRequest]);

  useEffect(() => {
    if (tabKey === FulfilmentDetailsTab.pharmacy) {
      getUsedPharmacies(fulfilmentRequest!.enrolleePhone);
    }
  }, [tabKey, fulfilmentRequest]);

  // const getFulfilmentPartners = async () => {
  //   let url = endpoints.FulfilmentPartners.mainUrl;
  //   try {
  //     const response = await axios.get(url);

  //     const data: IFulfilmentConfiguration = response.data.map((x: IFulfilmentPartners) => {
  //       return {
  //         partnerCode: x.partnerCode,
  //         partnerName: x.partnerName,
  //         dispenseTests: true,
  //         dispenseDrugs: true,
  //         changePharmacy: true,
  //         sendDrugsDispensed: x.partnerCode === 'WHPX10002' ? true : false,
  //         services: ['telemedicine', 'acute', 'chronic'],
  //         serviceCharge: x.serviceCharge,
  //         chargeValueType: x.chargeValueType,
  //       };
  //     });
  //     setPartnerConfiguration(data);

  //     sessionStorage.setItem('partnersList', JSON.stringify(response.data));
  //   } catch (error:any) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    if (fulfilmentRequest && tabKey === FulfilmentDetailsTab.pharmacy) {
      const savedPartnerList = localStorage.getItem('partnersList');
      if (savedPartnerList !== null && typeof savedPartnerList !== 'undefined') {
        const parsedPartnerList = JSON.parse(savedPartnerList) as IFulfilmentPartners[];
        const selectedPartner = parsedPartnerList.filter(
          (x) => x.partnerCode === fulfilmentRequest.partnerCode
        )[0];

        const parsedPartnerConfig = selectedPartner || null;
        if (parsedPartnerConfig !== null) {
          //map
          const partnerConfig: IFulfilmentConfiguration = {
            partnerCode: parsedPartnerConfig.partnerCode,
            partnerName: parsedPartnerConfig.partnerName,
            dispenseTests: true,
            dispenseDrugs: true,
            changePharmacy: true,
            sendDrugsDispensed: parsedPartnerConfig.partnerCode === 'WHPX10002' ? true : false,
            services: ['telemedicine', 'acute', 'chronic'],
            serviceCharge: parsedPartnerConfig.serviceCharge,
            chargeValueType: parsedPartnerConfig.chargeValueType,
            lockTariff: true,
          };

          setPartnerConfiguration(partnerConfig);
        }
      } else {
        //get from api
        addToast('Could not load partner configuration. Close modal and reload page', 'error');
      }
    }
  }, [tabKey, fulfilmentRequest]);

  const mapSidebar = (
    <PharmacyMapSidebar
      showModal={showSidemodal}
      close={closeModal}
      startingAddress={fulfilmentRequest?.enrolleeAddress}
    />
  );

  let editPharmacyRatingModal = (
    <PharmacyRatingsModal
      open={showRatingModal}
      close={toggleRatingModal}
      data={rating}
      keepOpen={false}
    />
  );

  //isSaving is used to prevent closing modal if loading is taking place.
  //prevents bug resulting from computing cost and paying pharmacy.
  return (
    <div className='mt-5'>
      {mapSidebar}
      {editPharmacyRatingModal}
      {pharmacyData != null ? (
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 card'>
              {isLoading ? (
                <ButtonLoader />
              ) : (
                <div className='mt-3'>
                  {partnerConfiguration.changePharmacy ? (
                    <div>
                      <button
                        className='btn btn-success'
                        //   color='primary'
                        onClick={confirmPharmacy}
                      >
                        Confirm
                      </button>
                      <button
                        className='btn btn-info ml-3'
                        //   color='primary'
                        onClick={changePharmacy}
                      >
                        Change
                      </button>
                      <div className='ml-auto float-right'>
                        <span className=''>
                          <button className='btn btn-primary' onClick={() => toggleRatingModal()}>
                            Add rating
                          </button>
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}

              {isChangePharm ? (
                <div className='row mt-4'>
                  <div className='col-md-9'>
                    <Select
                      options={pharmacyListSelect}
                      onChange={selectChange}
                      className='react-select'
                      classNamePrefix='react-select'
                    />
                    <label>Select Pharmacy</label>
                  </div>
                  <div className='col-md-3'>
                    <button
                      type='button'
                      className='btn btn-primary btn-block'
                      onClick={saveBtnClick}
                    >
                      Save
                      {isSaving ? <ButtonLoader /> : ''}
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )}

              {pharmacyData.accountId === null || pharmacyData.accountId === '' ? (
                <div className='text-danger mb-3 mt-2'>Pharmacy does not have walllets</div>
              ) : (
                ''
              )}

              <div className='form-group mt-4'>
                <label>Pharmacy Name</label>
                <input
                  type='text'
                  readOnly
                  disabled
                  className='form-control'
                  value={pharmacyData.pharmacyName}
                />
              </div>
              <br />
              <div className='form-group'>
                <label>Phone Number</label>
                <input
                  type='text'
                  readOnly
                  disabled
                  className='form-control'
                  value={pharmacyData.phoneNumber}
                />
              </div>
              <br />
              <div className='form-group'>
                <label>State Of Premise</label>
                <input
                  type='text'
                  readOnly
                  disabled
                  className='form-control'
                  value={pharmacyData.addressInformation?.stateOfPremise}
                />
              </div>
              <br />
              <div className='form-group'>
                <label>LGA</label>
                <input
                  type='text'
                  readOnly
                  disabled
                  className='form-control'
                  value={pharmacyData.addressInformation?.lga}
                />
              </div>
              <br />
              <div className='form-group'>
                <label>Address</label>
                <textarea
                  className='form-control'
                  value={pharmacyData.addressInformation?.pharmacyAddress}
                  disabled
                  readOnly
                />
              </div>

              <br />
              <div className='form-group'>
                <label>Relationship Officer</label>
                <input
                  type='text'
                  readOnly
                  disabled
                  className='form-control'
                  value={pharmacyData.relationshipOfficer}
                />
              </div>
              <br />
            </div>
            <div className='col-sm-6'>
              {/* Add frequently used pharmacy for this fulfilment */}

              {usedPharmacies.length > 0 ? (
                <div>
                  <label>
                    Frequently used pharmacies for this Enrollee. Click to change to selected
                    pharmacy
                  </label>
                  {usedPharmacies.map((pharmacy, key) => (
                    <div
                      key={pharmacy.pharmacyId}
                      className='mt-3 pharmacy-card card p-3'
                      onClick={changePharmacyFromCard(pharmacy.pharmacyCode, pharmacy.pharmacyName)}
                    >
                      <strong style={{ fontSize: 'unset' }}>
                        {key + 1}. {pharmacy.pharmacyName}
                      </strong>
                      <div> {pharmacy.address}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
              <div className='mt-4'>
                <button
                  className='btn btn-primary'
                  onClick={() => setShowSideModal(!showSidemodal)}
                >
                  View Pharmacy Map
                </button>
              </div>
              {/* View pharmacy on Map */}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FulfilmentPharmacy;
