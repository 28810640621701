import React, { useState } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import { BOOL_MAPPING } from '../../../utils/mappings';
import { addFulfilmentPartnerData } from '../../../interface/FulfilmentPartnersInterface';
import ButtonLoader from '../../common/ButtonLoader';

const AddPartnerModal = ({ open, close }: { open: boolean; close: Function }) => {
  const [isLoading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(addFulfilmentPartnerData);

  const handleChange = (input: string) => (event: any) => {
    setModalData({
      ...modalData,
      [input]: event.target.value,
    });
  };

  const savePartner = async (event: any) => {
    let url = endpoints.FulfilmentPartners.mainUrl;
    let message = 'Partner Successfully Created';
    let errorMessage = 'Error Creating Partner';

    if (!isFormValidated('add-partner-modal')) {
      //addToast('Fill in all required fields', 'warning');
      return;
    }
    event.preventDefault();

    setLoading(true);
    try {
      const response = await axios.post(url, modalData);
      if (response.status === 200) {
        addToast(message, 'success');
        // await getPartners();
        close();
      }
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={() => close()}>
      <form id='add-partner-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add New Fulfilment Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Partner Name
              </label>
              <input
                value={modalData.partnerName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('partnerName')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Phone Number</label>
              <input
                value={modalData.phoneNumber}
                maxLength={13}
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                minLength={11}
                required
                className='form-control '
                type='text'
                onChange={handleChange('phoneNumber')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Support Email</label>
              <input
                value={modalData.supportEmail}
                className='form-control '
                type='email'
                placeholder='name@example.com'
                onChange={handleChange('supportEmail')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Contact Email</label>
              <input
                value={modalData.contactEmail}
                className='form-control '
                type='email'
                placeholder='name@example.com'
                onChange={handleChange('contactEmail')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label htmlFor='name' className='form-label'>
                Contact Name
              </label>
              <input
                value={modalData.contactName}
                required
                className='form-control '
                type='text'
                onChange={handleChange('contactName')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Location</label>
              <input
                value={modalData.location}
                required
                className='form-control '
                type='text'
                onChange={handleChange('location')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Active</label>
              <select
                required
                onChange={handleChange('isActive')}
                value={modalData.isActive.toString()}
                className='custom-select'
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Create login for the support email</label>
              <select
                required
                onChange={handleChange('createCredentials')}
                value={modalData.createCredentials.toString()}
                className='custom-select'
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Service Charge</label>
              <input
                value={modalData.serviceCharge}
                // pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                required
                className='form-control '
                type='text'
                onChange={handleChange('serviceCharge')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Charge Type</label>
              <select
                required
                onChange={handleChange('chargeValueType')}
                value={modalData.chargeValueType}
                className='custom-select'
              >
                <option key={0} value='-'>
                  -
                </option>
                <option key={1} value='Percentage'>
                  Percentage
                </option>
                <option key={2} value='Absolute'>
                  Absolute
                </option>
              </select>
            </div>
            <br />

            {/* <div className='col-md-12'>
              <label className='form-label'>Partner Email Template</label>
              <select
                required
                onChange={handleChange('partnerType')}
                // value={modalData.partnerType}
                className='custom-select'
              >
                {PARTNER_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br /> */}

            <div className='col-md-12'>
              <label className='form-label'>Send Api Keys?</label>
              <select
                required
                onChange={handleChange('sendApiKeys')}
                value={modalData.sendApiKeys.toString()}
                className='custom-select'
              >
                {BOOL_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='submit'
            className='btn btn-primary'
            onClick={savePartner}
            disabled={isLoading}
          >
            Submit
            {isLoading ? <ButtonLoader /> : ' '}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddPartnerModal;
