import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import {
  IFulfilments,
  IFulfilmentTableProps,
  initialFulfilmentsData,
  // initialSearchParams,
} from '../../../interface/FulfilmentsInterface';
import { AppContext } from '../../../context/AppContext';

import PharmacyModal from './PharmacyModal';
// modal imports
import MoreInfoModal from './MoreInfoModal';
import { Link } from 'react-router-dom';
import MessageModal from './MessageModal';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import AddNotesModal from './AddNotesModal';
import { IRealTimeUpdateProp } from '../../../interface/AppInterface';
import PickupCodeModal from './PickupCodeModal';

const FulfilmentsTableBody = ({ data, updateTableData }: IFulfilmentTableProps) => {
  const { appState } = useContext(AppContext);
  const [selectedFulfilment, setSelectedFulfilment] =
    useState<IFulfilments>(initialFulfilmentsData);

  // const [isTestModalOpen, setTestModalOpen] = useState(false);
  const [isPharmacyModalOpen, setPharmacyModalOpen] = useState(false);
  // const [isDrugModalOpen, setDrugModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [pickupModalOpen, setpickupModalOpen] = useState(false);
  const [realDataId, setRealDataId] = useState(0);
  //let selectedValues: number[] = [];

  const togglePharmacyModal = (fulfilment?: IFulfilments) => {
    setPharmacyModalOpen(!isPharmacyModalOpen);
    if (fulfilment) {
      setSelectedFulfilment(fulfilment);
    }
    if (isPharmacyModalOpen) {
      getUpdatedFulfilment(selectedFulfilment.requestId);
    }
  };

  const toggleMessageModal = (fulfilment?: IFulfilments) => {
    setIsMessageModalOpen(!isMessageModalOpen);
    if (fulfilment) {
      setSelectedFulfilment(fulfilment);
    }
  };

  // const toggleDrugModal = (fulfilment?: IFulfilments) => {
  //   setDrugModalOpen(!isDrugModalOpen);
  //   if (fulfilment) {
  //     setSelectedFulfilment(fulfilment);
  //   }
  //   if (isDrugModalOpen) {
  //     getUpdatedFulfilment(selectedFulfilment.requestId);
  //   }
  // };

  // const toggleTestModal = (fulfilment?: IFulfilments) => {
  //   setTestModalOpen(!isTestModalOpen);
  //   if (fulfilment) {
  //     setSelectedFulfilment(fulfilment);
  //   }
  // };

  const toggleInfoModal = (fulfilment?: IFulfilments) => {
    setInfoModalOpen(!infoModalOpen);
    if (fulfilment) {
      setSelectedFulfilment(fulfilment);
    }
    // //ifmodal closed. this is set to the condition since setState is not reflecting yet
    if (infoModalOpen) {
      getUpdatedFulfilment(selectedFulfilment.requestId);
    }
  };

  const toggleNoteModal = (fulfilment?: IFulfilments) => {
    setNoteModalOpen(!noteModalOpen);
    if (fulfilment) {
      setSelectedFulfilment(fulfilment);
    }
  };

  const togglePickupModal = (fulfilment?: IFulfilments) => {
    setpickupModalOpen(!pickupModalOpen);
    if (fulfilment) {
      setSelectedFulfilment(fulfilment);
    }
  };

  /*############## API CALLS ################## */

  const getUpdatedFulfilment = async (requestId: number) => {
    if (data.length === 0) {
      return;
    }
    //get the fulfilment using requestId
    //filter current data where requestId == updatedFulfilment request Id
    //if found, get index of found object from current data
    //update the current data by replacing the old data with new data
    let url = `${endpoints.FulfilmentRequests.mainUrl}/${requestId}`;

    await axios
      .get(url)
      .then((response) => {
        const updatedData = response.data;

        const oldData = data.filter((x) => x.requestId === requestId)[0];
        const parsedOldData = oldData || null;
        const filteredData: any = [...data];

        if (parsedOldData !== null) {
          filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
        } else {
          filteredData.unshift(updatedData);
        }

        if (updateTableData) {
          updateTableData(filteredData);
        }
        // setData(filteredData);

        // setAllData(filteredData);
      })
      .catch((error) => {
        console.error(error);
        addToast('An error occured while updating the request', 'error');
      });
  };

  /*########### End API Calls ######### */

  /*########### Table Functions ######### */

  let colorStyle = (service: string, status: string) => {
    switch (service) {
      case 'Acute':
        return 'status-success';
      case 'Chronic':
        return 'status-danger';
      case 'Telemedicine':
        return 'status-primary';
      default:
        return 'text-dark';
    }
  };

  /* ########### End Table Functions ################ */

  /* ######### Use effects ################# */

  // useEffect to filter fulfilment data
  //const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    //use the request id to update
    const realTimeData = appState.fulfilmentRequests as IRealTimeUpdateProp;

    //all efforts to move this out of the useEffect was not possible without
    //resorting to suppressing es-lint warnings

    if (realTimeData != null) {
      const dataId = parseInt(realTimeData.dataId);
      const realTimeDataId = realTimeData.id;

      if (realDataId === realTimeDataId) {
        return;
      }
      setRealDataId(realTimeDataId);
      getUpdatedFulfilment(dataId);
    }
  }, [appState.fulfilmentRequests]); // eslint-disable-line react-hooks/exhaustive-deps

  //useEffect for table search

  /* ######### End Use effects ################# */

  /*##########  Table fragments ########### */

  const addIcon = (row: IFulfilments) => {
    return (
      <>
        <div className='col-3'>
          <i
            onClick={() => togglePharmacyModal(row)}
            className='bi bi-shield-fill-plus export-icon'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Pharmacy'
          ></i>
        </div>

        <div className='col-3'>
          <i
            onClick={() => toggleMessageModal(row)}
            className='bi bi-chat-right-text-fill export-icon'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Message'
          ></i>
        </div>
        <div className='col-3'>
          <i
            onClick={() => togglePickupModal(row)}
            className='bi bi-qr-code export-icon'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Pickup code'
          ></i>
        </div>
        <div className='col-3'>
          <i
            onClick={() => toggleNoteModal(row)}
            className='bi bi-clipboard export-icon'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Tracking Note'
          ></i>
        </div>
        <div className='col-12 mt-1'>
          <Link to={`/fulfilments/${row.requestId}/details`} className='btn btn-primary btn-sm'>
            View
          </Link>
        </div>
      </>
    );
  };

  let pharmacyModal = (
    <PharmacyModal
      open={isPharmacyModalOpen}
      close={togglePharmacyModal}
      modalData={selectedFulfilment}
    />
  );

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th>{<div>Action</div>}</th>
        <th>Date Created</th>
        <th>Enrollee ID</th>
        <th>Enrollee Name</th>
        <th>Phone</th>
        <th>Address</th>
        <th>Service</th>
        <th>Status</th>
        <th>Drugs</th>
        <th>Tests</th>
        <th>Fulfilment Cost</th>
        <th>Amount by Partner</th>
        <th>Notes</th>
        <th>Partner</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {data.map((fulfilment: IFulfilments) => {
        return (
          <tr
            key={fulfilment.requestId}
            className={colorStyle(fulfilment.fulfilmentService, fulfilment.fulfilmentStatus)}
          >
            <td>
              <div className='row'>{addIcon(fulfilment)}</div>
            </td>

            <td>{formatDateTime(fulfilment.dateCreated)}</td>
            <td>{fulfilment.enrollmentCode}</td>
            <td>
              {fulfilment.enrolleeFirstName} {fulfilment.enrolleeLastName}
            </td>
            <td>{fulfilment.enrolleePhone}</td>
            <td>{fulfilment.enrolleeAddress}</td>
            <td>{fulfilment.fulfilmentService}</td>
            <td>{fulfilment.fulfilmentStatus}</td>
            <th>
              {fulfilment.drugsDispensed.filter((x) => x === true).length} /
              {fulfilment.drugsDispensed.length}
            </th>
            <th>
              {fulfilment.testsDispensed.filter((x) => x === true).length} /{' '}
              {fulfilment.testsDispensed.length}
            </th>

            <td>{fulfilment.drugCost + fulfilment.testCost}</td>
            <td>{fulfilment.drugTariffCost + fulfilment.testTariffCost}</td>
            <td>{fulfilment.notes}</td>
            <td>{fulfilment.requestSource}</td>
          </tr>
        );
      })}
    </tbody>
  );

  let infoModal = (
    <MoreInfoModal open={infoModalOpen} close={toggleInfoModal} modalData={selectedFulfilment} />
  );

  let messageModal = (
    <MessageModal
      open={isMessageModalOpen}
      close={toggleMessageModal}
      modalData={selectedFulfilment}
    />
  );

  let noteModal = (
    <AddNotesModal open={noteModalOpen} close={toggleNoteModal} modalData={selectedFulfilment} />
  );

  let pickupModal = (
    <PickupCodeModal
      open={pickupModalOpen}
      close={togglePickupModal}
      modalData={selectedFulfilment}
    />
  );
  /*########## End Table fragments ########### */

  return (
    <div className='table-responsive'>
      {infoModal}
      {noteModal}
      {pharmacyModal}
      {messageModal}
      {pickupModal}
      <table className='table table-bordered table-hover'>
        {tableHead}
        {tableBody}
      </table>
    </div>
  );
};

export default FulfilmentsTableBody;
