import { useState, useEffect, Fragment } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import { formatDate } from '../../../utils/dateTimeUtils';

import { INotificationTableData } from '../../../interface/NotificationInterface';

const NotificationTable = () => {
  const [notificationData, setNotificationData] = useState<INotificationTableData[]>([]);

  const getNotifications = async () => {
    try {
      const response = await axios.get(endpoints.InsuranceNotifications.getAllUnread);
      const list = response.data;
      setNotificationData(list);
    } catch (error: any) {
      console.error('Error getting notifications', error);
    }
  };

  const markAsRead = async (notificationTableData: INotificationTableData) => {
    try {
      const response = await axios.put(
        endpoints.InsuranceNotifications.markRead + notificationTableData.id
      );
      const status = response.status;
      if (status === 200) {
        //update localstate
        const updatedData = notificationTableData;
        updatedData.isRead = true;

        const alldata = [...notificationData];
        alldata[alldata.indexOf(notificationTableData)] = updatedData;

        setNotificationData(alldata);
      }
    } catch (error: any) {
      addToast('Error updating notifications', 'error');
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Fragment>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col'>Actions</th>
              <th scope='col'>Created</th>
              <th scope='col'>Notification</th>
              <th scope='col'>Priority</th>
              <th scope='col'>Is Notification Read?</th>
            </tr>
          </thead>
          <tbody>
            {notificationData.map((item: INotificationTableData) => {
              return (
                <tr
                  key={item.id}
                  style={{
                    color: item.isRead ? '#008000' : '#000',
                    fontWeight: item.isRead ? 300 : 700,
                  }}
                >
                  <td>
                    <i
                      onClick={() => markAsRead(item)}
                      className='bi bi-check check-icon'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Mark as Read'
                    ></i>
                  </td>
                  <td>{formatDate(item.dateCreated)}</td>
                  <td>{item.message}</td>
                  <td>{item.notificationType}</td>
                  {/* <td>{priorirityNotificationType(item)}</td> */}
                  <td>{item.isRead === true ? 'Yes' : 'No'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default NotificationTable;
