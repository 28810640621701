import axios from 'axios';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { IClaimEligibility, IClaims, initialClaim } from '../../../interface/ClaimsInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import PharmacyDetailsModal from '../claims/PharmacyDetailsModal';
import EditClaimModal from '../claims/EditClaimModal';
// import EditDrugsModal from './EditDrugsModal';
import './styles.css';
import { formatDate } from '../../../utils/dateTimeUtils';
import { ISubscriptionDetailsProp } from '../../../interface/SubscriptionInterface';
import { SubscriptionDetailsTab } from '../../../utils/mappings';
import TableLoader from '../../common/TableLoading';
//import EditPharmacyRatingModal from './EditPharamcyRatingsModal';

const SubscriptionClaims = ({ subscription, tabKey }: ISubscriptionDetailsProp) => {
  const [data, setData] = useState<IClaims[] | undefined>();
  const [pharmacyModalOpen, setPharmacyModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [pharmacyCode, setPharmacyCode] = useState('');
  const [editModalData, setEditModalData] = useState(initialClaim);
  const [claimEligibity, setClaimEligibility] = useState<IClaimEligibility | null>();

  const loadPharmacyDetails = (pharmacyCode: string) => {
    setPharmacyCode(pharmacyCode);
    setPharmacyModalOpen(true);
  };

  const handleChange = (input: string) => (event: any) => {
    setEditModalData({
      ...editModalData,
      [input]: event.target.value,
    });
  };

  const editClaimsData = (item: IClaims) => {
    setEditModalData(item);
    setEditModalOpen(true);
  };

  const closePharmacyModal = () => {
    setPharmacyModalOpen(false);
  };

  const loadClaims = useCallback(async () => {
    setData(undefined);
    try {
      let url = `${endpoints.InsuranceClaims.mainUrl}/subscriptioncode/${subscription?.subscriptionCode}`;

      const response = await axios.get(url);
      if (response.data.length === 0) {
        addToast('No claims found for the selected subscription code', 'info');
      }
      setData(response.data);
    } catch (error: any) {
      addToast('Error loading claims', 'error');
    }
  }, [subscription?.subscriptionCode]);

  const getClaimEligibility = useCallback(async () => {
    setClaimEligibility(null);
    const url = `${endpoints.InsuranceClaims.mainUrl}/claimeligibility/${subscription?.subscriptionCode}`;

    try {
      const response = await axios.get(url);
      setClaimEligibility(response.data);
    } catch (error: any) {
      console.error(error);
    }
  }, [subscription?.subscriptionCode]);

  useEffect(() => {
    if (subscription && tabKey === SubscriptionDetailsTab.claims) {
      getClaimEligibility();
    }
  }, [getClaimEligibility, tabKey, subscription]);

  useEffect(() => {
    if (subscription && tabKey === SubscriptionDetailsTab.claims) {
      loadClaims();
    }
  }, [tabKey, loadClaims, subscription]);

  const closeModal = () => {
    setEditModalOpen(false);
  };

  let pharmacyDetails = (
    <PharmacyDetailsModal
      open={pharmacyModalOpen}
      close={closePharmacyModal}
      pharmacyCode={pharmacyCode}
    />
  );

  let editModal = (
    <EditClaimModal
      open={editModalOpen}
      data={editModalData}
      close={closeModal}
      handleChange={handleChange}
      loadClaims={loadClaims}
    />
  );

  return (
    <Fragment>
      <div className='container-fluid mt-5'>
        {editModal}
        {pharmacyDetails}

        <br />
        <div>
          Claim Eligibility:
          {claimEligibity?.eligible ? (
            <span className='text-success'>Eligble</span>
          ) : (
            <span className='text-danger'>Ineligible</span>
          )}
        </div>
        <div>
          Reason:
          <span className={claimEligibity?.eligible ? 'text-success' : 'text-danger'}>
            {claimEligibity?.reason}
          </span>
        </div>
        <br />
        <div className='table-responsive'>
          {data ? (
            <table className='table table-bordered table-hover'>
              <thead>
                <tr>
                  <th scope='col-2'>Actions</th>
                  <th scope='col'>Date</th>
                  <th scope='col'>Beneficiary</th>
                  <th scope='col'>Pharmacy Name</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Claim Status</th>
                  <th scope='col'>NPS</th>
                  <th scope='col'>Notes</th>
                  <th scope='col'>Mode</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: IClaims) => {
                  return (
                    <tr key={item.insuranceClaimId}>
                      <td>
                        <i
                          onClick={() => editClaimsData(item)}
                          className='bi bi-pencil-fill update-icon mr-2'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Edit Claims Data'
                        ></i>
                      </td>
                      <td>{formatDate(item.claimDate)}</td>
                      <td>{item.beneficiaryName}</td>
                      <td
                        style={{ cursor: 'pointer', color: '#1489C8' }}
                        onClick={() => loadPharmacyDetails(item.pharmacyCode)}
                      >
                        {item.pharmacyName}
                      </td>
                      <td>{item.claimAmount}</td>
                      <td>{item.claimStatus}</td>
                      <td>{item.nps}</td>
                      <td>{item.notes}</td>
                      <td>{item.creationMode}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <TableLoader />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SubscriptionClaims;
