import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { IZoiUserResponse } from '../../../interface/ZoiUserInterface';
import { addToast } from '../../../utils/toastNotifications';
import TableLoading from '../../common/TableLoading';
import { useHistory } from 'react-router-dom';

const ZoiUsersTable = () => {
  const [allData, setAllData] = useState<IZoiUserResponse[]>([]); //to hold all table result in unfiltered format
  const [tableData, setTableData] = useState<IZoiUserResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const loadZoiOrders = useCallback(async (pageIndex: number) => {
    let url = `${endpoints.ZoiUsers.mainUrl}?pageSize=200&pageIndex=${pageIndex}`;

    try {
      setIsLoading(true);
      const response = await axios.get(url);

      setTableData(response.data);
      setAllData(response.data);

      if (response.data.length === 0) {
        addToast('No orders found', 'info');
      }
    } catch (error: any) {
      addToast('Error getting order', 'error');
      // setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setTableData(allData);
      return;
    }

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.phoneNumber?.toLowerCase().includes(searchParam) ||
        x.firstName?.toLowerCase().includes(searchParam) ||
        x.lastName?.toLowerCase().includes(searchParam)
    );

    // //set table data to search result
    setTableData(searchResult);
  };

  const viewOrders = async (userId: number) => {
    //push to orders
    const url = `/zoi/orders/${userId}`;
    history.push(url);
  };

  useEffect(() => {
    const runOnComponentMount = async () => {
      await loadZoiOrders(1);
    };
    runOnComponentMount();
  }, [loadZoiOrders]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col-4'>Actions</th>
        <th scope='col'>First Name</th>
        <th scope='col'>Last Name</th>
        <th scope='col'>Phone number</th>
        <th scope='col'>Email</th>
        <th scope='col'>Location </th>
        <th scope='col'>Available Claims</th>
        <th scope='col'>Available Telemedicine</th>
      </tr>
    </thead>
  );

  let noDataView = (
    <tbody className='text-center'>
      <tr>
        <td colSpan={11}>No Data found</td>
      </tr>
    </tbody>
  );

  let tableBody: JSX.Element = (
    <>
      {tableData ? (
        <tbody>
          {tableData.map((order: IZoiUserResponse) => {
            return (
              <tr key={order.userId}>
                <td>
                  <i
                    className='bi bi-list-stars group-icon'
                    onClick={() => viewOrders(order.userId)}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='View orders'
                  />
                </td>
                <td>{order.firstName}</td>
                <td>{order.lastName}</td>
                <td>{order.phoneNumber}</td>
                <td>{order.email}</td>
                <td>{order.locationAddress}</td>
                <td>{order.availableClaims}</td>
                <td>{order.availableTelemedicines}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        noDataView
      )}
    </>
  );

  if (isLoading) {
    return <TableLoading />;
  }

  return (
    <div>
      <br />
      <h3 className='mr-auto'>Zoi Users ({tableData.length})</h3>
      <div className='row'>
        <input
          type='search'
          className='form-control col-4'
          placeholder='Search by phone'
          onChange={searchTable}
        />
      </div>
      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default ZoiUsersTable;
