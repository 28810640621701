import { useState } from 'react';
import axios from 'axios';
import { initialBeneficiary } from '../../../interface/HealthSendInterface';
import { useParams } from 'react-router';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';

const AddHealthSendBeneficiary = () => {
  const { userId } = useParams() as any;

  const [beneficiary, setBeneficiary] = useState(initialBeneficiary);
  const [loading, setLoading] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setBeneficiary({
      ...beneficiary,
      [input]: event.target.value,
    });
  };

  const submit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    beneficiary.userId = userId;

    try {
      await axios.post(endpoints.HealthSend.beneficiaries, beneficiary);
      addToast('Beneficiary added successfully', 'success');
      setBeneficiary(initialBeneficiary);
    } catch (error) {
      addToast('Error creating beneficiary', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className='d-flex flex-column align-items-center shadow-lg p-5'>
        <div className='h4 my-5'>Add Beneficiary</div>
        <form onSubmit={submit}>
          <fieldset disabled={loading}>
            <div className='form-row'>
              <div className='form-group col-sm-12 col-md-4'>
                <label htmlFor=''>First Name</label>
                <input
                  required
                  type='text'
                  className='form-control'
                  value={beneficiary.firstName}
                  onChange={handleChange('firstName')}
                />
              </div>
              <div className='form-group col-sm-12 col-md-4'>
                <label htmlFor=''>Last Name</label>
                <input
                  required
                  type='text'
                  className='form-control'
                  value={beneficiary.lastName}
                  onChange={handleChange('lastName')}
                />
              </div>
              <div className='form-group col-sm-12 col-md-4'>
                <label htmlFor=''>Email</label>
                <input
                  required
                  type='email'
                  className='form-control'
                  value={beneficiary.email}
                  onChange={handleChange('email')}
                />
              </div>
            </div>
            {/* second row */}
            <div className='form-row'>
              <div className='form-group col-sm-12 col-md-4'>
                <label htmlFor=''>Phone Number</label>
                <input
                  required
                  type='text'
                  className='form-control'
                  value={beneficiary.phoneNumber}
                  onChange={handleChange('phoneNumber')}
                />
              </div>
              <div className='form-group col-sm-12 col-md-4'>
                <label htmlFor=''>Country Code</label>
                <input
                  required
                  type='text'
                  className='form-control'
                  value={beneficiary.countryCode}
                  onChange={handleChange('countryCode')}
                />
              </div>
              <div className='form-group col-sm-12 col-md-4'>
                <label htmlFor=''>Gender</label>
                <select
                  required
                  className='form-control'
                  value={beneficiary.gender}
                  onChange={handleChange('gender')}
                >
                  <option value='Female'>Female</option>
                  <option value='Male'>Male</option>
                </select>
              </div>
            </div>
            {/* third row*/}
            <div className='form-row'>
              <div className='form-group col-sm-12 col-md-6'>
                <label htmlFor=''>Address</label>
                <input
                  required
                  type='text'
                  className='form-control'
                  value={beneficiary.address}
                  onChange={handleChange('address')}
                />
              </div>
              <div className='form-group col-sm-12 col-md-6'>
                <label htmlFor=''>D.O.B</label>
                <input
                  required
                  type='date'
                  className='form-control'
                  value={beneficiary.dateOfBirth}
                  onChange={handleChange('dateOfBirth')}
                />
              </div>
            </div>
            {/* fourth row */}
            <button type='submit' className='btn btn-primary btn-block'>
              Submit
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export { AddHealthSendBeneficiary };
