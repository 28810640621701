import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { Link } from 'react-router-dom';

const FulfilmentView = ({ setActiveNav }: { setActiveNav: any }) => {
  const [pendingFulfilmentCount, setPendingFulfilmentCount] = useState(0);

  const getPendingFulfilmentCount = useCallback(async () => {
    try {
      await axios.get(endpoints.FulfilmentRequests.getPendingCount).then((response) => {
        const pendingCount = response.data;
        setPendingFulfilmentCount(pendingCount);
      });
    } catch (error: any) {
      addToast('There was an error getting pending fulfilment count', 'error');
    }
  }, []);

  useEffect(() => {
    getPendingFulfilmentCount();
  }, [getPendingFulfilmentCount]);

  return (
    <>
      <li className='nav-item'>
        <Link to='/fulfilments' className='nav-link position-relative' onClick={setActiveNav}>
          Fulfilments
          <h3 className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-danger'>
            {pendingFulfilmentCount}
          </h3>
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          to='/fulfilments/drafts'
          className='nav-link position-relative'
          onClick={setActiveNav}
        >
          Fulfilments Drafts
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/fulfilments/partners' className='nav-link' onClick={setActiveNav}>
          Fulfilment Partners
        </Link>
      </li>

      <li className='nav-item'>
        <Link to='/pharmacies' className='nav-link' onClick={setActiveNav}>
          Pharmacy
        </Link>
      </li>

      <li className='nav-item'>
        <Link to='/fulfilments/export' className='nav-link' onClick={setActiveNav}>
          Export Data
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/tariff' className='nav-link' onClick={setActiveNav}>
          Tariff
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/pharmacypricing/check' className='nav-link' onClick={setActiveNav}>
          Price Checker
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/pharmacypricing' className='nav-link' onClick={setActiveNav}>
          Pharmacy Pricing
        </Link>
      </li>
    </>
  );
};

export default FulfilmentView;
