import React, { useState } from 'react';

import { IPartnerBulkPayment } from '../../../interface/PartnerInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import { PAYMENT_PLAN_MAPPING, PRODUCT_TYPE } from '../../../utils/mappings';
import ButtonLoader from '../../common/ButtonLoader';
import { formatInputDate } from '../../../utils/dateTimeUtils';

const PartnerBulkPaymentModal = ({ open, close, modalData }: any) => {
  const initialState = {
    paymentPlan: 'Monthly',
    productType: 'Malaria',
    startDate: new Date().toString(),
  };

  const [isLoading, setLoading] = useState(false);
  const [localState, setLocalState] = useState(initialState);

  const handleChange = (state: string) => (event: any) => {
    setLocalState({
      ...localState,
      [state]: event.target.value,
    });
  };

  const uploadPayment = async (event: any) => {
    if (!isFormValidated('bulk-payment-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }

    event.preventDefault();

    let url = endpoints.InsurancePartners.postBulkPayment;
    let message = 'Payment Sent Successfully';
    let errorMessage = 'Error Sending Payment';

    const payload: IPartnerBulkPayment = {
      partnerCode: modalData.partnerCode,
      productType: localState.productType,
      paymentPlan: localState.paymentPlan,
      startDate: localState.startDate,
      name: modalData.name,
    };

    setLoading(true);
    try {
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        addToast(message, 'success');
        close();
      }
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        addToast('No subscription found for this partner', 'error');
      } else {
        addToast(errorMessage, 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={close}>
      <form id='bulk-payment-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Payment for {modalData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='col-md-12'>
              <label className='form-label'>Payment plan</label>
              <select
                onChange={handleChange('paymentPlan')}
                value={localState.paymentPlan}
                className='custom-select'
              >
                {PAYMENT_PLAN_MAPPING.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Product Type</label>
              <select
                onChange={handleChange('productType')}
                value={localState.productType}
                className='custom-select'
              >
                {PRODUCT_TYPE.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Start Date</label>
              <input
                value={formatInputDate(localState.startDate)}
                required
                className='form-control '
                type='date'
                onChange={handleChange('startDate')}
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={uploadPayment} disabled={isLoading}>
            SUBMIT
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PartnerBulkPaymentModal;
