import { useState, useEffect, useCallback } from 'react';
import {
  IPharmaciesData,
  initialPharmacyData,
  IPharmacyClientGroup,
  IPharmacyPostData,
} from '../../../interface/PharmacyInterface';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { PHARMACY_STATUS, PharmacyType } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import axios from 'axios';
import { STATES_LGA } from '../../../utils/states_lga';
import ButtonLoader from '../../common/ButtonLoader';
import WalletModal from '../wallets/AddEditWalletAccountModal';
import {
  IWalletAccountDTO,
  initialWalletAccountDTOData,
  IPharmacyWallet,
} from '../../../interface/WalletsInterface';
import { PharmacyOperationOfficers } from '../../../utils/constants';
import { usePlacesWidget } from 'react-google-autocomplete';
import ComplianceFormModal from './ComplianceFormModal';
import Select from 'react-select';
import useSWR from 'swr';
import fetcher from '../../../utils/axiosFetcher';
import { ReactSelectOption } from '../../../interface/CommonInterface';
// import SearchLocationInput from '../../../utils/pharmacyLocation';

const AddPharmacyForm = () => {
  const [loading, setLoading] = useState(false);
  const [localState, setLocalState] = useState<IPharmaciesData>(initialPharmacyData);
  const [pharmacies, setPharmacies] = useState<IPharmaciesData[]>([]);
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [walletModalData] = useState<IWalletAccountDTO>(initialWalletAccountDTOData);

  const [isFound, setFound] = useState(false);

  const [lgas, setLgas] = useState([{ key: 0, text: '', value: '' }]);
  const [complianceModalOpen, setComplianceModalOpen] = useState(false);
  const [clients, setClients] = useState<ReactSelectOption[]>([]);

  const { data } = useSWR<IPharmacyClientGroup[], any>(
    endpoints.InsurancePharmacy.getPharmacyClientList,
    fetcher,
    {
      fallbackData: [],
      errorRetryCount: 1,
      revalidateOnFocus: false,
      onSuccess: () => {
        const dataMap = data!.map((val: IPharmacyClientGroup) => {
          return {
            label: val.clientName,
            value: val.clientId,
          };
        });
        setClients(dataMap);
      },
    }
  );

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      const selectedAddress = place.address_components;
      if (selectedAddress === null || typeof selectedAddress === 'undefined') {
        return;
      }
      setFound(true);

      ///const a = place.adr_address;

      var placeLng = place!.geometry!.location!.lng();
      var placeLat = place!.geometry!.location!.lat();

      let pharmacyAddress = `${place!.name} ${place!.formatted_address}`;
      if (place!.formatted_address!.toLowerCase().startsWith(place!.name!.toLowerCase())) {
        pharmacyAddress = `${place.formatted_address}`;
      }

      //reset to defaults
      let addressInformation = {
        plus_code: '',
        street_number: '', //street number
        route: '', // street name
        locality: '', //Name of big town/City e.g Lagos
        administrative_area_level_1: '', //State
        administrative_area_level_2: '', //LGA
        administrative_area_level_3: '', //Area
        sublocality_level_1: '', //Area
        neighborhood: '', //Area
        country: '', //Country
        postal_code: '', //Postal code
        colloquial_area: '', //Area
      };

      for (const component of place?.address_components!) {
        const addressType = component.types[0] as string;

        //if proprerty exist
        if (addressType in addressInformation) {
          const addressValue = component['long_name'] as string;

          //https://stackoverflow.com/a/62438434/2929906
          let addressKey = addressType as keyof typeof addressInformation;
          addressInformation[addressKey] = addressValue;
        }
      }

      const locationArea =
        addressInformation?.neighborhood.trim() ||
        addressInformation?.sublocality_level_1.trim() ||
        addressInformation?.colloquial_area.trim() ||
        addressInformation?.administrative_area_level_3.trim();

      const lga =
        addressInformation?.administrative_area_level_2.trim() ||
        addressInformation?.locality.trim();

      // const city = addressInformation?.locality.trim();
      const zipCode = addressInformation.postal_code;
      const plusCode = addressInformation.plus_code;

      let stateOfPremise = addressInformation!.administrative_area_level_1;

      setLocalState({
        ...localState,
        //pharmacyAddress: pharmacyAddress!,
        googleAddress: pharmacyAddress!,
        stateOfPremise: stateOfPremise,
        lga: lga,
        locationArea: locationArea,
        locationLatitude: placeLat!.toString(),
        locationLongitude: placeLng!.toString(),
        postalCode: zipCode,
        plusCode: plusCode,
        streetName: addressInformation.route,
        streetNumber: addressInformation.street_number,
        placeId: place?.place_id,
      });
    },
    options: {
      //types: ['address'],
      types: [],
      fields: ['name', 'address_component', 'formatted_address', 'geometry.location', 'place_id'],
      componentRestrictions: { country: 'ng' },
    },
  });

  const closeComplianceModal = () => {
    setComplianceModalOpen(false);
  };

  const toggleWalletModal = () => {
    setWalletModalOpen(!walletModalOpen);
  };

  const handleChange = (input: string) => (event: any) => {
    if (input === 'stateOfPremise') {
      loadStateLga(event.target.value);
      return;
    }

    setLocalState({
      ...localState,
      [input]: event.target.value,
    });
  };

  const clearForm = () => {
    setLocalState(initialPharmacyData);
  };

  const savePharmacy = async (event: any) => {
    if (!isFormValidated('add-pharmacy-form')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }
    event.preventDefault();

    if (localState.walletAccount === null) {
      addToast('Please add wallet account details');
      return;
    }

    if (localState.googleAddress === '') {
      addToast('Please search and enter a google address', 'warning');
      return;
    }

    const phoneNumber = localState.phoneNumber.startsWith('0')
      ? localState.phoneNumber.substring(0, 1)
      : localState.phoneNumber;

    const pharmacyName = localState.pharmacyName
      .toLowerCase()
      .replace('pharmacy', '')
      .replace('laboratory', '');

    const likelyPharmacies = pharmacies.filter(
      (pharm) =>
        pharm.pharmacyName.toLowerCase().includes(pharmacyName) ||
        pharm.phoneNumber?.includes(phoneNumber)
    );

    if (likelyPharmacies.length > 0) {
      const confirmSave = window.confirm(
        'One or more pharmacies have same name / phone as the pharmacy you are trying to register. Do you want to continue?'
      );

      if (!confirmSave) {
        return;
      }
    }

    if (localState.pharmacyAddress === '' || localState.pharmacyAddress === null) {
      let addressElement = document.getElementById('address') as HTMLInputElement;
      let pharmacyAddress = addressElement.value;
      localState.pharmacyAddress = pharmacyAddress;
    }

    //map to new model
    const newPharmacy: IPharmacyPostData = {
      walletAccount: localState.walletAccount,
      pharmacyName: localState.pharmacyName,
      clientId: localState.clientId,
      payInstantly: localState.payInstantly,
      phoneNumber: localState.phoneNumber,
      providerType: localState.providerType,
      email: localState.email,
      contacts: localState.contacts,
      clientContact: localState.clientContact,
      relationshipOfficer: localState.relationshipOfficer,
      status: localState.status,
      showNote: false,
      addressInformation: {
        stateOfPremise: localState.stateOfPremise,
        pharmacyAddress: localState.pharmacyAddress,
        lga: localState.lga,
        locationArea: localState.locationArea,
        latitude: localState.locationLatitude,
        longitude: localState.locationLongitude,
        googleAddress: localState.googleAddress,
        pharmacyCode: '',
        streetName: localState.streetName,
        streetNumber: localState.streetNumber,
        placeId: localState.placeId,
        plusCode: localState.plusCode,
        postalCode: localState.postalCode,
      },
    };

    console.log(newPharmacy);

    event.preventDefault();

    let url = endpoints.InsurancePharmacy.mainUrl;
    let message = 'Pharmacy Successfully Created';
    let errorMessage = 'Error Creating Pharmacy';

    setLoading(true);
    try {
      const response = await axios.post(url, newPharmacy);
      const data = response.data;

      if (data.isSuccess) {
        addToast(message, 'success');
        clearForm();
      } else {
        const dataBody = JSON.parse(data.data);
        addToast(dataBody.detail, 'error');
      }
      //on success, update sessionStorage of pharmacies list if available
      // addToast('Pharmacy Created Successfully', 'success');
      // close();
    } catch (error: any) {
      addToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const addWallet = (walletDto: IWalletAccountDTO, bankAccountName: string) => {
    const wallet: IPharmacyWallet = {
      bankAccountName: bankAccountName,
      bankAccountNumber: walletDto.bankAccountNumber,
      bankName: walletDto.bankName,
      bankCode: walletDto.bankCode,
      accountId: walletDto.accountId,
      discount: walletDto.discount,
    };
    setLocalState({
      ...localState,
      walletAccount: wallet,
    });

    addToast('Wallet account feature added');
  };

  const getPharmacies = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.InsurancePharmacy.mainUrl);
      const data = response.data.data;

      setPharmacies(data);

      sessionStorage.setItem('pharmacies', JSON.stringify(data));
    } catch (error: any) {
      addToast('Error Getting Pharmacies', 'error');
    }
  }, []);

  const selectChange = (selectedValue: any) => {
    setLocalState({
      ...localState,
      clientId: selectedValue.value,
    });
  };

  const statesType = STATES_LGA;
  const statesMap = statesType.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const loadStateLga = (stateOfPremise: string) => {
    const stateFilter = STATES_LGA.filter((selectedState) => {
      return selectedState.state.toLowerCase() === stateOfPremise.toLowerCase();
    });

    let lgas = stateFilter[0].locals.map((lga) => {
      return {
        key: lga.id,
        text: lga.name,
        value: lga.name,
      };
    });
    setLocalState({
      ...localState,
      lga: lgas[0].value,
      stateOfPremise: stateOfPremise,
    });
    setLgas(lgas);
  };

  const loadInitialStateLga = useCallback(() => {
    const stateFilter = STATES_LGA.filter((selectedState) => {
      return selectedState.state.toLowerCase() === 'abia';
    });

    let lgas = stateFilter[0].locals.map((lga) => {
      return {
        key: lga.id,
        text: lga.name,
        value: lga.name,
      };
    });
    setLgas(lgas);
  }, []);

  useEffect(() => {
    //load existing pharmacies
    getPharmacies();
    loadInitialStateLga();
  }, [getPharmacies, loadInitialStateLga]);

  let addWalletModal = (
    <WalletModal
      open={walletModalOpen}
      close={toggleWalletModal}
      modalData={walletModalData}
      edit={false}
      newPharmacy={true}
      addWallet={addWallet}
    />
  );

  let complianceModal = (
    <ComplianceFormModal open={complianceModalOpen} close={closeComplianceModal} />
  );

  return (
    <>
      {addWalletModal}
      {complianceModal}
      <div className='container'>
        <h4 style={{ textAlign: 'center', color: '#000000' }}>Add New Pharmacy</h4>
        <br />
        <form id='add-pharmacy-form'>
          <fieldset disabled={loading}>
            <div className='row'>
              <div className='col-md-3 form-group'>
                <label className='form-label'>Pharmacy Client</label>
                <Select
                  options={clients}
                  onChange={selectChange}
                  className='react-select'
                  classNamePrefix='react-select'
                />
              </div>
              <div className='col-md-3 form-group'>
                <label>Provider Type</label>
                <select
                  className='custom-select'
                  value={localState.providerType}
                  onChange={handleChange('providerType')}
                >
                  {PharmacyType.map((pharmacyType) => (
                    <option key={pharmacyType.key} value={pharmacyType.value}>
                      {pharmacyType.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group col-md-3'>
                <label>Pharmacy Name</label>
                <input
                  required
                  type='text'
                  value={localState.pharmacyName}
                  onChange={handleChange('pharmacyName')}
                  className='form-control'
                />
              </div>
              <div className='form-group col-md-3'>
                <label>Phone</label>
                <input
                  required
                  type='text'
                  value={localState.phoneNumber}
                  onChange={handleChange('phoneNumber')}
                  className='form-control'
                  maxLength={13}
                  pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                  minLength={11}
                />
              </div>
              <div className='form-group col-md-3'>
                <label>Contact</label>
                <input
                  type='text'
                  value={localState.contacts}
                  onChange={handleChange('contacts')}
                  className='form-control'
                />
              </div>

              <div className='form-group col-md-3'>
                <label>Email</label>
                <input
                  type='email'
                  value={localState.email}
                  onChange={handleChange('email')}
                  className='form-control'
                />
              </div>

              <div className='form-group col-md-6'>
                <label>Pharmacy Address (google maps)</label>
                <input
                  required
                  type='text'
                  id='address'
                  // value={localState.pharmacyAddress}
                  // onChange={handleChange('pharmacyAddress')}
                  className='form-control'
                  ref={ref as unknown as React.LegacyRef<HTMLInputElement>}
                />
              </div>
              <div className='form-group col-md-3'>
                <label>State Of Premise</label>
                {isFound ? (
                  <input
                    disabled
                    className='form-control'
                    value={localState.stateOfPremise}
                    onChange={handleChange('stateOfPremise')}
                  />
                ) : (
                  <select
                    className='custom-select'
                    value={localState.stateOfPremise}
                    onChange={handleChange('stateOfPremise')}
                  >
                    {statesMap.map((states) => (
                      <option key={states.key} value={states.value}>
                        {states.text}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div className='form-group col-md-3'>
                <label>LGA</label>
                {isFound ? (
                  <input
                    disabled
                    className='form-control'
                    value={localState.lga}
                    onChange={handleChange('lga')}
                  />
                ) : (
                  <select
                    className='custom-select'
                    value={localState.lga}
                    onChange={handleChange('lga')}
                  >
                    {lgas.map((lga) => (
                      <option key={lga.key} value={lga.value}>
                        {lga.text}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className='form-group col-md-6'>
                <label>Descriptive Address</label>
                <input
                  type='text'
                  value={localState.pharmacyAddress}
                  onChange={handleChange('pharmacyAddress')}
                  className='form-control'
                  required
                />
              </div>

              <div className='form-group col-md-3'>
                <label>Activity Status</label>
                <select
                  required
                  className='custom-select'
                  value={localState.status}
                  onChange={handleChange('status')}
                >
                  {PHARMACY_STATUS.map((status) => (
                    <option key={status.key} value={status.value}>
                      {status.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group col-md-3'>
                <label>Relationship officer</label>
                <select
                  className='custom-select'
                  onChange={handleChange('relationshipOfficer')}
                  value={localState.relationshipOfficer}
                  required
                >
                  {PharmacyOperationOfficers.map((x) => (
                    <option value={x}>{x}</option>
                  ))}
                </select>
              </div>
              <div className='form-group col-md-3'>
                <label>CEO Contact</label>
                <input
                  required
                  type='text'
                  value={localState.clientContact}
                  onChange={handleChange('clientContact')}
                  className='form-control'
                  maxLength={13}
                  pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                  minLength={11}
                />
              </div>
            </div>
            <br />

            <div className='row mb-2'>
              <div className='col-md-4'>
                <button
                  type='button'
                  className='btn btn-block btn-info'
                  onClick={toggleWalletModal}
                >
                  ADD WALLET
                </button>
              </div>

              <div className='col-md-4'>
                <button
                  type='button'
                  className='btn btn-block btn-warning'
                  onClick={() => setComplianceModalOpen(true)}
                >
                  ADD COMPLIANCE INFO
                </button>
              </div>
            </div>
            <br />

            <div className='row mt-3'>
              <div className='col-md-6'>
                <button type='button' className='btn btn-block btn-danger' onClick={clearForm}>
                  CLEAR
                </button>
              </div>
              <div className='col-md-6'>
                <button type='submit' className='btn btn-block btn-primary' onClick={savePharmacy}>
                  {loading ? <ButtonLoader /> : 'SUBMIT'}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
};

export default AddPharmacyForm;
