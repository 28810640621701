import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import TableLoading from '../../common/TableLoading';
import { useHistory } from 'react-router-dom';
import { ILoansApplicationsDTO } from '../../../interface/LoansInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import LoanEditModal from './LoanEditModal';

const LoanApplicationTable = () => {
  const [allData, setAllData] = useState<ILoansApplicationsDTO[]>([]); //to hold all table result in unfiltered format
  const [tableData, setTableData] = useState<ILoansApplicationsDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<ILoansApplicationsDTO | undefined>();
  const history = useHistory();

  const editLoan = (item: ILoansApplicationsDTO) => {
    setModalData(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    getLoanApplications(1);
  };

  const getLoanApplications = useCallback(async (pageIndex: number) => {
    let url = `${endpoints.LoanApplications.mainUrl}`;

    try {
      setIsLoading(true);
      const response = await axios.get(url);

      setTableData(response.data);
      setAllData(response.data);

      if (response.data.length === 0) {
        addToast('No Loan Applications', 'info');
      }
    } catch (error: any) {
      addToast('Error getting loan applications', 'error');
      // setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const viewRepayments = (applicationId: string) => {
    //push to orders
    const url = `/loans/${applicationId}/repayments`;
    history.push(url);
  };

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setTableData(allData);
      return;
    }

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter((x) => x.pharmacyName?.toLowerCase().includes(searchParam));

    // //set table data to search result
    setTableData(searchResult);
  };

  useEffect(() => {
    getLoanApplications(1);
  }, [getLoanApplications]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col-4'>Actions</th>
        <th scope='col'>Date</th>
        <th scope='col'>Application Status</th>
        <th scope='col'>Pharmacy Name</th>
        <th scope='col'>Pharmacy Code</th>
        <th scope='col'>Loan Purpose </th>
        <th scope='col'>Monthly Inventory (₦)</th>
        <th scope='col'>Monthly Sales (₦)</th>
        <th scope='col'>Principal Requested (₦)</th>
        <th scope='col'>Principal Approved (₦)</th>
        <th scope='col'>Tenor Requested</th>
        <th scope='col'>Tenor Approved</th>
        <th scope='col'>Moratorium</th>
        <th scope='col'>Interest Rate</th>
        <th scope='col'>Loan Interest (₦)</th>
        <th scope='col'>Charges Percent</th>
        <th scope='col'>Charges Deducted (₦)</th>
        <th scope='col'>Amount Disbursed (₦)</th>
        <th scope='col'>Repayment Plan</th>
      </tr>
    </thead>
  );

  let noDataView = (
    <tbody className='text-center'>
      <tr>
        <td colSpan={11}>No Data found</td>
      </tr>
    </tbody>
  );

  let tableBody: JSX.Element = (
    <>
      {tableData ? (
        <tbody>
          {tableData.map((loanData: ILoansApplicationsDTO) => {
            return (
              <tr key={loanData.applicationId}>
                <td>
                  <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => editLoan(loanData)}
                    className='bi bi-pencil-square export-icon'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Edit'
                  ></i>
                  <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => viewRepayments(loanData.applicationId)}
                    className='bi bi-clock-history group-icon'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Repayments'
                  ></i>
                </td>
                <td>{formatDate(loanData.dateCreated)}</td>
                <td>{loanData.applicationStatus}</td>
                <td>{loanData.pharmacyName}</td>
                <td>{loanData.pharmacyCode}</td>
                <td>{loanData.loanPurpose}</td>
                <td>{loanData.monthlyInventory.toLocaleString()}</td>
                <td>{loanData.monthlySales.toLocaleString()}</td>
                <td>{loanData.principalRequested.toLocaleString()}</td>
                <td>{loanData.principalApproved.toLocaleString()}</td>
                <td>{loanData.tenorRequested}</td>
                <td>{loanData.tenorApproved}</td>
                <td>{loanData.moratorium}</td>
                <td>{loanData.interestRate}%</td>
                <td>{loanData.loanInterest.toLocaleString()}</td>
                <td>{loanData.chargesPercent}%</td>
                <td>{loanData.chargesDeducted.toLocaleString()}</td>
                <td>{loanData.amountDisbursed.toLocaleString()}</td>
                <td>{loanData.repaymentPlan}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        noDataView
      )}
    </>
  );

  if (isLoading) {
    return <TableLoading />;
  }

  let loanEditModal = <LoanEditModal open={modalOpen} close={closeModal} modalData={modalData!} />;

  return (
    <div>
      {typeof modalData === 'undefined' ? '' : loanEditModal}
      <br />
      <h3 className='mr-auto'>Loan Applications ({tableData.length})</h3>
      <div className='row'>
        <input
          type='search'
          className='form-control col-4'
          placeholder='Search by pharmacy name'
          onChange={searchTable}
        />
      </div>
      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default LoanApplicationTable;
