import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  ISubscriptionDetailsProp,
  ISubscriptionResponse,
} from '../../../interface/SubscriptionInterface';
import { ALL_STATES } from '../../../utils/allStates';
import { formatDate } from '../../../utils/dateTimeUtils';
import { isFormValidated } from '../../../utils/formUtils';
import {
  OnboardingStatus,
  PAYMENT_PLAN_MAPPING,
  PRODUCT_TYPE,
  STATUS_DISPLAY,
  SubscriptionDetailsTab,
  ACQUISITION_CHANNEL_MAPPING,
  ACQUISITION_REASON_MAPPING,
} from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import TableLoader from '../../common/TableLoading';

const SubscriptionInfo = ({
  subscription,
  toggleLock,
  getSubscription,
  tabKey,
  openNoteModal,
}: ISubscriptionDetailsProp) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<ISubscriptionResponse | undefined>(
    subscription
  );
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  //const { requestId } = useParams() as any;

  const handleChange = (input: any) => (event: any) => {
    setSubscriptionInfo({
      ...subscriptionInfo!,
      [input]: event.target.value,
    });

    //if Acquisition Reason set to Others, show modal
    if (input === 'acquisitionReason' && event.target.value === 'Others') {
      if (openNoteModal) {
        openNoteModal('Acquistion Reason');
      }
    }
  };

  const updateSubscription = async (event: any) => {
    if (!isFormValidated('edit-subscription-modal')) {
      addToast('Fill in all required fields', 'warning');
      return;
    }
    toggleLock(false);
    event.preventDefault();
    setButtonLoading(true);
    const payload = subscriptionInfo;
    try {
      await axios.put(endpoints.InsuranceSubscriptions.mainUrl, payload);
      getSubscription();
      addToast('Subscription updated successfully', 'success');
    } catch (error: any) {
      addToast('Error updating subscription', 'error');
    } finally {
      setButtonLoading(false);
      toggleLock(true);
    }
  };

  useEffect(() => {
    if (subscription && tabKey === SubscriptionDetailsTab.info && !dataLoaded) {
      setSubscriptionInfo(subscription);
      setDataLoaded(true);
    }
  }, [subscription, tabKey, dataLoaded]);

  return (
    <div className='container mt-5'>
      {subscriptionInfo ? (
        <form id='edit-subscription-modal'>
          <fieldset disabled={isButtonLoading}>
            <div className='row'>
              <div className='form-group col-sm-4 mt-3'>
                <label>Subscription Code</label>
                <input
                  type='text'
                  className='form-control'
                  value={subscriptionInfo.subscriptionCode}
                  disabled
                />
              </div>

              <div className='form-group col-sm-4 mt-3'>
                <label>First Name</label>
                <input
                  type='text'
                  value={subscriptionInfo.firstName}
                  required
                  className='form-control'
                  onChange={handleChange('firstName')}
                />
              </div>
              <div className='form-group  col-sm-4 mt-3'>
                <label>Last Name</label>
                <input
                  type='text'
                  value={subscriptionInfo.lastName}
                  required
                  className='form-control'
                  onChange={handleChange('lastName')}
                />
              </div>
              <div className='form-group  col-sm-4 mt-3'>
                <label>Phone Number</label>
                <input
                  type='text'
                  disabled
                  value={subscriptionInfo.phone}
                  required
                  className='form-control'
                  onChange={handleChange('phone')}
                />
              </div>
              <div className='form-group  col-sm-4 mt-3'>
                <label>Email</label>
                <input
                  type='text'
                  value={subscriptionInfo.email}
                  className='form-control'
                  onChange={handleChange('email')}
                />
              </div>
              <div className='form-group  col-sm-4 mt-3'>
                <label>Product</label>
                <select
                  value={subscriptionInfo.product}
                  required
                  onChange={handleChange('product')}
                  className='custom-select'
                >
                  {PRODUCT_TYPE.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group  col-sm-4 mt-3'>
                <label> Status</label>
                <select
                  className='custom-select'
                  value={subscriptionInfo.status}
                  onChange={handleChange('status')}
                >
                  {STATUS_DISPLAY.map((plan) => (
                    <option key={plan.key} value={plan.value}>
                      {plan.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group  col-sm-4 mt-3'>
                <label>Onboarding Status</label>
                <select
                  className='custom-select'
                  value={subscriptionInfo.onboardingStatus}
                  onChange={handleChange('onboardingStatus')}
                >
                  {OnboardingStatus.map((plan) => (
                    <option key={plan.key} value={plan.value}>
                      {plan.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-3'>
                <label>Payment Plan</label>
                <select
                  className='custom-select'
                  value={subscriptionInfo.paymentPlan}
                  onChange={handleChange('paymentPlan')}
                >
                  {PAYMENT_PLAN_MAPPING.map((plan) => (
                    <option key={plan.key} value={plan.value}>
                      {plan.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group col-sm-2 mt-3'>
                <label>Amount</label>
                <input
                  type='number'
                  value={subscriptionInfo.amount}
                  className='form-control'
                  disabled
                />
              </div>
              <div className='form-group col-sm-2 mt-3'>
                <label>No of Beneficiaries</label>
                <input
                  type='number'
                  value={subscriptionInfo.numberOfSubscribers}
                  className='form-control'
                  disabled
                />
              </div>
              {subscriptionInfo.agentCode !== null || subscriptionInfo.partnerCode !== null ? (
                <div className='form-group col-sm-4 mt-3'>
                  {subscriptionInfo.agentCode !== null ? (
                    <div>
                      <label>Agent</label>
                      <input
                        type='text'
                        value={subscriptionInfo.agentCode}
                        className='form-control'
                        readOnly
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {subscriptionInfo.partnerCode !== null ? (
                    <div>
                      <label>PartnerCode</label>
                      <input
                        type='text'
                        value={subscriptionInfo.partnerCode}
                        className='form-control'
                        readOnly
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}

              <div className='form-group col-sm-4 mt-3'>
                <label>Acquisition Channel</label>
                <select
                  required
                  onChange={handleChange('acquisitionChannel')}
                  value={subscriptionInfo.acquisitionChannel}
                  className='custom-select'
                >
                  {ACQUISITION_CHANNEL_MAPPING.map((option) => (
                    <option key={option.key} value={option.value.toString()}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-3'>
                <label className='form-label'>Acquisition Reason</label>
                <select
                  required
                  onChange={handleChange('acquisitionReason')}
                  value={subscriptionInfo.acquisitionReason}
                  className='custom-select'
                >
                  {/* <option value={''}>-- Select -- </option> */}
                  {ACQUISITION_REASON_MAPPING.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-3'>
                <label>State of Residence</label>
                <select
                  required
                  onChange={handleChange('stateOfResidence')}
                  value={subscriptionInfo.stateOfResidence}
                  className='custom-select'
                >
                  {ALL_STATES.map((option) => (
                    <option key={option.value} value={option.value.toString()}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-3'>
                <label>Location</label>
                <input
                  type='text'
                  value={subscriptionInfo.location}
                  onChange={handleChange('location')}
                  className='form-control'
                />
              </div>
              <div className='col-sm-12'>
                <div className='row'>
                  <div className='form-group col-sm-4 mt-3'>
                    <label>Initial Payment</label>
                    <input
                      type='text'
                      value={formatDate(subscriptionInfo.initialPayment)}
                      disabled
                      className='form-control'
                    />
                  </div>
                  <div className='form-group col-sm-4 mt-3'>
                    <label>Last Payment</label>
                    <input
                      type='text'
                      value={formatDate(subscriptionInfo.lastPayment)}
                      className='form-control'
                      disabled
                    />
                  </div>
                  <div className='form-group col-sm-4 mt-3'>
                    <label>Next Payment</label>
                    <input
                      type='text'
                      value={formatDate(subscriptionInfo.nextPayment)}
                      disabled
                      className='form-control'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='form-group col-sm-4 mt-3'>
                <button
                  className='btn btn-primary btn-block'
                  type='submit'
                  disabled={isButtonLoading}
                  onClick={updateSubscription}
                >
                  Submit
                  {isButtonLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      ) : (
        <TableLoader />
      )}
    </div>
  );
};

export default SubscriptionInfo;
