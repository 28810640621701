import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { FulfilmentStatusMap, FulfilmentServiceMap } from '../../../utils/mappings';
import { isFormValidated } from '../../../utils/formUtils';
import { IEditFulfilmentModal } from '../../../interface/FulfilmentsInterface';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import { IPharmacyWalletDTO } from '../../../interface/PharmacyInterface';

const MoreInfoModal = ({ open, close, modalData }: IEditFulfilmentModal) => {
  //const newPharmacyRating = pharmacyRating;
  const [isButtonLoading, setButtonLoading] = useState(false);
  //const [rating, setRating] = useState(newPharmacyRating);
  const [fulfilmentRequest, setFulfilmentRequest] = useState(modalData);
  const [payInstantly, setPayInstantly] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [pharmacy, setPharmacy] = useState<IPharmacyWalletDTO | undefined>();

  const handleChange = (input: any) => (event: any) => {
    setFulfilmentRequest({
      ...fulfilmentRequest,
      [input]: event.target.value,
    });
    const eventValue = event.target.value;
    if (input === 'fulfilmentStatus' && (eventValue === 'Approved' || eventValue === 'Completed')) {
      setShowCheckbox(true);
      //call pharmacy api
      getPharmacyData();
    } else {
      setShowCheckbox(false);
      setPayInstantly(false);
    }
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayInstantly(event.target.checked);
  };

  const getPharmacyData = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/${fulfilmentRequest.pharmacyCode}`;
    try {
      const response = await axios.get(url);

      setPharmacy(response.data);
    } catch (error: any) {
      console.error(error);
    } finally {
      //setLoading(false);
    }
  };

  const updateFulfilment = async (event: any) => {
    if (!isFormValidated('edit-fulfilment-form')) {
      addToast('Please fill all required fields!', 'warning');
      return;
    }

    event.preventDefault();
    //blacklist users from using WellaHealth pharmacies
    const pharmacyBlacklist = ['WHP10390', 'WHP10817', 'WHP11145', 'WHP11026', 'WHP10970'];
    if (
      pharmacyBlacklist.includes(fulfilmentRequest.pharmacyCode) &&
      fulfilmentRequest.fulfilmentStatus !== 'Cancelled'
    ) {
      addToast('Please change from default pharmacy', 'error');
      return;
    }

    event.preventDefault();

    // create payload
    let payload = {
      requestId: fulfilmentRequest.requestId,
      enrollmentId: fulfilmentRequest.enrollmentId,
      enrollmentCode: fulfilmentRequest.enrollmentCode,
      enrolleeFirstName: fulfilmentRequest.enrolleeFirstName,
      enrolleeLastName: fulfilmentRequest.enrolleeLastName,
      enrolleePhone: fulfilmentRequest.enrolleePhone,
      enrolleeEmail: fulfilmentRequest.enrolleeEmail,
      enrolleeAddress: fulfilmentRequest.enrolleeAddress,
      pharmacyCode: fulfilmentRequest.pharmacyCode,
      isPharmacyPaid: fulfilmentRequest.isPharmacyPaid,
      fulfilmentStatus: fulfilmentRequest.fulfilmentStatus,
      fulfilmentService: fulfilmentRequest.fulfilmentService,
      notes: fulfilmentRequest.notes,
      payInstantly: payInstantly,
      //rating: rating,
    };

    setButtonLoading(true);
    //call api
    let url = endpoints.FulfilmentRequests.mainUrl;
    try {
      await axios.put(url, payload);
    } catch (error) {
      addToast('An error occured updating the fulfilment', 'error');
    } finally {
      setButtonLoading(false);
      close();
    }
  };

  useEffect(() => {
    if (open) {
      setFulfilmentRequest(modalData);
      setPayInstantly(false);
      setShowCheckbox(false);
      setPharmacy(undefined);
    }
  }, [open, modalData]);

  return (
    <Modal show={open} onHide={() => close()}>
      <form id='edit-fulfilment-form'>
        <Modal.Header closeButton>
          <Modal.Title>
            Request for {fulfilmentRequest.enrolleeFirstName} {fulfilmentRequest.enrolleeLastName} -
            {fulfilmentRequest.requestSource}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isButtonLoading}>
            <div className='form-group'>
              <label>Enrollment Code</label>
              <input
                type='text'
                className='form-control'
                value={fulfilmentRequest.enrollmentCode}
                disabled
              />
            </div>

            <div className='form-group'>
              <label>First Name</label>
              <input
                type='text'
                value={fulfilmentRequest.enrolleeFirstName}
                required
                className='form-control'
                onChange={handleChange('enrolleeFirstName')}
              />
            </div>
            <div className='form-group'>
              <label>Last Name</label>
              <input
                type='text'
                value={fulfilmentRequest.enrolleeLastName}
                required
                className='form-control'
                onChange={handleChange('enrolleeLastName')}
              />
            </div>
            <div className='form-group'>
              <label>Phone Number</label>
              <input
                type='text'
                value={fulfilmentRequest.enrolleePhone}
                required
                className='form-control'
                onChange={handleChange('enrolleePhone')}
              />
            </div>
            <div className='form-group'>
              <label>Email</label>
              <input
                type='text'
                value={fulfilmentRequest.enrolleeEmail}
                className='form-control'
                onChange={handleChange('enrolleeEmail')}
              />
            </div>
            <div className='form-group'>
              <label>Address</label>
              <input
                type='text'
                value={fulfilmentRequest.enrolleeAddress}
                className='form-control'
                required
                disabled
              />
            </div>
            <div className='form-group'>
              <label>Fulfilment Service</label>
              <select
                value={fulfilmentRequest.fulfilmentService}
                className='custom-select'
                onChange={handleChange('fulfilmentService')}
              >
                {FulfilmentServiceMap.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label>Fulfilment Status</label>
              <select
                value={fulfilmentRequest.fulfilmentStatus}
                className='custom-select'
                onChange={handleChange('fulfilmentStatus')}
              >
                {FulfilmentStatusMap.map((option) => (
                  <option value={option.value} key={option.key}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            {showCheckbox ? (
              <div className=' mb-3'>
                {pharmacy ? (
                  <div>
                    {pharmacy?.payInstantly ? (
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          checked={payInstantly}
                          onChange={handleCheckbox}
                          type='checkbox'
                          value=''
                        />

                        <label className='form-check-label'>Pay Pharmacy Instantly</label>
                      </div>
                    ) : (
                      <span className='text-info '>
                        Instant Payment not available for this pharmacy
                      </span>
                    )}
                    <div>
                      {pharmacy.accountId === null || pharmacy.accountId === '' ? (
                        <div>
                          {pharmacy.pharmacyName}
                          <span className='text-danger'>: does not have wallet.</span>
                        </div>
                      ) : (
                        <div>
                          {pharmacy.pharmacyName}
                          <span className='text-success'>
                            : has wallet. Please be sure that this is the correct pharmacy
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='text-info'>Checking pharmacy wallet detail...</div>
                )}
              </div>
            ) : (
              ''
            )}

            <div className='form-group'>
              <label>Fulfilment Cost</label>
              <input
                type='text'
                value={fulfilmentRequest.drugCost + fulfilmentRequest.testCost}
                className='form-control'
                required
                disabled
              />
            </div>
            <div className='form-group'>
              <label>Amount to be Paid by Partner</label>
              <input
                type='text'
                value={fulfilmentRequest.drugTariffCost + fulfilmentRequest.testTariffCost}
                className='form-control'
                required
                disabled
              />
            </div>
            {parseFloat((fulfilmentRequest.drugCost + fulfilmentRequest.testCost).toString()) >
            parseFloat(
              (fulfilmentRequest.drugTariffCost + fulfilmentRequest.testTariffCost).toString()
            ) ? (
              <div className='mb-4 text-danger'>Fulfilment cost is Higher than tarrif cost</div>
            ) : (
              ''
            )}
            <div className='form-group'>
              <label>Request Source</label>
              <input
                type='text'
                value={fulfilmentRequest.requestSource}
                className='form-control'
                required
                disabled
              />
            </div>
            <div className='form-group'>
              <label>Notes</label>
              <input
                type='text'
                value={fulfilmentRequest.notes}
                className='form-control'
                onChange={handleChange('notes')}
              />
            </div>
            <br />
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-primary'
            type='submit'
            disabled={isButtonLoading}
            onClick={updateFulfilment}
          >
            Submit
            {isButtonLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MoreInfoModal;
