import { IAppState, IDispatch, initialAppState } from '../interface/AppInterface';

const appReducer = (state: IAppState, action: IDispatch) => {
  switch (action.type) {
    case 'LOG_OUT':
      sessionStorage.clear();
      localStorage.setItem('rememberMe', 'false');
      return {
        token: '',
        isAuthenticated: false,
        userRole: '',
        loginId: '',
        oneClickLeadsData: initialAppState.oneClickLeadsData,
        subscriptionCode: '',
        numberOfSubscribers: 0,
        paymentPlan: '',
        isMalariaView: true,
        pharmacyList: initialAppState.pharmacyList,
        productView: initialAppState.productView,
        fulfilmentPartnersList: initialAppState.fulfilmentPartnersList,
        trackingNumber: '',
        selectedWallet: null,
        insuranceSubscriptions: null,
        supportRequests: null,
        insurancePayments: null,
        fulfilmentRequests: null,
        walletTransactions: null,
        insuranceClaims: null,
        axaLookupData: null,
        notesList: initialAppState.notesList,
        fulfilmentSearchResult: initialAppState.fulfilmentSearchResult,
      };
    case 'SET_PHARMACY_LIST':
      return {
        ...state,
        pharmacyList: action.payload,
      };
    case 'SET_PARTNER_LIST':
      return {
        ...state,
        fulfilmentPartnersList: action.payload,
      };
    case 'SET_PAYMENT_PLAN':
      return {
        ...state,
        paymentPlan: action.payload,
      };
    case 'SET_PEOPLE_IN_PLAN':
      return {
        ...state,
        numberOfSubscribers: action.payload,
      };
    case 'SET_SUBSCRIPTION_CODE':
      return {
        ...state,
        subscriptionCode: action.payload,
      };
    case 'SET_PRODUCT_VIEW':
      return {
        ...state,
        productView: action.payload,
      };
    case 'LOG_IN':
      return {
        ...state,
        token: action.payload.token,
        loginId: action.payload.loginId,
        userRole: action.payload.userRole,
        isAuthenticated: true,
        oneClickLeadsData: initialAppState.oneClickLeadsData,
      };
    case 'SET_AUTH':
      localStorage.setItem('rememberMe', 'true'); //
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'SET_ONE_CLICK_LEADS_DATA':
      return {
        ...state,
        oneClickLeadsData: action.payload,
      };
    case 'CLEAR_ONE_CLICK_LEADS_DATA':
      return {
        ...state,
        oneClickLeadsData: initialAppState.oneClickLeadsData,
      };
    case 'SET_WALLET_DATA':
      return {
        ...state,
        walletData: action.payload,
      };
    case 'SET_TRACKING_NUMBER':
      return {
        ...state,
        trackingNumber: action.payload,
      };
    case 'SET_AXA_DATA':
      return {
        ...state,
        axaLookupData: action.payload,
      };
    case 'SET_USER_NOTES':
      return {
        ...state,
        notesList: action.payload,
      };
    case 'ADD_USER_NOTE':
      return {
        ...state,
        notesList: [...state.notesList, action.payload],
      };
    case 'SET_PROPERTY_STATE': //updates a property of ANY IAppState object e.g. firstName. to use, pass the property name and new value e.g propName:'subscriptionCode', propData:'9720KKW'
      return {
        ...state,
        [action.propName]: action.propData,
      };

    default:
      return state;
  }
};

export default appReducer;
