import { IFulfilmentDraftResponse } from '../../../interface/FulfilmentsInterface';

const FulfilmentDraftCardLayout = ({
  selectDraft,
  drafts,
  callback,
}: {
  selectDraft: any;
  drafts: IFulfilmentDraftResponse[];
  callback?: Function;
}) => {
  const selectNewDraft = (draft: IFulfilmentDraftResponse) => (event: any) => {
    event.preventDefault();
    if (selectDraft) {
      selectDraft(draft);
    }
  };
  return (
    <div>
      {drafts.length > 0
        ? drafts.map((draft, key) => (
            <div key={key} className='card pointer wella-card' onClick={selectNewDraft(draft)}>
              <div className='card-body'>
                {draft.patientInfo?.firstName} {draft.patientInfo?.lastName}
                <div>
                  <strong className='fs-7'>{draft.hmoId}</strong>
                </div>
                <div>{draft.patientInfo?.phoneNumber}</div>
                <small>{draft.partnerName}</small>
              </div>
            </div>
          ))
        : ''}
    </div>
  );
};

export default FulfilmentDraftCardLayout;
