import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import {
  IReceivedPayment,
  initialReceivedPayment,
} from '../../../interface/ReceivedPaymentInterface';

const PaymentTable = () => {
  const [tableData, setTableData] = useState([initialReceivedPayment]);
  let tableBody;
  const getPaymentData = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.InsurancePayment.getAllPayment);
      setTableData(response.data);
    } catch (error: any) {
      addToast('There was an error getting payment data', 'error');
    }
  }, []);

  useEffect(() => {
    getPaymentData();
  }, [getPaymentData]);

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th>Name</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Transaction Date</th>
        <th>Payment Matched</th>
        <th>Transaction Reference</th>
        <th>Payment Status</th>
        <th>Amount Paid</th>
        <th>Payment Description</th>
        <th>Payment Source</th>
        <th>Payment Status</th>
        <th>Payment Matched To</th>
        <th>Subscription Type</th>
        <th>Product Type</th>
      </tr>
    </thead>
  );

  if (tableData.length === 0) {
    tableBody = (
      <div className='d-flex'>
        <div className='justify-content-center' style={{ marginLeft: '200px' }}>
          No Data Available.
        </div>
      </div>
    );
  } else {
    tableBody = (
      <tbody>
        {tableData.map((payment: IReceivedPayment) => {
          return (
            <tr key={payment.transactionReference}>
              <td>{payment.name}</td>
              <td>{payment.phoneNumber}</td>
              <td>{payment.email}</td>
              <td>{payment.transactionDate}</td>
              <td>{payment.isPaymentMatched}</td>
              <td>{payment.transactionReference}</td>
              <td>{payment.paymentStatus}</td>
              <td>{payment.amountPaid}</td>
              <td>{payment.paymentDescription}</td>
              <td>{payment.paymentSource}</td>
              <td>{payment.paymentStatus}</td>
              <td>{payment.paymentMatchedTo}</td>
              <td>{payment.subscriptionType}</td>
              <td>{payment.productType}</td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <>
      <h3>Payments</h3>
      <div className='table-responsive'>
        <table className='table table-striped table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </>
  );
};

export default PaymentTable;
