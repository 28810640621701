import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }: any) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }

  // const history = useHistory();

  const goBack = () => {
    //check if previous page exist and is not call-back page
    const previousPage = document.referrer;
    const callbackPage = process.env.REACT_APP_OIDC_REDIRECT_URL;

    if (previousPage === '' || previousPage.includes(callbackPage!)) {
      return;
    }
    window.history.back();
  };

  return (
    <div className='mb-4 bg-gray-300' style={{ fontSize: '0.9rem' }}>
      {'<<'}
      <span className='mr-3 underline text-blue-500 bread-space' onClick={goBack}>
        Back
      </span>
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path }: { name: string; path: string }, key: number) =>
        key + 1 === crumbs.length ? (
          <span key={key} className='bold bread-space'>
            {name}
          </span>
        ) : (
          <span key={key}>
            <Link className='underline text-blue-500 bread-space' to={path}>
              {name}
            </Link>
            {'>>'}
          </span>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
