import axios from 'axios';
import { useEffect, useState } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';
import {
  PatientRequestDrugDTO,
  PatientRequestModalData,
} from '../../../interface/PatientRequestInterface';
import Select from 'react-select';

const PatientRequestModal = ({ open, close, modalData }: PatientRequestModalData) => {
  const [drugsData, setDrugsData] = useState<PatientRequestDrugDTO[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [pharmacyListSelect, setPharmacyListSelect] = useState([]);
  const [newPharmacyCode, setNewPharmacyCode] = useState(modalData.pharmacyCode);

  const saveData = async () => {
    if (!isFormValidated('drugs-form')) {
      addToast('Please fill all required fields');
      return;
    }

    modalData.pharmacyCode = newPharmacyCode;
    const noDrugCost = drugsData.filter((x) => x.cost === 0);

    if (noDrugCost.length > 0) {
      addToast('Please enter drug cost for all drugs dispensed');
      return;
    }

    try {
      setBtnLoading(true);
    } catch (error: any) {
      console.error(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const getPharmacyList = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/list`;

    try {
      const response = await axios.get(url);
      let selectData = response.data.map((val: any) => {
        return {
          label: val.pharmacyName,
          value: val.pharmacyCode,
        };
      });
      //set to state
      setPharmacyListSelect(selectData);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('pharmacyList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const selectChange = (selectedValue: any) => {
    setNewPharmacyCode(selectedValue.value);
  };
  //https://stackoverflow.com/a/55988040/2929906
  //https://stackoverflow.com/a/55788298/2929906
  const handleChange = (input: string, id: number) => (event: any) => {
    setDrugsData(
      drugsData.map((drug) =>
        drug.drugId === id ? { ...drug, [input]: event.target.value } : drug
      )
    );
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      setDrugsData([]);

      const url = `${endpoints.PatientRequest.mainUrl}/drugs/${modalData?.orderId}`;
      try {
        const response = await axios.get(url);
        const data = response.data;

        //for each data, check if the tariff exist, then
        // get price list from indexDB

        setDrugsData(data);
      } catch (error: any) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      getDetails();
      getPharmacyList();
    }
  }, [modalData?.orderId, open]); //, modalData.fulfilmentService]);

  return (
    <Modal show={open} onHide={() => close()} backdrop={isBtnLoading ? 'static' : true}>
      <Modal.Header closeButton={!isBtnLoading}>
        <Modal.Title>
          Prescribed Drugs for {modalData?.firstName} {modalData?.lastName} -
          {isLoading ? <ButtonLoader /> : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body'>
        <div className='container' style={{ marginTop: '10px' }}>
          <div className='row'>
            <div className='col-md-8'>
              <Select
                options={pharmacyListSelect}
                onChange={selectChange}
                className='react-select'
                classNamePrefix='react-select'
              />
              <label>Select Pharmacy</label>
            </div>
            {/* <div className='col-md-4'>
              <button type='button' className='btn btn-primary' onClick={savePharmacyCode}>
                Save
                {isSaving ? <ButtonLoader /> : ''}
              </button>
            </div> */}
          </div>
        </div>
        <form id='drugs-form'>
          {drugsData.map((drug, key) => (
            <div key={drug.drugId}>
              <label>Drug {key + 1}</label>
              <div className='col-md-12'>
                <label htmlFor='name' className='form-label'>
                  Drug Name
                </label>
                <input
                  value={drug.drugName}
                  required
                  className='form-control '
                  type='text'
                  disabled
                  id={'drug' + key}
                  onChange={handleChange('name', drug.drugId)}
                />
              </div>
              <br />

              <div className='col-md-12'>
                <label className='form-label'>Dose </label>
                <label className='form-control'>{`${drug.form} ${drug.strength} `}</label>
              </div>
              <br />

              <div className='col-md-12'>
                <label className='form-label'>Drug Cost</label>
                <input
                  value={drug.cost}
                  id={'drugCost' + key}
                  required
                  className='form-control '
                  min={1}
                  type='number'
                  onChange={handleChange('cost', drug.drugId)}
                  step={0.01}
                />
              </div>
              <br />
              <hr />
            </div>
          ))}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn  btn-primary'
          disabled={isBtnLoading}
          onClick={saveData}
        >
          Save
          {isBtnLoading ? <ButtonLoader /> : ''}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PatientRequestModal;
