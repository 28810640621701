import { Fragment, useEffect, useState } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import axios from 'axios';
import { isFormValidated } from '../../../utils/formUtils';
import {
  initialFulfilmentFormData,
  IFulfilmentPatientData,
  IFulfilmentDraft,
  defaultFulfilmentDraft,
  defaultFulfilmentPatient,
} from '../../../interface/FulfilmentsInterface';
import { IPharmacyListData } from '../../../interface/PharmacyInterface';
import Select from 'react-select';
import { FulfilmentServiceMap, PharmacyStatusMap } from '../../../utils/mappings';
import { IFulfilmentPartners } from '../../../interface/FulfilmentPartnersInterface';
import PatientSearch from '../../common/PatientSearch';
import { ReactSelectOption } from '../../../interface/CommonInterface';
import { Modal, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { Link } from 'react-router-dom';

const AddFulfilmentDraft = () => {
  const [isLoading, setLoading] = useState(false);
  const [fulfilmentDraft, setFulfilmentDraft] = useState<IFulfilmentDraft>(defaultFulfilmentDraft);

  const [fulfilmentPatient, setFulfilmentPatient] =
    useState<IFulfilmentPatientData>(defaultFulfilmentPatient);

  const [patientFound, setPatientFound] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const [howToModal, setHowToModal] = useState(false);

  const [pharmacyListSelect, setPharmacyListSelect] = useState<ReactSelectOption[]>([]);
  const [defaultPharmacy, setDefaultPharmacy] = useState({
    label: '',
    value: '',
  });
  const [partnersList, setPartnersList] = useState<IFulfilmentPartners[]>([]);
  const [partnersListSelect, setPartnersListSelect] = useState<IFulfilmentPartners[]>([]);

  const [newPharmacyCode, setNewPharmacyCode] = useState(initialFulfilmentFormData.pharmacyCode);
  const [newPartnerCode, setNewPartnerCode] = useState(initialFulfilmentFormData.partnerCode);

  const getPharmacyList = async () => {
    let url = `${endpoints.InsurancePharmacy.mainUrl}/list`;

    try {
      const response = await axios.get(url);

      let selectData = response.data
        .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
        .map((val: IPharmacyListData) => {
          return {
            label: val.pharmacyName,
            value: val.pharmacyCode,
          };
        });
      //set to state
      setPharmacyListSelect(selectData);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('pharmacyList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const getPartnersList = async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;

    try {
      const response = await axios.get(url);

      let selectData = response.data.map((val: IFulfilmentPartners) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      //set to state
      setPartnersListSelect(selectData);
      setPartnersList(response.data);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const selectChange = (selectedValue: any) => {
    setNewPharmacyCode(selectedValue.value);
    setFulfilmentPatient({
      ...fulfilmentPatient,
      pharmacyCode: selectedValue.value,
    });

    //so that it shows on the select element since we are setting the 'value' props manually
    setDefaultPharmacy({
      ...defaultPharmacy,
      label: selectedValue.label,
      value: selectedValue.value,
    });
  };

  const selectPartnerCodeChange = (selectedValue: any) => {
    setNewPartnerCode(selectedValue.value);
    //if partnercode is changed, get partner config and apply to processing fee

    // const partnerConfig = FulfilmentConfigurations.filter(
    //   (x) => x.partnerCode === selectedValue.value
    // )[0];
    const selectedPartner = partnersList.filter((x) => x.partnerCode === selectedValue.value)[0];
    setFulfilmentDraft({
      ...fulfilmentDraft,
      partnerCode: selectedPartner.partnerCode,
      partnerName: selectedPartner.partnerName,
    });
  };

  const submit = async (e: React.FormEvent) => {
    if (!isFormValidated('fulfilment-bulk')) {
      addToast('Please fill all fields');
      return;
    }
    e.preventDefault();

    if (newPartnerCode === '') {
      addToast('Please select a HMO', 'warning');
      setLoading(false);
      return;
    }

    setLoading(true);

    const payload = fulfilmentDraft;
    payload.pharmacyCode = newPharmacyCode;
    payload.partnerCode = newPartnerCode;

    if (payload.partnerName === '' || payload.partnerName === null) {
      const selectedPartner = partnersList.filter((x) => x.partnerCode === newPartnerCode)[0];
      payload.partnerName = selectedPartner.partnerName;
    }
    //if patient not found, set patient to null
    fulfilmentDraft.patientInfo = fulfilmentPatient;

    if (!patientFound) {
      fulfilmentDraft.patientInfo = null!;
    }

    try {
      setLoading(true);
      const response = await axios.post(endpoints.FulfilmentDrafts.mainUrl, payload);
      if (response.status === 200) {
        addToast('Draft saved successfully. Check the Draft to see result', 'success');
        clearForm();
      }
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setFulfilmentPatient({
      ...fulfilmentPatient,
      [input]: event.target.value,
    });
  };

  const handleDraftChange = (input: string) => (event: any) => {
    setFulfilmentDraft({
      ...fulfilmentDraft,
      [input]: event.target.value,
    });
  };

  const clearForm = () => {
    setLoading(false);
    setFulfilmentDraft(defaultFulfilmentDraft);
    setFulfilmentPatient(defaultFulfilmentPatient);
  };

  useEffect(() => {
    const savedPharmacyList = sessionStorage.getItem('pharmacyList');

    if (savedPharmacyList !== null) {
      const pharmacyList = JSON.parse(savedPharmacyList);
      let selectData = pharmacyList
        .filter((x: any) => x.pharmacyStatus !== PharmacyStatusMap.Cancelled)
        .map((val: { pharmacyName: string; pharmacyCode: string }) => {
          return {
            label: val.pharmacyName,
            value: val.pharmacyCode,
          };
        });
      //set to state
      setPharmacyListSelect(selectData);
    } else {
      //call api
      getPharmacyList();
    }
  }, []);

  useEffect(() => {
    const savedPartnersList = sessionStorage.getItem('partnersList');

    if (savedPartnersList !== null && typeof savedPartnersList !== 'undefined') {
      const partnersList = JSON.parse(savedPartnersList);
      let selectData = partnersList.map((val: { partnerName: string; partnerCode: string }) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      //set to state
      setPartnersListSelect(selectData);
      setPartnersList(partnersList);
    } else {
      //call api
      getPartnersList();
    }
  }, []);

  const selectPatient = (searchTerm: string, patient?: IFulfilmentPatientData) => {
    setIsSearching(false);

    if (typeof patient === 'undefined' || patient === null) {
      addToast('No patient found for the phone number or HMOID', 'info');
      setPatientFound(false);
      setFulfilmentPatient(defaultFulfilmentPatient);
      setFulfilmentDraft({
        ...fulfilmentDraft,
        hmoId: searchTerm,
      });
      return;
    }
    setPatientFound(true);
    setFulfilmentPatient({
      ...fulfilmentPatient,
      firstName: patient!.firstName,
      lastName: patient!.lastName,
      address: patient!.address,
      email: patient!.email,
      phoneNumber: patient!.phoneNumber,
      fulfilmentService: patient!.fulfilmentService, ///change
      pharmacyCode: patient!.pharmacyCode,
      pharmacyName: patient!.pharmacyName,
      hmoId: patient!.hmoId,
    });

    setFulfilmentDraft({
      ...fulfilmentDraft,
      hmoId: searchTerm,
      fulfilmentService: patient!.fulfilmentService,
    });
    let primaryPharmacy: ReactSelectOption = {
      label: patient.pharmacyName,
      value: patient.pharmacyCode,
    };

    setDefaultPharmacy(primaryPharmacy);
    setNewPharmacyCode(patient.pharmacyCode);
  };

  const howItWorks = (
    <Modal show={howToModal} centered size='lg' onHide={() => setHowToModal(!howToModal)}>
      <ModalHeader>How to use Drafts</ModalHeader>
      <ModalBody>
        <div>
          <ol>
            <li>Start by search for a patient using the patient HMO ID or phone number</li>

            <li className='mt-2'>
              If the patient is found, select the patient from the dropdown and proceed to enter
              Diagnois and also select a fulfilment service
            </li>
            <li className='mt-2'>
              Then copy and paste the medication (from email or whatsapp) into the
              <strong className='fs-7'>Medication Info</strong>
              <strong className='fs-7'>
                It's important that you enter one medication per line e.g, a line can have 'Panadol
                2tabs 3 times daily'
              </strong>
            </li>
            <li className='mt-2'>
              If the patient is not found, You can also paste the patient information in the
              <strong className='fs-7 mr-1 ml-1'>Patient Info</strong>box.
              <strong className='fs-7 ml-2'>
                Enter each part of patient info on a new line. i.e, Name, Phone and address should
                be entered on a new line
              </strong>
              box
            </li>
            <li className='mt-2'>Click 'Save Draft' and you can continue for multiple patients.</li>
            <li className='mt-2'>
              After saving all, wait for about 2-5 minutes. You can continue working on other
              things. In the background, a machine will process all the information you entered
            </li>
            <li className='mt-2'>
              After waiting a while, navigate to{' '}
              <Link to='/fulfilmentdrafts'>Fulfilment Drafts</Link>{' '}
            </li>
            <li className='mt-2'>
              On that page, you can view all drafts. All you need to do then is just confirm all
              information is correct and then click a button to create the fulfilment
            </li>
          </ol>
        </div>
      </ModalBody>
    </Modal>
  );

  return (
    <Fragment>
      {howItWorks}
      <div className='container'>
        {/* <div className='row'>
          <i className='bi bi-arrow-left'></i> Back
        </div> */}

        <h4 className='text-center'>Fulfilment Draft</h4>
        <br />

        <br />
        <form id='fulfilment-bulk'>
          <fieldset disabled={isLoading}>
            <div className='row'>
              <div className='col-4'>
                <label className='form-label'>Partner Name</label>
                <Select
                  options={partnersListSelect}
                  onChange={selectPartnerCodeChange}
                  className='react-select'
                  classNamePrefix='react-select'
                />
              </div>
              <div className='col-md-6 col-8'>
                <label className='form-label'>Search by HMO ID or phone number</label>
                <PatientSearch partnerCode={newPartnerCode} callback={selectPatient} />
              </div>
            </div>
            <div className={!isSearching && patientFound ? 'row mt-4' : 'row mt-4 d-none'}>
              <div className='col-md-3'>
                <label className='form-label'>
                  Enrollee First Name <span className='text-danger'>*</span>
                </label>
                <input
                  value={fulfilmentPatient.firstName}
                  required={patientFound}
                  className='form-control'
                  type='text'
                  onChange={handleChange('firstName')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>
                  Enrollee Last Name <span className='text-danger'>*</span>
                </label>
                <input
                  value={fulfilmentPatient.lastName}
                  required={patientFound}
                  className='form-control '
                  type='text'
                  onChange={handleChange('lastName')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>
                  Enrollee Code <span className='text-danger'>*</span>
                </label>
                <input
                  value={fulfilmentDraft.hmoId}
                  required
                  className='form-control '
                  type='text'
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <input
                  value={fulfilmentPatient.phoneNumber}
                  maxLength={13}
                  minLength={11}
                  pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                  required={patientFound}
                  className='form-control '
                  type='tel'
                  onChange={handleChange('phoneNumber')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>Enrollee Email</label>
                <input
                  value={fulfilmentPatient.email}
                  className='form-control '
                  type='email'
                  placeholder='name@example.com'
                  onChange={handleChange('email')}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>
                  Enrollee Address <span className='text-danger'>*</span>
                </label>
                <input
                  value={fulfilmentPatient.address}
                  required={patientFound}
                  multiple
                  className='form-control '
                  type='text'
                  onChange={handleChange('address')}
                />
              </div>
            </div>
            <div className={!isSearching ? 'row mt-2' : 'row d-none mt-2'}>
              <div className='col-md-3 form-group'>
                <label className='form-label'>Pharmacy Name</label>
                <Select
                  options={pharmacyListSelect}
                  onChange={selectChange}
                  className='react-select'
                  classNamePrefix='react-select'
                  value={{ label: defaultPharmacy.label, value: defaultPharmacy.value }}
                />
              </div>
              <div className='col-md-3 form-group'>
                <label htmlFor='FulfilmentService' className='form-label'>
                  Fulfilment Service <span className='text-danger'>*</span>
                </label>
                <select
                  required
                  onChange={handleChange('fulfilmentService')}
                  value={fulfilmentPatient.fulfilmentService}
                  className='custom-select '
                >
                  {FulfilmentServiceMap.map((option) => (
                    <option key={option.key} value={option.value.toString()}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3 form-group'>
                <label className='form-label'>PA Code</label>
                <input
                  value={fulfilmentDraft.preAuthorizationCode}
                  className='form-control '
                  type='text'
                  onChange={handleDraftChange('preAuthorizationCode')}
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>
                  Diagnosis <span className='text-danger'>*</span>
                </label>
                <input
                  value={fulfilmentDraft.diagnosis}
                  required
                  className='form-control'
                  type='text'
                  onChange={handleDraftChange('diagnosis')}
                />
              </div>

              <div className='col-md-3'>
                <label className='form-label'>Notes</label>
                <input
                  value={fulfilmentDraft.notes}
                  multiple
                  className='form-control '
                  type='text'
                  onChange={handleDraftChange('notes')}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={!isSearching && !patientFound ? 'col-md-6' : 'd-none'}>
                <label>
                  Personal Information <span className='text-danger'>*</span>
                </label>
                <textarea
                  value={fulfilmentDraft.rawPatientInfo}
                  onChange={handleDraftChange('rawPatientInfo')}
                  className='form-control custom-textarea'
                  placeholder='Enter personal info. Seperate by ; or a new line'
                  rows={7}
                  required={!patientFound}
                ></textarea>
              </div>
              <div className={!isSearching ? 'col-md-6' : 'd-none'}>
                <label>Medication Information</label>
                <textarea
                  value={fulfilmentDraft.rawMedicationInfo}
                  onChange={handleDraftChange('rawMedicationInfo')}
                  className='form-control custom-textarea'
                  placeholder='Enter medication info. Seperate by ; or a new line'
                  required
                  rows={7}
                ></textarea>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-sm-12 mb-3'>
                <textarea
                  rows={7}
                  className='form-control'
                  placeholder='Paste your working text here. Please note, the information in the box is never saved'
                ></textarea>
              </div>
              <div className='col-sm-6 col-md-3'>
                <button className='btn btn-block  btn-primary' type='submit' onClick={submit}>
                  {isLoading ? <ButtonLoader /> : 'Save Draft'}
                </button>
              </div>
              <div className='col-sm-6 col-md-3'>
                <button className='btn btn-block btn-warning' type='reset' onClick={clearForm}>
                  CLEAR
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </Fragment>
  );
};

export default AddFulfilmentDraft;
