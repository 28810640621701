export interface IHealthSendBeneficiary {
  beneficiaryId: string;
  metaData: string;
  notes: string;
  dateCreated: string;
  dateUpdated: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  address: string;
}

export interface ICreateHealthSendBeneficiary {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  address: string;
  gender: string;
  dateOfBirth: string;
  planCode?: string;
}

export interface IHealthSendRequest {
  metaData: string;
  notes: string;
  dateCreated: string;
  dateUpdated: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  countryCode: string;
  email: string;
  transactionReference: string;
  externalId: string;
  partnerCode: string;
  totalAmountSent: number;
  amountFromSender: number;
  amountToBeneficiary: number;
  serviceCharge: number;
  exchangeRate: number;
  currencyCode: string;
  services: string;
  requestDate: string;
}

export interface IHealthSendUser {
  metaData: string;
  notes: string;
  dateCreated: string;
  dateUpdated: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  address: string;
  gender: string;
  dateOfBirth: string;
  externalId: string;
  partnerCode: string;
  totalCredits: number;
  totalDebits: number;
}

export interface ICreateHealthSendRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  countryCode: string;
  externalId: string;
  email: string;
  transactionReference: string;
  totalAmount: number;
  beneficiaries: Array<ICreateHealthSendBeneficiary>;
}

export interface IHealthSendDrug {
  drugId: number;
  enrollmentDrugId: number;
  name: string;
  dose: string;
  frequency: string;
  strength: string;
  duration: string;
  requestId: number;
  isDispensed: boolean;
  cost: number;
  tariffCost: number;
  pharmacyCode: string;
  isPharmacyPaid: boolean;
  useSortingCenter: boolean;
  notes: string;
  tariffDrugName: string;
  unitCost: number;
  quantity: number;
}

export interface IHealthSendTest {
  testId: number;
  enrollmentTestId: number;
  testName: string;
  isCarriedOut: boolean;
  testResult: string;
  cost: number;
  tariffCost: number;
  requestId: number;
  fileName: string;
  notes: string;
  pharmacyCode: string;
  isPharmacyPaid: boolean;
  hasPartnerPaid: boolean;
}

export interface IGetHealthSendClaims {
  claimId: number;
  beneficiaryId: number;
  enrollmentCode: string;
  pharmacyCode: string;
  fulfilmentStatus: string;
  fulfilmentService: string;
  partnerCode: string;
  drugs: Array<IHealthSendDrug>;
  tests: Array<IHealthSendTest>;
}

export interface IPostHealthSendClaims {
  claimId: number;
  beneficiaryId: number;
  enrollmentCode: string;
  pharmacyCode: string;
  fulfilmentStatus: string;
  fulfilmentService: string;
  partnerCode: string;
  drugs: Array<IHealthSendDrug>;
  tests: Array<IHealthSendTest>;
}

export const initialBeneficiary: ICreateHealthSendBeneficiary = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  countryCode: '',
  address: '',
  gender: '-',
  dateOfBirth: '',
};

export const initialRequest: ICreateHealthSendRequest = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  countryCode: '',
  externalId: '',
  email: '',
  transactionReference: '',
  totalAmount: 0,
  beneficiaries: [],
};

export const initialHealthSendClaim: IPostHealthSendClaims = {
  claimId: 0,
  beneficiaryId: 0,
  enrollmentCode: '',
  pharmacyCode: '',
  fulfilmentStatus: '',
  fulfilmentService: '',
  partnerCode: '',
  drugs: [],
  tests: [],
};
