import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { IPartnerDataDTO } from '../../../interface/PartnerInterface';
import { ISubscriptionResponse } from '../../../interface/SubscriptionInterface';
import { ACQUISITION_REASON_MAPPING, SubscriptionDetailsTab } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import NotesModal from '../comms/NotesModal';
import MiscCommunication from './MiscCommunication';
import SubscriptionBeneficiaries from './SubscriptionBeneficiaries';
import SubscriptionClaims from './SubscriptionClaims';
import SubscriptionInfo from './SubscriptionInfo';
import SubscriptionNextOfKin from './SubscriptionNextOfKin';
import SubscriptionPayments from './SubscriptionPayments';

const SubscriptionDetails = () => {
  const auth = useAuth();

  const [subscription, setSubscription] = useState<ISubscriptionResponse | undefined>();
  const [isEditing, setIsEditing] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState<IPartnerDataDTO>({
    partnerCode: '',
    partnerName: '',
  });
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [defaultNoteTags, setDefaultNoteTags] = useState<string | undefined>();

  const [key, setKey] = useState('Info');
  const { subscriptionCode } = useParams() as any;

  const getSubscription = useCallback(async () => {
    if (typeof subscriptionCode === 'undefined' && subscriptionCode === '') {
      addToast('Select a subscription');
      return;
    }
    let url = `${endpoints.InsuranceSubscriptions.mainUrl}/subscriptionCode/${subscriptionCode}`;

    try {
      const response = await axios.get(url);
      const data = response.data as ISubscriptionResponse;
      setSubscription(data);

      //set partner or agent info
      let code = '';
      if (data.partnerCode !== null && data.partnerCode !== '') {
        code = data.partnerCode;
      }
      if (data.acquisitionReason === null || data.acquisitionReason === '') {
        data.acquisitionReason = ACQUISITION_REASON_MAPPING[0].value;
      }
      // if (data.agentCode !== null && data.agentCode !== '') {
      //   code = data.agentCode;
      // }

      //https://reactjs.org/docs/hooks-reference.html#functional-updates
      setPartnerInfo((x) => {
        return { ...x, partnerCode: code };
      });
    } catch (error: any) {
      addToast('Error occured');
    } finally {
    }
  }, [subscriptionCode]);

  const getPatnerDetails = useCallback(async () => {
    if (partnerInfo.partnerCode === '') {
      return;
    }

    //check in session
    const partnerList = sessionStorage.getItem('insurance-partner-list');
    if (partnerList !== null && typeof partnerList !== 'undefined') {
      const partners = JSON.parse(partnerList) as IPartnerDataDTO[];

      const subscriptionPartner = partners.filter((x) => x.partnerCode === partnerInfo.partnerCode);

      if (subscriptionPartner !== null && subscriptionPartner.length > 0) {
        setPartnerInfo(subscriptionPartner[0]);
        return;
      }
    }

    const url = endpoints.InsurancePartners.getPartnerList;
    try {
      const response = await axios.get(url);
      const data = response.data as IPartnerDataDTO[];

      sessionStorage.setItem('insurance-partner-list', JSON.stringify(data));

      const subscriptionPartner = data.filter((x) => x.partnerCode === partnerInfo.partnerCode);

      if (subscriptionPartner !== null && subscriptionPartner.length > 0) {
        setPartnerInfo(subscriptionPartner[0]);
      }
    } catch (error: any) {
      addToast('Error getting list of Partners', 'error');
    }

    //get all partners list
    //store in session
    //
  }, [partnerInfo.partnerCode]);

  const toggleLock = (state: boolean) => {
    setIsEditing(state);
  };

  const toggleNoteModal = (defaultTags?: string) => {
    setShowNoteModal(!showNoteModal);
    setDefaultNoteTags(defaultTags);
  };

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  useEffect(() => {
    getPatnerDetails();
  }, [getPatnerDetails]);

  const reloadNotes = () => {
    setKey('Beneficiaries');
    setKey('Misc');
  };

  let noteModal = (
    <NotesModal
      open={showNoteModal}
      close={toggleNoteModal}
      noteType={'Insurance'}
      noteTypeId={subscriptionCode}
      callback={reloadNotes}
      defaultTags={defaultNoteTags}
    />
  );

  return (
    <div className='mt-5'>
      {noteModal}
      <h4 className='text-center'>
        {subscription?.firstName} {subscription?.lastName} - {subscription?.subscriptionCode}
      </h4>
      <div className='text-center'>{partnerInfo.partnerName}</div>
      <br />
      <div>
        <span className='mr-3 ml-3'>
          <label>Status:</label>
          {isEditing ? (
            <span className='text-danger'>Busy</span>
          ) : (
            <span className='text-success'>Ready</span>
          )}
        </span>

        <span className='mr-3 ml-3'>
          <label className='text-info'>Agent currently working it:</label>
          {auth?.user?.profile.email}
        </span>
        {key !== SubscriptionDetailsTab.misc ? (
          <span className='ml-auto float-right'>
            <button className='btn btn-primary' onClick={() => toggleNoteModal()}>
              Add note
            </button>
          </span>
        ) : (
          ''
        )}
      </div>
      <br />
      <fieldset disabled={isEditing} className='mt-4'>
        <Tabs
          fill
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
        >
          <Tab eventKey={SubscriptionDetailsTab.info} title={SubscriptionDetailsTab.info}>
            <SubscriptionInfo
              subscription={subscription!}
              toggleLock={toggleLock}
              getSubscription={getSubscription}
              tabKey={key}
              openNoteModal={toggleNoteModal}
            />
          </Tab>
          <Tab eventKey={SubscriptionDetailsTab.payments} title={SubscriptionDetailsTab.payments}>
            <SubscriptionPayments
              subscription={subscription!}
              tabKey={key}
              toggleLock={toggleLock}
              getSubscription={getSubscription}
            />
          </Tab>

          <Tab eventKey={SubscriptionDetailsTab.claims} title={SubscriptionDetailsTab.claims}>
            <SubscriptionClaims
              subscription={subscription!}
              tabKey={key}
              toggleLock={toggleLock}
              getSubscription={getSubscription}
            />
          </Tab>
          <Tab
            eventKey={SubscriptionDetailsTab.beneficiaries}
            title={SubscriptionDetailsTab.beneficiaries}
          >
            <SubscriptionBeneficiaries
              subscription={subscription!}
              tabKey={key}
              toggleLock={toggleLock}
              getSubscription={getSubscription}
            />
          </Tab>
          <Tab eventKey={SubscriptionDetailsTab.misc} title={SubscriptionDetailsTab.misc}>
            <MiscCommunication
              subscription={subscription!}
              tabKey={key}
              toggleLock={toggleLock}
              getSubscription={getSubscription}
            />
          </Tab>
          {subscription?.product === 'Cashplus' ? (
            <Tab eventKey={SubscriptionDetailsTab.nextOfKin} title='Next Of Kin'>
              <SubscriptionNextOfKin
                subscription={subscription!}
                tabKey={key}
                toggleLock={toggleLock}
                getSubscription={getSubscription}
              />
            </Tab>
          ) : (
            ''
          )}
        </Tabs>
      </fieldset>
    </div>
  );
};

export default SubscriptionDetails;
