import axios from 'axios';
import { Fragment, useEffect, useState, useContext, useCallback } from 'react';
import {
  IPharmacyListProp,
  IPharmacyWalletDTO,
  pharmacyRating,
} from '../../../interface/PharmacyInterface';
import {
  initialPostClaim,
  initialClaimDrug,
  initialClaimTest,
  IClaimEligibility,
  IPostClaimDTO,
} from '../../../interface/ClaimsInterface';
import { IBeneficiaryListProp } from '../../../interface/BeneficiaryInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { BOOL_MAPPING, FulfilmentStatus, FulfilmentStatusMap } from '../../../utils/mappings';
import Select from 'react-select';
import ButtonLoader from '../../common/ButtonLoader';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import AddDrugModal from './AddDrugModal';
import AddTestModal from './AddTestModal';
import { IAXAClaimDTO, IAXALookupResult } from '../../../interface/AXALookupInterface';

const AddClaimForm = ({ history }: any) => {
  const { appState } = useContext(AppContext);

  const { subscriptionCode } = useParams() as any;
  const { beneficiaryId } = useParams() as any;

  const [pharmacyList, setPharmacyList] = useState<IPharmacyListProp[]>([]);
  const [beneficiaryList, setBeneficiaryList] = useState<IBeneficiaryListProp[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState<IPostClaimDTO>(initialPostClaim);
  const [localSubscriptionCode, setSubscriptionCode] = useState('');
  const [claimAmount, setClaimAmount] = useState(0);
  const [rating, setRating] = useState(pharmacyRating);
  const [claimEligibity, setClaimEligibility] = useState<IClaimEligibility | null>();

  const [drugModalOpen, setDrugModalOpen] = useState(false);
  const [drugModalData, setDrugModalData] = useState(initialClaimDrug);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [testModalData, setTestModalData] = useState(initialClaimTest);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [pharmacyWallet, setPharmacyWallet] = useState<IPharmacyWalletDTO | undefined>();

  const toggleDrugModal = () => {
    setDrugModalOpen(!drugModalOpen);
  };

  const toggleTestModal = () => {
    setTestModalOpen(!testModalOpen);
  };

  const handleDrugModalChange = (input: string) => (event: any) => {
    if (input === 'cost') {
      setDrugModalData({
        ...drugModalData,
        [input]: parseInt(event.target.value),
      });
      return;
    }
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setDrugModalData({
      ...drugModalData,
      [input]: value,
    });
  };

  const getPharmacyWalletData = async (pharmacyCode: string) => {
    if (pharmacyCode === '') {
      addToast('Please select a pharmacy', 'error');
      return;
    }

    let url = `${endpoints.InsurancePharmacy.mainUrl}/${pharmacyCode}`;
    try {
      const response = await axios.get(url);

      setPharmacyWallet(response.data);
    } catch (error: any) {
      console.error(error);
    } finally {
      //setLoading(false);
    }
  };

  const handleTestModalChange = (input: string) => (event: any) => {
    if (input === 'cost') {
      setTestModalData({
        ...testModalData,
        [input]: parseInt(event.target.value),
      });
    }

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setTestModalData({
      ...testModalData,
      [input]: value,
    });
  };

  const addDrugToClaim = () => {
    if (drugModalData.name === '') {
      addToast('Please add a drug', 'warning');
      return;
    }

    //if already added, skip
    let isExisting = values.drugs.find((x) => x.localId === drugModalData.localId);

    if (isExisting) {
      return;
    }

    try {
      const savedDrugs = values.drugs;
      savedDrugs.push(drugModalData);
      setValues({
        ...values,
        drugs: savedDrugs,
      });
      addToast('Drug added to claim', 'success');
      // clearDrug();
    } catch (error: any) {
      addToast('Unable to add drug to claim', 'error');
    }
  };

  const addTestToClaim = () => {
    if (testModalData.testName === '') {
      addToast('Please add a drug', 'warning');
      return;
    }
    //if already added, skip
    let isExisting = values.tests.find((x) => x.localId === testModalData.localId);

    if (isExisting) {
      return;
    }
    try {
      const savedTests = values.tests;
      savedTests.push(testModalData);
      setValues({
        ...values,
        tests: savedTests,
      });
      addToast('Test added to claim', 'success');
      //  clearTest();
    } catch (error: any) {
      addToast('Unable to add test to claim', 'error');
    }
  };

  const drugModalSelectChange = (selectedValue: any) => {
    setDrugModalData({
      ...drugModalData,
      localId: selectedValue.id,
      name: selectedValue.label,
      cost: selectedValue.value,
    });
  };

  const testModalSelectChange = (selectedValue: any) => {
    setTestModalData({
      ...testModalData,
      localId: selectedValue.id,
      testName: selectedValue.label,
      cost: selectedValue.value,
    });
  };

  // const clearDrug = () => {
  //   setDrugModalData(initialClaimDrug);
  // };

  // const clearTest = () => {
  //   setTestModalData(initialClaimTest);
  // };

  const deleteDrug = (index: number) => {
    const listOfDrugs = values.drugs.filter((x) => x.localId !== index);
    if (listOfDrugs.length === 0) {
      listOfDrugs.length = 0;
    }
    setValues({
      ...values,
      drugs: listOfDrugs,
    });
  };

  const deleteTest = (index: number) => {
    const listOfTests = values.tests.filter((x) => x.localId !== index);
    setValues({
      ...values,
      tests: listOfTests,
    });
  };

  const handleChange = (input: any) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (input === 'beneficiaryId') {
      setValues({
        ...values,
        beneficiaryId: value,
      });
      getClaimEligibility(value);
    }

    let instantPay = values.payInstantly;

    if (input === 'claimStatus') {
      if (value === FulfilmentStatus.Approved || value === FulfilmentStatus.Completed) {
        setShowCheckbox(true);
        getPharmacyWalletData(values!.pharmacyCode);
      } else {
        setShowCheckbox(false);
        instantPay = false;
      }
    }
    if (input === 'payInstantly') {
      instantPay = value;
      setValues({
        ...values,
        payInstantly: instantPay,
      });
      return;
    }

    setValues({
      ...values,
      [input]: value,
      payInstantly: instantPay,
    });
  };

  const clearForm = () => {
    // e.preventDefault();

    setLoading(false);
    setClaimAmount(0);
    values.drugs.length = 0;
    values.tests.length = 0;

    setValues(initialPostClaim);

    setRating(pharmacyRating);
  };

  const postClaim = async (event: any) => {
    let url = endpoints.InsuranceClaims.mainUrl;
    values.subscriptionCode = localSubscriptionCode;
    // values.pharmacyId = pharmacyId;
    values.rating = rating;

    let payload: any = values;

    if (values.claimProvider === 'AXA') {
      // postAXAClaim();
      values.subscriptionCode = appState.trackingNumber;
      values.beneficiaryId = appState.trackingNumber;
      //
      const axaSubscription = appState.axaLookupData as IAXALookupResult;
      const axaPayload: IAXAClaimDTO = {
        firstName: axaSubscription.firstName,
        lastName: axaSubscription.lastName,
        location: axaSubscription.address,
        emailAddress: axaSubscription.emailAddress,
        phoneNumber: axaSubscription.phoneNumber,
        drugs: values.drugs,
        tests: values.tests,
        trackingNumber: appState.trackingNumber,
        pharmacyCode: values.pharmacyCode,
        pharmacyName: values.pharmacyName,
        claimStatus: values.claimStatus,
        claimDate: values.claimDate,
        notes: values.notes,
      };

      payload = axaPayload;
      url = `${url}/axa`;
    }

    if (!isFormValidated('claims-form')) {
      addToast('Please fill all required fields and select pharmacy/beneficiary', 'warning');
      return;
    }
    event.preventDefault();

    if (values.pharmacyCode === '') {
      addToast('Please select pharmacy/beneficiary', 'warning');
      return;
    }

    event.preventDefault();

    if (values.drugs.length === 0 && values.tests.length === 0) {
      addToast('Please added atleast one test and one drug', 'warning');
      return;
    }

    event.preventDefault();

    setLoading(true);

    if (!claimEligibity?.eligible) {
      values.notes += `. Claims is not eligible. Reason: ${claimEligibity?.reason}`;
      return;
    }

    //check if wefill
    var isWefill = document.getElementById('send-to-fill') as HTMLInputElement;

    var wefillValue = isWefill.checked;

    if (wefillValue) {
      url = `${endpoints.InsuranceClaims.mainUrl}?isWefill=true`;
      if (values.claimProvider === 'AXA') {
        url = `${endpoints.InsuranceClaims.mainUrl}/axa?isWefill=true`;
      }
    }

    try {
      await axios.post(url, payload);

      addToast('Claim successfully added', 'success');
      clearForm();
      getClaimEligibility(values.beneficiaryId);
    } catch (error: any) {
      if (error.response.status === 422) {
        addToast(
          'An error occured when saving the claim. Check that subscription is valid and Active',
          'error'
        );
      } else {
        addToast('An error occured when saving the claim', 'error');
      }
      setLoading(false);
    }
  };

  const selectChange = (selectedValue: any) => {
    setValues({
      ...values,
      pharmacyCode: selectedValue.value,
      pharmacyName: selectedValue.label,
    });
    getPharmacyWalletData(selectedValue.value);
  };

  const getClaimEligibility = useCallback(
    async (beneficiaryId: number) => {
      //if axa, claim is eligible

      if (subscriptionCode === 'axa') {
        const axaClaimsEligibility: IClaimEligibility = {
          eligible: true,
          reason: 'Axa claim',
        };

        setClaimEligibility(axaClaimsEligibility);

        return;
      }

      setClaimEligibility(null);

      const url = `${endpoints.InsuranceClaims.mainUrl}/claimeligibility/${subscriptionCode}/beneficiary/${beneficiaryId}`;

      try {
        const response = await axios.get(url);
        setClaimEligibility(response.data);
      } catch (error: any) {
        console.error(error);
      }
    },
    [subscriptionCode]
  );

  const goToPage = async (event: any) => {
    event.preventDefault();
    history.push(`/subscriptions/${subscriptionCode}/${beneficiaryId}/claims/hospicash/add`);
  };

  const getBeneficiaryList = useCallback(async () => {
    if (subscriptionCode === 'axa') {
      //values.claimProvider = 'AXA';
      setValues({
        ...values,
        claimProvider: 'AXA',
      });
      return;
    }

    //https://reactrouter.com/web/example/url-params
    //we put the setstate here to ensure subscription code is obtained on load
    setSubscriptionCode(subscriptionCode);

    if (beneficiaryList.length === 0) {
      try {
        const response = await axios.get(
          endpoints.InsuranceBeneficiary.getBeneficiaryListByCode + subscriptionCode
        );
        const beneficiaryData = response.data.map((x: any) => {
          return {
            value: x.insuranceBeneficiaryId,
            label: x.firstName + ' ' + x.lastName,
          };
        });
        setBeneficiaryList(beneficiaryData);

        //to reset array of drugs on page load
        values.drugs.length = 0;
        values.tests.length = 0;

        setValues({
          ...values,
          beneficiaryId: beneficiaryData[0].value,
        });
      } catch (error: any) {
        addToast('An error occured while loading list of beneficiaries', 'error');
      }
    }
  }, [subscriptionCode, beneficiaryList.length]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getBeneficiaryList();
  }, [getBeneficiaryList]);

  useEffect(() => {
    const getPharmacies = async () => {
      try {
        const response = await axios.get(endpoints.InsurancePharmacy.getPharmacyList);
        const pharmacyData = response.data.map((x: any) => {
          return {
            value: x.pharmacyCode,
            label: `${x.pharmacyName} ${x.address}`,
          };
        });
        setPharmacyList(pharmacyData);

        // let firstPharmacy = pharmacyData[0];

        // setValues((f) => {
        //   return { ...f, pharmacyCode: firstPharmacy.value, pharmacyName: firstPharmacy.label };
        // });
      } catch (error: any) {
        addToast('An error occured while loading list of pharmacies', 'error');
      }
    };
    getPharmacies();
  }, []);

  useEffect(() => {
    const calculateTotalCost = () => {
      const drugCost = values.drugs.reduce((total, drug) => {
        return total + parseInt(drug.cost.toString());
      }, 0);

      const testCost = values.tests.reduce((total, test) => {
        return total + parseInt(test.cost.toString());
      }, 0);

      const claimAmount = drugCost + testCost;
      setClaimAmount(claimAmount);
    };
    calculateTotalCost();
  }, [values.drugs, values.tests, values.drugs.length, values.tests.length]);

  useEffect(() => {
    getClaimEligibility(beneficiaryId);
  }, [getClaimEligibility, beneficiaryId]);

  let drugModal = (
    <AddDrugModal
      open={drugModalOpen}
      close={toggleDrugModal}
      data={drugModalData}
      handleChange={handleDrugModalChange}
      addToClaim={addDrugToClaim}
      selectChange={drugModalSelectChange}
      drugList={values.drugs}
      deleteDrug={deleteDrug}
    />
  );

  let testModal = (
    <AddTestModal
      open={testModalOpen}
      close={toggleTestModal}
      data={testModalData}
      handleChange={handleTestModalChange}
      addToClaim={addTestToClaim}
      selectChange={testModalSelectChange}
      testList={values.tests}
      deleteTest={deleteTest}
    />
  );

  return (
    <Fragment>
      {drugModal}
      {testModal}
      <div className='container'>
        <h4 style={{ textAlign: 'center', color: '#000000' }}>Add Claims</h4>
        <br />
        <div>
          Or Click
          <button className='ml-2 mr-2 btn btn-primary btn-sm' onClick={goToPage}>
            here
          </button>
          to add Hospicash Claims
        </div>
        <br />
        {/* <div>
          Claim Eligibility:
          {claimEligibity?.eligible ? (
            <span className='text-success'>Eligble</span>
          ) : (
            <span className='text-danger'>Ineligible</span>
          )}
        </div>
        <div>
          Reason:
          <span className={claimEligibity?.eligible ? 'text-success' : 'text-danger'}>
            {claimEligibity?.reason}
          </span>
        </div> */}
        <br />
        <form id='claims-form'>
          <fieldset disabled={!claimEligibity?.eligible || isLoading ? true : false}>
            <div className='row'>
              <div className='col-md-3'>
                <label className='form-label'>Subscription Code</label>
                <input
                  value={localSubscriptionCode}
                  required
                  className='form-control '
                  type='text'
                  disabled
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Beneficiary making claim</label>

                <select
                  value={values.beneficiaryId}
                  required={values.claimProvider === 'AXA' ? false : true}
                  className='custom-select'
                  onChange={handleChange('beneficiaryId')}
                  disabled={values.claimProvider === 'AXA' ? true : false}
                >
                  {beneficiaryList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Date of Claim</label>
                <input
                  value={values.claimDate}
                  required
                  className='form-control '
                  type='date'
                  onChange={handleChange('claimDate')}
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Claim Provider</label>
                <select
                  value={values.claimProvider}
                  required
                  disabled
                  className='form-control '
                  onChange={handleChange('claimProvider')}
                >
                  <option value='Wellahealth'>WELLAHEALTH</option>
                  <option value='AXA'>AXA</option>
                </select>
              </div>
            </div>
            <br />
            <br />
            <div className='row'>
              <div className='col-md-3'>
                <label className='form-label'>Claim Status?</label>
                <select
                  value={values.claimStatus}
                  required
                  className='custom-select'
                  onChange={handleChange('claimStatus')}
                >
                  {FulfilmentStatusMap.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Pharmacy Name</label>
                <Select
                  options={pharmacyList}
                  onChange={selectChange}
                  className='react-select'
                  classNamePrefix='react-select'
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Set as primary pharmacy</label>
                <select
                  value={values.setPrimaryPharmacy.toString()}
                  required
                  className='custom-select'
                  onChange={handleChange('setPrimaryPharmacy')}
                  disabled={values.claimProvider === 'AXA' ? true : false}
                >
                  {BOOL_MAPPING.map((option) => (
                    <option key={option.key} value={option.value.toString()}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3'>
                <label className='form-label'>NPS (0 - 10)</label>
                <input
                  value={values.nps}
                  className='form-control '
                  type='number'
                  required
                  onChange={handleChange('nps')}
                  min={0}
                  max={10}
                />
              </div>
            </div>
            <br />
            <br />
            <div className='row'>
              <div className='col-md-3'>
                <label className='form-label'>Total Claim Amount</label>
                <input
                  value={claimAmount}
                  className='form-control '
                  type='number'
                  required
                  min={0}
                  step={0.01}
                  disabled
                />
              </div>
              <div className='col-md-3'>
                <label className='form-label'>Notes</label>
                <input
                  value={values.notes}
                  className='form-control '
                  type='text'
                  aria-multiline
                  onChange={handleChange('notes')}
                />
              </div>

              <div className='col-md-3'>
                <label className='form-label'>.</label>
                <div>
                  <button
                    className='btn btn-info btn-block'
                    type='button'
                    onClick={toggleDrugModal}
                  >
                    Drugs - ({values.drugs.length})
                  </button>
                </div>
              </div>

              <div className='col-md-3'>
                <label className='form-label'>.</label>
                <div>
                  <button
                    className='btn btn-block btn-warning'
                    type='button'
                    onClick={toggleTestModal}
                  >
                    Tests - ({values.tests.length})
                  </button>
                </div>
              </div>

              {showCheckbox ? (
                <div className='col-md-12 mt-3'>
                  {pharmacyWallet ? (
                    <div>
                      {pharmacyWallet?.payInstantly ? (
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            checked={values.payInstantly}
                            onChange={handleChange('payInstantly')}
                            type='checkbox'
                            value=''
                            id='instant-pay'
                          />

                          <label className='form-check-label' htmlFor='instant-pay'>
                            Pay Pharmacy Instantly
                          </label>
                        </div>
                      ) : (
                        <span className='text-info '>
                          Instant Payment not available for this pharmacy
                        </span>
                      )}
                      <div>
                        {pharmacyWallet.accountId === null || pharmacyWallet.accountId === '' ? (
                          <div>
                            {pharmacyWallet.pharmacyName}
                            <span className='text-danger'>: does not have wallet.</span>
                          </div>
                        ) : (
                          <div>
                            {pharmacyWallet.pharmacyName}
                            <span className='text-success'>
                              : has wallet. Please be sure that this is the correct pharmacy
                            </span>
                          </div>
                        )}
                        <div className='text-danger mt-2'>
                          You are about to pay {pharmacyWallet.pharmacyName} the sum of ₦
                          {claimAmount} for this claim. Please confirm before proceeding.
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='text-info'>Checking pharmacy wallet detail...</div>
                  )}
                </div>
              ) : (
                ''
              )}

              <div className='col-md-3 mt-4'>
                <label className='form-label'>Send to Wefill ?</label>
                <input
                  type='checkbox'
                  id='send-to-fill'
                  className='ml-4'
                  style={{ width: '15px', height: '15px' }}
                />
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-4'>
                <button className='btn btn-block btn-primary' type='submit' onClick={postClaim}>
                  SUBMIT
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
              <div className='col-md-4'>
                <button className='btn btn-block btn-danger' type='reset' onClick={clearForm}>
                  CLEAR
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </Fragment>
  );
};

export default AddClaimForm;
