import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { IPriceListUpdate, priceListFormData } from '../../../interface/PriceListInterface';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { isFormValidated } from '../../../utils/formUtils';
import Select from 'react-select';
import { PricelistFulfilmentServiceMap } from '../../../utils/mappings';
import './styles.css';
import { IFulfilmentPartners } from '../../../interface/FulfilmentPartnersInterface';
import {
  DosageForms,
  DrugClass,
  SortingCenterPharmacy,
  SortingCenterPharmacyName,
} from '../../../utils/constants';
import { useParams } from 'react-router-dom';

const PriceListingForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState(priceListFormData);
  const [partnersListSelect, setPartnersListSelect] = useState<IFulfilmentPartners[]>([]);
  const [newPartnerCode, setNewPartnerCode] = useState(priceListFormData.source);
  const { pricelistId } = useParams() as any;

  const getPartnersList = async () => {
    let url = endpoints.FulfilmentPartners.mainUrl;

    try {
      const response = await axios.get(url);
      let selectData = response.data.map((val: IFulfilmentPartners) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      const sortingCenter = {
        partnerCode: SortingCenterPharmacy,
        partnerName: SortingCenterPharmacyName,
      };
      selectData.push(sortingCenter);

      //set to state
      setPartnersListSelect(selectData);

      //save to local
      //TODO: save to context
      sessionStorage.setItem('partnersList', JSON.stringify(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const selectPartnerCodeChange = (selectedValue: any) => {
    setNewPartnerCode(selectedValue.value);
  };

  const submit = async (e: React.FormEvent) => {
    if (!isFormValidated('add-pricelist-form')) {
      return;
    }
    e.preventDefault();

    if (!isEdit && newPartnerCode === '') {
      addToast('Select a partner', 'error');
    }

    const payload = formData;
    if (!isEdit) {
      payload.source = newPartnerCode;
    }

    if (formData.newDrugName.trim() === '') {
      payload.newDrugName = `${formData.brandName} ${formData.dosageForm} ${formData.strength}`;
    }

    try {
      setLoading(true);
      const url = `${endpoints.Pricelist.mainUrl}/partner`;
      if (isEdit) {
        await axios.put(url, payload);
        addToast('Price List saved successfully', 'success');
        return;
      }
      await axios.post(url, payload);
      addToast('Price List saved successfully', 'success');
      clearForm();
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setFormData({
      ...formData,
      [input]: event.target.value,
    });
  };

  const clearForm = () => {
    setLoading(false);
    setFormData({ ...priceListFormData });
  };

  const getPricelist = async (pricelistId: number) => {
    const url = `${endpoints.Pricelist.mainUrl}/${pricelistId}`;

    try {
      const response = await axios.get(url);
      const data = response.data as IPriceListUpdate;
      setFormData(data);
      setIsEdit(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const savedPartnersList = sessionStorage.getItem('partnersList');

    if (savedPartnersList !== null) {
      const partnersList = JSON.parse(savedPartnersList);
      let selectData = partnersList.map((val: { partnerName: string; partnerCode: string }) => {
        return {
          label: val.partnerName,
          value: val.partnerCode,
        };
      });
      //set to state
      const sortingCenter = {
        partnerCode: SortingCenterPharmacy,
        partnerName: SortingCenterPharmacyName,
      };

      selectData.push(sortingCenter);
      setPartnersListSelect(selectData);
    } else {
      //call api
      getPartnersList();
    }
  }, []);

  //check if editing or adding
  useEffect(() => {
    //if we have a pricelist id, then we editing, so we load it?
    if (typeof pricelistId !== 'undefined' && pricelistId !== null) {
      getPricelist(pricelistId);
    }
  }, [pricelistId]);

  useEffect(() => {
    const drugPrice = formData.drugPrice ?? 0;
    const packSize = formData.packSize ?? 1;

    const unitPrice = parseFloat((drugPrice / packSize).toFixed(2));

    setFormData((f) => {
      return { ...f, unitPrice: unitPrice };
    });
  }, [formData.drugPrice, formData.packSize]);

  // useEffect(() => {
  //   const newDrugName = `${formData.brandName} ${formData.dosageForm} ${formData.strength} `;

  //   if (!isEdit) {
  //     setFormData((f) => {
  //       return { ...f, newDrugName: newDrugName };
  //     });
  //   } else {
  //     console.log('here');
  //     if (existingName.trim() !== '' && formData.newDrugName.trim() !== '') {
  //       console.log('nope');
  //       return;
  //     }
  //     console.log('do');
  //     setFormData((f) => {
  //       return { ...f, newDrugName: newDrugName };
  //     });
  //   }
  // }, [formData.strength, formData.brandName, formData.dosageForm]);

  return (
    <Fragment>
      <div className='row justify-content-center'>
        <form id='add-pricelist-form' className='col-md-10 col-lg-9 col-xl-7'>
          <h4 className='text-center'>{isEdit ? `Editing ${formData.drugName}` : 'Add Tariff'}</h4>
          <fieldset className='row mt-5' id='form-fields' disabled={isLoading ? true : false}>
            <div className='form-group col-12'>
              <label htmlFor='drugName' className='form-label'>
                Drug Name (as sent by partner)
              </label>
              <input
                value={formData.drugName}
                className='form-control '
                type='text'
                required
                onChange={handleChange('drugName')}
              />
            </div>
            <br />

            <div className='form-group col-sm-12 mt-2'>
              <label className='form-label'>Generic Name</label>
              <input
                value={formData.genericName}
                className='form-control '
                type='text'
                required
                onChange={handleChange('genericName')}
              />
            </div>
            <div className='form-group col-sm-12 mt-2'>
              <label className='form-label'>Brand Name</label>
              <input
                value={formData.brandName}
                className='form-control '
                type='text'
                onChange={handleChange('brandName')}
                required
              />
            </div>
            <div className='form-group col-sm-8 mt-2'>
              <label className='form-label'>Drug Class</label>
              {/* <input
                value={formData.drugClass}
                className='form-control '
                type='text'
                onChange={handleChange('drugClass')}
                required
              /> */}
              <select
                value={formData.drugClass}
                className='form-control '
                onChange={handleChange('drugClass')}
                required
              >
                {DrugClass.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group col-sm-4 mt-2'>
              <label className='form-label'>Dosage Form</label>
              <select
                value={formData.dosageForm}
                className='form-control '
                onChange={handleChange('dosageForm')}
                required
              >
                {DosageForms.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group col-sm-4 mt-2'>
              <label className='form-label'>Strength</label>
              <input
                value={formData.strength}
                className='form-control '
                type='text'
                onChange={handleChange('strength')}
                required
              />
            </div>

            <div className='form-group col-sm-4 mt-2'>
              <label className='form-label'>Drug Price</label>
              <input
                value={formData.drugPrice}
                className='form-control '
                type='number'
                onChange={handleChange('drugPrice')}
                min={1}
                step={0.01}
                required
              />
            </div>
            <div className='form-group col-sm-4 mt-2'>
              <label className='form-label'>Pack Size</label>
              <input
                value={formData.packSize}
                className='form-control '
                type='number'
                onChange={handleChange('packSize')}
                min={1}
                step={1}
                required
              />
            </div>
            <div className='form-group col-sm-4 mt-2'>
              <label className='form-label'>Unit Price</label>
              <input
                value={formData.unitPrice}
                className='form-control '
                type='number'
                readOnly
                min={1}
                step={1}
                disabled
              />
            </div>
            {!isEdit ? (
              <div className='form-group col-sm-8 mt-2'>
                <label htmlFor='partnerName' className='form-label'>
                  Select Partner Name
                </label>
                <Select
                  options={partnersListSelect}
                  onChange={selectPartnerCodeChange}
                  className='react-select'
                  classNamePrefix='react-select'
                />
              </div>
            ) : (
              ''
            )}
            <br />

            <div className='form-group col-sm-6 mt-2'>
              <label htmlFor='fulfilmentService' className='form-label'>
                Fulfilment Service
              </label>
              <select
                required
                onChange={handleChange('services')}
                value={formData.services}
                className='custom-select'
              >
                <option value=''></option>
                {PricelistFulfilmentServiceMap.map((option) => (
                  <option key={option.key} value={option.value.toString()}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group col-sm-12 mt-2'>
              <label className='form-label'>New name (Brand Name + Form + Strength) </label>
              <input
                value={formData.newDrugName}
                className='form-control '
                type='text'
                onChange={handleChange('newDrugName')}
              />
              <small>
                This will be used if value is provided:
                <span className='ml-2 text-danger'>
                  {formData.brandName} {formData.dosageForm} {formData.strength}
                </span>
              </small>
            </div>
            <div className='form-group col-sm-12 mt-2'>
              <label htmlFor='notes' className='form-label'>
                Notes
              </label>
              <input
                value={formData.notes}
                className='form-control '
                type='text'
                onChange={handleChange('notes')}
              />
            </div>
            <br />
            <div className='form-group col-sm-12 mt-2 mt-3'>
              <button type='submit' className='btn btn-primary btn-block' onClick={submit}>
                SUBMIT
                {isLoading ? <ButtonLoader /> : ''}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </Fragment>
  );
};

export default PriceListingForm;
