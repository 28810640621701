export interface IPharmacyRating {
  ratingId?: number;
  communication: number;
  customerService: number;
  pharmaceuticalCare: number;
  payment: number;
  nps: number;
  serviceType: string;
  pharmacyCode?: string;
  serviceId?: number;
  dateCreated?: string;
  dateUpdated?: string;
}

export interface IPharmacyRatingDTO {
  communication: number;
  customerService: number;
  pharmaceuticalCare: number;
  payment: number;
  nps: number;
  serviceType: string;
}

export interface IUpdatePharmacyRatingDTO extends IPharmacyRatingDTO {
  serviceId: number;
  pharmacyCode: string;
}
export interface IPharmacyRatingData {
  serviceId: number;
  pharmacyCode: string;
  pharmacyName: string;
  serviceType: 'Claims' | 'Fulfilment';
}

export interface IEditPharmacyRating {
  open: boolean;
  close: () => void;
  data: IPharmacyRatingData;
  keepOpen: boolean;
  // loadClaims: () => void;
}

//Initializations

export const initialRatingUpdate: IUpdatePharmacyRatingDTO = {
  communication: 0,
  customerService: 0,
  pharmaceuticalCare: 0,
  payment: 0,
  nps: 0,
  serviceType: 'Claims',
  serviceId: 0,
  pharmacyCode: '',
};
