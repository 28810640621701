import React, { useState, useCallback, useEffect, useContext } from 'react';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import {
  initialWalletAccountDTOData,
  IWalletAccountDTO,
} from '../../../interface/WalletsInterface';
import AddEditWalletAccountModal from './AddEditWalletAccountModal';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';

const WalletAccountsTable = () => {
  const [allData, setAllData] = useState<IWalletAccountDTO[]>([]);
  const [tableData, setTableData] = useState<IWalletAccountDTO[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [modalData, setModalData] = useState(initialWalletAccountDTOData);

  const { dispatch } = useContext(AppContext);
  const history = useHistory();

  const closeModal = () => {
    setModalOpen(false);
    loadWallets();
  };

  const openModal = (data: IWalletAccountDTO, edit: boolean, newPharm: boolean) => {
    setEdit(edit);
    setModalData(data);
    setModalOpen(true);
  };

  // const changeModalData = (input: string) => (event: any) => {
  //   setModalData({
  //     ...modalData,
  //     [input]: event.target.value
  //   })
  // }

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setTableData(allData);
      return;
    }

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.bankAccountName?.toLowerCase().includes(searchParam) ||
        x.bankAccountNumber?.toLowerCase().includes(searchParam) ||
        x.bankName?.toLowerCase().includes(searchParam) ||
        x.providerName?.toLowerCase().includes(searchParam)
    );

    //set table data to search result
    setTableData(searchResult);
  };

  const viewTransactions = async (wallet: IWalletAccountDTO, viewType = 1) => {
    //push to transactions

    dispatch({
      type: 'SET_PROPERTY_STATE',
      propName: 'selectedWallet',
      propData: wallet,
    });

    let url = `/wallets/${wallet.accountId}/transactions`;
    if (viewType === 2) {
      url = `/wallets/${wallet.accountId}/payouts`;
    }
    history.push(url);
  };

  const loadWallets = useCallback(async () => {
    const url = `${endpoints.WalletAccounts.mainUrl}?pageSize=3000`;

    try {
      const response = await axios.get(url);
      setTableData(response.data.data);
      setAllData(response.data.data);
    } catch (error) {
      console.error(error);
      addToast('Error fetching wallets', 'error');
    }
  }, []);

  useEffect(() => {
    loadWallets();
  }, [loadWallets]);

  let addEditWalletModal = (
    <AddEditWalletAccountModal
      open={isModalOpen}
      close={closeModal}
      modalData={modalData}
      edit={edit}
      newPharmacy={false}
    />
  );

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Pharmacy Code</th>
        <th scope='col'>Pharmacy Name</th>
        <th scope='col'>Balance (₦)</th>
        <th scope='col'>Discount (%)</th>
        <th scope='col'>Account Number</th>
        <th scope='col'>Account Name</th>
        <th scope='col'>Bank Name</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((wallet: IWalletAccountDTO) => {
        return (
          <tr key={wallet.providerCode}>
            <td>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => openModal(wallet, true, false)}
                className='bi bi-pencil-square group-icon'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Edit'
              ></i>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => viewTransactions(wallet, 1)}
                className='bi bi-clock-history group-icon'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Transactions'
              ></i>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => viewTransactions(wallet, 2)}
                className='bi bi-send-check group-icon'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Payouts'
              ></i>
            </td>
            <td>{wallet.providerCode}</td>
            <td>{wallet.providerName}</td>
            <td>{wallet.walletBalance.toLocaleString()}</td>
            <td>{wallet.discount}</td>
            <td>{wallet.bankAccountNumber}</td>
            <td>{wallet.bankAccountName}</td>
            <td>{wallet.bankName}</td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div>
      {addEditWalletModal}
      <div className='d-flex'>
        <h3 className='mr-auto'>Wallet Accounts</h3>
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='form-inline'>
            <input
              type='search'
              className='form-control'
              placeholder='Search...'
              onChange={searchTable}
              id='sub-search-param'
            />
          </div>
        </div>
        <div className='col-6 text-right'>
          <button
            onClick={() => openModal(initialWalletAccountDTOData, false, true)}
            className='btn btn-primary'
          >
            Add Account
          </button>
        </div>
      </div>
      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default WalletAccountsTable;
